import React from 'react';

import Grid from '@material-ui/core/Grid';

import { AimTypography } from '../../atoms';

import { theme } from '../../../theme';

const AbstractInfo = ({ data, i18n }) => {
  const InfoRow = ({ title, value }) => {
    return (
      <Grid
        container
        style={{ backgroundColor: theme.colors.greyScale.grey2, margin: 1 }}
      >
        <Grid item xs={4}>
          <AimTypography variant="textBold">{title}</AimTypography>
        </Grid>
        <Grid item xs={8}>
          <AimTypography variant="h4regular" textAlign="right">
            {value}
          </AimTypography>
        </Grid>
      </Grid>
    );
  };

  const tagsList = () => {
    const list = data?.tags?.map((i) => i).join(', ');
    return list;
  };

  const keywordsList = () => data?.keywords?.map((i) => i).join(', ');

  return (
    <Grid container>
      <Grid item xs={6}>
        <InfoRow
          title={i18n.page.abstractInfo.category}
          value={data?.category?.name || '-'}
        />
      </Grid>
      <Grid item xs={6}>
        <InfoRow
          title={i18n.page.abstractInfo.status}
          value={data?.status || '-'}
        />
      </Grid>
      <Grid item xs={6}>
        <InfoRow
          title={i18n.page.abstractInfo.subCategory}
          value={data?.subCategory?.name || '-'}
        />
      </Grid>
      <Grid item xs={6}>
        <InfoRow title={i18n.page.abstractInfo.tag} value={tagsList() || '-'} />
      </Grid>
      <Grid item xs={6}>
        <InfoRow
          title={i18n.page.abstractInfo.presentationPreferred}
          value={
            data?.acceptedPresentationTypology?.name ||
            data?.proposedPresentationTypology?.name ||
            '-'
          }
        />
      </Grid>
      <Grid item xs={6}>
        <InfoRow
          title={i18n.page.abstractInfo.keywords}
          value={keywordsList() || '-'}
        />
      </Grid>
    </Grid>
  );
};

export default AbstractInfo;
