import React, { useState, useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';

import { styled } from './../styled';
import { theme } from './../../theme';
import { appState, constants } from '@aim/common';

const variantsBACKEND = {
  yellow: {
    border: '2px solid ' + theme.colors.primary.yellow,
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  black: {
    border: '2px solid ' + theme.colors.primary.black,
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  grey: {
    border: '2px solid ' + theme.colors.greyScale.grey3,
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.grey3,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  lightBlue: {
    border: '2px solid ' + theme.colors.secondary.lightBlue,
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  red: {
    border: '2px solid ' + theme.colors.secondary.red,
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  green: {
    border: '2px solid ' + theme.colors.secondary.green,
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  violet: {
    border: '2px solid ' + theme.colors.secondary.violet,
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  blue: {
    border: '2px solid ' + theme.colors.secondary.blue,
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  none: {
    border: '2px solid transparent',
    color: theme.colors.primary.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  yellowFill: {
    background: theme.colors.light.yellow,
    color: theme.colors.primary.yellow,
    '&:hover': {
      color: theme.colors.dark.yellow,
      background: theme.colors.light.yellow,
    },
    '&:disabled': {
      color: theme.colors.primary.yellow,
      background: theme.colors.light.yellow + ' !important',
      opacity: 0.4,
    },
  },
  greyFill: {
    background: theme.colors.light.grey,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      color: theme.colors.dark.grey,
      background: theme.colors.light.grey,
    },
    '&:disabled': {
      color: theme.colors.primary.black,
      background: theme.colors.light.grey + ' !important',
      opacity: 0.4,
    },
  },
  lightBlueFill: {
    background: theme.colors.light.lightBlue,
    color: theme.colors.secondary.lightBlue,
    '&:hover': {
      color: theme.colors.dark.lightBlue,
      background: theme.colors.light.lightBlue,
    },
    '&:disabled': {
      color: theme.colors.secondary.lightBlue,
      background: theme.colors.light.lightBlue + ' !important',
      opacity: 0.4,
    },
  },
  redFill: {
    background: theme.colors.light.red,
    color: theme.colors.secondary.red,
    '&:hover': {
      color: theme.colors.dark.red,
      background: theme.colors.light.red,
    },
    '&:disabled': {
      color: theme.colors.secondary.red,
      background: theme.colors.light.red + ' !important',
      opacity: 0.4,
    },
  },
  greenFill: {
    background: theme.colors.light.green,
    color: theme.colors.secondary.green,
    '&:hover': {
      color: theme.colors.dark.green,
      background: theme.colors.light.green,
    },
    '&:disabled': {
      color: theme.colors.secondary.green,
      background: theme.colors.light.green + ' !important',
      opacity: 0.4,
    },
  },
  violetFill: {
    background: theme.colors.light.violet,
    color: theme.colors.secondary.violet,
    '&:hover': {
      color: theme.colors.dark.violet,
      background: theme.colors.light.violet,
    },
    '&:disabled': {
      color: theme.colors.secondary.violet,
      background: theme.colors.light.violet + ' !important',
      opacity: 0.4,
    },
  },
  blueFill: {
    background: theme.colors.light.blue,
    color: theme.colors.secondary.blue,
    '&:hover': {
      color: theme.colors.dark.blue,
      background: theme.colors.light.blue,
    },
    '&:disabled': {
      color: theme.colors.secondary.blue,
      background: theme.colors.light.blue + ' !important',
      opacity: 0.4,
    },
  },
};

const buildButtonStyle = (eventConfiguration, variant, color, borderRadius) => {
  switch (variant) {
    case constants.TemplateButtonVariants.OUTLINED:
      return {
        borderRadius,
        background: theme.colors.primary.white,
        border: '2px solid ' + color,
        color: theme.colors.primary.black,
        '&:hover': {
          borderRadius: borderRadius,
          border: '2px solid ' + color,
          background: theme.colors.primary.white,
          color: theme.colors.primary.black,
        },
        '&:disabled': {
          borderRadius,
          border: '2px solid ' + theme.colors.greyScale.grey3,
          color: theme.colors.greyScale.grey3,
          background: theme.colors.primary.white,
        },
      };
    case constants.TemplateButtonVariants.CONTAINED:
      return {
        borderRadius,
        background: color,
        color: theme.colors.primary.white,
        '&:hover': {
          borderRadius,
          background: color,
          color: theme.colors.primary.white,
        },
        '&:disabled': {
          color: theme.colors.greyScale.grey3,
          background: theme.colors.greyScale.grey1,
        },
      };
    case constants.TemplateButtonVariants.TEXT:
      return {
        background: 'transparent !important',
        color: color,
        border: 'none !important',
        '&:hover': {
          background: 'transparent !important',
          color: color,
          border: 'none !important',
        },
        '&:disabled': {
          background: 'transparent !important',
          color: theme.colors.greyScale.grey3,
          border: 'none !important',
        },
      };
  }
};

const variantsFRONTEND = ({ eventConfiguration }) => ({
  primary: buildButtonStyle(
    eventConfiguration,
    eventConfiguration.primaryButtonVariant,
    eventConfiguration.primaryColor,
    eventConfiguration.primaryButtonBorderRadius
  ),
  secondary: buildButtonStyle(
    eventConfiguration,
    eventConfiguration.secondaryButtonVariant,
    eventConfiguration.secondaryColor,
    eventConfiguration.secondaryButtonBorderRadius
  ),
});

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

// TODO delete when restyle is completed
const variantMap = {
  yellow: 'primary',
  black: 'secondary',
  grey: 'secondary',
  lightBlue: 'secondary',
  red: 'secondary',
  green: 'secondary',
  violet: 'secondary',
  blue: 'secondary',
  none: 'secondary',
  yellowFill: 'primary',
  greyFill: 'secondary',
  lightBlueFill: 'secondary',
  redFill: 'secondary',
  greenFill: 'primary',
  violetFill: 'secondary',
  blueFill: 'secondary',
};

const variantTemplate = appState.template.getValue();

const getVariant = ({ eventConfiguration, variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[variantTemplate]({ eventConfiguration });
    return template[variant] || template[variantMap[variant]];
  }
};

const CustomIconButton = styled(IconButton)(
  ({
    variant = variantTemplate === constants.Templates.BACKEND
      ? 'yellow'
      : 'primary',
    small = false,
    style,
    eventConfiguration,
  }) => ({
    width: small ? '30px !important' : '40px !important',
    height: small ? '30px !important' : '40px !important',
    margin: '4px !important',
    borderRadius: '4px !important',
    ...(variant && getVariant({ eventConfiguration, variant })),
    ...(style && style),
  })
);

const InnerAimIconButton = ({ children, ...rest }, ref) => {
  const [eventConfiguration, setEventConfiguration] = useState({});

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  return (
    <CustomIconButton
      ref={ref}
      {...rest}
      eventConfiguration={eventConfiguration}
      disableRipple
      disableFocusRipple
    >
      {children}
    </CustomIconButton>
  );
};

export const AimIconButton = React.forwardRef(InnerAimIconButton);
