import React from 'react';

import isEqual from 'date-fns/isEqual';
import format from 'date-fns/format';

import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';

import { AimTypography, AimIconButton, AimRichText } from '../atoms';
import { AimGallery } from '../bundles';
import { theme } from '../../theme';
import { styled } from '../styled';

const InnerContainer = styled('div')({
  width: '1280px',
  display: 'flex',
  flexDirection: 'column',
});

const DocumentMainContainer = styled('div')({
  background: theme.colors.greyScale.backgroundGrey,
  marginBottom: 50,
});

const DocumentInnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.colors.greyScale.white,
  margin: 50,
  padding: 10,
});

export const AgencyWelcomePageTemplate = ({ data, actionButtons }) => {
  const { start, end } = data;
  const isStartEqEnd = isEqual(start, end);
  const startFormatted = format(start, 'dd MMMM');
  const dates = isStartEqEnd
    ? startFormatted
    : `${startFormatted} - ${format(end, 'dd MMMM')}`;
  console.log('data', data);
  return (
    <InnerContainer>
      <Grid container>
        <Grid item xs={6}>
          {data.start && data.end && (
            <AimTypography variant="text">{dates}</AimTypography>
          )}
        </Grid>
        <Grid item xs={6}>
          {actionButtons}
        </Grid>
      </Grid>

      <div style={{ marginTop: 20 }}>
        {data?.cover ? (
          <AimGallery images={data.cover ? [data.cover] : []} />
        ) : null}
      </div>

      {data?.instructions && (
        <AimRichText
          value={data?.instructions}
          readOnly
          variant="white"
          editorStyleOverride={{
            minHeight: 'auto',
            maxHeight: 'fit-content',
            overflow: 'hidden !important',
          }}
        />
      )}

      {data.policies && (
        <DocumentMainContainer>
          {data.policies.map((policy) => (
            <DocumentInnerContainer key={policy.id}>
              <AimTypography variant="h2Regular">
                {policy.originalName}
              </AimTypography>

              <AimIconButton
                variant="lightBlueFill"
                onClick={() => window.open(policy.url)}
              >
                <GetAppIcon />
              </AimIconButton>
            </DocumentInnerContainer>
          ))}
        </DocumentMainContainer>
      )}
    </InnerContainer>
  );
};
