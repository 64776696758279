import React from 'react';
import { AimDialog } from '@aim/components';
import { publishPaxServiceHelper } from './utils';

const PublishPaxServiceDefaultValues = {
  isOpen: false,
  onAgree: null,
  onClose: null,
  isPublished: false,
};

const PublishPaxServiceDialog = ({ dialog, intl }) => {
  const i18n = publishPaxServiceHelper.publishServiceTranslation.PublishServices(
    intl
  );
  return (
    <AimDialog
      open={dialog?.isOpen}
      title={i18n.dialog[dialog.isPublished ? 'depublish' : 'publish']}
      disableEnforceFocus
      onAgree={() => dialog.onAgree()}
      onClose={() => dialog.onClose()}
      agreeText={i18n.dialog.confirmBtn}
      disagreeText={i18n.dialog.cancelBtn}
    >
      {i18n.dialog.message}
    </AimDialog>
  );
};
export { PublishPaxServiceDialog, PublishPaxServiceDefaultValues };
