import { customAlphabet } from 'nanoid';
import { constants } from '@aim/common';

import { createFieldValue } from './gqlHelper';

import {
  postDeadlineModificationRequestCreate,
  postDeadlineModificationRequestUpdate,
  createParticipationSnapshot,
} from './gqlHelper';

export const createModificationRequestData = async ({
  submittedData,
  user,
  eventId,
  participationId,
  cluster,
  clusterId,
}) => {
  //Hooks
  const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const nanoid = customAlphabet(alphabet, 8); // 1 id/hour --> 1% probability of collision in 27 years

  //creare richiesta di modifica + participationRequest
  const inputModificationRequest = {
    eventId: eventId,
    participationId: participationId,
    requestId: nanoid(),
    requestStatus: constants.ModificationRequestStatus.PENDING,
    type: participationId
      ? constants.ModificationRequestTypes.UPDATE
      : constants.ModificationRequestTypes.CREATE,
    modificationRequestRequestedById: user.id,
  };
  const modificationRequest = await postDeadlineModificationRequestCreate(
    inputModificationRequest,
    false
  );

  const {
    baseDefaultValues: {
      title,
      givenName,
      familyName,
      email,
      phone,
      notes,
      isParticipant,
      isReviewer,
      isSpeaker,
      profile,
      status,
      typology,
      feeDateRange,
      type,
    },
    standardDefaultValues,
    customDefaultValues,
  } = submittedData;

  const inputParticipationRequest = {
    title,
    givenName,
    familyName,
    email,
    phone,
    notes,
    isParticipant,
    isReviewer,
    isSpeaker,
    status,
    cluster,
    eventId,
    participationRequestParticipationSubjectId: participationId,
    participationRequestFeeDateRangeId: feeDateRange,
    participationRequestModificationRequestId: modificationRequest.id,
    participationRequestProfileId: profile !== '' ? profile : null,
    participationRequestTypologyId: typology !== '' ? typology : null,
    type,
  };

  if (cluster === constants.Clusters.Delegations.id) {
    inputParticipationRequest.participationRequestDelegationId = clusterId;
  } else if (cluster === constants.Clusters.SponsorList.id) {
    inputParticipationRequest.participationRequestSponsorListId = clusterId;
  } else if (cluster === constants.Clusters.Groups.id) {
    inputParticipationRequest.participationRequestAgencyGroupId = clusterId;
  }

  const participationRequest = await createParticipationSnapshot(
    inputParticipationRequest,
    false
  );

  const otherFields = {
    ...standardDefaultValues,
    ...customDefaultValues,
  };

  const fieldPromises = Object.values(otherFields).map(
    ({ fieldDefinition, value }) =>
      // console.log('fieldDefinition  ', fieldDefinition) ||
      createFieldValue({
        participationRequestFieldValuesId: participationRequest.id,
        fieldValueFieldDefinitionId: fieldDefinition.id,
        value: JSON.stringify(value),
      })
  );
  await Promise.all(fieldPromises);

  await postDeadlineModificationRequestUpdate(
    {
      id: modificationRequest.id,
      modificationRequestParticipationRequestId: participationRequest.id,
    },
    false
  );
};
