import React, { useReducer, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { styled } from './../styled';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { AimTextField } from './../atoms/AimTextField';
import { AimIconButton } from './../atoms/AimIconButton';
import { AimCheckbox } from './../atoms/AimCheckbox';
import { AimSelectMenuItem, AimSelect } from './../atoms/AimSelect';
import { useAimMediaQuery } from './../hooks/useAimMediaQuery';

const CollapseContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '28px 28px 26px 28px',
  backgroundColor: '#30CFD021',
  marginBottom: '20px',
  flexWrap: 'wrap',
});

const LightContent = styled('div')(({ noPadding }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: noPadding ? 0 : '28px 28px 26px 28px',
  marginBottom: '20px',
  flexWrap: 'wrap',
}));

const AimInnerFilter = ({
  rows,
  initialState,
  filtersFns,
  model = [],
  onFilterResult,
  isWidthOfFiltersEqual,
  doClear = false,
  setDoClear = () => false,
  formControlStyle = {
    // width: 'calc(100% - 10px)',
    maxWidth: '20%',
  },
}) => {
  const { isMobile } = useAimMediaQuery();
  const nextFormControlStyle = {
    minWidth: isMobile ? '100% !important' : 120,
  };
  const calcFormControlStyle = (numOfComponents) => {
    return {
      minWidth: isMobile ? '100% !important' : numOfComponents < 10 ? 120 : 200,
      maxWidth: isWidthOfFiltersEqual ? `${100 / numOfComponents}%` : '20%',
    };
  };

  useEffect(() => {
    if (doClear) handleClearFilter();
  }, [doClear]);

  // Filter Logic - Begin

  const [filter, updateFilter] = useReducer(filterReducer, initialState);

  // Internal Function
  function filterReducer(state, action) {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(action.type)) {
      return { ...state, [action.type]: action.value };
    } else if (action.type === 'clear') {
      return initialState;
    }
    throw new Error();
  }

  // Internal function
  const doFilter = () => {
    let filterMultiselectFixed = {};
    for (const [key, value] of Object.entries(filter)) {
      if (Array.isArray(value) && value.length < 1) {
        filterMultiselectFixed[key] = '';
      } else {
        filterMultiselectFixed[key] = value;
      }
    }

    const result = Object.entries(filterMultiselectFixed).reduce(
      (res, [key, value]) =>
        value !== '' && key !== 'minScore' && key !== 'maxScore'
          ? res.filter((i) => {
              return filtersFns[key](i[key], value, i, filter);
            })
          : res,
      rows
    );

    return result;
  };

  const handleApplyFilter = () => {
    let _filtered = doFilter();

    if (
      {}.propertyIsEnumerable.call(filter, 'minScore') &&
      {}.propertyIsEnumerable.call(filter, 'maxScore') &&
      !isNaN(parseInt(filter.minScore)) &&
      !isNaN(parseInt(filter.maxScore))
    ) {
      _filtered = _filtered.filter(
        (x) =>
          parseInt(x.finalScore) >= parseInt(filter.minScore) &&
          parseInt(x.finalScore) <= parseInt(filter.maxScore)
      );
    }

    onFilterResult(_filtered, filter);
  };

  const handleClearFilter = () => {
    updateFilter({ type: 'clear' });
    onFilterResult(rows);
    if (setDoClear) setDoClear(false);
  };

  const handleFilterUpdate = (type) => (changeEvent) => {
    updateFilter({ type, value: changeEvent.target.value });
  };

  const handleCheckboxFilterUpdate = (type) => (changeEvent) => {
    updateFilter({ type, value: changeEvent.target.checked });
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
      }}
    >
      {model.map(
        ({
          type,
          name,
          label,
          isHidden,
          placeholder,
          items,
          displayLabel,
          variant,
          customComponentWidth,
          inputProps,
        }) => {
          if (displayLabel === undefined) displayLabel = true;
          if (isHidden) return null;

          if (type === 'AimTextField') {
            return (
              <AimTextField
                textfieldVariant={variant}
                key={name}
                formControlStyle={
                  customComponentWidth
                    ? { minWidth: customComponentWidth }
                    : nextFormControlStyle
                    ? nextFormControlStyle
                    : calcFormControlStyle(model.length)
                }
                label={label}
                displayLabel={displayLabel}
                placeholder={placeholder}
                value={filter[name]}
                onChange={handleFilterUpdate(name)}
              />
            );
          } else if (type === 'AimDateTextField') {
            return (
              <AimTextField
                type="date"
                key={name}
                textfieldVariant={variant}
                formControlStyle={
                  customComponentWidth
                    ? { minWidth: customComponentWidth }
                    : nextFormControlStyle
                }
                label={label}
                displayLabel={displayLabel}
                placeholder={placeholder}
                value={filter[name]}
                onChange={handleFilterUpdate(name)}
              />
            );
          } else if (type === 'AimNumberTextField') {
            return (
              <AimTextField
                type="number"
                key={name}
                textfieldVariant={variant}
                formControlStyle={
                  customComponentWidth
                    ? { minWidth: customComponentWidth }
                    : nextFormControlStyle
                }
                label={label}
                displayLabel={displayLabel}
                placeholder={placeholder}
                value={filter[name]}
                onChange={handleFilterUpdate(name)}
                inputProps={inputProps ? inputProps : {}}
              />
            );
          } else if (type === 'AimCheckbox') {
            return (
              <AimCheckbox
                key={name}
                variant="white"
                label={label}
                onChange={handleCheckboxFilterUpdate(name)}
                checked={filter[name]}
              />
            );
          } else if (type === 'AimSelect') {
            if (!items) {
              console.error('with AimSelect you must provide items!');
              return;
            }
            return (
              <AimSelect
                hasNoneValue
                selectVariant={variant}
                key={name}
                label={label}
                selectPlaceholder={placeholder}
                formControlStyle={
                  customComponentWidth
                    ? { minWidth: customComponentWidth }
                    : nextFormControlStyle
                }
                displayLabel={displayLabel}
                value={filter[name]}
                onChange={handleFilterUpdate(name)}
              >
                {items.map((item) => {
                  return (
                    <AimSelectMenuItem key={item} value={item}>
                      {item}
                    </AimSelectMenuItem>
                  );
                })}
              </AimSelect>
            );
          } else if (type === 'AimSelectMultiple') {
            if (!items) {
              console.error('with AimSelect you must provide items!');
              return;
            }
            return (
              <AimSelect
                hasNoneValue
                multiple
                selectVariant={variant}
                key={name}
                label={label}
                selectPlaceholder={placeholder}
                formControlStyle={
                  customComponentWidth
                    ? { minWidth: customComponentWidth }
                    : nextFormControlStyle
                }
                displayLabel={displayLabel}
                value={filter[name]}
                onChange={handleFilterUpdate(name)}
              >
                {items.map((item) => {
                  return (
                    <AimSelectMenuItem key={item} value={item}>
                      {item}
                    </AimSelectMenuItem>
                  );
                })}
              </AimSelect>
            );
          } else if (type === 'AimSelectValueLabel') {
            if (!items) {
              console.error('with AimSelectValueLabel you must provide items!');
              return;
            }
            return (
              <AimSelect
                selectVariant={variant}
                key={name}
                label={label}
                displayLabel={displayLabel}
                formControlStyle={
                  customComponentWidth
                    ? { minWidth: customComponentWidth }
                    : nextFormControlStyle
                }
                value={filter[name]}
                onChange={handleFilterUpdate(name)}
              >
                {items.map((item) => {
                  return (
                    <AimSelectMenuItem key={item.value} value={item.value}>
                      {item.label}
                    </AimSelectMenuItem>
                  );
                })}
              </AimSelect>
            );
          } else {
            console.error('Field type ' + type + ' not found');
          }
        }
      )}
      <div style={{ paddingBottom: 5 }}>
        <AimIconButton
          variant="greenFill"
          aria-label="apply"
          onClick={handleApplyFilter}
        >
          <CheckIcon />
        </AimIconButton>
        <AimIconButton
          variant="redFill"
          aria-label="reset"
          onClick={handleClearFilter}
        >
          <ClearIcon />
        </AimIconButton>
      </div>
    </div>
  );
};

export const AimFilter = (props) => {
  return (
    <CollapseContent>
      <AimInnerFilter {...props} />
    </CollapseContent>
  );
};

export const AimFilterLight = ({ noPadding, ...rest }) => {
  return (
    <LightContent noPadding={noPadding}>
      <AimInnerFilter {...rest} />
    </LightContent>
  );
};
