export const translation = {
  roomDetail: (intl) => ({
    tablePage: {
      header: {
        back: intl.formatMessage({
          description: 'Page back button label',
          defaultMessage: 'back',
        }),
        title: intl.formatMessage({
          description: 'room detail page title',
          defaultMessage: 'Room detail',
        }),
      },
      stats: {
        totalBusy: intl.formatMessage({
          description: 'total busy label',
          defaultMessage: 'Total Busy',
        }),
        totalBooked: intl.formatMessage({
          description: 'total booked label',
          defaultMessage: 'Total Booked',
        }),
        totalOverbooked: intl.formatMessage({
          description: 'total overbooked label',
          defaultMessage: 'Total Overbooked',
        }),
      },
      table: {
        empty: intl.formatMessage({
          description: 'Table empty',
          defaultMessage: 'There are not participants in this channel',
        }),
        columns: {
          givenName: intl.formatMessage({
            description: 'name label',
            defaultMessage: 'Name',
          }),
          familyName: intl.formatMessage({
            description: 'familyName label',
            defaultMessage: 'Surname',
          }),
          email: intl.formatMessage({
            description: 'email label',
            defaultMessage: 'Email',
          }),
          hotel: intl.formatMessage({
            description: 'hotel label',
            defaultMessage: 'Hotel',
          }),
          room: intl.formatMessage({
            description: 'room label',
            defaultMessage: 'Room',
          }),
          checkIn: intl.formatMessage({
            description: 'check in label',
            defaultMessage: 'Check in',
          }),
          checkOut: intl.formatMessage({
            description: 'check out label',
            defaultMessage: 'Check out',
          }),
          escorts: intl.formatMessage({
            description: 'escorts label',
            defaultMessage: 'Accompanying persons',
          }),
          status: intl.formatMessage({
            description: 'status label',
            defaultMessage: 'Status',
          }),
        },
        rowAction: {
          clear: intl.formatMessage({
            description: 'clear tooltip',
            defaultMessage: 'Clear',
          }),
        },
      },
      dialog: {
        delete: {
          title: intl.formatMessage({
            description: 'Delete reservation title',
            defaultMessage: 'Delete Reservation',
          }),
          message: intl.formatMessage({
            description: 'Dialog delete message',
            defaultMessage: 'Are you sure you want to continue?',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog delete onAgreeText',
            defaultMessage: 'Delete',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
      },
      snackbar: {
        delete: {
          success: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Delete ok',
          }),
          error: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Some error',
          }),
        },
      },
    },
  }),
};
