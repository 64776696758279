import React, { useState, useMemo } from 'react';
import { AimDataGrid } from '../bundles/AimDataGrid/AimDataGrid';
import { AimDialog } from '../atoms/AimDialog';
import { AimTypography } from '../atoms/AimTypography';

export const MassiveImportPartecipantsDialog = ({
  dialog,
  setDialog,
  handleOnAgree,
  i18n,
}) => {
  const columns = useMemo(
    () => [
      { field: 'row', headerName: i18n.AlertDialog.DataGrid.row, width: 100 },
      {
        field: 'surname',
        headerName: i18n.AlertDialog.DataGrid.surname,
        flex: 1,
        renderCell: (params) => {
          return (
            <div
              style={{
                color: params.row.isDuplicate ? 'red' : 'inherit',
              }}
            >
              {params.value}
            </div>
          );
        },
      },
      {
        field: 'name',
        headerName: i18n.AlertDialog.DataGrid.name,
        flex: 1,
        renderCell: (params) => {
          return (
            <div
              style={{
                color: params.row.isDuplicate ? 'red' : 'inherit',
              }}
            >
              {params.value}
            </div>
          );
        },
      },
      {
        field: 'email',
        headerName: i18n.AlertDialog.DataGrid.email,
        flex: 1,
      },
      {
        field: 'phone number',
        headerName: i18n.AlertDialog.DataGrid.telephone,
        flex: 1,
      },
    ],
    []
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const removeIsDuplicate = (items) => {
    const { isDuplicate, row, ...rest } = items;
    return rest;
  };

  const onSelectionModelChange = (selectionModel) => {
    const selectedData = (dialog.gridData || [])
      .filter((row) => selectionModel.includes(row.id))
      .map(removeIsDuplicate);
    setSelectedRows(selectedData);
  };

  console.log('selectedRows', selectedRows);

  const handleAgree = async () => {
    await handleOnAgree(selectedRows);
    setDialog({ isOpen: false, gridData: [] });
  };

  return (
    <AimDialog
      fullWidth
      maxWidth="xl"
      title={i18n.AlertDialog.title}
      open={dialog.isOpen}
      onAgree={handleAgree}
      onAgreeDisabled={selectedRows.length === 0}
      disableOnClose
      onClose={() => setDialog({ isOpen: false })}
      onDisagree={() => setDialog({ isOpen: false })}
      agreeText={i18n.AlertDialog.agreeText}
    >
      <AimTypography>{i18n.AlertDialog.description}</AimTypography>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: 600 }}>
        <AimDataGrid
          columns={columns}
          rows={dialog.gridData || []}
          checkboxSelection
          disableSelectionOnClick
          tableName={i18n.AlertDialog.tableName}
          onSelectionModelChange={onSelectionModelChange}
        />
      </div>
    </AimDialog>
  );
};
