import { aws, appState } from '@aim/common';
const { API } = aws;

// const { customAlphabet } = require('nanoid');
// const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
// const nanoid = customAlphabet(alphabet, 8); // 1 id/hour --> 1% probability of collision in 27 years

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const createPaymentMutation = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updatePaymentMutation = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
    }
  }
`;

const deletePaymentMutation = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      end
      id
    }
  }
`;

const updateParticipationQuery = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getEndDateOfEvent = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEvent,
      variables: { id },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-event-end', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateParticipationQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipationQuery))
      .catch((e) => {
        console.error('update-particpation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createPayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createPaymentMutation,
      variables: { input }, //TODO set paymentId with the one from effective payment
    })
      .then((response) => resolve(response.data.createPayment))
      .catch((e) => {
        console.error('create-payment', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updatePayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updatePaymentMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updatePayment))
      .catch((e) => {
        console.error('update-payment-components-paymentcart', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const removePayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: deletePaymentMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.deletePayment))
      .catch((e) => {
        console.error('delete-payment', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
