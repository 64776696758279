import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  AimDialogForm,
  AimRadioForm,
  AimRadioButton,
  AimFormControlLabel,
  AimTextFieldForm,
  AimSelectForm,
  AimSelectMenuItem,
  AimDragDropFileUploader,
  AimTypography,
  AimAutocomplete,
  AimAutocompleteForm,
  AimInputForm,
} from '@aim/components';
import Grid from '@material-ui/core/Grid';

import { styled } from '../../../../styled';
import translation from './translation';

import { constants, fileHelper, utilities } from '@aim/common';

import {
  // listRoutes,
  listRoutesByEvent,
  getTravelRoute,
} from '../assignTravelDialog/AssignTravelGqlHelper';
import GuestsFieldArray from './fieldArray';
const { decodeDbNumber } = utilities;
const formControlStyle = { width: 'calc(100% - 15px)' };

const CustomGrid = styled(Grid)({
  '& div:first-child > label': {
    display: 'none',
  },
});

export const AssignTravelDialog = ({
  intl,
  setIsOpen,
  isOpen,
  addTravel,
  data,
  isMultiple,
  participantId,
  eventId,
  businessUnitItems,
  /* handleFiles, */
}) => {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    register,
    clearErrors,
    getValues,
    errors,
  } = useForm({
    shouldUnregister: false,
  });
  const [files, setFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  // const [guests, setGuests] = useState([]);

  //Hooks
  const i18n = translation.dialog(intl);

  const ticketRequest = Object.values(constants.TicketRequest);
  const requestTypes = ticketRequest?.map(({ id, label }) => ({
    value: id,
    label: label(intl),
  }));

  const tripTypes = [
    { value: 'A', label: i18n.oneWay },
    { value: 'R', label: i18n.return },
  ];

  const travelTypeWatcher = watch('travelType');

  // useEffect(() => {
  //   if (!data) reset({});
  // }, [isOpen]);

  useEffect(() => {
    if (data) {
      console.log('data', data);
      const formData = {
        ...data,
        route: data.routeId,
        returnTrip: data.returnTrip ? 'R' : 'A',
      };
      // reset form with formData object
      reset(formData);
      setFiles(data.files);
      setFilesToRemove([]);
    } else {
      reset({});
      setFiles([]);
      setFilesToRemove([]);
    }
  }, [isOpen]);

  useEffect(() => {
    const setFormData = async () => {
      // Get route by data.routeId
      await getTravelRoute(data.routeId).then((res) => {
        // setSelectedRoute by res
        setSelectedRoute(res);
      });
    };

    if (data) {
      setFormData();
    }
  }, [data]);

  useEffect(() => {
    const fetchRoutes = async () => {
      await listRoutesByEvent(eventId, travelTypeWatcher).then((res) => {
        setRoutes(
          res.map((r) => ({
            ...r,
            value: r.id,
            label: `${r.transportCode} ${r.terminalIn?.name} ${r.startDate} ${r.terminalOut?.name} ${r.endDate}`,
          }))
        );
        setFirstLoad(false);
      });
    };
    if (travelTypeWatcher) {
      fetchRoutes();
      if (!firstLoad) {
        setSelectedRoute();
        if (!data) {
          setValue('route', '');
          setValue('routeId', '');
          setValue('transportCode', '');
          setValue('terminalIn', '');
          setValue('startDate', '');
          setValue('endDate', '');
          setValue('terminalOut', '');
          setValue('pnrCode', '');
        }
      }
    }
  }, [travelTypeWatcher, data]);

  const removeFile = async (file) => {
    setFilesToRemove([...filesToRemove, file]);
    const updatedFiles = files.filter((f) =>
      file.id ? f.id !== file.id : f.name !== file.name
    );
    setFiles(updatedFiles);
  };

  const onSelect = (route) => {
    if (route) {
      setSelectedRoute(route);
      setValue('routeId', route.id);
      setValue('transportCode', route.transportCode);
      setValue('terminalIn', route.terminalIn?.name);
      setValue('startDate', route.startDate);
      setValue('endDate', route.endDate);
      setValue('terminalOut', route.terminalOut?.name);
      if (travelTypeWatcher === constants.TravelTypes.GROUPFLIGHT.id) {
        setValue('date', route.date);
        setValue('arrivalDate', route.arrivalDate);
        setValue('cost', decodeDbNumber(route.costPerPerson));
        setValue('pnrCode', route.pnrCode || '');
      }
      // setValue('occupation', route.occupation);
    } else {
      setSelectedRoute();
      setValue('routeId', '');
      setValue('transportCode', '');
      setValue('terminalIn', '');
      setValue('startDate', '');
      setValue('endDate', '');
      setValue('terminalOut', '');
    }
  };

  return (
    <AimDialogForm
      maxWidth={'xl'}
      fullWidth
      control={control}
      name="dialog"
      handleSubmit={handleSubmit}
      errors={errors}
      title={i18n.title}
      onClose={() => setIsOpen(false)}
      onDisagree={() => setIsOpen(false)}
      onAgree={(data) => {
        addTravel({ ...data, files, filesToRemove }, selectedRoute);
      }}
      agreeText={data ? i18n.edit : i18n.add}
      open={isOpen}
      PaperProps={{
        style: {
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 20,
          // maxWidth: 'fit-content',
          // width: 'fit-content',
          minWidth: '800px',
        },
      }}
    >
      <Controller
        control={control}
        name="routeId"
        render={() => (
          <input
            style={{ display: 'none' }}
            key={selectedRoute && selectedRoute.id} // important to include key with field's id
          />
        )}
      />

      <CustomGrid
        container
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <AimRadioForm
          control={control}
          name="returnTrip"
          errors={errors}
          label={i18n.oneWayReturn}
          isRequired
        >
          {tripTypes.map(({ label, value }, index) => (
            <AimFormControlLabel
              key={index}
              value={value}
              control={<AimRadioButton />}
              label={label}
            />
          ))}
        </AimRadioForm>
      </CustomGrid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            type="date"
            name="date"
            placeholder="dd/mm/yyyy"
            label={i18n.departureDate}
            formControlStyle={formControlStyle}
            disabled={
              travelTypeWatcher === constants.TravelTypes.GROUPFLIGHT.id
            }
            errors={errors}
            isRequired={
              travelTypeWatcher !== constants.TravelTypes.PERSONALTRANSPORT.id
            }
          />
        </Grid>
        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            type="date"
            name="arrivalDate"
            placeholder="dd/mm/yyyy"
            label={i18n.arrivalDate}
            formControlStyle={formControlStyle}
            disabled={
              travelTypeWatcher === constants.TravelTypes.GROUPFLIGHT.id
            }
            errors={errors}
            isRequired={
              travelTypeWatcher !== constants.TravelTypes.PERSONALTRANSPORT.id
            }
          />
        </Grid>
        <Grid item xs={3}>
          <AimSelectForm
            control={control}
            name="travelType"
            label={i18n.travelType}
            formControlStyle={formControlStyle}
            errors={errors}
            isRequired
          >
            {Object.values(constants.TravelTypes).map((travelType) => (
              <AimSelectMenuItem key={travelType.id} value={travelType.id}>
                {travelType.label(intl)}
              </AimSelectMenuItem>
            ))}
          </AimSelectForm>
        </Grid>
        <Grid item xs={3}>
          {/* <Controller
            name="route"
            control={control}
            defaultValue={
              travelTypeWatcher === constants.TravelTypes.PERSONALTRANSPORT.id
                ? ''
                : 
            }
            render={(props) => (
              <AimAutocomplete
                label={i18n.travelDetails}
                options={routes}
                // getOptionLabel={(opt) => {
                //   return `${opt.transportCode} ${opt.terminalIn?.name} ${opt.startDate} ${opt.terminalOut?.name} ${opt.endDate}`;
                // }}
                getOptionLabel={(opt) => opt.label || ''}
                // getOptionSelected={(opt, value) => opt.value === value?.value}
                // filterOptions={(options) => options}
                formControlStyle={formControlStyle}
                onChange={(_, value) => onSelect(value)}
                disabled={!travelTypeWatcher}
                value={props.value}
              />
            )}
          /> */}
          <AimAutocompleteForm
            label={i18n.travelDetails}
            control={control}
            register={register}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
            name={`route`}
            options={routes || []}
            formControlStyle={formControlStyle}
            disabled={
              !travelTypeWatcher ||
              travelTypeWatcher ===
                constants.TravelTypes.PERSONALTRANSPORT.id ||
              travelTypeWatcher === constants.TravelTypes.OTHER.id
            }
            onChange={(e, value) => {
              onSelect(value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            name="transportCode"
            label={i18n.code}
            formControlStyle={formControlStyle}
            disabled={
              (selectedRoute &&
                travelTypeWatcher !== selectedRoute.travelType) ||
              travelTypeWatcher ===
                constants.TravelTypes.PERSONALTRANSPORT.id ||
              travelTypeWatcher === constants.TravelTypes.OTHER.id
            }
            isRequired={
              travelTypeWatcher !==
                constants.TravelTypes.PERSONALTRANSPORT.id &&
              travelTypeWatcher !== constants.TravelTypes.OTHER.id
            }
            errors={errors}
          />
        </Grid>

        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            name="terminalIn"
            label={i18n.startingPoint}
            formControlStyle={formControlStyle}
            disabled={
              (selectedRoute &&
                travelTypeWatcher !== selectedRoute.travelType) ||
              travelTypeWatcher ===
                constants.TravelTypes.PERSONALTRANSPORT.id ||
              travelTypeWatcher === constants.TravelTypes.OTHER.id
            }
          />
        </Grid>
        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            name="startDate"
            type="time"
            placeholder="HH:mm"
            label={i18n.departureTime}
            formControlStyle={formControlStyle}
            disabled={
              (selectedRoute &&
                travelTypeWatcher !== selectedRoute.travelType) ||
              travelTypeWatcher ===
                constants.TravelTypes.PERSONALTRANSPORT.id ||
              travelTypeWatcher === constants.TravelTypes.OTHER.id
            }
          />
        </Grid>
        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            name="terminalOut"
            label={i18n.pointOfArrival}
            formControlStyle={formControlStyle}
            disabled={
              (selectedRoute &&
                travelTypeWatcher !== selectedRoute.travelType) ||
              travelTypeWatcher ===
                constants.TravelTypes.PERSONALTRANSPORT.id ||
              travelTypeWatcher === constants.TravelTypes.OTHER.id
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            name="endDate"
            label={i18n.arrivalTime}
            type="time"
            placeholder="HH:mm"
            formControlStyle={formControlStyle}
            disabled={
              (selectedRoute &&
                travelTypeWatcher !== selectedRoute.travelType) ||
              travelTypeWatcher ===
                constants.TravelTypes.PERSONALTRANSPORT.id ||
              travelTypeWatcher === constants.TravelTypes.OTHER.id
            }
          />
        </Grid>
        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            name="pnrCode"
            label={i18n.pnr}
            formControlStyle={formControlStyle}
            disabled={
              isMultiple ||
              travelTypeWatcher === constants.TravelTypes.GROUPFLIGHT.id ||
              travelTypeWatcher ===
                constants.TravelTypes.PERSONALTRANSPORT.id ||
              travelTypeWatcher === constants.TravelTypes.OTHER.id
            }
          />
        </Grid>
        <Grid item xs={3}>
          <AimSelectForm
            control={control}
            name="ticketStatus"
            label={i18n.ticketRequest}
            formControlStyle={formControlStyle}
            disabled={travelTypeWatcher !== 'airplane'}
          >
            {requestTypes.map(({ value, label }) => (
              <AimSelectMenuItem key={label} value={value}>
                {label}
              </AimSelectMenuItem>
            ))}
          </AimSelectForm>
        </Grid>
        <Grid item xs={3}>
          <AimTypography
            variant="textBold"
            boxStyle={{ margin: 0, padding: 0 }}
          >
            {i18n.totalCost}
          </AimTypography>
          <AimInputForm
            control={control}
            name="cost"
            formControlStyle={formControlStyle}
            type="number"
            inputProps={{
              style: {
                textAlign: 'left',
                height: 28,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AimTextFieldForm
            control={control}
            name="checkInLink"
            label={i18n.checkInLink}
            formControlStyle={formControlStyle}
            disabled={travelTypeWatcher !== constants.TravelTypes.TRAIN.id}
          />
        </Grid>
        <Grid item xs={3}>
          <AimSelectForm
            control={control}
            name="businessUnit"
            label={i18n.businessUnit}
            formControlStyle={formControlStyle}
            onChange={(value) => {
              const found = businessUnitItems.find((b) => b.value === value);
              setValue('orderCode', found.code);
              return value;
            }}
            isRequired={
              travelTypeWatcher !==
                constants.TravelTypes.PERSONALTRANSPORT.id &&
              travelTypeWatcher !== constants.TravelTypes.OTHER.id
            }
            errors={errors}
          >
            {businessUnitItems.map(({ value, label }) => {
              return (
                <AimSelectMenuItem key={value} value={value}>
                  <AimTypography
                    ellipsis
                    style={{ width: '99%' }}
                    boxStyle={{ maxWidth: '100%' }}
                  >
                    {label}
                  </AimTypography>
                </AimSelectMenuItem>
              );
            })}
          </AimSelectForm>
        </Grid>
        <Grid item xs={3}>
          <AimTextFieldForm
            control={control}
            name="orderCode"
            label={i18n.order}
            formControlStyle={formControlStyle}
            defaultValue={''}
            disabled
            isRequired={
              travelTypeWatcher !==
                constants.TravelTypes.PERSONALTRANSPORT.id &&
              travelTypeWatcher !== constants.TravelTypes.OTHER.id
            }
            errors={errors}
          />
        </Grid>
      </Grid>
      <Grid container>
        <AimTextFieldForm
          control={control}
          name="note"
          label={i18n.note}
          formControlStyle={formControlStyle}
        />
      </Grid>
      {!isMultiple && (
        <Grid container>
          <GuestsFieldArray
            {...{
              control,
              register,
              setValue,
              errors,
              clearErrors,
              guests: data?.guests,
              watch,
              participantId: participantId,
              getValues,
              eventId,
              i18n,
            }}
          />
        </Grid>
      )}
      <div style={{ marginTop: 20 }}>
        <AimTypography variant="h4">{i18n.uploadTicket}</AimTypography>
        <AimDragDropFileUploader
          dragTitle={`${i18n.dragTitle} (.jpeg,.jpg,.png,.pdf)`}
          dragSubTitle={i18n.dragSubTitle}
          clickText={i18n.selectFileText}
          fileLimitText={i18n.fileLimitText}
          onUploadFile={(filesToAdd) => setFiles([...files, ...filesToAdd])}
          onRemove={(file) => removeFile(file)}
          onDownload={(file) =>
            fileHelper.downloadFromS3({
              dirPath: `events/${eventId}/participations/${participantId}/`,
              fileData: file,
            })
          }
          files={files}
          dropzoneAdditionalProps={{
            accept: '.jpeg,.jpg,.png,.pdf',
            maxFiles: 10,
            multiple: true,
          }}
        />
      </div>
    </AimDialogForm>
  );
};
