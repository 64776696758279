const publishServiceTranslation = {
  PublishServices: (intl) => ({
    page: {
      title: intl.formatMessage({
        description: 'Publish Services page title',
        defaultMessage: 'Publish services',
      }),
      selectedParticipants: intl.formatMessage({
        description: 'Publish Services page selected participants',
        defaultMessage: 'N° of selected participants: ',
      }),
      service: intl.formatMessage({
        description: 'Publish Services page service label',
        defaultMessage: 'Service',
      }),
      publish: intl.formatMessage({
        description: 'Publish Services page publish label',
        defaultMessage: 'Publish',
      }),
      unpublish: intl.formatMessage({
        description: 'Publish Services page unpublish label',
        defaultMessage: 'Unpublish',
      }),
      travel: intl.formatMessage({
        description: 'Publish Services page travel label',
        defaultMessage: 'Travel',
      }),
      transfer: intl.formatMessage({
        description: 'Publish Services page transfer label',
        defaultMessage: 'Transfer',
      }),
      backButton: {
        label: intl.formatMessage({
          description: 'back',
          defaultMessage: 'Back',
        }),
        tooltip: intl.formatMessage({
          description: 'back',
          defaultMessage: 'Back',
        }),
      },
      snackbar: {
        error: {
          noPax: intl.formatMessage({
            description: 'snackbar error noPax selected message',
            defaultMessage:
              'An error occured getting Participants informations',
          }),
          publish: intl.formatMessage({
            description: 'snackbar error publish service',
            defaultMessage: 'An error occured updating the service',
          }),
        },
        success: {
          correctlyPublish: intl.formatMessage({
            description: 'snackbar success correctlyPublish message',
            defaultMessage: 'service correctly published',
          }),
          correctlyUnpublish: intl.formatMessage({
            description: 'snackbar success correctlyUnpublish message',
            defaultMessage: 'service correctly unpublished',
          }),
        },
      },
    },
    dialog: {
      depublish: intl.formatMessage({
        description: 'Publish Services dialog depublish title',
        defaultMessage: 'De-publish service',
      }),
      publish: intl.formatMessage({
        description: 'Publish Services dialog publish title',
        defaultMessage: 'Publish service',
      }),
      message: intl.formatMessage({
        description: 'Publish Services dialog message',
        defaultMessage: 'Are you sure you want to continue?',
      }),
      cancelBtn: intl.formatMessage({
        description: 'Publish Services dialog cancelBtn',
        defaultMessage: 'cancel',
      }),
      confirmBtn: intl.formatMessage({
        description: 'Publish Services dialog confirmBtn',
        defaultMessage: 'confirm',
      }),
    },
  }),
};

export { publishServiceTranslation };
