import React, { useState, useEffect, useRef } from 'react';

import { AimImage } from '../atoms/AimImage';

import { styled } from '../styled';
import { theme } from '../../theme';

const TilesList = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  marginLeft: '20px !important',
  marginRight: '20px !important',
  overflow: 'hidden',
  // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  transform: 'translateZ(0)',
});

const Tile = styled('div')({
  cursor: 'pointer',
});

const TilesContainer = styled('div')({
  position: 'relative',
  maxWidth: '100%',
});

const ArrowRight = styled('i')({
  border: `solid ${theme.colors.greyScale.black}`,
  borderWidth: '0 1px 1px 0',
  display: 'inline-block',
  padding: 5,
  transform: 'rotate(-45deg) translateY(-50%)',
  WebkitTransform: 'rotate(-45deg) translateY(-50%)',
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  zIndex: 1,
  right: 0,
});

const ArrowLeft = styled('i')({
  border: `solid ${theme.colors.greyScale.black}`,
  borderWidth: '0 1px 1px 0',
  display: 'inline-block',
  padding: 5,
  transform: 'rotate(135deg) translateY(50%)',
  WebkitTransform: 'rotate(135deg) translateY(50%)',
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  zIndex: 1,
  left: 0,
});

const CoverImage = styled(AimImage)({
  height: 500,
  objectFit: 'contain',
  marginBottom: 10,
});

const TileImage = styled(AimImage)({
  objectFit: 'cover',
  height: 100,
  width: 100,
  marginRight: 10,
});

export const AimGallery = ({ images }) => {
  // States
  const [coverImageIdx, setCoverImageIdx] = useState();
  // Refs
  const tilesListRef = useRef();

  // Effects
  useEffect(() => {
    if (images?.length) {
      setCoverImageIdx(0);
    }
  }, [images]);

  // Functions
  const handlePrevClick = () => {
    const nextCoverImageIdx = coverImageIdx - 1;
    setCoverImageIdx(nextCoverImageIdx < 0 ? 0 : nextCoverImageIdx);

    tilesListRef.current.scrollBy({
      top: 0,
      left: -110,
      behavior: 'smooth',
    });
  };

  const handleNextClick = () => {
    const nextCoverImageIdx = coverImageIdx + 1;
    setCoverImageIdx(
      nextCoverImageIdx === images.length
        ? images.length - 1
        : nextCoverImageIdx
    );

    tilesListRef.current.scrollBy({
      top: 0,
      left: 110,
      behavior: 'smooth',
    });
  };

  return (
    images?.length && (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {coverImageIdx != null && (
          <CoverImage src={images[coverImageIdx]} alt="gallery-cover" />
        )}
        <TilesContainer>
          <ArrowLeft onClick={handlePrevClick} />
          <TilesList ref={tilesListRef}>
            {images.map((img, idx) => (
              <Tile
                key={`gallery-${idx}`}
                onClick={() => setCoverImageIdx(idx)}
              >
                <TileImage
                  style={{
                    border:
                      idx === coverImageIdx
                        ? `3px solid ${theme.colors.primary.yellow}`
                        : 'medium none',
                  }}
                  src={img}
                  alt={`gallery-${idx}`}
                />
              </Tile>
            ))}
          </TilesList>
          <ArrowRight onClick={handleNextClick} />
        </TilesContainer>
      </div>
    )
  );
};
