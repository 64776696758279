import React, { useState, useEffect } from 'react';
import { styled } from './../styled';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';

import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { theme } from './../../theme';
import { appState, constants } from '@aim/common';
import { AimTypography } from './AimTypography';
import { AimFormControlLabel } from './AimFormControlLabel';

const StyledCheckbox = styled(Checkbox)(({ style }) => ({
  height: '18px !important',
  width: '18px !important',
  padding: 3,
  // paddingLeft: 0,
  // '&.MuiCheckbox-colorSecondary.Mui-checked': {
  //   color: theme.colors.primary.yellow,
  // },
  '&.MuiCheckbox-colorSecondary.Mui-disabled': {
    opacity: 0.4,
  },
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
  ...style,
}));
const StyledCheckboxOutline = styled(CheckBoxOutlineBlankIcon)(
  ({ variant, style }) => {
    return {
      height: '18px !important',
      width: '18px !important',
      margin: 3,
      // backgroundColor: 'transparent !important',
      ...variant,
      ...(variant?.background && { background: variant.background }),
      ...(variant?.background && { color: variant.background }),
      ...style,
    };
  }
);
const StyledCheckedIcon = styled(CheckIcon)(({ variant, style }) => {
  return {
    height: '18px !important',
    width: '18px !important',
    margin: 3,
    color: '#FFFFFF !important',
    ...variant,
    ...{
      background: variant?.backgroundChecked,
    },
    ...style,
    // ...(variant?.background && { opacity: 1 }),
  };
});

const StyledRemoveIcon = styled(RemoveIcon)(({ variant, style }) => {
  return {
    width: 18,
    height: 18,
    margin: 3,
    ...variant,
    ...{
      background: variant?.backgroundChecked,
    },
    ...style,
    // ...(variant?.backgroundChecked && { opacity: 1 }),
  };
});

const variantsBACKEND = {
  greyLight: {
    background: theme.colors.greyScale.backgroundGrey + ' !important',
    backgroundChecked: theme.colors.primary.yellow,
    color: theme.colors.greyScale.backgroundGrey + ' !important',
    // opacity: '0.4',
  },
  grey: {
    background: theme.colors.greyScale.grey2 + ' !important', // or grey5 with opacity 0.4 ?
    backgroundChecked: theme.colors.primary.yellow,
    color: 'white !important',
    // opacity: '0.4',
  },
  white: {
    background: theme.colors.primary.white + ' !important',
    backgroundChecked: theme.colors.primary.yellow,
    color: 'white !important',
    // opacity: '0.4',
  },
};

const variantsFRONTEND = ({ eventConfiguration }) => {
  return {
    // backgr color checkbox unchecked is "grey", backgr color checkbox checked is "primaryColor"
    primary: {
      background: theme.colors.greyScale.grey2, // or grey5 with opacity 0.4 ?
      backgroundChecked: eventConfiguration.primaryColor,
      color: 'white !important',
      // opacity: '0.4',
    },
    // backgr color checkbox unchecked is "white", backgr color checkbox checked is "secondaryColor"
    secondary: {
      background: theme.colors.primary.white,
      backgroundChecked: eventConfiguration.primaryColor,
      color: 'white !important',
      // opacity: '0.4',
    },
  };
};

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const variantTemplate = appState.template.getValue();

// TODO delete when restyle is completed
const variantMap = {
  greyLight: 'primary',
  grey: 'primary',
  white: 'secondary',
};

const getVariant = ({ eventConfiguration, variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else if (eventConfiguration) {
    const template = variants[variantTemplate]({ variant, eventConfiguration });
    return template[variant] || template[variantMap[variant]];
  }
};

// default variants
// BACKEND:
// default variant grey
// FRONTEND:
// event config with background white -> default variant is primary: backgr color checkbox unchecked is "grey", backgr color checkbox checked is "primaryColor"
// event config with background grey -> default variant is secondary: backgr color checkbox unchecked is "white", backgr color checkbox checked is "secondaryColor"
const getDefaultVariant = (eventConfiguration) =>
  variantTemplate === constants.Templates.BACKEND
    ? 'grey'
    : eventConfiguration.background === theme.colors.primary.white
    ? 'primary' // backgr grey
    : 'secondary'; // backgr white

// variant "custom" da controllare che la prenda bene
export const AimCheckbox = ({
  variant,
  label,
  labelPlacementLeft,
  labelPlacement,
  labelVariant,
  formControLabelStyle = {},
  ...rest
}) => {
  const [eventConfiguration, setEventConfiguration] = useState({});
  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  const nextCheckboxVariant = variant || getDefaultVariant(eventConfiguration);

  const nextPlacement = labelPlacement
    ? labelPlacement
    : labelPlacementLeft
    ? 'start'
    : 'top';

  if (!label) {
    return (
      eventConfiguration && (
        <StyledCheckbox
          icon={
            <StyledCheckboxOutline
              variant={getVariant({
                eventConfiguration,
                variant: nextCheckboxVariant,
              })}
            />
          }
          checkedIcon={
            <StyledCheckedIcon
              variant={getVariant({
                eventConfiguration,
                variant: nextCheckboxVariant,
              })}
            />
          }
          indeterminateIcon={
            <StyledRemoveIcon
              variant={getVariant({
                eventConfiguration,
                variant: nextCheckboxVariant,
              })}
            />
          }
          {...rest}
        />
      )
    );
  }
  return (
    <AimFormControlLabel
      style={{
        margin: 8,
        paddingBottom: 0,
        flex: 1,
        justifyContent: 'start',
        gap: 8,
        ...formControLabelStyle,
      }}
      control={
        <StyledCheckbox
          icon={
            <StyledCheckboxOutline
              variant={getVariant({
                eventConfiguration,
                variant: nextCheckboxVariant,
              })}
            />
          }
          checkedIcon={
            <StyledCheckedIcon
              variant={getVariant({
                eventConfiguration,
                variant: nextCheckboxVariant,
              })}
            />
          }
          indeterminateIcon={
            <StyledRemoveIcon
              variant={getVariant({
                eventConfiguration,
                variant: nextCheckboxVariant,
              })}
            />
          }
          {...rest}
          disableRipple
        />
      }
      label={
        <AimTypography variant={labelVariant ? labelVariant : 'textBold'}>
          {label}
        </AimTypography>
      }
      labelPlacement={nextPlacement}
    />
  );
};

export const AimCheckboxForm = ({
  control,
  name,
  defaultValue,
  errors,
  isRequired,
  label,
  ...rest
}) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || false}
      render={(props) => (
        <AimCheckbox
          label={label}
          onChange={(e) => props.onChange(e.target.checked)}
          checked={props.value}
          {...rest}
        />
      )}
      rules={{
        required: isRequired,
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <AimTypography variant="formError">
            {message || `${label} is required`}
          </AimTypography>
        )}
      />
    )}
  </>
);

export const AimCheckboxDynamic = ({
  control,
  errors,
  variant,
  label,
  name,
  isRequired,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '1.7rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      }}
    >
      <AimTypography noWrap variant="h5">
        {label}
      </AimTypography>
      <AimCheckboxForm
        control={control}
        name={name}
        errors={errors}
        variant={variant}
        isRequired={isRequired}
      ></AimCheckboxForm>
    </div>
  );
};
