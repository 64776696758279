import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  AimDialog,
  AimIconAndTextButton,
  AimTextFieldForm,
  AimSelectForm,
  AimSelectMenuItem,
} from '@aim/components';
import { constants } from '@aim/common';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const formControlStyle = { width: 'calc(100% - 15px)' };

export const PaymentDialog = ({
  i18n,
  intl,
  isOpen,
  onClose,
  onDisagree,
  dialogState,
  handleConfirm,
}) => {
  if (!dialogState) return null;
  const { handleSubmit, control, getValues, watch, errors, reset } = useForm({
    shouldUnregister: false,
    defaultValues: {
      status: dialogState?.payment?.status,
      totalPrice: dialogState?.payment?.totalPrice,
      paidFee: dialogState.payment?.totalPrice,
      paymentDate: dialogState.payment?.paymentDate
        ?.split(' ')[0]
        .split('/')
        .reverse()
        .join('-'),
    },
  });

  const handleConfirmPayment = (submittedData) => {
    handleConfirm(submittedData);
  };

  useEffect(() => {
    if (dialogState.isOpen) reset();
  }, [dialogState.isOpen]);

  return (
    <AimDialog
      title={i18n.title}
      open={isOpen}
      onClose={onClose}
      onDisagree={onDisagree}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
    >
      <form onSubmit={handleSubmit(handleConfirmPayment)}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0 15px',
          }}
        >
          <AimTextFieldForm
            control={control}
            name="totalPrice"
            label={i18n.totalPrice}
            formControlStyle={formControlStyle}
            disabled
          />
        </div>
        {/* <div
        style={{ display: 'flex', alignItems: 'center', margin: '10px 0 15px' }}
      >
        <AimTextFieldForm
          control={control}
          name="paidFee"
          label={i18n.paidFee}
          formControlStyle={formControlStyle}
        />
      </div> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0 15px',
          }}
        >
          <AimSelectForm
            control={control}
            errors={errors}
            disabled={dialogState?.payment?.status?.toLowerCase() === 'done'} // being this specifically for the payments section and having it to be unique, let's lock it in case the status matches 'done'
            label={i18n.paymentStatus.label}
            name="status"
            selectPlaceholder={i18n.paymentStatus.placeholder}
            formControlStyle={formControlStyle}
            // hasNoneValue
          >
            {Object.values(constants.PaymentStatuses).map((status) => {
              return (
                <AimSelectMenuItem key={status.key} value={status.key}>
                  {status.label(intl)}
                </AimSelectMenuItem>
              );
            })}
          </AimSelectForm>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0 15px',
          }}
        >
          <AimTextFieldForm
            type="date" //using Safari on desktop? https://www.google.com/chrome/ - update: nevermind, Safari has it now.
            control={control}
            name="paymentDate"
            label={i18n.paymentDate.label}
            formControlStyle={formControlStyle}
            disabled={dialogState?.payment?.status?.toLowerCase() === 'done'} // see above
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <AimIconAndTextButton
            variant={'greyFill'}
            text={i18n.buttons.cancel}
            onClick={() => {
              onClose();
            }}
          >
            <ClearIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            variant={'greenFill'}
            text={i18n.buttons.confirm}
            type="submit"
            // onClick={() =>
            //   handleConfirm?.(
            //     getValues('totalPrice'),
            //     getValues('paidFee'),
            //     getValues('status')
            //   )
            // }
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </div>
      </form>
    </AimDialog>
  );
};
