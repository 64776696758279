import React, { useState } from 'react';

import { format, parseISO } from 'date-fns';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';

import useI18n from './i18n';
import { styled } from '../../styled';
import { AimTypography } from '../../atoms/AimTypography';

const StyledPreviewDiv = styled(({ imageUrl, ...rest }) => <div {...rest} />)(
  ({ imageUrl }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: 1280,
    height: 720,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundColor: '#cccccc',
  })
);

const GetIFrameByProvider = ({ webcast }) => {
  // console.log('webcast', webcast, webcast.videoProvider);
  return (
    ((webcast.videoProvider === 'vimeo' || !webcast.videoProvider) && (
      <iframe
        style={{ width: 1280, height: 720 }}
        // src={`https://player.vimeo.com/video/${webcast.videoId}?autoplay=1`
        src={
          `${webcast.link}?autoplay=1` ||
          (webcast.videoId &&
            `https://player.vimeo.com/video/${webcast.videoId}?autoplay=1`)
        }
        frameBorder="0"
        allow="autoplay"
        allowFullScreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
      />
    )) ||
    (webcast.videoProvider === 'youtube' && (
      <iframe
        style={{ width: 1280, height: 720 }}
        // src={`https://www.youtube.com/embed/${webcast.videoId}?autoplay=1`}
        src={
          `${webcast.link}?autoplay=1` ||
          (webcast.videoId &&
            `https://www.youtube.com/embed/${webcast.videoId}?autoplay=1`)
        }
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    ))
  );
};

const hasOneOfThreeItems = (webcast) => {
  if (
    webcast?.tags?.length > 0 ||
    webcast?.topic ||
    webcast?.webcastSpeeches?.items?.length > 0
  ) {
    return true;
  }
  return false;
};

export const WebcastPreview = ({
  intl,
  webcast,
  imageUrl,
  onVideoClick,
  showLink,
}) => {
  const i18n = useI18n(intl);
  const [isvideoStarted, setIsVideoStarted] = useState(false);

  const formattedDate = (date) => {
    if (date) return format(parseISO(date), 'dd MMMM yyyy');
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginLeft: 20,
        }}
      >
        <AimTypography variant="h1" style={{ width: 1280 }}>
          {webcast.title}
        </AimTypography>
        <AimTypography variant="h4">{webcast.subtitle}</AimTypography>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <AimTypography variant="h4Regular">
            {`${formattedDate(webcast.createdAt)} - ${webcast.views || '0'}`}{' '}
            views
          </AimTypography>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          paddingLeft: '26px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div>
          {isvideoStarted ? (
            <GetIFrameByProvider webcast={webcast} />
          ) : (
            <StyledPreviewDiv
              onClick={() => {
                if (onVideoClick) onVideoClick();
                setIsVideoStarted(true);
              }}
              imageUrl={imageUrl}
            >
              <div
                style={{
                  height: '53%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginBottom: 100,
                }}
              >
                <PlayCircleFilledWhiteIcon
                  style={{
                    fontSize: 90,
                    color: '#4c4c4c',
                    cursor: 'pointer',
                  }}
                />
              </div>

              <AimTypography
                variant="h1"
                style={{ marginLeft: 80, fontSize: 2.5625, color: '#4c4c4c' }}
              >
                {webcast.title}
              </AimTypography>
              <AimTypography
                variant="h4"
                style={{ marginLeft: 80, color: '#4c4c4c' }}
              >
                {webcast.subtitle}
              </AimTypography>
            </StyledPreviewDiv>
          )}
        </div>
      </div>
      {!webcast?.description && !hasOneOfThreeItems(webcast) ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 30,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minHeight: 200,
          }}
        />
      ) : null}

      {/* {webcast?.description ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 30,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minHeight: 200,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                backgroundColor: '#FFD400',
                width: 26,
                height: 26,
              }}
            ></div>
            <AimTypography
              style={{
                transform: 'rotate(270deg)',
                marginTop: 37,
                marginLeft: -37,
              }}
              variant="h4Regular"
            >
              {i18n.page.footer.firstContainer}
            </AimTypography>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingTop: 13,
            }}
          >
            <AimTypography variant="h4Regular">
              {webcast.description}
            </AimTypography>
          </div>
        </div>
      ) : null} */}

      {/* {hasOneOfThreeItems(webcast) ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 30,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minHeight: 200,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                backgroundColor: '#FFD400',
                width: 26,
                height: 26,
              }}
            ></div>
            <AimTypography
              style={{
                transform: 'rotate(270deg)',
                marginTop: 37,
                marginLeft: -37,
              }}
              variant="h4Regular"
            >
              {i18n.page.footer.secondContainer}
            </AimTypography>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              maxWidth: '90%',
            }}
          >
            {showLink && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <AimTypography variant="h4">
                  {i18n.page.labels.link}
                </AimTypography>
                <AimTypography variant="h4Regular">
                  {webcast.link}
                </AimTypography>
              </div>
            )}
            {webcast?.topic ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <AimTypography variant="h4">
                  {i18n.page.labels.topic}
                </AimTypography>
                <AimTypography variant="h4Regular">
                  {webcast.topic}
                </AimTypography>
              </div>
            ) : null}
            {webcast.webcastSpeeches?.items?.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <AimTypography variant="h4">
                  {i18n.page.labels.speakers}
                </AimTypography>
                <AimTypography variant="h4Regular">
                  {webcast.webcastSpeeches?.items
                    .map((item) => {
                      return `${item.participation.user.givenName} ${item.participation.user.familyName}`;
                    })
                    .join(', ')}
                </AimTypography>
              </div>
            ) : null}
            {webcast?.tags?.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <AimTypography variant="h4">
                  {i18n.page.labels.tags}
                </AimTypography>
                <AimTypography variant="h4Regular">
                  {webcast.tags && webcast.tags.join('; ')}
                </AimTypography>
              </div>
            ) : null}
          </div>
        </div>
      ) : null} */}
    </>
  );
};
