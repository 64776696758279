import React from 'react';
import { styled } from './../styled';
import { isMobile } from 'react-device-detect';
import { AimTypography } from './../atoms/AimTypography';

const RootContainer = styled('div')({
  display: 'flex',
  justifyContent: isMobile ? 'flex-start' : 'flex-end',
  flexDirection: isMobile ? 'column' : 'row',
  flexWrap: isMobile ? 'wrap' : 'no-wrap',
});

const TypographyContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: isMobile ? 'flex-start' : 'flex-end',
  alignItems: 'center',
});

export const AimTitleAndButtons = ({
  title,
  children,
  titleBoxStyle,
  titleChildren = false,
}) => (
  <RootContainer>
    <TypographyContainer>
      <AimTypography variant="h1" boxStyle={titleBoxStyle} margin={0}>
        {title}
      </AimTypography>
      {titleChildren ? titleChildren : null}
    </TypographyContainer>
    <ButtonsContainer>{children}</ButtonsContainer>
  </RootContainer>
);
