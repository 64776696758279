import React from 'react';
import Grid from '@material-ui/core/Grid';

import { AimTypography } from '../../atoms/AimTypography';
import { AimList } from '../AimList';
import translation from './translation';
import { theme } from '@aim/components';

const Row = ({ row }) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <AimTypography>{row.label}</AimTypography>
      </Grid>
    </Grid>
  );
};

export const ExportDialog = ({
  intl,
  headCells,
  setCheckedItems,
  checkedItems,
}) => {
  // Hooks
  const i18n = intl && translation.exportDialog(intl);

  return (
    <Grid container>
      <Grid item xs={12}>
        <AimTypography variant="h3">{i18n && i18n.subTitle}</AimTypography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
        }}
      >
        <AimList
          rows={headCells.filter((h) => !!h?.label)}
          checkable
          deletable={false}
          rowKey="id"
          childrenProps={{ i18n }}
          variant="grey"
          HeaderRow={
            <AimTypography boxStyle={{ marginTop: 0 }}>
              {i18n && i18n.listHeaderRowName}
            </AimTypography>
          }
          headerStyle={{ margin: '0px', padding: '2px' }}
          itemStyle={{ padding: '2px' }}
          onChecked={setCheckedItems}
          checkedItems={checkedItems}
        >
          <Row />
        </AimList>
      </Grid>
    </Grid>
  );
};
