import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
  AimTypography,
  AimTextField,
  AimSelect,
  AimSelectMenuItem,
  AimRichText,
  AimFileSelector,
  AimSwitch,
  AimIconAndTextButton,
  AimAutocomplete,
} from '@aim/components';
import { useDataHelper } from '../dataHelper';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import TextField from '@material-ui/core/TextField';

export const renderLabel = (label, style, rest) => {
  return (
    <AimTypography variant="columnHeader" style={{ ...style }} {...rest}>
      {label || `#NO-LABEL#${label}#`}
    </AimTypography>
  );
};

export const useFormComponents = ({
  formControlStyle,
  defaultValues,
  joiSchema,
  validationMode = 'onSubmit',
  control: _control,
  errors: _errors,
  getValues: _getValues,
  labels,
}) => {
  //se non c'è control lo creo con un nuovo useForm
  let formHelper = {};
  if (!_control) {
    const {
      handleSubmit,
      errors,
      control,
      getValues,
      setValue,
      reset,
      trigger,
    } = useForm({
      mode: validationMode,
      reValidateMode: validationMode,
      shouldUnregister: false,
      defaultValues: defaultValues,
      criteriaMode: 'all',
      shouldFocusError: true,
      ...(joiSchema && { resolver: joiResolver(joiSchema) }),
    });

    _control = control;
    _errors = errors;
    _getValues = getValues;

    formHelper = {
      handleSubmit,
      errors: _errors,
      control: _control,
      getValues: _getValues,
      setValue,
      reset,
      trigger,
    };
  }

  const { parseRichText } = useDataHelper();

  const renderErrorMessage = (message = null) => {
    return (
      !!message && <AimTypography variant="formError">{message}</AimTypography>
    );
  };

  const getLabel = (key) => {
    try {
      const label = labels[key].label || labels[key];
      return label;
    } catch (e) {
      console.error('FormComponents no label at', key, e);
      return `#NO-LABEL#${key}#`;
    }
  };

  const convertErrorMessage = (message) => {
    if (message) {
      const mess = message.split(']');
      const idx = Number(mess[0].split('[')[1]);
      const msg = mess[1].split('"')[1];
      return [idx, msg];
    }
    return [0, null];
  };

  const renderError = (key, valueKey = null) => {
    let message = `UNDEFINED ERROR ON ${key}`;
    const values = _getValues(key);
    if (Array.isArray(_errors[key])) {
      let messages = [];
      _errors[key].map((error) => {
        if (error) {
          const [idx, msg] = convertErrorMessage(
            error.value
              ? error.value.message
              : error.message
              ? error.message
              : null
          );
          if (values[idx]) {
            messages.push(
              `${values[idx][valueKey ? `${valueKey}` : 'value']}: ${msg}`
            );
          }
        }
      });
      message = messages.join(',');
    } else {
      message = _errors[key]?.message;
    }
    //TODO: implementare il messaggio di errore
    return (
      !!_errors[key] && (
        <AimTypography variant="formError">{`${message}`}</AimTypography>
      )
    );
  };

  const renderTextInput = ({
    key,
    name = null,
    isRequired = false,
    defaultValue = '',
    isDisabled = false,
    customOnChange = null,
    placeholder = null,
    type = 'text',
    variant = null,
    customInputProps = null,
  }) => {
    try {
      return (
        <>
          <Controller
            render={(props) => (
              <AimTextField
                key={key}
                textfieldVariant={variant || 'grey'}
                disabled={isDisabled}
                onChange={(e) => {
                  if (customOnChange) customOnChange(e.target.value);
                  props.onChange(e.target.value);
                }}
                customInputProps={customInputProps}
                type={type}
                name={name || key}
                value={props.value || defaultValue}
                label={getLabel(key)}
                formControlStyle={formControlStyle}
                placeholder={
                  isDisabled
                    ? labels.empty || 'No data'
                    : placeholder ||
                      `${
                        labels.placeholder.label || labels.placeholder
                      } ${getLabel(key)}`
                }
              />
            )}
            rules={{
              required: { isRequired },
            }}
            defaultValue={defaultValue}
            name={name || key}
            control={_control}
          />
          {renderError(key)}
        </>
      );
    } catch (e) {
      console.error('FormComponents at', key, e);
    }
  };

  const renderSwitchInput = ({
    key,
    name = null,
    isRequired = false,
    defaultValue = false,
    isDisabled = false,
    customOnChange = null,
    valueLabels,
    valueLabelStyle,
  }) => {
    const vlabel = valueLabels || ['OFF', 'ON'];
    try {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...formControlStyle,
          }}
        >
          {renderLabel(getLabel(key))}
          <Controller
            render={(props) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  ...valueLabelStyle,
                }}
              >
                <AimSwitch
                  key={key}
                  disabled={isDisabled}
                  onChange={(e) => {
                    if (customOnChange) customOnChange(e.target.checked);
                    props.onChange(e.target.checked);
                  }}
                  name={name || key}
                  checked={props.value || defaultValue}
                />
                <AimTypography variant="text">
                  {vlabel[props.value ? 1 : 0]}
                </AimTypography>
              </div>
            )}
            rules={{
              required: { isRequired },
            }}
            defaultValue={defaultValue}
            name={name || key}
            control={_control}
          />

          {renderError(key)}
        </div>
      );
    } catch (e) {
      console.error('FormComponents at', key, e);
    }
  };

  const renderSelectInput = ({
    key,
    name = null,
    array,
    keyName = null,
    boldKeyName = null,
    isRequired = false,
    isDisabled = false,
    customOnChange = null,
    emptyLabel,
  }) => {
    try {
      const emptyArray = !array || array.length === 0;
      if (emptyArray)
        array = [
          {
            id: '',
            value: emptyLabel || labels.empty || 'No selectable options',
          },
        ];
      return (
        <>
          <Controller
            render={(props) => {
              return (
                <AimSelect
                  disabled={emptyArray || isDisabled}
                  label={getLabel(key)}
                  value={props.value}
                  onChange={(e) => {
                    if (customOnChange) customOnChange(e.target.value);
                    props.onChange(e.target.value);
                  }}
                  formControlStyle={formControlStyle}
                >
                  {array.map((item) => {
                    const style = item[boldKeyName]
                      ? { fontWeight: 'bold' }
                      : { marginLeft: 8 };

                    return (
                      <AimSelectMenuItem
                        key={item.id}
                        value={keyName ? item[keyName] : item.value}
                        style={{ ...(boldKeyName && style) }}
                      >
                        {item.value}
                      </AimSelectMenuItem>
                    );
                  })}
                </AimSelect>
              );
            }}
            defaultValue={keyName ? array[0][keyName] : array[0].value}
            name={name || key}
            control={_control}
          />
          {renderError(key, keyName)}
        </>
      );
    } catch (e) {
      console.error('FormComponents at', key, e);
    }
  };

  const renderAutoCompleteInput = ({
    key,
    name,
    array,
    valueKeyName = null,
    textKeyName = null,
    customOnChange = null,
    isRequired = false,
    onInsertCustom = null,
    valueMutation = null,
  }) => {
    return (
      <div style={{ ...formControlStyle, margin: '8px 0 16px' }}>
        {array.length === 0 ? (
          <AimAutocomplete
            disabled
            options={[]}
            renderInput={(params) => (
              <TextField {...params} label={getLabel(key)}></TextField>
            )}
          />
        ) : (
          <Controller
            render={(props) => {
              return (
                <AimAutocomplete
                  multiple
                  id={key}
                  freeSolo={onInsertCustom ? true : false}
                  options={array || []}
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option
                      ? textKeyName
                        ? option[textKeyName]
                        : option.text
                      : '-'
                  }
                  value={props.value?.map((item) =>
                    array.find(
                      (obj) =>
                        obj.value ===
                        (valueKeyName ? item[valueKeyName] : item.value)
                    )
                  )}
                  onChange={(e, value) => {
                    const last = value.pop();
                    const newValue = value; //value è senza l'ultimo item (ho fatto pop)
                    //mi chiedo se l'ultimo item inserito era presente nell'array (in caso di cancellazione l'array dovrebbe essere aggiornato e quindi passa oltre)
                    if (last) {
                      const obj = array.find(
                        (obj) =>
                          obj.value ===
                            (valueKeyName ? last[valueKeyName] : last.value) ||
                          obj.value === last
                      );
                      if (!obj) {
                        const obj = array.find(
                          (obj) =>
                            obj.value ===
                            (valueKeyName ? last[valueKeyName] : last.value)
                        );
                        const parsedObj = {
                          [valueKeyName
                            ? `${valueKeyName}`
                            : 'value']: valueMutation
                            ? valueMutation(last)
                            : last,
                          [textKeyName
                            ? `${textKeyName}`
                            : 'text']: valueMutation
                            ? valueMutation(last)
                            : last,
                        };
                        if (onInsertCustom) {
                          onInsertCustom(parsedObj);
                          newValue.push(parsedObj);
                        }
                      } else {
                        newValue.push(obj);
                      }
                    }
                    if (customOnChange) customOnChange(newValue);
                    props.onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={getLabel(key)}></TextField>
                  )}
                />
              );
            }}
            defaultValue={
              valueKeyName ? array[0][valueKeyName] : array[0].value
            }
            name={name || key}
            control={_control}
          />
        )}
        {renderError(key, valueKeyName)}
      </div>
    );
  };

  const renderRichTextInput = ({
    key,
    isRequired = false,
    editorStyleOverride,
  }) => {
    try {
      return (
        <>
          <Controller
            render={(props) => {
              return (
                <AimRichText
                  label={getLabel(key)}
                  value={parseRichText(_getValues(key))}
                  formControlStyle={formControlStyle}
                  onChange={(value) => {
                    props.onChange(value);
                  }}
                  editorStyleOverride={editorStyleOverride}
                  defaultValue={null}
                />
              );
            }}
            rules={{
              required: { isRequired },
            }}
            name={key}
            control={_control}
          />
          {renderError(key)}
        </>
      );
    } catch (e) {
      console.error('FormComponents at', key, e);
    }
  };

  const renderFileSelectorInput = ({
    key,
    isRequired = false,
    onUpload,
    onRemove,
    onDownload,
    accept = '.jpeg,.jpg,.png',
  }) => {
    try {
      return (
        <>
          <Controller
            render={(props) => (
              <AimFileSelector
                label={getLabel(key)}
                selectFileLabel={`Upload ${labels[key].toLowerCase()}`}
                onUploadFile={(res) => {
                  onUpload(res);
                  props.onChange(res.parsed);
                }}
                onRemove={(file) => {
                  onRemove({ parsed: props.value, file: file });
                  props.onChange(null);
                }}
                onDownload={(fileData) => {
                  onDownload(fileData);
                }}
                file={props.value}
                formControlStyle={{
                  ...formControlStyle,
                }}
                dropzoneAdditionalProps={{ accept }}
              />
            )}
            rules={{
              required: { isRequired },
            }}
            name={key}
            control={_control}
          />
          {renderError(key)}
        </>
      );
    } catch (e) {
      console.error('FormComponents at', key, e);
    }
  };

  const renderButtons = ({
    buttonLabels,
    containerStyle,
    onCancelClick,
    onSaveClick,
    otherButtons,
    isDisabled = false,
  }) => {
    const isOk =
      (_errors ? Object.entries(_errors).length === 0 : false) && !isDisabled;
    if (!buttonLabels) {
      console.error('undefined buttonLabels', buttonLabels);
      return null;
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: 'calc(100% - 3px)',
          ...containerStyle,
        }}
      >
        <AimIconAndTextButton
          variant={'greyFill'}
          //TODO: fai i testi
          text={buttonLabels.cancel.label}
          onClick={() => {
            onCancelClick();
          }}
        >
          <ReplayIcon />
        </AimIconAndTextButton>
        <AimIconAndTextButton
          disabled={!isOk}
          variant={'greenFill'}
          text={buttonLabels.save.label}
          onClick={() => {
            onSaveClick();
          }}
        >
          <CheckIcon />
        </AimIconAndTextButton>
        {otherButtons ? otherButtons : null}
      </div>
    );
  };

  return {
    renderSelectInput,
    renderAutoCompleteInput,
    renderTextInput,
    renderFileSelectorInput,
    renderRichTextInput,
    renderSwitchInput,
    renderError,
    renderErrorMessage,
    renderButtons,
    formHelper,
  };
};
