import React, { useState } from 'react';

import {
  PublishPaxServiceDialog,
  PublishPaxServiceDefaultValues,
} from '../../../PublishPaxServiceDialog';

import {
  AimTypography,
  AimSelectForm,
  AimTextFieldForm,
  AimSelectMenuItem,
  AimIconButton,
} from '../../../../atoms';
import { theme } from '../../../../../theme';
import Grid from '@material-ui/core/Grid';
import LanguageIcon from '@material-ui/icons/Language';
import { constants } from '@aim/common';

export const TransferAssign = ({
  i18n,
  formControlStyle,
  control,
  intl,
  dataTravel,
  handlePublishTransferStatus,
}) => {
  const [publishServiceDialog, setPublishServiceDialog] = useState(
    PublishPaxServiceDefaultValues
  );
  const transportTypes = Object.values(constants.DestinationTravelTypes);

  const onPublishClick = (service) => {
    setPublishServiceDialog({
      isOpen: true,
      onAgree: () => handlePublishTransferStatus?.(service),
      onClose: () => setPublishServiceDialog(PublishPaxServiceDefaultValues),
      isPublished: service?.isPublished === 'true',
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingRight: '6px',
        }}
      >
        <AimTypography variant="text">{i18n.page.transfer.title}</AimTypography>
        {dataTravel?.transfer?.id ? (
          <AimIconButton
            aria-label="view"
            variant={
              dataTravel.transfer.isPublished === 'true'
                ? 'greenFill'
                : 'redFill'
            }
            onClick={() => onPublishClick(dataTravel.transfer)}
          >
            <LanguageIcon />
          </AimIconButton>
        ) : null}
      </div>
      <div
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          margin: 10,
        }}
      >
        <AimTypography variant="text">
          {i18n.page.transfer.oneWay}
        </AimTypography>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <AimTextFieldForm
              textfieldVariant="white"
              control={control}
              type="date"
              name="oneWayDate"
              placeholder="dd/mm/yyyy"
              label={i18n.page.transfer.transferDate}
              formControlStyle={formControlStyle}
            />
          </Grid>
          <Grid item xs={2}>
            <AimTextFieldForm
              textfieldVariant="white"
              control={control}
              name="oneWayTime"
              type="time"
              placeholder="HH:mm"
              label={i18n.page.transfer.pickUpTime}
              formControlStyle={formControlStyle}
            />
          </Grid>
          <Grid item xs={2}>
            <AimSelectForm
              selectVariant="white"
              control={control}
              name="oneWayFrom"
              label={i18n.page.transfer.from}
              formControlStyle={formControlStyle}
            >
              {transportTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={2}>
            <AimTextFieldForm
              textfieldVariant="white"
              control={control}
              name="oneWayPickUpLocation"
              label={i18n.page.transfer.pickUpPlace}
              formControlStyle={formControlStyle}
            />
          </Grid>
          <Grid item xs={2}>
            <AimSelectForm
              selectVariant="white"
              control={control}
              name="oneWayTo"
              label={i18n.page.transfer.to}
              formControlStyle={formControlStyle}
            >
              {transportTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={2}>
            <AimTextFieldForm
              textfieldVariant="white"
              control={control}
              name="oneWayPickUpDestination"
              label={i18n.page.transfer.pickUpDestination}
              formControlStyle={formControlStyle}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          margin: 10,
        }}
      >
        <AimTypography variant="text">
          {i18n.page.transfer.return}
        </AimTypography>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <AimTextFieldForm
              textfieldVariant="white"
              control={control}
              type="date"
              name="returnDate"
              placeholder="dd/mm/yyyy"
              label={i18n.page.transfer.transferDate}
              formControlStyle={formControlStyle}
            />
          </Grid>
          <Grid item xs={2}>
            <AimTextFieldForm
              textfieldVariant="white"
              control={control}
              name="returnTime"
              type="time"
              placeholder="HH:mm"
              label={i18n.page.transfer.pickUpTime}
              formControlStyle={formControlStyle}
            />
          </Grid>
          <Grid item xs={2}>
            <AimSelectForm
              selectVariant="white"
              control={control}
              name="returnFrom"
              label={i18n.page.transfer.from}
              formControlStyle={formControlStyle}
            >
              {transportTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={2}>
            <AimTextFieldForm
              textfieldVariant="white"
              control={control}
              name="returnPickUpLocation"
              label={i18n.page.transfer.pickUpPlace}
              formControlStyle={formControlStyle}
            />
          </Grid>
          <Grid item xs={2}>
            <AimSelectForm
              selectVariant="white"
              control={control}
              name="returnTo"
              label={i18n.page.transfer.to}
              formControlStyle={formControlStyle}
            >
              {transportTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={2}>
            <AimTextFieldForm
              textfieldVariant="white"
              control={control}
              name="returnPickUpDestination"
              label={i18n.page.transfer.pickUpDestination}
              formControlStyle={formControlStyle}
            />
          </Grid>
        </Grid>
      </div>
      <PublishPaxServiceDialog dialog={publishServiceDialog} intl={intl} />
    </>
  );
};
