import React, { useState } from 'react';

import { styled } from './../styled';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { theme } from '../../theme';
import { AimTypography } from './AimTypography';

const Expander = styled('div')({
  display: 'flex',
  flex: 1,
  cursor: 'pointer',
  marginTop: 10,
});

export const AimExpander = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleTaggleOnCLick = () => setIsExpanded(!isExpanded);

  return (
    <>
      <Expander onClick={handleTaggleOnCLick}>
        <AimTypography variant="h3" margin="0">
          {title}
        </AimTypography>
        <AimTypography variant="h3" margin="0">
          {isExpanded ? (
            <RemoveIcon style={{ color: theme.colors.greyScale.grey3 }} />
          ) : (
            <AddIcon style={{ color: theme.colors.primary.yellow }} />
          )}
        </AimTypography>
      </Expander>
      {isExpanded ? children : null}
    </>
  );
};
