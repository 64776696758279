import React, { useState, useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';

import { AimTypography } from './AimTypography';
import { styled } from './../styled';
import { theme } from './../../theme';
import { appState, constants } from '@aim/common';

const variantsBACKEND = {
  yellow: {
    border: '2px solid ' + theme.colors.primary.yellow,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  black: {
    border: '2px solid ' + theme.colors.primary.black,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  lightBlue: {
    border: '2px solid ' + theme.colors.secondary.lightBlue,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  red: {
    border: '2px solid ' + theme.colors.secondary.red,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  green: {
    border: '2px solid ' + theme.colors.secondary.green,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  violet: {
    border: '2px solid ' + theme.colors.secondary.violet,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  blue: {
    border: '2px solid ' + theme.colors.secondary.blue,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  none: {
    border: '2px solid transparent',
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  greyNone: {
    border: '2px solid transparent',
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      background: theme.colors.greyScale.backgroundGrey,
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: theme.colors.greyScale.backgroundGrey + ' !important',
      opacity: 0.23,
    },
  },
  yellowFill: {
    background: theme.colors.light.yellow,
    color: theme.colors.primary.yellow,
    '&:hover': {
      color: theme.colors.dark.yellow,
      background: theme.colors.light.yellow,
    },
    '&:disabled': {
      color: theme.colors.primary.yellow,
      background: theme.colors.light.yellow + ' !important',
      opacity: 0.23,
    },
  },
  greyFill: {
    background: theme.colors.light.grey,
    color: theme.colors.greyScale.grey3,
    '&:hover': {
      color: theme.colors.dark.grey,
      background: theme.colors.light.grey,
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey3,
      background: theme.colors.light.grey + ' !important',
      opacity: 0.23,
    },
  },
  darkGreyFill: {
    background: theme.colors.greyScale.grey4,
    color: theme.colors.greyScale.grey4,
    '&:hover': {
      color: theme.colors.dark.grey,
      background: theme.colors.greyScale.grey4,
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey4,
      background: theme.colors.greyScale.grey4 + ' !important',
      opacity: 0.23,
    },
  },
  lightBlueFill: {
    background: theme.colors.light.lightBlue,
    color: theme.colors.secondary.lightBlue,
    '&:hover': {
      color: theme.colors.dark.lightBlue,
      background: theme.colors.light.lightBlue,
    },
    '&:disabled': {
      color: theme.colors.secondary.lightBlue,
      background: theme.colors.light.lightBlue + ' !important',
      opacity: 0.23,
    },
  },
  redFill: {
    background: theme.colors.light.red,
    color: theme.colors.secondary.red,
    '&:hover': {
      color: theme.colors.dark.red,
      background: theme.colors.light.red,
    },
    '&:disabled': {
      color: theme.colors.secondary.red,
      background: theme.colors.light.red + ' !important',
      opacity: 0.23,
    },
  },
  brightRedFill: {
    background: theme.colors.secondary.red,
    color: theme.colors.secondary.red,
    '&:hover': {
      color: theme.colors.dark.red,
      background: theme.colors.secondary.red,
    },
    '&:disabled': {
      color: theme.colors.secondary.red,
      background: theme.colors.secondary.red + ' !important',
      opacity: 0.23,
    },
  },
  greenFill: {
    background: theme.colors.light.green,
    color: theme.colors.secondary.green,
    '&:hover': {
      color: theme.colors.dark.green,
      background: theme.colors.light.green,
    },
    '&:disabled': {
      color: theme.colors.secondary.green,
      background: theme.colors.light.green + ' !important',
      opacity: 0.23,
    },
  },
  violetFill: {
    background: theme.colors.light.violet,
    color: theme.colors.secondary.violet,
    '&:hover': {
      color: theme.colors.dark.violet,
      background: theme.colors.light.violet,
    },
    '&:disabled': {
      color: theme.colors.secondary.violet,
      background: theme.colors.light.violet + ' !important',
      opacity: 0.23,
    },
  },
  blueFill: {
    background: theme.colors.light.blue,
    color: theme.colors.secondary.blue,
    '&:hover': {
      color: theme.colors.dark.blue,
      background: theme.colors.light.blue,
    },
    '&:disabled': {
      color: theme.colors.secondary.blue,
      background: theme.colors.light.blue + ' !important',
      opacity: 0.23,
    },
  },
};

const buildButtonStyle = (variant, color, borderRadius) => {
  switch (variant) {
    case constants.TemplateButtonVariants.OUTLINED:
      return {
        borderRadius: borderRadius,
        background: 'transparent',
        border: '2px solid ' + color,
        color: theme.colors.primary.black,
        '&:hover': {
          borderRadius: borderRadius,
          border: '2px solid ' + color,
          background: 'transparent',
          color: theme.colors.primary.black,
        },
        '&:disabled': {
          borderRadius: borderRadius,
          border: '2px solid ' + theme.colors.greyScale.grey3,
          color: theme.colors.greyScale.grey3,
          background: 'transparent',
        },
      };
    case constants.TemplateButtonVariants.CONTAINED:
      return {
        borderRadius: borderRadius,
        background: color,
        color: theme.colors.primary.white,
        '&:hover': {
          borderRadius: borderRadius,
          background: color,
          color: theme.colors.primary.white,
        },
        '&:disabled': {
          color: theme.colors.greyScale.grey3,
          // background: theme.colors.greyScale.grey1,
          background: theme.colors.light.grey,
        },
      };
    case constants.TemplateButtonVariants.TEXT:
      return {
        background: 'transparent !important',
        color: color,
        border: 'none !important',
        '&:hover': {
          background: 'transparent !important',
          color: color,
          border: 'none !important',
        },
        '&:disabled': {
          background: 'transparent !important',
          color: theme.colors.greyScale.grey3,
          border: 'none !important',
        },
      };
  }
};

const variantsFRONTEND = ({ eventConfiguration }) => ({
  primary: buildButtonStyle(
    eventConfiguration.primaryButtonVariant,
    eventConfiguration.primaryColor,
    eventConfiguration.primaryButtonBorderRadius
  ),
  secondary: buildButtonStyle(
    eventConfiguration.secondaryButtonVariant,
    eventConfiguration.secondaryColor,
    eventConfiguration.secondaryButtonBorderRadius
  ),
  none: buildButtonStyle(
    'text',
    theme.colors.primary.black,
    eventConfiguration.secondaryButtonBorderRadius
  ),
  // primary: {
  //   height: 55,
  //   background: theme.palette.primary,
  //   color: `${theme.palette.secondary}`,
  //   borderRadius: 5,
  //   '&:hover': {
  //     background: theme.colors.primary.monza,
  //   },
  //   '&:disabled': {
  //     color: theme.colors.primary.lightGrey,
  //     background: '#959B9D !important',
  //   },
  // },
  // secondary: {
  //   height: 55,
  //   borderRadius: 5,
  //   background: theme.palette.secondary,
  //   color: theme.colors.primary.black,
  //   '&:hover': {
  //     color: `${theme.palette.primary} !important`,
  //   },
  //   '&:disabled': {
  //     color: theme.colors.secondary.lightGrey,
  //     background: '#959B9D !important',
  //   },
  // },
});

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

// TODO delete when restyle is completed
const variantMap = {
  yellow: 'primary',
  black: 'secondary',
  grey: 'secondary',
  lightBlue: 'secondary',
  red: 'secondary',
  green: 'secondary',
  violet: 'secondary',
  blue: 'secondary',
  none: 'secondary',
  yellowFill: 'primary',
  greyFill: 'secondary',
  lightBlueFill: 'secondary',
  redFill: 'primary',
  greenFill: 'primary',
  violetFill: 'secondary',
  blueFill: 'secondary',
};
const variantTemplate = appState.template.getValue();

const CustomIconButton = styled(IconButton)(
  ({
    variant = variantTemplate === constants.Templates.BACKEND
      ? 'yellow'
      : 'primary',
    small = false,
    large = false,
    eventConfiguration,
    forceVariantTemplate,
  }) => ({
    borderRadius: 4,
    margin: 4,
    height: small ? '30px !important' : '40px !important',
    padding: large ? '12px 40px' : 12,
    cursor: 'pointer',
    ...(variant &&
      getVariant({ eventConfiguration, variant, forceVariantTemplate })),
  })
);

const getVariant = ({ eventConfiguration, variant, forceVariantTemplate }) => {
  if (
    !forceVariantTemplate &&
    variantTemplate === constants.Templates.BACKEND
  ) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[forceVariantTemplate || variantTemplate]({
      eventConfiguration,
    });
    return template[variant] || template[variantMap[variant]];
  }
};

// eslint-disable-next-line react/display-name
export const AimIconAndTextButton = React.forwardRef(
  (
    {
      text,
      textColor,
      textVariant,
      isUpperCase = false,
      isLowerCase = false,
      children,
      isIconLeft = false,
      forceEventConfiguration,
      forceVariantTemplate,
      small,
      ...rest
    },
    ref
  ) => {
    const [eventConfiguration, setEventConfiguration] = useState({});

    useEffect(() => {
      const subscription = appState.eventConfiguration.subscribe((nextConfig) =>
        setEventConfiguration(forceEventConfiguration || nextConfig)
      );
      return () => subscription.unsubscribe();
    }, []);
    return (
      <CustomIconButton
        ref={ref}
        small={small}
        {...rest}
        disableRipple
        disableFocusRipple
        eventConfiguration={eventConfiguration}
        forceVariantTemplate={forceVariantTemplate}
      >
        {!isIconLeft && children}
        {text && (
          <AimTypography
            ellipsis
            margin={small ? 0 : 1}
            variant={textVariant || 'text'}
            style={{
              color: textColor || null,
              textTransform: isLowerCase
                ? 'lowercase'
                : isUpperCase
                ? 'uppercase'
                : 'none',
            }}
            boxStyle={{ fontFamily: eventConfiguration.font }}
          >
            {text}
          </AimTypography>
        )}
        {isIconLeft && children}
      </CustomIconButton>
    );
  }
);
