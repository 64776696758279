import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { appState, constants } from '@aim/common';

import { theme } from '../../../theme';
import { styled } from './../../styled';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './AimRichText.css';

const optionsWithImage = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'colorPicker',
  'link',
  'image',
  'history',
];

const options = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'colorPicker',
  'link',
  'history',
];

const CustomFormControl = styled(FormControl)({
  margin: theme.spacing(1),
  minWidth: 120,
  flex: 1,
  display: 'flex',
});

const CustomFormLabel = styled(FormLabel)(({ variantTemplate }) => ({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
  fontFamily:
    variantTemplate === constants.Templates.FRONTEND ? 'hind' : 'Roboto',
  '&.MuiFormLabel-root.Mui-focused': {
    color: 'black',
  },
}));

const formControlLabelPlacementTop = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const variants = {
  grey: theme.colors.greyScale.backgroundGrey,
  white: theme.colors.greyScale.white,
  transparent: 'transparent',
};

export const AimRichText = ({
  readOnly,
  value,
  onChange,
  isImageEnabled,
  label,
  displayLabel = true,
  labelPlacementLeft,
  formControlStyle,
  uploadFunction,
  variant = 'grey',
  editorStyleOverride = {},
  stripPastedStyles = true,
  onLengthChange,
  // maxLength,
  // maxLengthLabel,
  // counter,
  // counterLabel,
}) => {
  const [lastChangeType, setLastChangeType] = useState();
  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };
  const labelStyle = labelPlacementLeft ? formLabelLabelPlacementTop : {};
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value) {
      setEditorState(EditorState.createWithContent(convertFromRaw(value)));
    }
  }, []);

  const onEditorStateChange = async (newState) => {
    setEditorState(newState);
    setLastChangeType(newState.getLastChangeType());
    const raw = convertToRaw(newState.getCurrentContent());
    onChange && onChange(raw);
  };

  //uploadFunction MUST return object like this { data: { link: www.google.com/image } }
  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      try {
        uploadFunction(file).then(resolve);
      } catch (err) {
        console.error(err);
        reject();
      }
    });
  };

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();
    if (
      onLengthChange &&
      onLengthChange(currentContentLength - selectedTextLength + 1)
    )
      return 'handled';
  };

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (
      onLengthChange &&
      onLengthChange(
        currentContentLength + pastedText.length - selectedTextLength
      )
    )
      return 'handled';
  };

  const handleKeyCommandText = (command, editorState) => {
    if (command === 'backspace') {
      const currentContent = editorState.getCurrentContent();
      let currentContentLength = currentContent.getPlainText('').length;
      const selectedTextLength = getLengthOfSelectedText();

      currentContentLength =
        currentContentLength > 1 ? currentContentLength - 1 : 0;

      if (
        onLengthChange &&
        onLengthChange(currentContentLength - selectedTextLength)
      )
        return 'handled';
    }
  };

  const variantTemplate = appState.template.getValue();

  return (
    <>
      <CustomFormControl style={controlStyle}>
        {displayLabel ? (
          <CustomFormLabel variantTemplate={variantTemplate} style={labelStyle}>
            {label}
          </CustomFormLabel>
        ) : (
          <></>
        )}
        {/* {maxLength && maxLengthLabel && counterLabel && counter && !readOnly && (
          <Grid container>
            <AimTypography>{`${maxLengthLabel} ${
              maxLength || 0
            }`}</AimTypography>
            <AimTypography>
              {counterLabel}{' '}
              {editorState.getCurrentContent().getPlainText('').length}
            </AimTypography>
          </Grid>
        )} */}
        <div
          style={{
            background: variants[variant],
            borderRadius: 4,
            display: 'flex',
            flex: 1,
          }}
        >
          <Editor
            stripPastedStyles={stripPastedStyles}
            readOnly={readOnly}
            toolbarHidden={readOnly}
            wrapperStyle={{
              flex: 1,
            }}
            editorStyle={{
              flex: 1,
              background: variants[variant],
              marginRight: 10,
              paddingLeft: 10,
              minHeight: 180,
              maxHeight: 180,
              overflowY: 'auto',
              ...editorStyleOverride,
            }}
            toolbarStyle={{
              background: variants[variant],
              flex: 1,
            }}
            editorState={editorState}
            toolbar={{
              options: isImageEnabled ? optionsWithImage : options,
              inline: { inDropdown: false },
              blockType: { inDropdown: true, className: 'toolbar-dropdown' },
              fontSize: { inDropdown: true, className: 'toolbar-dropdown' },
              fontFamily: { inDropdown: true, className: 'toolbar-dropdown' },
              list: { inDropdown: false },
              textAlign: { inDropdown: false },
              link: { inDropdown: false },
              remove: {
                icon: undefined,
                className: undefined,
                component: undefined,
              },

              history: { inDropdown: false },
              image: {
                icon: undefined,
                className: undefined,
                component: undefined,
                popupClassName: 'toolbar-dropdown',
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: true,
                uploadCallback: uploadImageCallBack,
                previewImage: true,
                inputAccept:
                  'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: 'auto',
                  width: 'auto',
                },
              },
            }}
            onEditorStateChange={onEditorStateChange}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handlePastedText}
            handleKeyCommand={handleKeyCommandText}
          />
        </div>
      </CustomFormControl>
    </>
  );
};

export const richTextToHTML = (raw) => {
  // update empty rows text with \n before draftToHtml
  const blocks = raw?.blocks?.map((b) =>
    b.text === '' || b.text === ' ' ? { ...b, text: '\n' } : b
  );
  const nextRaw = { ...raw, blocks };
  const markup = nextRaw ? draftToHtml(nextRaw) : '';
  return markup;
};

export const AimRichTextForm = ({
  control,
  name,
  defaultValue,
  errors,
  ...rest
}) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <AimRichText
          value={props.value}
          onChange={(e) => props.onChange(e)}
          {...rest}
        />
      )}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p>{message || 'Error'}</p>}
      />
    )}
  </>
);
