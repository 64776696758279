import { aws } from '@aim/common';
import { useLoaderHelper } from './loaderHelper';
const { API } = aws;

export const fileSchema = `
  id
  extension
  originalName
  size
`;
/**
 * Handling Sponsor ProfileImage, CoverImage, Documents
 */
export const createFileQuery = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteFileQuery = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
    }
  }
`;

export const useFileHelper = (s3FolderBase, useLoader = true) => {
  const { showLoader, hideLoader } = useLoaderHelper();
  const handleDirectory = (dirPath) =>
    dirPath ? `${dirPath}${dirPath.slice(-1) === '/' ? '' : '/'}` : '';

  /**
   * downloadFromS3
   * @param dirPath: specifica il percorso delle directory su s3 successivo a s3FolderBase
   */
  const getS3Link = async (dirPath, fileData) => {
    const link = await aws.Storage.get(
      `${s3FolderBase}${handleDirectory(dirPath)}${fileData.id}${
        fileData.extension
      }`
    );
    return link;
  };

  const getAwsS3Link = (dirPath, fileData) =>
    `${s3FolderBase}${handleDirectory(dirPath)}${fileData.id}${
      fileData.extension
    }`;

  const downloadFromS3 = async (dirPath, fileData) => {
    useLoader && showLoader();
    const link = await getS3Link(dirPath, fileData);
    useLoader && hideLoader();
    window.open(link);
  };
  /**
   * Handling File CRUD
   */

  const createFile = (dirPath, fileData, localFile) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createFileQuery,
        variables: { input: fileData },
      })
        .then(async (response) => {
          try {
            const link = getAwsS3Link(dirPath, {
              id: response.data.createFile.id,
              extension: fileData.extension,
            });
            await aws.Storage.put(link, localFile);
          } catch (e) {
            console.error('website-create-s3-file', e);
            reject();
          }
          resolve(response.data.createFile.id);
        })
        .catch((e) => {
          console.error('website-create-file', e);
          reject();
        })
        .finally(() => {
          useLoader && hideLoader();
        });
    });

  const deleteFile = (dirPath, fileData) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: deleteFileQuery,
        variables: { input: { id: fileData.id } },
      })
        .then(async (response) => {
          try {
            const link = getAwsS3Link(dirPath, fileData);
            await aws.Storage.remove(link);
          } catch (e) {
            console.error('website-delete-s3-file', e);
            reject();
          }
          resolve(response.data.deleteFile);
        })
        .catch((e) => {
          console.error('website-delete-file', e);
          reject();
        })
        .finally(() => {
          useLoader && hideLoader();
        });
    });

  return {
    createFile,
    deleteFile,
    downloadFromS3,
    getS3Link,
  };
};
