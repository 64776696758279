export default {
  TravelTransfer: (intl) => ({
    page: {
      snackbar: {
        success: intl.formatMessage({
          description: 'snackbar success',
          defaultMessage: 'Success',
        }),
        error: intl.formatMessage({
          description: 'snackbar error',
          defaultMessage: 'Error',
        }),
        maxOccupationError: intl.formatMessage({
          description: 'snackbar occupation error',
          defaultMessage: 'Exceeded the maximum occupancy',
        }),
      },
      title: intl.formatMessage({
        description: 'noParticipant label',
        defaultMessage: 'Travel & Transfer - Assign',
      }),
      save: intl.formatMessage({
        description: 'Save',
        defaultMessage: 'save',
      }),
      cancel: intl.formatMessage({
        description: 'Cancel',
        defaultMessage: 'Cancel',
      }),
      travelConfigurationCompleted: intl.formatMessage({
        description: 'configuration completed',
        defaultMessage: 'Configuration completed',
      }),
      noParticipant: intl.formatMessage({
        description: 'noParticipant label',
        defaultMessage: 'Please select at least one participant',
      }),
      selectedParticipants: intl.formatMessage({
        description: 'selected participants',
        defaultMessage: 'N° of selected participants: ',
      }),
      generalPreferences: intl.formatMessage({
        description: 'General Preferences',
        defaultMessage: 'General Preferences',
      }),
      yes: intl.formatMessage({
        description: 'Yes',
        defaultMessage: 'Yes',
      }),
      authBooking: intl.formatMessage({
        description:
          'I authorise AIM Travel to proceed with the booking of my Services',
        defaultMessage:
          'I authorise AIM Travel to proceed with the booking of my Services',
      }),
      useAgencyToBook: intl.formatMessage({
        description: 'I use an agency to book the Services*',
        defaultMessage: 'I use an agency to book the Services*',
      }),
      autonomousBooking: intl.formatMessage({
        description:
          'I prefer to make my own booking and purchase and request a refund afterwards',
        defaultMessage:
          'I prefer to make my own booking and purchase and request a refund afterwards',
      }),
      noAnswer: intl.formatMessage({
        description: 'No Answer',
        defaultMessage: 'No Answer',
      }),
      no: intl.formatMessage({
        description: 'No',
        defaultMessage: 'No',
      }),
      backButton: {
        label: intl.formatMessage({
          description: 'back',
          defaultMessage: 'Back',
        }),
        tooltip: intl.formatMessage({
          description: 'back',
          defaultMessage: 'Back',
        }),
      },
      name: intl.formatMessage({
        description: 'Name',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'Surname',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'Email user',
        defaultMessage: 'Email user',
      }),
      consentForTheCheckedBaggage: intl.formatMessage({
        description: 'consent for the checked baggage',
        defaultMessage: 'Consent for the checked baggage',
      }),
      issueTickets: intl.formatMessage({
        description: 'issue tickets according to the indications provided',
        defaultMessage: 'Issue tickets according to the indications provided',
      }),
      proposal: intl.formatMessage({
        description: 'send me proposal accoding to the specifications',
        defaultMessage: 'Send me proposal accoding to the specifications',
      }),
      uploadByDocId: intl.formatMessage({
        description: 'upload by document ID',
        defaultMessage: 'Upload by Document ID',
      }),
      uploadForPurchase: intl.formatMessage({
        description: 'upload for purchase through Agency',
        defaultMessage: 'Upload for purchase through Agency',
      }),
      uploadForAutoBooking: intl.formatMessage({
        description: 'upload for autonomous booking',
        defaultMessage: 'Upload for autonomous booking',
      }),
      participant: {
        username: intl.formatMessage({
          description:
            'participation assign travel transfer header id column label',
          defaultMessage: 'ID',
        }),
        name: intl.formatMessage({
          description:
            'participation assign travel transfer header name column label',
          defaultMessage: 'Name',
        }),
        surname: intl.formatMessage({
          description:
            'participation assign travel transfer header surname column label',
          defaultMessage: 'Surname',
        }),
        email: intl.formatMessage({
          description:
            'participation assign travel transfer header email column label',
          defaultMessage: 'Email user',
        }),
        profile: intl.formatMessage({
          description:
            'participation assign travel transfer header profile column label',
          defaultMessage: 'Profile',
        }),
        typology: intl.formatMessage({
          description:
            'participation assign travel transfer header typology column label',
          defaultMessage: 'Typology',
        }),
        cluster: intl.formatMessage({
          description:
            'participation assign travel transfer header cluster column label',
          defaultMessage: 'Cluster',
        }),
        invited: intl.formatMessage({
          description:
            'participation assign travel transfer header invited column label',
          defaultMessage: 'Invited',
        }),
        status: intl.formatMessage({
          description:
            'participation assign travel transfer header status column label',
          defaultMessage: 'Status',
        }),
      },
      generalInfo: {
        title: intl.formatMessage({
          description: 'GENERAL INFO',
          defaultMessage: 'GENERAL INFO',
        }),
        travel: intl.formatMessage({
          description: 'travel included',
          defaultMessage: 'Travel Included',
        }),
        accompanying: intl.formatMessage({
          description: 'accompanying person',
          defaultMessage: 'Accompanying person',
        }),
        businessUnit: intl.formatMessage({
          description: 'business unit label',
          defaultMessage: 'Business unit',
        }),
        orderCode: intl.formatMessage({
          description: 'order code label',
          defaultMessage: 'Order code',
        }),
        transferA: intl.formatMessage({
          description: 'transfer A included',
          defaultMessage: 'Included Transfer A',
        }),
        transferR: intl.formatMessage({
          description: 'transfer R included',
          defaultMessage: 'Included Transfer R',
        }),
        yes: intl.formatMessage({
          description: 'yes',
          defaultMessage: 'yes',
        }),
        no: intl.formatMessage({
          description: 'no',
          defaultMessage: 'no',
        }),
        travelPreferences: intl.formatMessage({
          description: 'travel preferences',
          defaultMessage: 'Travel Preferences',
        }),
      },
      travelsTable: {
        table: {
          title: intl.formatMessage({
            description: 'title label',
            defaultMessage: 'ASSIGNED TRAVEL',
          }),
          columns: {
            travelType: intl.formatMessage({
              description: 'travel type label',
              defaultMessage: 'Type of travel',
            }),
            code: intl.formatMessage({
              description: 'code label',
              defaultMessage: 'Code travel',
            }),
            start: intl.formatMessage({
              description: 'start label',
              defaultMessage: 'Start',
            }),
            startTime: intl.formatMessage({
              description: 'start time label',
              defaultMessage: 'Start time',
            }),
            arrive: intl.formatMessage({
              description: 'arrive label',
              defaultMessage: 'Arrive',
            }),
            arriveTime: intl.formatMessage({
              description: 'arrive time label',
              defaultMessage: 'Arrive time',
            }),
            oneWayReturn: intl.formatMessage({
              description: 'one way / return label',
              defaultMessage: 'A/R',
            }),
            departureDate: intl.formatMessage({
              description: 'departure date label',
              defaultMessage: 'Departure date',
            }),
            arrivalDate: intl.formatMessage({
              description: 'arrival date label',
              defaultMessage: 'Arrival date',
            }),
            publicationDate: intl.formatMessage({
              description: 'publication date label',
              defaultMessage: 'Pubblication date',
            }),
            pnr: intl.formatMessage({
              description: 'pnr label',
              defaultMessage: 'PNR',
            }),
            order: intl.formatMessage({
              description: 'order label',
              defaultMessage: 'Order',
            }),
          },
          dialog: {
            title: intl.formatMessage({
              description: 'delete dialog title',
              defaultMessage: 'Cancellation confirmation',
            }),
            message: intl.formatMessage({
              description: 'Delete dialog message',
              defaultMessage: 'Are you sure you want to proceed?',
            }),
            agreeText: intl.formatMessage({
              description: 'Delete dialog agree text',
              defaultMessage: 'Confirm',
            }),
          },
        },
      },
      dialog: {
        publicationDate: {
          title: intl.formatMessage({
            description: 'publication date dialog title',
            defaultMessage: 'Publication date',
          }),
          message: intl.formatMessage({
            description: 'publication date dialog message',
            defaultMessage: 'Choose publication date',
          }),
          agreeText: intl.formatMessage({
            description: 'publication date dialog agree text',
            defaultMessage: 'Save',
          }),
        },
      },
      transfer: {
        title: intl.formatMessage({
          description: 'TRANSFER ',
          defaultMessage: 'TRANSFER',
        }),
        oneWay: intl.formatMessage({
          description: 'One Way ',
          defaultMessage: 'A',
        }),
        return: intl.formatMessage({
          description: 'return ',
          defaultMessage: 'R',
        }),
        transferDate: intl.formatMessage({
          description: 'Date ',
          defaultMessage: 'Date',
        }),
        returnDate: intl.formatMessage({
          description: 'Pick Up Date ',
          defaultMessage: 'Pick Up Date',
        }),
        pickUpTime: intl.formatMessage({
          description: 'Time pick up ',
          defaultMessage: 'Time pick up',
        }),
        from: intl.formatMessage({
          description: 'From ',
          defaultMessage: 'From',
        }),
        to: intl.formatMessage({
          description: 'To',
          defaultMessage: 'To',
        }),
        pickUpPlace: intl.formatMessage({
          description: 'Place Pick Up',
          defaultMessage: 'Place Pick Up',
        }),
        pickUpDestination: intl.formatMessage({
          description: 'Destination Pick Up',
          defaultMessage: 'Destination Pick Up',
        }),
      },
    },
  }),
};
