import { Extension } from '@tiptap/core';

export const FullScreen = Extension.create({
  name: 'fullScreen',

  addStorage() {
    return {
      isFullScreen: false,
    };
  },

  addOptions() {
    return {
      setIsEditorFullScreen: () => {},
    };
  },

  addCommands() {
    return {
      toggleFullScreen: (bubbleMenuOptions = {}) => ({}) => {
        this.storage.isFullScreen = !this.storage.isFullScreen;
        this.options.setIsEditorFullScreen(
          (isEditorFullScreen) => !isEditorFullScreen
        );
        return true;
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-f': () => {
        this.editor.commands.toggleFullScreen();
      },
    };
  },
});
