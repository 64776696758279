import React from 'react';

import { isMobile } from 'react-device-detect';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { styled } from '../../styled';
import { richTextToHTML } from '../../atoms/AimRichText/AimRichText';
import { AimTypography } from '../../atoms/AimTypography';
import { AimIconAndTextButton } from '../../atoms/AimIconAndTextButton';

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
});

const DescriptionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

export const PublicLandingPageTemplate = ({ model, history }) => {
  const isRegistrationButtonVisible = () => {
    const now = new Date();
    const start = new Date(model.registrationActivationDate);
    const end = new Date(model.registrationDeactivationDate);
    const isVisible = now >= start && now <= end ? true : false;
    return isVisible;
  };
  const isSurveyButtonVisible = () => {
    const now = new Date();
    const start = new Date(model.surveyActivationDate);
    const end = new Date(model.surveyDeactivationDate);
    const isVisible =
      (now >= start && now <= end ? true : false) &&
      model.publicLandingPageSurveyExist;
    return isVisible;
  };

  if (!model) return null;

  return (
    <RootContainer
      style={{ backgroundImage: `url("${model.coverImage || ''}")` }}
    >
      <Container>
        <AimTypography
          variant="body1"
          component="h2"
          style={{
            fontSize: isMobile ? '2rem' : '6rem',
            fontWeight: '700',
            color: model.titleColor,
            marginBottom: '20px',
          }}
          boxStyle={{ textAlign: isMobile ? 'center' : 'left' }}
        >
          {model?.title}
        </AimTypography>
        <DescriptionContainer>
          <div
            style={{
              color: model.descriptionColor,
              maxWidth: '80%', // 'calc(100% - 40px)',
              textAlign: 'center',
              marginBottom: '30px',
            }}
            dangerouslySetInnerHTML={{
              __html: richTextToHTML(model.description),
            }}
          />
        </DescriptionContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          {isRegistrationButtonVisible() && (
            <AimIconAndTextButton
              style={{ width: '235px', height: '59px' }}
              variant="primary"
              text={model.registrationButtonLabel || model.registerText}
              onClick={() => history && history.push(model.registerLink)}
              textColor="white"
              forceEventConfiguration={model.forceEventConfiguration}
              forceVariantTemplate={model.forceVariantTemplate}
            >
              <ArrowForwardIcon style={{ color: 'white' }} />
            </AimIconAndTextButton>
          )}
          {isRegistrationButtonVisible() && (
            <AimIconAndTextButton
              style={{ width: '235px', height: '59px' }}
              variant="primary"
              text={model.loginButtonLabel || model.loginText}
              onClick={() => history && history.push(model.loginLink)}
              textColor="white"
              // disabled={isButtonDisabled()}
              forceEventConfiguration={model.forceEventConfiguration}
              forceVariantTemplate={model.forceVariantTemplate}
            >
              <ArrowForwardIcon style={{ color: 'white' }} />
            </AimIconAndTextButton>
          )}
          {isSurveyButtonVisible() && (
            <AimIconAndTextButton
              style={{ width: '235px', height: '59px' }}
              variant="primary"
              text={model.surveyButtonLabel || model.surveyText}
              onClick={() => history && history.push(model.surveyLink)}
              textColor="white"
              forceEventConfiguration={model.forceEventConfiguration}
              forceVariantTemplate={model.forceVariantTemplate}
            >
              <ArrowForwardIcon style={{ color: 'white' }} />
            </AimIconAndTextButton>
          )}
        </div>
      </Container>
    </RootContainer>
  );
};
