import { aws, appState } from '@aim/common';
const { API } = aws;

export const listFoldersQuery = /* GraphQL */ `
  query ListFolderss {
    listFolderss {
      items {
        updatedAt
        path
        id
        createdAt
      }
    }
  }
`;

export const listFolders = () =>
  new Promise((resolve, reject) => {
    const { API } = aws;
    API.graphql({ query: listFoldersQuery })
      .then(async (response) => {
        resolve(response?.data?.listFolderss?.items);
      })
      .catch((e) => {
        console.error('list-folders', e);
        reject();
      });
  });
