import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createModificationRequest = /* GraphQL */ `
  mutation CreateModificationRequest(
    $input: CreateModificationRequestInput!
    $condition: ModelModificationRequestConditionInput
  ) {
    createModificationRequest(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateModificationRequest = /* GraphQL */ `
  mutation UpdateModificationRequest(
    $input: UpdateModificationRequestInput!
    $condition: ModelModificationRequestConditionInput
  ) {
    updateModificationRequest(input: $input, condition: $condition) {
      id
    }
  }
`;

export const listModificationRequests = /* GraphQL */ `
  query ListModificationRequests($eventId: ID) {
    listModificationRequests(filter: { eventId: { eq: $eventId } }) {
      items {
        id
        eventId
        requestId
        type
        requestStatus
        participationId
        isDeleted
        requestedBy {
          id
          givenName
          familyName
        }
        participationRequest {
          cluster
          sponsorList {
            id
            name
          }
        }
      }
    }
  }
`;

export const getModificationRequest = /* GraphQL */ `
  query GetModificationRequest($id: ID!) {
    getModificationRequest(id: $id) {
      id
      participationId
      participationRequest {
        id
        givenName
        familyName
        email
        cluster
        isParticipant
        isReviewer
        isSpeaker
        feeDateRange {
          id
        }
        profile {
          id
        }
        typology {
          id
        }
        eventId
        delegation {
          id
        }
        fieldValues {
          items {
            id
            value
            fieldDefinition {
              id
              options
              label
              placeholder
              isDeleted
              controlType
            }
          }
        }
      }
      eventId
      requestId
      type
      requestStatus
      participationId
      isDeleted
      requestedBy {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
      }
    }
  }
`;

export const useModificationRequest = () => {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createModificationRequest,
        variables: { input },
      })
        .then((response) => {
          const responseData = response.data.createModificationRequest;
          resolve(responseData);
        })
        .catch((e) => {
          console.error('create-modification-request', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateModificationRequest,
        variables: { input },
      })
        .then((response) => {
          const responseData = response.data.updateModificationRequest;
          resolve(responseData);
        })
        .catch((e) => {
          console.error('udpate-modification-request', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const list = (eventId, useLoader = true) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({
        query: listModificationRequests,
        variables: { eventId },
      })
        .then((response) =>
          resolve(response.data.listModificationRequests.items)
        )
        .catch((e) => {
          console.error('list-modification-request', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const get = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getModificationRequest,
        variables: { id },
      })
        .then((response) => {
          resolve(response.data.getModificationRequest);
        })
        .catch((e) => {
          console.error('get-modification-request', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { create, update, list, get };
};
