import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ArrowForward from '@material-ui/icons/ArrowForward';

import { appState, constants } from '@aim/common';
import {
  AimTypography,
  AimIconAndTextButton,
  // AimSnackbarSeverity,
  // AimSnackbar,
} from '../../atoms';
import translation from './translation';

export const PaymentSuccess = ({ intl, history, eventId }) => {
  const [participation] = useState(
    appState.user.getValue()?.userAndParticipation?.participation
  );
  // const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [order, setOrder] = useState({});

  useEffect(() => {
    checkoutResponse();
  }, []);

  const checkoutResponse = () => {
    // const isAllotment = location.pathname.includes('allotment');
    const query = new URLSearchParams(location.search);
    const order = query.get('order');
    const serviceType = query.get('t');
    setOrder({
      orderId: order,
      serviceType,
    });
  };

  const i18n = translation.paymentSuccess(intl);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AimTypography variant="h2Regular" margin={0}>
          {i18n.paymentConfirmMessageConfirm}
        </AimTypography>
        <AimTypography variant="h2Regular" margin={0}>
          {i18n.paymentConfirmMessageTitle}
        </AimTypography>
        {order.serviceType !== constants.ProductType.allotment && (
          <AimTypography variant="textBold" margin={0}>
            {i18n.paymentConfirmMessageSubtitle}
          </AimTypography>
        )}
        <AimTypography variant="h2Regular" margin={0}>
          {i18n.orderNumber} {order.orderId}
        </AimTypography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <AimIconAndTextButton
            isUpperCase
            text={i18n.goToPersonalArea}
            onClick={() =>
              history.push(`/events/${eventId}/user/${participation.id}`)
            }
          >
            <ArrowForward />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            isUpperCase
            text={i18n.goToServices}
            onClick={() =>
              history.push(
                `/events/${eventId}/user/${participation.id}/services`
              )
            }
          >
            <ArrowForward />
          </AimIconAndTextButton>
        </div>
      </div>
      {/* <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar> */}
    </>
  );
};
