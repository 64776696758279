import React, { useEffect, useState } from 'react';
import { PluginComponent, getDecorated } from 'react-markdown-editor-lite';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { nanoid } from 'nanoid';
import {
  AimDialog,
  AimAutocomplete,
  AimTypography,
  AimIconAndTextButton,
} from '@aim/components';
import Grid from '@material-ui/core/Grid';
import { aws, fileHelper, appState, constants } from '@aim/common';
import { listFolders } from './gqlHelper';

const mainFolderPath = 'aim_main/';

const getUploadPlaceholder = (file, onImageUpload) => {
  const placeholder = getDecorated('', 'image', {
    target: `Uploading_${nanoid()}`,
    imageUrl: '',
  }).text;
  const uploaded = new Promise((resolve) => {
    const handleUploaded = (url) => {
      resolve(
        getDecorated('', 'image', {
          target: file.name,
          imageUrl: url,
        }).text
      );
    };
    onImageUpload(file, handleUploaded).then(handleUploaded);
  });
  return { placeholder, uploaded };
};

const MarkdownUploadFromRepoPlugin = (props) => {
  const [isRepoDialogOpen, setIsRepoDialogOpen] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedFolderPath, setSelectedFolderPath] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);

  const handleClick = () => setIsRepoDialogOpen(true);

  useEffect(() => {
    listFolders().then((result) => {
      const foldersPaths = result.map((x) => {
        return {
          path: x.path,
          label: x.path.substring(mainFolderPath.length),
          id: x.id,
        };
      });
      setFolders([...foldersPaths]);
    });
  }, []);

  useEffect(() => {
    if (selectedFolderPath) {
      listFilesInPath({ path: selectedFolderPath });
    } else {
      setDisplayFiles([]);
    }
  }, [selectedFolderPath]);

  const listFilesInPath = async ({ path }) => {
    let res = await aws.Storage.list(path, { pageSize: 'ALL' });
    res = res.filter((x) => !x.key.endsWith('/'));

    const filesToDisplay = res
      .map((x) => {
        const fileData = x.key.split('/');
        const fileNameWithExtension = fileData[fileData.length - 1];

        const fileExtensions = fileNameWithExtension.split('.');
        const fileExtension = `.${fileExtensions[fileExtensions.length - 1]}`;
        const fileName = fileExtensions
          .slice(0, fileExtensions.length - 1)
          .join('.');

        const filePath = fileData.slice(0, fileData.length - 1).join('/');
        return {
          ...x,
          label: x.key.substring(selectedFolderPath.length),
          dirPath: filePath,
          id: fileName,
          extension: fileExtension,
          originalName: fileNameWithExtension,
        };
      })
      .filter((f) =>
        (props.editorConfig.imageAccept || '')
          .split(',')
          .includes(f.extension.toLowerCase())
      );

    setDisplayFiles(filesToDisplay);
  };

  const uploadFromRepo = async () => {
    const file = await fileHelper.getS3LinkWithDownload({
      dirPath: selectedFile.dirPath,
      fileData: selectedFile,
    });
    const localFile = file.Body;
    localFile.name = selectedFile.originalName;

    if (props.editorConfig.onImageUpload) {
      const placeholder = getUploadPlaceholder(
        localFile,
        props.editorConfig.onImageUpload
      );
      props.editor.insertPlaceholder(
        placeholder.placeholder,
        placeholder.uploaded
      );
    }
    setIsRepoDialogOpen(false);
  };

  return (
    <span
      className="button button-type-counter"
      title="Image from repository"
      style={{ display: 'flex' }}
      onClick={handleClick}
    >
      <AllInboxIcon style={{ fontSize: 18, alignSelf: 'center' }} />
      <AimDialog
        title={'Upload from Repository'}
        open={isRepoDialogOpen}
        onClose={(e) => {
          console.log('e', e);
          e.stopPropagation();
          setIsRepoDialogOpen(false);
        }}
        hideAgreeButton
        hideCancelButton
        fullWidth
        minWidth="md"
      >
        {/* <Grid container> */}
        {/* <Grid item xs={12}> */}
        <AimTypography>{`Folders`}</AimTypography>
        <AimAutocomplete
          formControlStyle={{ width: 'calc(100% - 10px)' }}
          autoHighlight
          // value={props.value || []}
          options={folders}
          getOptionLabel={(option) => option.label || []}
          // getOptionSelected={(opt, value) => opt.value === value?.value}
          onChange={(e, value) => {
            setSelectedFolderPath(value ? value.path : null);
          }}
        />
        {/* </Grid> */}

        {/* <Grid item xs={12}> */}
        {displayFiles.length > 0 ? (
          <>
            <AimTypography>{`Files`}</AimTypography>
            <AimAutocomplete
              formControlStyle={{ width: 'calc(100% - 10px)' }}
              autoHighlight
              // value={props.value || []}
              options={displayFiles}
              getOptionLabel={(option) => option.label || []}
              // getOptionSelected={(opt, value) => opt.value === value?.value}
              onChange={(e, value) => {
                setSelectedFile(value);
              }}
            />
          </>
        ) : (
          <AimTypography>
            {selectedFolderPath
              ? `No files for this folder.`
              : `Pick a folder.`}
          </AimTypography>
        )}
        {/* </Grid> */}

        {/* <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            > */}
        <AimIconAndTextButton
          variant={`greenFill`}
          large
          text={`Upload`}
          disabled={selectedFile ? false : true}
          onClick={() => {
            uploadFromRepo();
          }}
        />
        {/* </Grid> */}
        {/* </Grid> */}
      </AimDialog>
    </span>
  );
};

MarkdownUploadFromRepoPlugin.align = 'left';
MarkdownUploadFromRepoPlugin.pluginName = 'image-from-repo';

export default MarkdownUploadFromRepoPlugin;
