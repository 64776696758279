export default {
  palette: {
    action: {
      selected: '#FFD400',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        display: 'none',
      },
    },
    MuiTextField: {
      root: {
        height: 50,
        backgroundColor: '#fff',
        marginTop: 4,
        borderRadius: 4,
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000',
      },
    },
    MuiIconButton: {
      root: {
        color: 'black',
        // border: '2px solid #FFD400',
        borderRadius: 4,
      },
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  props: {
    MuiTextField: {
      variant: 'standard',
      InputProps: {
        disableUnderline: true,
      },
      inputProps: {
        style: {
          padding: 0,
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
    },
    appBarProps: {
      color: '#1A171B',
    },
  },
  shape: {
    borderRadius: 13,
  },
};
