import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const listRoutesByEventQuery = `
  query listRoutesByEvent($eventId: ID!, $travelType: String) {
    getEvent(id: $eventId) {
      travelRoutes(filter: { travelType: { eq: $travelType } isDeleted: {ne: true	}}) {
        items {
          id
          isDeleted
          startDate
          endDate
          transportCode
          travelType
          terminalIn {
            name
          }
          terminalOut {
            name
          }
          date
          arrivalDate
          pnrCode
          costPerPerson
          occupation
          travel(filter: { isDeleted: { ne: true } }) {
            items {
              id
              isDeleted
            }
          }
        }
      }
    }
  }
`;

const getTravelRouteQuery = `
  query GetTravelRoute($id: ID!) {
    getTravelRoute(id: $id) {
      endDate
      startDate
      id
      terminalIn {
        name
      }
      terminalOut {
        name
      }
      transportCode
      travelType
    }
  }
`;

export const getParticipationQuery = /* GraphQL */ `
  query GetParticipation($id: ID!) {
    getParticipation(id: $id) {
      id
      createdAt
      updatedAt
      givenName
      familyName
      businessName
      email
      phone
    }
  }
`;

const listEventParticipationsQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      participations(filter: { isDeleted: { ne: true } }) {
        items {
          id
          email
          familyName
          givenName
          phone
        }
      }
    }
  }
`;

export const listRoutesByEvent = (eventId, travelType) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: listRoutesByEventQuery,
      variables: { eventId, travelType },
    })
      .then((response) =>
        resolve([...response?.data?.getEvent.travelRoutes.items])
      )
      .catch((e) => {
        console.error('list-travel-routes-by-event', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getTravelRoute = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getTravelRouteQuery, variables: { id } })
      .then((response) =>
        resolve({
          ...response?.data?.getTravelRoute,
        })
      )
      .catch((e) => {
        console.error('get-travel-route', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getParticipation = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getParticipationQuery, variables: { id } })
      .then((response) =>
        resolve({
          ...response?.data?.getParticipation,
        })
      )
      .catch((e) => {
        console.error('get-participation', e);
        reject();
      })
      .finally(hideLoader);
  });

export const listParticipations = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: listEventParticipationsQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.participations.items);
      })
      .catch((e) => {
        console.error('list-event-participations', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
