import { appState, constants } from '@aim/common';

const scalingFactor = 8;

const themeBACKEND = {
  spacing: (value) => value * scalingFactor,
  colors: {
    primary: {
      white: '#FFFFFF',
      yellow: '#FFD400',
      black: '#000000',
      red: '#da0512',
    },
    secondary: {
      lightBlue: '#30CFD0',
      red: '#FE0C4E',
      green: '#29E129',
      violet: '#9336FD',
      blue: '#3A86FF',
    },
    greyScale: {
      white: '#FFFFFF',
      grey1: '#95989A',
      grey2: '#ECEAF1',
      grey3: '#84819A',
      grey4: '#A2A4A1',
      grey5: '#ADADAD',
      backgroundGrey: '#F8F6FA',
      black: '#000000',
    },
    light: {
      yellow: '#FFF3B8',
      grey: '#EEEDF1',
      lightBlue: '#CFF4F4',
      red: '#FFCEDB',
      green: '#BEF6BE',
      violet: '#EEDEFF',
      blue: '#C9DEFF',
    },
    dark: {
      yellow: '#D9B918',
      grey: '#000000',
      lightBlue: '#14A6A7',
      red: '#C01C49',
      green: '#1FB81F',
      violet: '#6C15CF',
      blue: '#145BCB',
    },
    filters: {
      background: '#30CFD021',
    },
  },
};

const themeFRONTEND = {
  spacing: (value) => value * scalingFactor,
  colors: {
    primary: {
      red: '#da0512',
      grey: '#555555',
      white: '#FFFFFF',
      black: '#323232',
      darkRed: '#A31011',
      lightRed: '#E8A7A8',
    },
    secondary: {
      lightBlue: '#30CFD0',
      red: '#FE0C4E',
      grey: '#6F8E99',
      darkGrey: '#51707B',
      lightGrey: '#CAD5D9',
    },
    // TODO delete when restyle is completed
    greyScale: {
      white: '#FFFFFF',
      grey1: '#95989A',
      grey2: '#ECEAF1',
      grey3: '#84819A',
      grey4: '#A2A4A1',
      grey5: '#ADADAD',
      grey6: '#F4F3F5',
      backgroundGrey: '#F8F6FA',
      black: '#000000',
    },
    light: {
      yellow: '#FFF3B8',
      grey: '#EEEDF1',
      lightBlue: '#CFF4F4',
      red: '#FFCEDB',
      green: '#BEF6BE',
      violet: '#EEDEFF',
      blue: '#C9DEFF',
    },
    dark: {
      yellow: '#D9B918',
      grey: '#000000',
      lightBlue: '#14A6A7',
      red: '#C01C49',
      green: '#1FB81F',
      violet: '#6C15CF',
      blue: '#145BCB',
    },
    filters: {
      background: '#30CFD021',
    },
  },
};

const themes = {
  [constants.Templates.BACKEND]: {
    ...themeBACKEND,
    palette: {
      primary: themeBACKEND.colors.primary.yellow,
      secondary: themeBACKEND.colors.greyScale.grey3,
    },
  },
  [constants.Templates.FRONTEND]: {
    ...themeFRONTEND,
    palette: {
      primary: themeFRONTEND.colors.primary.red,
      secondary: themeFRONTEND.colors.primary.white,
    },
  },
};

const variantTemplate = appState.template.getValue();

export const theme = themes[variantTemplate] || themeBACKEND;
