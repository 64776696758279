import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { UploadFromRepoMenuState } from '../extensions/UploadFromRepoBubbleMenuHandler';
import {
  useRichTextEditorContext,
  ControlledBubbleMenu,
  insertImages,
} from 'mui-tiptap';
import UploadFromRepoBubbleMenuContent from './UploadFromRepoBubbleMenuContent';
import { fileHelper } from '@aim/common';
import { onImageUpload } from '../tipTapRichTextUtilities';

const useStyles = makeStyles({
  name: { UploadFromRepoBubbleMenu },
})((theme) => ({
  content: {
    padding: theme.spacing(1.5, 2, 0.5),
  },
}));

/**
 * A component that renders a bubble menu when viewing, creating, or editing a
 * link. Requires the mui-tiptap LinkBubbleMenuHandler extension and Tiptap's
 * Link extension (@tiptap/extension-link, https://tiptap.dev/api/marks/link) to
 * both be included in your editor `extensions` array.
 *
 * Pairs well with the `<MenuButtonEditLink />` component.
 *
 * If you're using `RichTextEditor`, include this component via
 * `RichTextEditor`’s `children` render-prop. Otherwise, include the
 * `LinkBubbleMenu` as a child of the component where you call `useEditor` and
 * render your `RichTextField` or `RichTextContent`. (The bubble menu itself
 * will be positioned appropriately no matter where you put it in your React
 * tree, as long as it is re-rendered whenever the Tiptap `editor` forces an
 * update, which will happen if it's a child of the component using
 * `useEditor`).
 */
const UploadFromRepoBubbleMenu = ({
  eventId,
  labels,
  ...controlledBubbleMenuProps
}) => {
  const { classes } = useStyles();
  const editor = useRichTextEditorContext();

  if (!editor?.isEditable) {
    return null;
  }

  if (!('uploadFromRepoBubbleMenuHandler' in editor.storage)) {
    throw new Error(
      'You must add the UploadFromRepoBubbleMenuHandler extension to the useEditor `extensions` array in order to use this component!'
    );
  }

  const handleAndInsertRepoFiles = async (selectedFile) => {
    if (!editor || editor.isDestroyed || !selectedFile) {
      return;
    }
    const file = await fileHelper.getS3LinkWithDownload({
      dirPath: selectedFile.dirPath,
      fileData: selectedFile,
    });
    const localFile = file.Body;
    localFile.name = selectedFile.originalName;

    const attributesForImages = await onImageUpload(localFile, eventId);
    insertImages({
      editor,
      images: [attributesForImages],
    });
  };

  const handlerStorage = editor.storage.uploadFromRepoBubbleMenuHandler;

  // Update the menu step if the bubble menu state has changed
  const menuState = handlerStorage.state;

  return (
    <ControlledBubbleMenu
      editor={editor}
      open={menuState !== UploadFromRepoMenuState.HIDDEN}
      {...handlerStorage.bubbleMenuOptions}
      {...controlledBubbleMenuProps}
    >
      <div className={classes.content}>
        <UploadFromRepoBubbleMenuContent
          editor={editor}
          onCancel={editor.commands.closeLinkBubbleMenu}
          onSave={async ({ selectedFile }) => {
            await handleAndInsertRepoFiles(selectedFile);
            editor.commands.closeLinkBubbleMenu();
          }}
          labels={labels}
        />
      </div>
    </ControlledBubbleMenu>
  );
};

export default UploadFromRepoBubbleMenu;
