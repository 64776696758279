import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';

import { styled } from '../styled';
import { AimSubWallCard } from './AimSubWallCard';
import { AimTypography } from '../atoms/AimTypography';
import { AimIconButton } from '../atoms/AimIconButton';

const CollapseContent = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '28px 28px 26px 28px',
  backgroundColor: '#30CFD021',
  marginBottom: '20px',
  flexWrap: 'wrap',
});

export const AimButtonsPanel = ({
  isButtonsBoxOpen,
  model,
  title,
  // checkedItems = [],
  onApply,
  onCancel,
  onItemClick,
  // dataArray = [],
}) => {
  return (
    <Collapse in={isButtonsBoxOpen}>
      <CollapseContent>
        <AimTypography variant="textBold">{title}</AimTypography>

        <Grid container>
          {model?.map((item) => (
            <AimSubWallCard
              key={item.id}
              label={item.text}
              onEyeClick={() => onItemClick(item)}
              icon={item.icon || <EditIcon />}
              disableClick={item?.disabled}
            />
          ))}
        </Grid>
        <Grid
          container
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: 10,
          }}
        >
          {onApply && (
            <AimIconButton
              variant="greenFill"
              aria-label="apply"
              onClick={onApply}
            >
              <CheckIcon />
            </AimIconButton>
          )}
          {onCancel && (
            <AimIconButton
              variant="redFill"
              aria-label="reset"
              onClick={onCancel}
            >
              <ClearIcon />
            </AimIconButton>
          )}
        </Grid>
      </CollapseContent>
    </Collapse>
  );
};
