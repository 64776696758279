import React, { useEffect } from 'react';
import { appState, constants } from '@aim/common';

export const LinkMatcher = ({ component, children, params }) => {
  const { eventId, hotelEventVentureId, eventConfigurationId } = params;
  useEffect(() => {
    //when component is null we reset to empry list of links
    const nextLinks =
      component?.links?.map((key) => constants.LinkMatcherConstants[key]) || [];

    appState.linksHistory.next(
      nextLinks.map(({ label, path }) => ({
        label,
        link: path({ eventId, hotelEventVentureId, eventConfigurationId }),
      }))
    );
  }, [component]);

  return <>{children}</>;
};
