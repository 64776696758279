export default {
  exportDialog: (intl) => ({
    title: intl.formatMessage({
      description: 'export table title',
      defaultMessage: 'Export table',
    }),
    subTitle: intl.formatMessage({
      description: 'export subtitle',
      defaultMessage: 'Information set: Select the columns you want to export',
    }),
    listHeaderRowName: intl.formatMessage({
      description: 'Select all header',
      defaultMessage: 'Select all ',
    }),
  }),
};
