import React, { useState, useEffect, useMemo } from 'react';
import {
  AimDialog,
  AimTypography,
  NumericTableGrid,
  AimSelect,
  AimSelectMenuItem,
} from '@aim/components';
import { opensearch, appState, aws, utilities } from '@aim/common';
import { eachDayOfInterval, format, isSameDay } from 'date-fns';

const { API } = aws;
const { getAllotmentBookedCount, getAllotmentRemainingCount } = utilities;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getNights = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      allotmentDateRange {
        start
        end
      }
    }
  }
`;

const listHotelsQuery = /* GraphQL */ `
  query ListEventHotelQuery($eventId: ID!) {
    getEvent(id: $eventId) {
      hotelEventVentures: hotels {
        items {
          id
          hotel {
            id
            name
          }
        }
      }
    }
  }
`;

const getHotelEventVentureQuery = /* GraphQL */ `
  query GetHotelEventVenture($id: ID!) {
    getHotelEventVenture(id: $id) {
      id
      hotel {
        id
        name
        rooms(filter: { isDeleted: { ne: true } }) {
          items {
            id
            name
            roomByDate {
              items {
                id
                date
              }
            }
          }
        }
      }
      hotelRoomByDate {
        items {
          id
          availableRoomCount
          date
          room {
            id
            name
          }
          roomAvailabilities {
            items {
              roomsBusy
              roomsLocked
              id
            }
          }
        }
      }
    }
  }
`;

export const getHotelEventVenture = (hotelEventVentureId) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getHotelEventVentureQuery,
      variables: {
        id: hotelEventVentureId,
      },
    })
      .then((response) => resolve(response?.data?.getHotelEventVenture))
      .catch((e) => {
        console.error('allotment-channel-detail-get-hotel-event-venturea', e);
        reject();
      });
  });

export const listEventHotel = (eventId) =>
  new Promise((resolve, reject) => {
    showLoader();
    API.graphql({
      query: listHotelsQuery,
      variables: { eventId },
    })
      .then((response) => resolve(response?.data?.getEvent.hotelEventVentures))
      .catch((e) => {
        console.error('allotment-list-event-hotel', e);
        reject();
      })
      .finally(() => hideLoader());
  });

export const getAllotmentNights = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({ query: getNights, variables: { id } })
      .then((response) =>
        resolve({
          ...response.data.getEvent,
        })
      )
      .catch((e) => {
        console.error('event-get-event-nights', e);
        reject();
      });
  });

const makeOpenSearchQuery = ({ eventId, start, end, hotelEventVentureId }) => {
  return {
    index: 'allotmentbookingoverview',
    fullOpensearchResponse: true,
    filterBody: {
      query: {
        bool: {
          filter: [
            {
              match_phrase: {
                eventId,
              },
            },
            {
              match_phrase: {
                hotelEventVentureId,
              },
            },
            {
              range: {
                hotelRoomByDateDate: {
                  gte: start,
                  lte: end,
                },
              },
            },
          ],
        },
      },
      aggs: {
        hotels: {
          terms: {
            field: 'hotelEventVentureId.keyword',
            size: 10000,
          },
          aggs: {
            rooms: {
              terms: {
                field: 'hotelRoomId.keyword',
                size: 10000,
              },
              aggs: {
                dates: {
                  terms: {
                    field: 'hotelRoomByDateId.keyword',
                    size: 10000,
                  },
                  aggs: {
                    availabilities: {
                      terms: {
                        field: 'hotelRoomAvailabilityId.keyword',
                        size: 10000,
                      },
                      aggs: {
                        available_rooms: {
                          sum: {
                            field: 'hotelRoomByDateAvailableRoomCount',
                          },
                        },
                        free_rooms: {
                          sum: {
                            field: 'freeRooms',
                          },
                        },
                        booked_rooms: {
                          sum: {
                            field: 'bookedRooms',
                          },
                        },
                        busy_rooms: {
                          sum: {
                            field: 'hotelRoomAvailabilityRoomsBusy',
                          },
                        },
                        locked_rooms: {
                          sum: {
                            field: 'hotelRoomAvailabilityRoomsLocked',
                          },
                        },
                        overbooked_rooms: {
                          bucket_script: {
                            buckets_path: {
                              busyRooms: 'busy_rooms.value',
                              bookedRooms: 'booked_rooms.value',
                            },
                            script:
                              'params.busyRooms > params.bookedRooms ? 0 : params.bookedRooms - params.busyRooms',
                          },
                        },
                      },
                    },
                    by_date_available_rooms: {
                      sum_bucket: {
                        buckets_path: 'availabilities>available_rooms',
                      },
                    },
                    by_date_booked_rooms: {
                      sum_bucket: {
                        buckets_path: 'availabilities>booked_rooms',
                      },
                    },
                    by_date_busy_rooms: {
                      sum_bucket: {
                        buckets_path: 'availabilities>busy_rooms',
                      },
                    },
                    by_date_locked_rooms: {
                      sum_bucket: {
                        buckets_path: 'availabilities>locked_rooms',
                      },
                    },
                    by_date_free_rooms: {
                      sum_bucket: {
                        buckets_path: 'availabilities>free_rooms',
                      },
                    },
                    by_date_overbooked_rooms: {
                      sum_bucket: {
                        buckets_path: 'availabilities>overbooked_rooms',
                      },
                    },
                  },
                },
                by_room_available_rooms: {
                  sum_bucket: {
                    buckets_path: 'dates>by_date_available_rooms',
                  },
                },
                by_room_booked_rooms: {
                  sum_bucket: {
                    buckets_path: 'dates>by_date_booked_rooms',
                  },
                },
                by_room_busy_rooms: {
                  sum_bucket: {
                    buckets_path: 'dates>by_date_busy_rooms',
                  },
                },
                by_room_locked_rooms: {
                  sum_bucket: {
                    buckets_path: 'dates>by_date_locked_rooms',
                  },
                },
                by_room_overbooked_rooms: {
                  sum_bucket: {
                    buckets_path: 'dates>by_date_overbooked_rooms',
                  },
                },
              },
            },
            by_hotel_available_rooms: {
              sum_bucket: {
                buckets_path: 'rooms>by_room_available_rooms',
              },
            },
            by_hotel_booked_rooms: {
              sum_bucket: {
                buckets_path: 'rooms>by_room_booked_rooms',
              },
            },
            by_hotel_busy_rooms: {
              sum_bucket: {
                buckets_path: 'rooms>by_room_busy_rooms',
              },
            },
            by_hotel_locked_rooms: {
              sum_bucket: {
                buckets_path: 'rooms>by_room_locked_rooms',
              },
            },
            by_hotel_overbooked_rooms: {
              sum_bucket: {
                buckets_path: 'rooms>by_room_overbooked_rooms',
              },
            },
          },
        },
        total_available_rooms: {
          sum_bucket: {
            buckets_path: 'hotels>by_hotel_available_rooms',
          },
        },
        total_booked_rooms: {
          sum_bucket: {
            buckets_path: 'hotels>by_hotel_booked_rooms',
          },
        },
        total_busy_rooms: {
          sum_bucket: {
            buckets_path: 'hotels>by_hotel_busy_rooms',
          },
        },
        total_locked_rooms: {
          sum_bucket: {
            buckets_path: 'hotels>by_hotel_locked_rooms',
          },
        },
        total_overbooked_rooms: {
          sum_bucket: {
            buckets_path: 'hotels>by_hotel_overbooked_rooms',
          },
        },
      },
      size: 10000,
      from: 0,
    },
  };
};

export const AllotmentGridDialog = ({
  dialog,
  setDialog,
  titleDialog = 'allotment owerview',
  labelHotel = 'hotel',
  totlaPercentText = 'occupation rate',
  totalRowText = 'Total',
  totalColText = 'Total',
  typologyText = 'Typology',
  bookedText = 'booked',
  busyText = 'busy',
  remainingText = 'remaining',
  availableText = 'available',
  lockedText = 'locked',
}) => {
  const handleClose = () => {
    setDialog({ ...dialog, isOpen: false });
  };

  const [hotelName, setHotelName] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [rooms, setRooms] = useState([]);

  const [
    selectedHotelEventVentureId,
    setSelectedHotelEventVentureId,
  ] = useState(null);

  const eventId = dialog.eventId;
  const currentHotelName = dialog.currentHotelName;

  useEffect(() => {
    if (!eventId) return;
    const listHotelName = async () => {
      const res = await listEventHotel(eventId);
      const hotels = res?.items.map((item) => ({
        label: item.hotel.name,
        value: item.hotel.id,
        eventVentureId: item.id,
      }));
      setHotelName(hotels);

      const currentHotel = currentHotelName
        ? hotels.find((hotel) => hotel.label === currentHotelName)
        : hotels[0];

      if (currentHotel) {
        setSelectedHotel(currentHotel.value);
        setSelectedHotelEventVentureId(currentHotel.eventVentureId);
      }
    };
    listHotelName();
  }, [eventId, currentHotelName]);

  useEffect(() => {
    if (selectedHotelEventVentureId) {
      const fetchRooms = async () => {
        appState.isLoader.next(true);
        const resNights = await getAllotmentNights(eventId);
        const respOpenSearch = await opensearch.sendStandardQuery(
          makeOpenSearchQuery({
            hotelEventVentureId: selectedHotelEventVentureId,
            eventId,
            start: resNights?.allotmentDateRange
              ? format(
                  new Date(resNights?.allotmentDateRange?.start),
                  'yyyy-MM-dd'
                )
              : null,
            end: resNights?.allotmentDateRange
              ? format(
                  new Date(resNights?.allotmentDateRange?.end),
                  'yyyy-MM-dd'
                )
              : null,
          })
        );
        var res = await getHotelEventVenture(selectedHotelEventVentureId);
        let totalAvailable = 0;
        let mapped = {};
        if (res) {
          const hev = res;
          mapped = {
            ...hev,
            hotelEventventureId: hev.id,
            roomsOS: hev.hotel.rooms.items.map((room) => {
              const hDates = eachDayOfInterval({
                start: new Date(resNights?.allotmentDateRange?.start),
                end: new Date(resNights?.allotmentDateRange?.end),
              });

              const dates = hDates
                .map((date, idx) => {
                  const realDate = hev.hotelRoomByDate.items.find(
                    (rDate) =>
                      isSameDay(new Date(rDate.date), new Date(date)) &&
                      rDate.room.id === room.id
                  );
                  if (realDate) {
                    return realDate;
                  } else {
                    return { id: idx, availableRoomCount: 0, date };
                  }
                })
                .map((rDate) => {
                  const hevOS = respOpenSearch.aggregations.hotels.buckets?.find(
                    (hevOS) => hevOS.key === hev.id
                  );
                  const roomOS = hevOS?.rooms.buckets?.find(
                    (roomOS) => roomOS.key === room.id
                  );
                  const roomDate = roomOS?.dates.buckets?.find(
                    (rDateOS) => rDateOS.key === rDate.id
                  );
                  totalAvailable += rDate?.availableRoomCount || 0;
                  return {
                    ...rDate,
                    roomByDateId: rDate.id,
                    available: rDate?.availableRoomCount || 0,
                    booked: getAllotmentBookedCount(
                      roomDate?.by_date_booked_rooms.value,
                      roomDate?.by_date_locked_rooms.value
                    ),
                    freerooms: getAllotmentRemainingCount(
                      rDate?.availableRoomCount,
                      roomDate?.by_date_busy_rooms.value
                    ), // was roomDate?.by_date_free_rooms.value || 0,
                    overbooked: roomDate?.by_date_overbooked_rooms.value || 0,
                    roomsbusy: roomDate?.by_date_busy_rooms.value || 0,
                    roomslocked: roomDate?.by_date_locked_rooms.value || 0,
                  };
                });
              return {
                ...room,
                roomId: room.id,
                dates,
              };
            }),
          };
        }
        setRooms(mapped);
        appState.isLoader.next(false);
      };
      fetchRooms();
    }
  }, [selectedHotelEventVentureId]);

  const findHotelNameById = (hotelId) => {
    const hotel = hotelName.find((hotel) => hotel.value === hotelId);
    return hotel ? hotel.label : '';
  };

  const transformData = (hev) => {
    const headerData = [];
    const bodyData = [];

    if (hev.roomsOS && hev.roomsOS.length > 0) {
      const dates = hev.roomsOS.flatMap((room) =>
        room.dates.map((dateObj) => {
          const date = new Date(dateObj.date);
          return date.toLocaleDateString('it-IT');
        })
      );
      const uniqueDates = [...new Set(dates)];

      headerData.push([
        { label: '', isHeader: true, isFirst: true },
        ...uniqueDates.map((date) => ({
          label: date,
          isHeader: true,
          isBold: true,
        })),
      ]);

      const statusLabels = [
        `${bookedText}`,
        `${busyText}`,
        `${remainingText}`,
        `${availableText}`,
        `${lockedText}`,
      ];
      const repeatedLabelsTypology = [];
      uniqueDates.forEach(() => {
        statusLabels.forEach((label) => {
          repeatedLabelsTypology.push({ label, isHeader: true });
        });
      });

      headerData.push([
        {
          label: `${typologyText}`,
          isHeader: true,
          isBold: true,
          isFirst: true,
          isAlign: 'left',
        },
        ...repeatedLabelsTypology,
      ]);

      hev.roomsOS.forEach((room) => {
        const roomData = [
          { label: room.name, isBody: true, isAlign: 'left', isFirst: true },
        ];

        room.dates.forEach((date) => {
          roomData.push({ label: date.booked, isBody: true, type: 'number' });
          roomData.push({
            label: date.roomsbusy,
            isBody: true,
            type: 'number',
          });
          roomData.push({
            label: date.freerooms,
            isBody: true,
            type: 'number',
          });
          roomData.push({
            label: date.available,
            isBody: true,
            type: 'number',
          });
          roomData.push({
            label: date.roomslocked,
            isBody: true,
            type: 'number',
          });
        });

        bodyData.push(roomData);
      });
    }

    return { headerData, bodyData };
  };

  const { headerData, bodyData } = useMemo(() => transformData(rooms), [rooms]);

  return (
    <AimDialog
      maxWidth="lg"
      title={titleDialog}
      open={dialog.isOpen}
      disableOnClose
      onClose={handleClose}
      hideAgreeButton={true}
      hideCancelButton={false}
    >
      <AimSelect
        label={labelHotel}
        formControlStyle={{ width: '30%' }}
        value={selectedHotel}
        onChange={(e) => {
          const selectedHotel = hotelName.find(
            (hotel) => hotel.value === e.target.value
          );
          setSelectedHotel(selectedHotel ? selectedHotel.value : null);
          setSelectedHotelEventVentureId(
            selectedHotel ? selectedHotel.eventVentureId : null
          );
        }}
      >
        {hotelName.map((hotel) => (
          <AimSelectMenuItem key={hotel.value} value={hotel.value}>
            {hotel.label}
          </AimSelectMenuItem>
        ))}
      </AimSelect>
      <AimTypography variant="h2">
        {findHotelNameById(selectedHotel)}
      </AimTypography>
      <NumericTableGrid
        headerData={headerData}
        bodyData={bodyData}
        numberOfCell={5}
        formStyle={{ margin: 0 }}
        totlaPercentText={totlaPercentText}
        totalRowText={totalRowText}
        totalColText={totalColText}
        paramsPercentFirst={bookedText}
        paramsPercentSecond={availableText}
        handleDisagree={handleClose}
      />
    </AimDialog>
  );
};
