import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { PromisePool } from '@supercharge/promise-pool';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { appState, utilities, constants } from '@aim/common';

import useI18n from './i18n';
import ImportFromFileTable from './ImportFromFileTable';
import { styled } from '../../styled';

import { getEventDetailsByClusters } from '../../bundles/MassiveImportDialog/utils/eventDetailsGqlHelper';
import { AimSnackbar, AimSnackbarSeverity } from '../../atoms/AimSnackbar';
import { AimIconAndTextButton } from '../../atoms/AimIconAndTextButton';
import { AimTypography } from '../../atoms/AimTypography';
import { AimSelect, AimSelectMenuItem } from '../../atoms/AimSelect';
import { AimFileSelector } from '../../bundles/AimFileSelector';
import { AimIconButton } from '../../atoms/AimIconButton';
import { useMassiveImport } from '../../../utils/hooks/MassiveImportUtils/massiveImport';
import { getEmailRegexError } from '../../bundles/MassiveImportDialog/utils/utils';
import { useModificationRequest } from '../../bundles/MassiveImportDialog/utils/modificationRequestGqlHelper';
import {
  getEventCode,
  getParticipationsByUsernames,
} from '../../../utils/hooks/MassiveImportUtils/gqlHelper';
import { parse } from 'date-fns';
import { MassiveImportPartecipantsDialog } from '../MassiveImportPartecipantsDialog';

const ImportContainer = styled('div')({
  flex: '1 1 0%',
});

const ImportDetails = styled('div')({
  height: '50px',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 10,
});

export const ImportFromFile = ({
  isPostDeadline,
  history,
  eventId,
  cluster,
  clusterId,
  intl,
}) => {
  // Hooks
  const i18n = useI18n.importFromFile(intl);
  const {
    getHeaderFromFile,
    getAvailableFields,
    deleteFailedImportedParticipation,
    insertOrUpdateParticipationPromise,
    insertParticipationRequestPromise,
    insertOrUpdateFieldsPromise,
    createError,
  } = useMassiveImport();
  // const { callLambdaConflictsOnCreate } = useConflictHelper();
  const modificationRequestHelper = useModificationRequest();

  const showLoader = () => appState.isLoader.next(true);
  const hideLoader = () => appState.isLoader.next(false);

  // State
  const [fetchedFile, setFetchedFile] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [profile, setProfile] = useState('');
  const [feeDateRange, setFeeDateRange] = useState('');
  const [user, setUser] = useState();
  const [availableProfiles, setAvailableProfiles] = useState([]);
  const [availableFeeDateRanges, setAvailableFeeDateRanges] = useState([]);
  const [availableTypologies, setAvailableTypologies] = useState([]);
  const [availablePreTitles, setAvailablePreTitles] = useState([]);
  const [templateFields, setTemplateFields] = useState();
  const [fetchedParticipants, setFetchedParticipants] = useState();
  const [participantsWithErrors, setParticipantsWithErrors] = useState([]);
  const [
    effectiveParticipantsWithErrors,
    setEffectiveParticipantsWithErrors,
  ] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState();
  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    gridData: [],
  });

  // Effects
  useEffect(() => {
    // Get profiles from the specific cluster
    if (!cluster) return;
    fetchProfiles();
  }, [cluster]);

  useEffect(() => {
    // Get cognitoId of the user logged to know if it is Congress or Travel
    const subscription = appState.user.subscribe((user) => {
      setUser(user);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (
      cluster === constants.Clusters.Delegations.id ||
      (availableProfiles && profile)
    ) {
      // Get the specific fields for the profile selected
      getTemplateFields();
    }
  }, [availableProfiles, profile]);

  useEffect(() => {
    if (templateFields && fetchedFile) {
      // Check if the template header match with the profile fields
      checkTemplateMatching();
    } else {
      setError(i18n.snackbar.errors.files);
    }
  }, [templateFields, fetchedFile]);

  /**
   * Functions used ONLY before effective import
   */
  const fetchProfiles = async () => {
    if (eventId) {
      showLoader();
      const clusters = [];
      clusters.push(cluster);
      const res = await getEventDetailsByClusters(eventId, clusters);
      const _profiles = [];
      res.profiles.items.map((profile) => {
        _profiles.push({
          label: `${profile.name}${
            profile?.category?.name ? ` - ${profile.category.name}` : ''
          }`,
          name: profile.name,
          id: profile.id,
          cluster: profile.cluster,
        });
      });
      _profiles.unshift({ id: 1, label: 'All' });
      setAvailableProfiles(_profiles);
      const nextFeeDateRanges = res?.fee?.feeDateRanges.items.map(
        ({ id, start, end, label }) => {
          const eventDateStart = format(new Date(start), 'PP');
          const eventDateEnd = format(new Date(end), 'PP');
          const nextLabel =
            eventDateStart === eventDateEnd
              ? `${label} - ${eventDateStart}`
              : `${label} - ${eventDateStart} - ${eventDateEnd}`;
          return {
            id,
            label: nextLabel,
          };
        }
      );
      setAvailableFeeDateRanges(nextFeeDateRanges);
      setAvailableTypologies(res.participationTypologies.items);
      setAvailablePreTitles(res.preTitles.items);
      setProfile(1);
      hideLoader();
    }
  };

  const getTemplateFields = async () => {
    const isNotAll = profile !== 1;
    showLoader();
    const profileId =
      (cluster === constants.Clusters.Delegations.id ||
        cluster === constants.Clusters.Pax.id ||
        cluster === constants.Clusters.Groups.id ||
        cluster === constants.Clusters.SponsorStaff.id ||
        cluster === constants.Clusters.SponsorList.id) &&
      isNotAll
        ? profile
        : false;
    const fields = await getAvailableFields({
      eventId,
      cluster,
      clusterId,
      profileId,
      intl,
    });
    setTemplateFields(fields);
    hideLoader();
  };

  const getFileHeaders = async () => {
    const fileFields = await getHeaderFromFile(fetchedFile.file);
    return fileFields;
  };

  const checkTemplateMatching = async () => {
    if (templateFields.length) {
      const fileFields = await getFileHeaders();
      const criticism = templateFields
        .map((f) => f.name.trim().toLowerCase())
        .filter((fieldname) => !fileFields.includes(fieldname));
      if (criticism.length) {
        const missingFields = criticism.join(', ');
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: `${i18n.snackbar.errors.field}: ${missingFields}`,
        });
        setError(i18n.snackbar.errors.field);
      } else {
        setError();
      }
    } else {
      setError(i18n.snackbar.errors.field);
    }
  };

  /**
   * Functions used for Massive spo
   */
  const validate = (field, value) => {
    if (value != null && value != undefined) {
      switch (field.controlType) {
        case 'selectautocomplete':
        case 'select':
        case 'radio':
          // eslint-disable-next-line no-case-declarations
          const optionsValues = field.options.map(
            (o) => o.value?.toLowerCase?.() || ''
          );
          if (!optionsValues.includes(value?.toLowerCase?.())) {
            throw new Error(value + ' is not a valid option for ' + field.name);
          }
          break;
        case 'checkbox':
          if (!(typeof value === 'boolean')) {
            throw new Error(value + ' is not a valid option for ' + field.name);
          }
      }
    }
  };

  const createCustomParticipation = async (mappedInput, usernames) => {
    const response = await getParticipationsByUsernames({ eventId, usernames });

    response.map((part) => {
      const foundIdx = mappedInput.indexOf(
        mappedInput.find((p) => p.usernameToCheck === part.username)
      );
      if (foundIdx > -1) {
        if (
          !clusterId ||
          (part.delegation && part.delegation.id === clusterId) ||
          (part.agencyGroup && part.agencyGroup.id === clusterId) ||
          (part.sponsorList && part.sponsorList.id === clusterId) ||
          (part.sponsorStaff && part.sponsorStaff.id === clusterId)
        ) {
          mappedInput[foundIdx].id = part.id;
          mappedInput[foundIdx].fieldsInputs = mappedInput[
            foundIdx
          ]?.fieldsInputs.map((field) => {
            const fieldValue = part.fieldValues.items.find(
              (pfv) => pfv.fieldDefinition.id === field.fieldDefinitionId
            );
            return { ...field, id: fieldValue?.id };
          });
        } else {
          mappedInput[foundIdx] = {
            ...mappedInput[foundIdx],
            error: mappedInput[foundIdx]?.error || [],
          };
          mappedInput[foundIdx].error.push(
            cluster === constants.Clusters.Delegations.id
              ? `Usernmame already registered in this or another Delegation with name '${part.delegation.name}'`
              : cluster === constants.Clusters.sponsorList.id
              ? `Usernmame already registered outside of this Sponsor delegates group`
              : cluster === constants.Clusters.Delegations.id
              ? `Usernmame already registered outside od this Sponsor staff group`
              : cluster === constants.Clusters.agencyGroup.id
              ? 'username already registered outside of this group'
              : ''
          );
        }
      }
    });

    //mapped import conserva lo stesso numero di righe dell'excel - per le righe in errore possiede la chiave "error"
    return isPostDeadline
      ? createParticipationRequests(mappedInput)
      : createOrUpdateParticipations(mappedInput);
  };

  const createOrUpdateParticipations = async (mappedInput) => {
    const eventCode = await getEventCode(eventId);
    const { errors } = await PromisePool.for(mappedInput || [])
      .withConcurrency(15)
      .onTaskStarted((user, pool) => {
        appState.loaderValue.next(
          pool.processedPercentage() > 1 ? pool.processedPercentage() : 1
        );
        // update the progress bar with the processed using `pool.processedPercentage()`
      })
      .process(async (mapped, idx) => {
        // For each participation
        if (!mapped.error || !mapped.error.length) {
          // If there weren't errors, create the Participation
          const checkedBooleanMapped = {
            ...mapped,
            participationInput: mapped.participationInput.map((i) => ({
              ...i,
              value:
                i.value === 'true'
                  ? true
                  : i.value === 'false'
                  ? false
                  : i.value,
            })),
          };

          const res = await insertOrUpdateParticipationPromise(
            checkedBooleanMapped,
            eventId,
            eventCode,
            cluster,
            clusterId,
            profile,
            feeDateRange
          );

          if (res.other.fieldsInputs?.length) {
            // For each participation creted, it creates the corrisponding Fields
            const { response, other } = res;

            return new Promise((resolve, reject) => {
              insertOrUpdateFieldsPromise({
                participationId: response.id,
                eventId,
                // we added id before if is an update
                other,
                idx,
              })
                .then(resolve)
                .catch((e) => {
                  console.error('e', e);
                  const participationId = response.id;
                  const _other = other.participationInput;
                  _other.push({ name: 'id', value: response.id });
                  // Rollback, deleting the participation, if the creation of the Fields hasn't work
                  deleteFailedImportedParticipation(participationId);
                  _participantsWithErrors.push(
                    createError(idx, _other, e?.message)
                  );
                  reject(e);
                });
            });
          }
        } else {
          // Set up an error
          throw new Error(mapped.error.join(', '));
        }
      });

    const _participantsWithErrors = [];

    errors.map((error, idx) => {
      _participantsWithErrors.push(
        createError(idx, error.item.participationInput, error.raw.message)
      );
    });

    //eseguo tutti gli inserimenti dei field
    setParticipantsWithErrors(_participantsWithErrors);
    setEffectiveParticipantsWithErrors(_participantsWithErrors);
    // if (
    //   res.length &&
    //   cluster !== constants.Clusters.Delegations.id &&
    //   cluster !== constants.Clusters.Groups.id
    // )
    //   callLambdaConflictsOnCreate(eventId);
    hideLoader();
  };

  const createParticipationRequests = (mappedInput) => {
    return new Promise((resolve) => {
      const participationPromises = [];
      mappedInput.map(async (mapped) => {
        // For each participation
        if (!mapped.error) {
          // If there weren't errors, create the Participation
          const checkedBooleanMapped = {
            ...mapped,
            participationInput: mapped.participationInput.map((i) => ({
              ...i,
              value:
                i.value === 'true'
                  ? true
                  : i.value === 'false'
                  ? false
                  : i.value,
            })),
          };

          participationPromises.push(
            insertParticipationRequestPromise({
              mapped: checkedBooleanMapped,
              eventId,
              cluster,
              clusterId,
              profile,
              feeDateRange,
              modificationRequestHelper,
              user,
            })
          );
        } else {
          // Set up an error
          participationPromises.push(Promise.reject(mapped.error.join(', ')));
        }
      });

      const _participantsWithErrors = [];
      const validImportedParticipations = [];
      //anche le promises mantengono l'indice delle righe dell'excel - quelle che sono andate in
      //errore non sono fulfilled e possiedono una reason (precedentemente creata con createError)
      Promise.allSettled(participationPromises)
        .then((results) => {
          results.map((res, idx) => {
            if (res.status === 'fulfilled') {
              if (res.value.other.fieldsInputs?.length) {
                // For each participation creted, it creates the corrisponding Fields
                const { response, other } = res.value;
                validImportedParticipations.push(
                  new Promise((resolve, reject) => {
                    insertOrUpdateFieldsPromise({
                      participationRequestId: response.id,
                      other,
                      idx,
                    })
                      .then(resolve)
                      .catch((e) => {
                        const participationId = response.id;
                        const _other = other.participationInput;
                        _other.push({ name: 'id', value: response.id });
                        // Rollback, deleting the participation, if the creation of the Fields hasn't work
                        deleteFailedImportedParticipation(participationId);
                        _participantsWithErrors.push(
                          createError(idx, _other, e?.message)
                        );
                        reject(e);
                      });
                  })
                );
              }
            } else {
              _participantsWithErrors.push(
                createError(
                  idx,
                  mappedInput[idx].participationInput,
                  res.reason
                )
              );
            }
          });
        })
        .finally(async () => {
          //eseguo tutti gli inserimenti dei field
          const res = await Promise.all(validImportedParticipations);
          setParticipantsWithErrors(_participantsWithErrors);
          setEffectiveParticipantsWithErrors(_participantsWithErrors);
          // if (
          //   res.length &&
          //   cluster !== constants.Clusters.Delegations.id &&
          //   cluster !== constants.Clusters.Groups.id &&
          //   cluster !== constants.Clusters.SponsorList.id &&
          //   cluster !== constants.Clusters.SponsorStaff.id
          // )
          //   callLambdaConflictsOnCreate(eventId);
          hideLoader();
          resolve();
        });
    });
  };

  const handleExportParticipantsWithError = () => {
    const participationToExport = participantsWithErrors.map((p) => {
      const row = fetchedParticipants.find(
        (pr) =>
          (p.username && p.username === pr.ID) ||
          (p.givenName === pr.name &&
            p.familyName === pr.surname &&
            p.email === pr.email)
      );

      row['reason'] = p.aim_failed_import_reason;
      return row;
    });

    const header = templateFields.map((f) => f.name);
    header.push('reason');
    utilities.objectsToExcel({
      filename: 'conflicts',
      header,
      keys: header,
      objects: participationToExport,
    });
  };

  const deleteParticipantWithErrorsRow = (row) => {
    const _newParticipantWithErrorsRow = participantsWithErrors.filter(
      (p) => p.aim_excel_index !== row.aim_excel_index
    );
    setParticipantsWithErrors(_newParticipantWithErrorsRow);
  };

  const handleBack = () => {
    history.push(history?.location?.state?.backUrl || `/events/${eventId}`);
  };

  const compareParticipants = (participationsRows, delegationParticipants) => {
    return participationsRows.map((p, index) => {
      const isDuplicate = delegationParticipants.some(
        (d) =>
          (d.givenName || '').trim().toLowerCase() ===
            (p.name || '').trim().toLowerCase() &&
          (d.familyName || '').trim().toLowerCase() ===
            (p.surname || '').trim().toLowerCase()
      );

      return {
        ...p,
        id: index,
        row: index + 2,
        isDuplicate,
      };
    });
  };

  const processParticipations = async (participationsRowsSelect) => {
    console.log('participationsRowsSelect', participationsRowsSelect);
    appState.loaderValue.next(1);
    const participationsRows =
      Array.isArray(participationsRowsSelect) &&
      participationsRowsSelect.length > 0
        ? participationsRowsSelect
        : await utilities.ExcelToObjects(
            templateFields?.map((f) => f.name),
            fetchedFile.file,
            false
          );
    console.log('participationsRows', participationsRows);
    if (
      !history.location.state?.remainingPlaces ||
      history.location.state.remainingPlaces >= participationsRows.length
    ) {
      setFetchedParticipants(participationsRows);
      const templateFieldsNamed = {};
      // remap with db fields
      templateFields.forEach((f) => {
        templateFieldsNamed[`${f.name.trim().toLowerCase()}`] = f;
      });
      const participationsToCreate = [];
      const usernameSet = []; //lista di email univoche
      for (let j = 0; j < participationsRows.length; j++) {
        const currentParticipationRow = participationsRows[j];
        let usernameToCheck;
        const fieldsInputs = [];
        const participationInput = [];
        const participationError = [];
        const participationFieldKeys = Object.keys(currentParticipationRow);
        for (let i = 0; i < participationFieldKeys.length; i++) {
          const participationFieldKey = participationFieldKeys[i];
          const field =
            templateFieldsNamed[
              `${participationFieldKey.trim().toLocaleLowerCase()}`
            ];
          let fieldValue = currentParticipationRow[participationFieldKey];
          switch (field?.type) {
            case 'custom':
            case 'standard':
              try {
                validate(field, fieldValue);
                let calculatedValue = '';
                if (field.controlType === 'selectautocomplete' && fieldValue) {
                  calculatedValue = field.options.find(
                    (o) => o.value?.toLowerCase() === fieldValue.toLowerCase()
                  );
                } else if (field.controlType === 'multiselect' && fieldValue) {
                  calculatedValue = field.options.find(
                    (o) => o.value?.toLowerCase() === fieldValue.toLowerCase()
                  );
                  calculatedValue = [calculatedValue?.value];
                } else if (
                  (field.controlType === 'select' ||
                    field.controlType === 'radio') &&
                  fieldValue
                ) {
                  calculatedValue = field.options.find(
                    (o) => o.value?.toLowerCase() === fieldValue.toLowerCase()
                  )?.value;
                } else if (field.controlType === 'date' && fieldValue) {
                  try {
                    if (typeof fieldValue === 'number') {
                      calculatedValue = format(
                        utilities.excelDateNumberToDate(fieldValue),
                        'yyyy-MM-dd'
                      );
                    } else if (typeof fieldValue === 'string') {
                      if (fieldValue.includes('/')) {
                        calculatedValue = format(
                          parse(fieldValue, 'dd/MM/yyyy', new Date()),
                          'yyyy-MM-dd'
                        );
                      } else {
                        //iso date
                        calculatedValue = fieldValue;
                      }
                    }
                  } catch (e) {
                    console.log('e', e);
                    throw new Error(`${field.name} is not a valid date`);
                  }
                } else {
                  calculatedValue = fieldValue;
                }
                fieldsInputs.push({
                  fieldDefinitionId: field.value,
                  value: calculatedValue,
                });
              } catch (e) {
                participationError.push(e.message);
              }
              break;
            case 'param': {
              switch (field.value) {
                case 'username':
                  {
                    if (!fieldValue) {
                      break;
                    }
                    if (!usernameSet.includes(fieldValue)) {
                      usernameSet.push(fieldValue);
                      usernameToCheck = fieldValue;
                    } else {
                      participationError.push('username duplicate in the file');
                    }
                  }
                  break;
                case 'email':
                  {
                    //Regex email
                    if (fieldValue) {
                      fieldValue = fieldValue?.trim();
                      const regexError = getEmailRegexError(fieldValue);
                      if (regexError) participationError.push(regexError);
                    }
                  }
                  break;
                case 'givenName':
                  {
                    if (!fieldValue) {
                      participationError.push('name is required');
                    }
                  }
                  break;
                case 'familyName':
                  {
                    if (!fieldValue) {
                      participationError.push('surname is required');
                    }
                  }
                  break;
                case 'typology':
                  {
                    if (fieldValue) {
                      const foundId = availableTypologies.find(
                        (t) =>
                          fieldValue?.trim?.()?.toLowerCase?.() ===
                          t.name?.trim?.()?.toLowerCase?.()
                      )?.id;
                      if (fieldValue && !foundId) {
                        participationError.push(
                          fieldValue + ' is not a valid option for typology'
                        );
                      } else {
                        fieldValue = foundId;
                      }
                    }
                  }
                  break;
                case 'profile':
                  {
                    if (fieldValue) {
                      const foundId = availableProfiles.find(
                        (t) =>
                          fieldValue?.trim?.()?.toLowerCase?.() ===
                          t.name?.trim?.()?.toLowerCase?.()
                      )?.id;
                      if (fieldValue && !foundId) {
                        participationError.push(
                          fieldValue + ' is not a valid option for profile'
                        );
                      } else {
                        fieldValue = foundId;
                      }
                    }
                  }
                  break;
                case 'title':
                  {
                    if (fieldValue) {
                      const foundValue = availablePreTitles.find(
                        (t) => fieldValue?.trim?.() === t.title?.trim?.()
                      )?.key;
                      if (fieldValue && !foundValue) {
                        participationError.push(
                          fieldValue + ' is not a valid option for title'
                        );
                      } else {
                        fieldValue = foundValue;
                      }
                    }
                  }
                  break;
                case 'notes':
                  break;
              }
              participationInput.push({
                name: field.value,
                value: fieldValue || undefined,
              });
              break;
            }
          }
        }
        const filteredfieldsInputs = fieldsInputs.filter(
          (field) => !!field.value
        );
        participationsToCreate.push({
          participationInput,
          fieldsInputs: filteredfieldsInputs,
          ...(participationError.length && { error: participationError }),
          ...(usernameToCheck && { usernameToCheck }),
        });
      }
      await createCustomParticipation(participationsToCreate, usernameSet);
      setTotalRecords(participationsRows?.length);
    } else {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.errors.remainingPlaces,
      });
    }
  };

  const handleFileUpload = async () => {
    const participationsRows = await utilities.ExcelToObjects(
      templateFields?.map((f) => f.name),
      fetchedFile.file,
      false
    );
    const delegationParticipants = history.location.state?.participations;
    const comparedData = compareParticipants(
      participationsRows,
      delegationParticipants
    );

    setAlertDialog({ isOpen: true, gridData: comparedData });
  };

  return (
    <>
      <ImportContainer>
        <div style={{ display: 'flex', flex: 1 }}>
          <Tooltip title={i18n.backLabel}>
            <AimIconAndTextButton
              style={{
                padding: 0,
              }}
              variant="text"
              text={i18n.backLabel}
              onClick={() => handleBack()}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <AimTypography variant="h1">{i18n.title}</AimTypography>
        <Grid container>
          {cluster !== constants.Clusters.Delegations.id ? (
            <Grid item xs={6}>
              <AimTypography variant={'h6'}>
                {i18n.selectTemplate}
              </AimTypography>
              <AimSelect
                selectVariant="white"
                name="profile"
                value={profile}
                onChange={(e) => setProfile(e.target.value)}
              >
                {availableProfiles &&
                  availableProfiles.map((x) => (
                    <AimSelectMenuItem key={x.id} value={x.id}>
                      {x.label}
                    </AimSelectMenuItem>
                  ))}
              </AimSelect>
              <AimSelect
                selectVariant="white"
                name="feeDateRange"
                value={feeDateRange}
                onChange={(e) => setFeeDateRange(e.target.value)}
                hasNoneValue
              >
                {availableFeeDateRanges &&
                  availableFeeDateRanges.map((x) => (
                    <AimSelectMenuItem key={x.id} value={x.id}>
                      {x.label}
                    </AimSelectMenuItem>
                  ))}
              </AimSelect>
            </Grid>
          ) : (
            <></>
          )}
          <Grid
            item
            xs={cluster !== constants.Clusters.Delegations.id ? 6 : 12}
          >
            <div style={{ display: 'flex' }}>
              <AimTypography variant={'textBold'}>Fields:</AimTypography>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {templateFields?.map((f, idx) => (
                  <AimTypography key={idx} variant={'text'}>
                    {f.name}
                    {idx !== templateFields.length - 1 ? ',' : ''}
                  </AimTypography>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <AimFileSelector
              label={i18n.fileSelector.label}
              selectFileLabel={`${
                i18n.fileSelector.clickHere
              }, ${i18n.fileSelector.label.toLowerCase()}`}
              onUploadFile={(res) => {
                setFetchedFile(res);
              }}
              onRemove={() => {
                setFetchedFile();
              }}
              file={fetchedFile?.parsed}
              maxFiles={1}
              dropzoneAdditionalProps={{ accept: '.xls, .xlsx' }}
            />
            <div
              style={{
                width: 'calc(100% - 20px)',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <AimIconAndTextButton
                variant={'redFill'}
                text={i18n.buttons.cancel}
                onClick={() => {
                  handleBack();
                }}
              >
                <ClearIcon />
              </AimIconAndTextButton>
              <Tooltip title={error ? error : i18n.importText}>
                <span>
                  <AimIconAndTextButton
                    variant={'greenFill'}
                    text={i18n.buttons.import}
                    disabled={!fetchedFile || !!error}
                    onClick={() =>
                      history.location.state?.isTravelImport
                        ? handleFileUpload()
                        : processParticipations()
                    }
                  >
                    <CheckIcon />
                  </AimIconAndTextButton>
                </span>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <AimTypography variant="h6">{i18n.titleTable}</AimTypography>
            <ImportDetails>
              <AimTypography variant="h6">{`${i18n.record.total}: ${totalRecords}`}</AimTypography>
              <AimTypography variant="h6">
                {`${i18n.record.imported}: ${
                  totalRecords - effectiveParticipantsWithErrors?.length > 0
                    ? totalRecords - effectiveParticipantsWithErrors?.length
                    : 0
                }`}
              </AimTypography>
              <AimTypography variant="h6">
                {`${i18n.record.notImported}: ${effectiveParticipantsWithErrors?.length}`}
              </AimTypography>
              <Tooltip title={i18n.buttons.export}>
                <span>
                  <AimIconButton
                    aria-label="delete"
                    variant="greenFill"
                    disabled={participantsWithErrors.length === 0}
                    onClick={() => handleExportParticipantsWithError()}
                  >
                    <CallMadeIcon />
                  </AimIconButton>
                </span>
              </Tooltip>
            </ImportDetails>
            <ImportFromFileTable
              intl={intl}
              participantsWithErrors={participantsWithErrors}
              onClickRowDelete={deleteParticipantWithErrorsRow}
            />
          </Grid>
        </Grid>
        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
        <MassiveImportPartecipantsDialog
          setDialog={setAlertDialog}
          dialog={alertDialog}
          handleOnAgree={processParticipations}
          i18n={i18n}
        />
      </ImportContainer>
    </>
  );
};
