import { useState, useRef, useEffect, useCallback } from 'react';

const useStateWithPromise = (initialState) => {
  const [state, setState] = useState({ value: initialState });
  const resolverRef = useRef(null);

  useEffect(() => {
    if (resolverRef.current) {
      resolverRef.current(state.value);
      resolverRef.current = null;
    }
  }, [resolverRef, state]);

  const handleSetState = useCallback(
    (stateAction) => {
      return new Promise((resolve) => {
        setState({ value: stateAction });
        resolverRef.current = resolve;
      });
    },
    [setState]
  );

  return [state.value, handleSetState];
};

export { useStateWithPromise };
