export const translation = {
  purchasesPage: (intl) => ({
    aimTablePage: {
      header: {
        backButton: intl.formatMessage({
          description: 'back label',
          defaultMessage: 'Back',
        }),
        action: {
          sync: {
            tooltip: intl.formatMessage({
              description: 'Sync table button tooltip',
              defaultMessage: 'Sync',
            }),
          },
          toProcess: {
            label: intl.formatMessage({
              description: 'To process table button',
              defaultMessage: 'TO PROCESS',
            }),
            tooltip: intl.formatMessage({
              description: 'To process table button tooltip',
              defaultMessage: 'To process',
            }),
          },
          processInvoice: {
            label: intl.formatMessage({
              description: 'Invoice table button',
              defaultMessage: 'INVOICE',
            }),
            tooltip: intl.formatMessage({
              description: 'Process invoice table button tooltip',
              defaultMessage: 'Process invoice',
            }),
          },
          processCreditNote: {
            label: intl.formatMessage({
              description: 'Credit Note table button',
              defaultMessage: 'CREDIT NOTE',
            }),
            tooltip: intl.formatMessage({
              description: 'Credit note table button tooltip',
              defaultMessage: 'Process credit note',
            }),
          },
          settings: {
            tooltip: intl.formatMessage({
              description: 'Settings table button tooltip',
              defaultMessage: 'Billing settings',
            }),
          },
        },
        title: intl.formatMessage({
          description: 'Purchases table title',
          defaultMessage: 'Purchases',
        }),
      },
      table: {
        empty: intl.formatMessage({
          description: 'Table empty',
          defaultMessage: 'No purchases',
        }),
        paymentStatus: {
          done: {
            label: intl.formatMessage({
              description: 'paid label',
              defaultMessage: 'Paid',
            }),
          },
          pending: {
            label: intl.formatMessage({
              description: 'pending label',
              defaultMessage: 'Pending',
            }),
          },
        },
        paymentTypes: {
          bankTransfer: {
            label: intl.formatMessage({
              description: 'payemnt by bank transfer',
              defaultMessage: 'Bank Transfer',
            }),
          },
          creditCard: {
            label: intl.formatMessage({
              description: 'payment by credit card',
              defaultMessage: 'Credit Card',
            }),
          },
          free: {
            label: intl.formatMessage({
              description: 'payment Free',
              defaultMessage: 'Free',
            }),
          },
          both: {
            label: intl.formatMessage({
              description: 'payment by credit card or credit card',
              defaultMessage: 'Bank Transfer/Credit Card',
            }),
          },
        },
        invoiceToTypes: {
          company: intl.formatMessage({
            description: 'company description',
            defaultMessage: 'Company',
          }),
          individual: intl.formatMessage({
            description: 'individual description',
            defaultMessage: 'Individual',
          }),
        },
        yes: intl.formatMessage({
          description: 'yes label',
          defaultMessage: 'Yes',
        }),
        no: intl.formatMessage({
          description: 'no label',
          defaultMessage: 'No',
        }),
        columns: {
          paymentNanoId: intl.formatMessage({
            description: 'payment id description',
            defaultMessage: 'Payment id',
          }),
          billingStatus: intl.formatMessage({
            description: 'billing status description',
            defaultMessage: 'Status',
          }),
          billingStatusLabel: intl.formatMessage({
            description: 'billing status description',
            defaultMessage: 'Status',
          }),
          service: intl.formatMessage({
            description: 'service',
            defaultMessage: 'Service',
          }),
          creationDate: intl.formatMessage({
            description: 'payment creation date description',
            defaultMessage: 'Creation Date',
          }),
          lastUpdateDate: intl.formatMessage({
            description: 'payment Last Update description',
            defaultMessage: 'Last Update',
          }),
          participationUsername: intl.formatMessage({
            description: 'participant ID description',
            defaultMessage: 'Participant ID',
          }),
          participationName: intl.formatMessage({
            description: 'participant name description',
            defaultMessage: 'Participant name',
          }),
          participationEmail: intl.formatMessage({
            description: 'participant email description',
            defaultMessage: 'Participant email',
          }),
          participationTypeLabel: intl.formatMessage({
            description: 'purchase ticket type',
            defaultMessage: 'Type',
          }),
          billingEmail: intl.formatMessage({
            description: 'billing email description',
            defaultMessage: 'Billing email',
          }),
          invoiceToLabel: intl.formatMessage({
            description: 'invoice to description',
            defaultMessage: 'Invoice to',
          }),
          billingName: intl.formatMessage({
            description: 'billing name description',
            defaultMessage: 'Billing name',
          }),
          address: intl.formatMessage({
            description: 'address description',
            defaultMessage: 'Address',
          }),
          postalCode: intl.formatMessage({
            description: 'postal code description',
            defaultMessage: 'Postal code',
          }),
          city: intl.formatMessage({
            description: 'city description',
            defaultMessage: 'City',
          }),
          country: intl.formatMessage({
            description: 'country description',
            defaultMessage: 'Country',
          }),
          province: intl.formatMessage({
            description: 'Province description',
            defaultMessage: 'Province',
          }),
          region: intl.formatMessage({
            description: 'region description',
            defaultMessage: 'Region',
          }),
          countryIsoCode: intl.formatMessage({
            description: 'country iso alpha 2 code description',
            defaultMessage: 'Country ISO alpha 2',
          }),
          countryIsoAlpha3: intl.formatMessage({
            description: 'country iso alpha 3 code description',
            defaultMessage: 'Country ISO alpha 3',
          }),
          isItaly: intl.formatMessage({
            description: 'is country Italy description',
            defaultMessage: 'is Italy',
          }),
          vatCodeOr99Cee: intl.formatMessage({
            description: 'VATcode or 99999999999 if CEE description',
            defaultMessage: 'VATcode or 99999999999 if CEE',
          }),
          isNoCee99: intl.formatMessage({
            description: '99999999999 if no CEE description',
            defaultMessage: '99999999999 if no CEE',
          }),
          pec: intl.formatMessage({
            description: 'Pec description',
            defaultMessage: 'Pec',
          }),
          vatCode: intl.formatMessage({
            description: 'vat code description',
            defaultMessage: 'Vat code',
          }),
          taxCode: intl.formatMessage({
            description: 'fiscal code description',
            defaultMessage: 'Fiscal code',
          }),
          reference: intl.formatMessage({
            description: 'reference',
            defaultMessage: 'Reference',
          }),
          phone: intl.formatMessage({
            description: 'phone description',
            defaultMessage: 'Phone',
          }),
          invoiceToPublicAuthority: intl.formatMessage({
            description: 'invoice to public authority description',
            defaultMessage: 'Invoice to public authority',
          }),
          uniqueCode: intl.formatMessage({
            description: 'unique code description',
            defaultMessage: 'Unique code',
          }),
          ipaCode: intl.formatMessage({
            description: 'ipa code description',
            defaultMessage: 'Ipa code',
          }),
          isSplitPayment: intl.formatMessage({
            description: 'split payment description',
            defaultMessage: 'Split payment',
          }),
          isVatEvent: intl.formatMessage({
            description: 'Vat exent description',
            defaultMessage: 'Vat exent',
          }),
          venue: intl.formatMessage({
            description: 'venue',
            defaultMessage: 'Venue',
          }),
          cig: intl.formatMessage({
            description: 'venue',
            defaultMessage: 'CIG',
          }),
          cup: intl.formatMessage({
            description: 'cup',
            defaultMessage: 'CUP',
          }),
          tripletta: intl.formatMessage({
            description: 'tripletta',
            defaultMessage: 'Tripletta',
          }),
          amount: intl.formatMessage({
            description: 'total vamount description',
            defaultMessage: 'Total amount',
          }),
          vatRateCode: intl.formatMessage({
            description: 'vat rate code description',
            defaultMessage: 'VAT Rate code',
          }),
          netAmount: intl.formatMessage({
            description: 'net aamount description',
            defaultMessage: 'Net amount',
          }),
          vat: intl.formatMessage({
            description: 'Vat vamount description',
            defaultMessage: 'Vat amount',
          }),
          paymentType: intl.formatMessage({
            description: 'payment method description',
            defaultMessage: 'Payment method',
          }),
          paymentStatus: intl.formatMessage({
            description: 'payment status description',
            defaultMessage: 'Payment status',
          }),
        },
        rowAction: {
          lock: {
            tooltip: intl.formatMessage({
              description: 'lock description',
              defaultMessage: 'Lock',
            }),
          },
          unlock: {
            tooltip: intl.formatMessage({
              description: 'unlock description',
              defaultMessage: 'Unlock',
            }),
          },
          billingCustomer: {
            tooltip: intl.formatMessage({
              description: 'billing customer purchase table row action',
              defaultMessage: 'Billing customer',
            }),
          },
          edit: {
            tooltip: intl.formatMessage({
              description: 'edit tooltip',
              defaultMessage: 'Edit',
            }),
          },
          delete: {
            tooltip: intl.formatMessage({
              description: 'delete tooltip',
              defaultMessage: 'Delete',
            }),
          },
        },
      },
      dialog: {
        delete: {
          title: intl.formatMessage({
            description: 'Delete purchase title',
            defaultMessage: 'Delete purchase',
          }),
          message: intl.formatMessage({
            description: 'Dialog delete message',
            defaultMessage: 'Are you sure?',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog delete onAgreeText',
            defaultMessage: 'Delete',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
        changeStatus: {
          title: intl.formatMessage({
            description: 'Change status (to process) dialog title',
            defaultMessage: 'Transaction Management to Process',
          }),
          message: intl.formatMessage({
            description: 'Change status (to process) dialog message',
            defaultMessage: 'Are you sure you want to process these elements?',
          }),
          agreeText: intl.formatMessage({
            description: 'Change status (to process) dialog agree text',
            defaultMessage: 'Save',
          }),
          disagreeText: intl.formatMessage({
            description: 'Change status (to process) dialog disagree text',
            defaultMessage: 'Cancel',
          }),
        },
        process: {
          title: intl.formatMessage({
            description: 'Process dialog title',
            defaultMessage: 'Start billing flow for selected transactions',
          }),
          agreeText: intl.formatMessage({
            description: 'Process dialog agree text',
            defaultMessage: 'Send to Zucchetti',
          }),
          disagreeText: intl.formatMessage({
            description: 'Process dialog disagree text',
            defaultMessage: 'Cancel',
          }),
          form: {
            labels: {
              docType: intl.formatMessage({
                description: 'Process dialog doc type label',
                defaultMessage: 'Start flow of',
              }),
              selectAccountCode: intl.formatMessage({
                description: 'select account code label',
                defaultMessage: 'Select the account code',
              }),
              insertAccountCode: intl.formatMessage({
                description: 'insert account code label',
                defaultMessage: 'Insert the account code',
              }),
              flowType: intl.formatMessage({
                description: 'Process dialog flow type label',
                defaultMessage: 'Assign the Flow Type',
              }),
            },
            docTypes: {
              invoice: intl.formatMessage({
                description: 'Process dialog invoice doc type',
                defaultMessage: 'Billing',
              }),
              creditNote: intl.formatMessage({
                description: 'Process dialog credit note doc type',
                defaultMessage: 'Credit Note',
              }),
            },
          },
        },
      },
      snackbar: {
        delete: {
          success: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Delete ok',
          }),
          error: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Some error',
          }),
        },
        changeStatus: {
          success: intl.formatMessage({
            description: 'Snackbar change status succes message',
            defaultMessage: 'Elements successfully processed',
          }),
          error: intl.formatMessage({
            description: 'Snackbar change status error message',
            defaultMessage:
              'Unable to process elements, please check if every transaction customer has filled billing info',
          }),
        },
        process: {
          success: intl.formatMessage({
            description: 'Snackbar process succes message',
            defaultMessage: 'Successfully created billing flow',
          }),
          error: intl.formatMessage({
            description: 'Snackbar process error message',
            defaultMessage:
              'Unable to create billing flow, please check if every transaction customer has filled billing info and billing flow types are configured for this event',
          }),
        },
      },
    },
    dialog: {
      payment: {
        title: intl.formatMessage({
          description: 'edit payment title',
          defaultMessage: 'Edit payment',
        }),
        paymentStatus: {
          label: intl.formatMessage({
            description: 'payment status label',
            defaultMessage: 'Payment status',
          }),
          placeholder: intl.formatMessage({
            description: 'payment status label',
            defaultMessage: 'select payment status',
          }),
        },
        paymentDate: {
          label: intl.formatMessage({
            description: 'payment status label',
            defaultMessage: 'Payment date',
          }),
        },
        totalPrice: intl.formatMessage({
          description: 'total price label',
          defaultMessage: 'Total price (€)',
        }),
        paidFee: intl.formatMessage({
          description: 'Paid fee label',
          defaultMessage: 'Paid fee(€)',
        }),
        buttons: {
          confirm: intl.formatMessage({
            description: 'confirm button',
            defaultMessage: 'confirm',
          }),
          cancel: intl.formatMessage({
            description: 'cancel btn',
            defaultMessage: 'cancel',
          }),
        },
        content: intl.formatMessage({
          description: 'do you want to confirm the payment?',
          defaultMessage: 'Do you want to confirm the payment?',
        }),
        success: intl.formatMessage({
          description: 'success confirm payment',
          defaultMessage: 'The payment have been successfully confirmed',
        }),
        error: intl.formatMessage({
          description: 'error confirm selected payment',
          defaultMessage: 'Error confirm selected payment',
        }),
        missingBillingError: intl.formatMessage({
          description: 'error change status done without billing info',
          defaultMessage:
            'Fill up customer billing information before changing payment status to DONE',
        }),
      },
    },
  }),
};
