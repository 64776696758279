import { utilities } from '@aim/common';
import { getEventFields } from './gqlHelper';

const { getContextsOfUsePerCluster } = utilities;

const unionToContextOfUse = (fields, contexts) => {
  const outFields = fields
    .filter((f) => f.contextsOfUse.items.length)
    .map((field) => {
      let fieldFirstContextOfUse;
      for (let i = 0; i < contexts.length; i++) {
        fieldFirstContextOfUse = field.contextsOfUse.items.find(
          (cu) => cu.contextName === contexts[i]
        );
        if (fieldFirstContextOfUse) break;
      }
      return {
        ...field,
        contextsOfUse: fieldFirstContextOfUse,
        isReadOnly:
          fieldFirstContextOfUse.contextName !== contexts[contexts.length - 1],
      };
    });
  return outFields;
};

export const getDynamicFields = async (
  eventId,
  cluster,
  clusterId,
  profileId,
  excludePaxAnagraphicFields = false
) => {
  if (!eventId || !cluster) return;

  const contextsOfUse = getContextsOfUsePerCluster({
    cluster,
    clusterId,
    profileId,
    excludePaxAnagraphicFields,
  });

  let standardFields = [];
  let customFields = [];
  if (contextsOfUse && contextsOfUse.length > 0) {
    const {
      standardFields: sFields,
      customFields: cFields,
    } = await getEventFields({
      eventId,
      contextsOfUse,
    });

    standardFields = unionToContextOfUse(sFields, contextsOfUse);
    customFields = unionToContextOfUse(cFields, contextsOfUse);
  }

  return { contextsOfUse, fields: [standardFields, customFields] };
};
