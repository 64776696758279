import React from 'react';
import { format, addMinutes, set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { appState } from '@aim/common';
import { calendarVariants } from '../../components/bundles/AimCalendar';
const currentTimezone = appState.eventInfo.getValue().timezone;

export const useDataHelper = () => {
  /**
   * Format Date
   */
  const getFormattedDate = (dateString, formatString = 'dd/MM/yyyy') => {
    try {
      const date = new Date(dateString);
      return format(date, formatString);
    } catch (e) {
      //console.log(e, dateString);
      return dateString;
    }
  };

  /**
   * Parse richText string to JSON
   */
  const parseRichText = (str) => {
    if (str) {
      try {
        const jsonParsed = JSON.parse(str);
        return jsonParsed.blocks[0].text ? jsonParsed : null;
      } catch (e) {
        console.warn(e);
      }
    }
    return null;
  };

  const safeNum = (value) => (isNaN(Number(value)) ? 0 : Number(value));

  const encodeDbNumber = (value) => safeNum((safeNum(value) * 100).toFixed(2));

  const decodeDbNumber = (value) => safeNum(value) / 100;
  const decodeDbHours = (value) => {
    const hd = safeNum(value) / 60;
    const hi = Math.floor(hd);
    const md = hd - hi;
    const mi = md * 60;
    return `${hi}h ${mi}min`;
  };

  const vatCalc = (p, v) => {
    return Number(p) + (Number(p) * Number(v)) / 100;
  };

  function convertToDuration(a, b) {
    const [sh, sm] = a.split(':');
    const [eh, em] = b.split(':');
    const sd = Number(sh) * 60 + Number(sm);
    const ed = Number(eh) * 60 + Number(em);
    const diff = ed - sd;
    return diff;
  }

  const dateValidation = ({
    start,
    end,
    helpers,
    checkEquals = true,
    convertToFormattedDate = false,
  }) => {
    if (start === end && checkEquals)
      return helpers.message({
        custom: `Start time cannot be equal to end time`,
      });

    if (start > end)
      return helpers.message({
        custom: `${
          !convertToFormattedDate ? start : getFormattedDate(start)
        } is greater than ${
          !convertToFormattedDate ? end : getFormattedDate(end)
        }`,
      });
  };

  const useBreakoutRoomDataHelpers = () => {
    function timeValidation(
      start,
      end,
      helpers,
      checkDuration = true,
      checkEquals = true
    ) {
      const res = dateValidation({ start, end, helpers, checkEquals });
      if (res) return res;
      if (!checkDuration) return start;
      const duration = helpers.state.ancestors[0].duration;

      const diff = convertToDuration(start, end);
      if (diff >= duration) return start;
      return helpers.message({
        custom: `${diff} minutes cannot contain a slot of ${duration} minutes`,
      });
    }

    function s1lte1(value, helpers) {
      const end = helpers.state.ancestors[0].end1;
      return timeValidation(value, end, helpers);
    }

    function s2lte2(value, helpers) {
      const end = helpers.state.ancestors[0].end2;
      return timeValidation(value, end, helpers);
    }

    function e1lts2(value, helpers) {
      const end = helpers.state.ancestors[0].start2;
      return timeValidation(value, end, helpers, false, false);
    }

    function checkIsTouched(originalBreakoutRoom, newBreakoutRoom) {
      let touched = false;
      if (newBreakoutRoom.date !== originalBreakoutRoom.date) {
        touched = true;
      }
      if (
        !touched &&
        newBreakoutRoom.duration !== originalBreakoutRoom.duration
      ) {
        touched = true;
      }
      if (!touched && newBreakoutRoom.start1 !== originalBreakoutRoom.start1) {
        touched = true;
      }
      if (!touched && newBreakoutRoom.end1 !== originalBreakoutRoom.end1) {
        touched = true;
      }
      if (!touched && newBreakoutRoom.start2 !== originalBreakoutRoom.start2) {
        touched = true;
      }
      if (!touched && newBreakoutRoom.end2 !== originalBreakoutRoom.end2) {
        touched = true;
      }
      if (
        !touched &&
        originalBreakoutRoom.maxAttendants === 1 &&
        newBreakoutRoom.maxAttendants !== originalBreakoutRoom.maxAttendants
      ) {
        touched = true;
      }

      return touched;
    }

    function calcBreakoutRoomSlots({
      data,
      maxAvailableHours,
      originalBreakoutRoom,
    }) {
      const getContainedSlot = (start, end, duration) => {
        const d = convertToDuration(start, end);
        const ns = d / duration;
        const intero = Math.floor(ns);
        const resto = ns - intero;
        return [intero, resto];
      };

      const setSlot = (i) => {
        let start = startDate;
        startDate = addMinutes(start, data.duration);
        if (i !== 0) start = slots[slots.length - 1].end;
        slots.push({
          start: start,
          end: startDate,
          invitations: [],
        });
      };

      const d = data.date.split('-');
      const [intero1, resto1] = getContainedSlot(
        data.start1,
        data.end1,
        data.duration
      );
      const [intero2, resto2] = getContainedSlot(
        data.start2,
        data.end2,
        data.duration
      );

      const totalDuration =
        (intero1 + intero2 + resto1 + resto2) * data.duration;
      const totalMinutes = (intero1 + intero2) * data.duration;
      const totalRest = (resto1 + resto2) * data.duration;

      const warningData = {
        maxAvailableHours: maxAvailableHours,
        purchasedHours: data.purchasedHours,
        totalDuration: totalDuration,
        totalMinutes: totalMinutes,
        duration: data.duration,
        slotTotalMinutes: {
          A: intero1,
          B: intero2,
        },
        totalRest: totalRest,
        slotRestMinutes: {
          A: resto1,
          B: resto2,
        },
      };
      const purchasedHours = totalDuration.toFixed(0);

      //se avevo degli slot devo capire se ho fatto delle modifiche che li hanno interessati
      if (originalBreakoutRoom?.slots?.length > 0) {
        if (!checkIsTouched(originalBreakoutRoom, data)) {
          return [originalBreakoutRoom.slots, warningData, purchasedHours];
        }
      }

      const slots = [];

      const s1 = data.start1.split(':');
      let startDate = new Date(d[0], d[1] - 1, d[2], s1[0], s1[1]);
      for (let i = 0; i < intero1; i++) {
        setSlot(i);
      }

      const s2 = data.start2.split(':');
      startDate = new Date(d[0], d[1] - 1, d[2], s2[0], s2[1]);
      for (let i = 0; i < intero2; i++) {
        setSlot(i);
      }

      return [slots, warningData, purchasedHours];
    }

    function getDurations(num) {
      const arr = [];
      for (let i = 1; i < num + 1; i++) {
        arr.push({ id: i, value: i * 15 });
      }
      arr.push({ id: num + 1, value: 120 });
      return arr;
    }

    function getHours(hhmm) {
      try {
        if (hhmm) return format(new Date(hhmm), 'HH:mm');
        return undefined;
      } catch (error) {
        return hhmm;
      }
    }

    function getDate(date) {
      try {
        if (date) return format(new Date(date), 'dd/MM/yyyy');
        return undefined;
      } catch (error) {
        return date;
      }
    }

    function setDate(date, hhmm) {
      const arr = hhmm.split(':');
      return set(date, {
        hours: Number(arr[0]),
        minutes: Number(arr[1]),
      }).toISOString();
    }

    function parseBreakoutRoom(breakoutRoom, setPrice = true) {
      if (breakoutRoom) {
        const br = {
          ...breakoutRoom,
          date: breakoutRoom.date
            ? utcToZonedTime(new Date(breakoutRoom.date), currentTimezone)
            : null,
          start1: getHours(breakoutRoom.start1),
          end1: getHours(breakoutRoom.end1),
          start2: getHours(breakoutRoom.start2),
          end2: getHours(breakoutRoom.end2),
          slots: breakoutRoom.slots.items?.map((s) => {
            return {
              ...s,
              start: utcToZonedTime(new Date(s.start), currentTimezone),
              end: utcToZonedTime(new Date(s.end), currentTimezone),
              invitations: s.invitations.items?.map((r) => r),
              ...(s.isOneToOne && {
                type: calendarVariants.BREAKOUT_ROOM_MEETING,
              }),
              emails: s.emails.items?.map((r) => r),
            };
          }),
        };
        if (setPrice)
          br.price =
            breakoutRoom.buyOperation?.price ||
            breakoutRoom.breakoutRoomService.price;
        return br;
      }
      return null;
    }

    return {
      timeValidation,
      s1lte1,
      s2lte2,
      e1lts2,
      calcBreakoutRoomSlots,
      getDurations,
      parseBreakoutRoom,
      getHours,
      getDate,
      setDate,
      checkIsTouched,
    };
  };

  /**
   *
   */
  const hideZendesk = () => {
    return (
      <style jsx global>{`
        iframe#launcher {
          opacity: 0 !important;
          width: 0px !important;
          height: 0px !important;
        }
      `}</style>
    );
  };

  return {
    getFormattedDate,
    parseRichText,
    convertToDuration,
    dateValidation,
    useBreakoutRoomDataHelpers,
    safeNum,
    decodeDbHours,
    decodeDbNumber,
    encodeDbNumber,
    vatCalc,
    hideZendesk,
  };
};
