import React, { useRef } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useRichTextEditorContext, MenuButton } from 'mui-tiptap';

const MenuButtonFullScreen = (props) => {
  const editor = useRichTextEditorContext();
  const buttonRef = useRef();
  return (
    <MenuButton
      buttonRef={buttonRef}
      tooltipLabel="Full screen"
      IconComponent={
        editor?.storage?.fullScreen?.isFullScreen
          ? FullscreenExitIcon
          : FullscreenIcon
      }
      selected={false}
      disabled={!editor?.isEditable}
      onClick={() =>
        // When clicking the button to open the bubble menu, we'll place the
        // menu below the button
        editor?.commands.toggleFullScreen()
      }
      {...props}
    />
  );
};

export default MenuButtonFullScreen;
