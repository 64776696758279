import { googleHelper } from '@aim/common';

const {
  getGoogleMapsCountriesByInput,
  getGoogleMapsPlaceByPlaceId,
} = googleHelper;

export const getFieldValueByFieldDefinitionKey = (
  participation,
  key,
  defaultValue
) =>
  JSON.parse(
    participation?.fieldValues?.items?.find?.(
      (i) => i?.fieldDefinition?.key === key
    )?.value ?? JSON.stringify(defaultValue || '')
  );

export const extractBillingInformationFromFieldValues = async (
  participation,
  joinNameAndSurname = true
) => {
  let country = '';
  let countryIsoCode = '';

  const countryField = getFieldValueByFieldDefinitionKey(
    participation,
    'country'
  );

  try {
    const countriesRes = await getGoogleMapsCountriesByInput(countryField);
    if (countriesRes?.length === 1) {
      const countryRes = (
        await getGoogleMapsPlaceByPlaceId(countriesRes[0].id)
      )?.address_components?.find?.((ac) => ac.types?.includes?.('country'));
      countryIsoCode = countryRes.short_name || '';
      country = countriesRes[0].label || '';
    }
  } catch (e) {
    console.error('extractBillingInformationFromFieldValues', e);
  }

  return {
    invoiceTo: 'individual',
    name: `${participation?.givenName || ''} ${
      joinNameAndSurname ? participation?.familyName || '' : ''
    }`.trim(),
    ...(joinNameAndSurname
      ? {}
      : { surname: `${participation?.familyName || ''}`.trim() }),
    address: getFieldValueByFieldDefinitionKey(participation, 'address'),
    city: getFieldValueByFieldDefinitionKey(participation, 'city'),
    postalCode: getFieldValueByFieldDefinitionKey(participation, 'zip_code'),
    region: getFieldValueByFieldDefinitionKey(participation, 'region'),
    province: getFieldValueByFieldDefinitionKey(participation, 'province'),
    country,
    countryIsoCode,
    vatCode: getFieldValueByFieldDefinitionKey(participation, 'vat_code'),
    taxCode: getFieldValueByFieldDefinitionKey(participation, 'fiscal_code'),
    reference: '',
    email: participation?.email || '',
    pec: '',
    phone: participation?.phone || '',
    invoiceToPublicAuthority: false,
    uniqueCode: '',
    ipaCode: '',
    isSplitPayment: false,
    isVatEvent: false,
  };
};
