import React from 'react';
import Collapse from '@material-ui/core/Collapse';
// import isSameDay from 'date-fns/isSameDay';
// import isBefore from 'date-fns/isBefore';
// import isAfter from 'date-fns/isAfter';

import { AimFilter } from '@aim/components';

const componentsMap = {
  string: 'AimTextField',
  checkbox: 'AimCheckbox',
  select: 'AimSelect',
  // isSameDay: 'AimDateTextField',
  // isBefore: 'AimDateTextField',
  // isAfter: 'AimDateTextField',
};

const functionsMap = {
  string: (res, column) => ({
    ...res,
    [column]: (item, value) => item.toLowerCase().includes(value.toLowerCase()),
  }),
  checkbox: (res, column) => ({
    ...res,
    [column]: (item, value) => !!item === !!value,
  }),
  select: (res, column) => ({
    ...res,
    [column]: (item, value) => item.toLowerCase().includes(value.toLowerCase()),
  }),
  // isSameDay: (res, column) => ({}),
  // isBefore: (res, column) => ({}),
  // isAfter: '(res, column) => ({}),
};

const CardsFilter = ({
  isFilterOpen,
  model,
  rows,
  translation,
  onFilterResult,
}) => {
  const modelBase = model.map((i) =>
    typeof i === 'string' ? { column: i, type: 'string' } : i
  );

  const initialState = modelBase.reduce(
    (res, curr) =>
      curr.type === 'checkbox'
        ? { ...res, [curr.column]: false }
        : { ...res, [curr.column]: '' },
    {}
  );

  const filtersFns = modelBase.reduce(
    (res, curr) => functionsMap[curr.type](res, curr.column),
    {}
  );

  const innerModel = modelBase.map((item) => {
    const name = item.column;
    const filterTr = translation.filters;
    const label =
      filterTr && filterTr[item.column]
        ? filterTr[item.column].label
        : translation.labels[item.column];
    const placeholder =
      filterTr && filterTr[item.column]
        ? filterTr[item.column].placeholder
        : translation.labels[item.column];
    const type = componentsMap[item.type];

    if (item.type !== 'select') {
      return {
        name,
        label,
        placeholder,
        type,
      };
    }
    return {
      name,
      label,
      placeholder,
      type,
      items:
        item.options ||
        rows.reduce((res, curr) => {
          const value = curr[item.column];
          const isAlreadyIn = res.find((i) => i === value);
          return isAlreadyIn ? res : [...res, value];
        }, []),
    };
  });

  return (
    <Collapse in={isFilterOpen}>
      <AimFilter
        rows={rows}
        initialState={initialState}
        filtersFns={filtersFns}
        model={innerModel}
        onFilterResult={onFilterResult}
      />
    </Collapse>
  );
};

export default CardsFilter;
