import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import { AimIconButton } from './AimIconButton';
import { AimIconAndTextButton } from './AimIconAndTextButton';

const specialChars = 'ABΓΔEZHΘIKMN≡OΛΠ ΡΣΤΥΦΧΨΩÀÁÂÃÇËÊÉÈÅÄÆ﹛|﹜～§Ⓒ®±²³M¹¼½¾'.split(
  ''
);

const CharItem = ({ char, onSelect }) => (
  <Grid item xs={3}>
    <Button size="small" style={{ padding: 0 }} onClick={() => onSelect(char)}>
      {char}
    </Button>
  </Grid>
);

export const AimSpecialChars = ({ onSelect, buttonText }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSelect = (e) => {
    setAnchorEl(null);
    onSelect && onSelect(e);
  };

  return (
    <div>
      <AimIconAndTextButton
        aria-controls="special-chars"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        variant="lightBlueFill"
        text={buttonText}
      ></AimIconAndTextButton>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Grid container style={{ maxWidth: 300 }}>
          {specialChars.map((c) => (
            <CharItem key={c} char={c} onSelect={handleSelect} />
          ))}
        </Grid>
      </Popover>
    </div>
  );
};
