/* eslint-disable react/display-name */
import React, { useState, useRef } from 'react';

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  //   PDFViewer,
  BlobProvider,
} from '@react-pdf/renderer';
import PDFViewer from 'mgr-pdf-viewer-react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { constants } from '@aim/common';
import { AimIconAndTextButton } from '../atoms/AimIconAndTextButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { globalStyles } from './AimReactPdfRenderer/ReactPdfHelper';
import './AimReactPdfRenderer/PDFViewer.css';
import { styled } from '../styled';

// Font.register({
//   family: 'Roboto',
//   fonts: [
//     { src: RobotoRegular, fontStyle: 'normal', fontWeight: 400 },
//     { src: RobotoBold, fontStyle: 'normal', fontWeight: 700 },
//     { src: RobotoBoldItalic, fontStyle: 'italic', fontWeight: 700 },
//   ],
// });

const CustomIconButton = styled(IconButton)({
  '&:hover': {
    background: 'transparent !important',
  },
});

const StyledReactPdfWithPrintOptionsText = ({ element, value }) => {
  const styles = StyleSheet.create({
    element: {
      fontSize:
        element[constants.PrintOptions[element.key].options.fontSize] || 16,
      fontWeight: element[
        constants.PrintOptions[element.key].options.format[0].key
      ]
        ? 'bold'
        : 400,
      fontStyle: element[
        constants.PrintOptions[element.key].options.format[1].key
      ]
        ? 'italic'
        : 'normal',
      textDecoration: element[
        constants.PrintOptions[element.key].options.format[2].key
      ]
        ? 'underline'
        : 'none',
    },
  });
  return <Text style={styles.element}>{value}</Text>;
};

const DefaultText = ({ value, spacing = 0, style }) => {
  const spacingText = '  '.repeat(spacing);
  const styles = StyleSheet.create({
    element: {
      // marginLeft: 20 * spacing,
      // marginRight: 20 * spacing,
      fontSize: 14,
      fontWeight: 100,
      fontStyle: 'normal',
      textDecoration: 'none',
      ...style,
    },
  });
  return (
    <Text style={styles.element}>
      {spacingText}
      {value}
      {spacingText}
    </Text>
  );
};

const PageTemplateRows = ({ rowsTemplate, model, printOptions }) => {
  return rowsTemplate.map(
    ({
      separator = {
        text: '',
        spacing: 0,
        style: {},
      },
      interline = 0.1,
      indentation = 0,
      textSections = [],
      iterateOnProperty,
      subRowsTemplate = [],
    }) => {
      const styles = StyleSheet.create({
        rowStyle: {
          flexDirection: 'row',
          display: 'flex',
          marginLeft: 20 * indentation,
          marginTop: 20 * interline,
        },
        subView: {
          display: 'flex',
          margin: 0.1,
          padding: 0.1,
        },
        mainView: {
          margin: 0.1,
          padding: 0.1,
          // flexGrow: 1,
          // ...(subRowsTemplate.length === 0
          //   ? { display: 'flex' }
          //   : { flexGrow: 1 }),
        },
      });

      const itemsToRender = iterateOnProperty
        ? model[iterateOnProperty]
        : [model];
      if (!itemsToRender) {
        throw new Error(`item with key ${iterateOnProperty} not found`);
      }
      return itemsToRender.map((currentLevelModel, idx) => {
        return (
          <View key={idx} style={styles.mainView}>
            {textSections.length > 0 &&
            textSections.filter(({ modelPath }) => currentLevelModel[modelPath])
              .length === textSections.length ? (
              <Text style={styles.rowStyle}>
                {textSections.map(({ key, modelPath }, idx) => {
                  const value = currentLevelModel[modelPath];
                  const element = printOptions.find((po) => po.key === key);
                  return (
                    <Text key={`${idx}${modelPath}`}>
                      {separator.text && idx > 0 ? (
                        <DefaultText
                          value={separator.text}
                          spacing={separator.spacing}
                          style={separator.style}
                        />
                      ) : null}
                      <StyledReactPdfWithPrintOptionsText
                        element={element}
                        value={value}
                      />
                    </Text>
                  );
                })}
              </Text>
            ) : null}
            {subRowsTemplate.length > 0 ? (
              <View style={styles.subView}>
                <PageTemplateRows
                  rowsTemplate={subRowsTemplate}
                  model={currentLevelModel}
                  printOptions={printOptions}
                />
              </View>
            ) : null}
          </View>
        );
      });
    }
  );
};

const PdfTemplateRender = ({ model, rowsTemplate, printOptions }) => {
  const modelArray = Array.isArray(model) ? model : [model];

  return modelArray.map((modelItem, idx) => (
    <PageTemplateRows
      key={idx}
      rowsTemplate={rowsTemplate}
      model={modelItem}
      printOptions={printOptions}
    />
  ));
};

export const AimReactPdfRenderer = ({
  rowsTemplate = [],
  model = [],
  printOptions = [],
}) => {
  const [page, setPage] = useState(1);
  const handleNextButtonRef = useRef(null);
  const handlePrevButtonRef = useRef(null);
  return (
    <>
      <PDFDownloadLink
        document={
          <Document>
            <Page size="A4" style={globalStyles.page}>
              <PdfTemplateRender
                rowsTemplate={rowsTemplate}
                model={model}
                printOptions={printOptions}
              />
            </Page>
          </Document>
        }
        fileName="program.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            'Loading document...'
          ) : (
            <AimIconAndTextButton
              text="Download"
              style={{ background: 'white', marginTop: 20, marginBottom: 20 }}
            >
              <GetAppIcon />
            </AimIconAndTextButton>
          )
        }
      </PDFDownloadLink>
      <BlobProvider
        document={
          <Document>
            <Page size="A4" style={globalStyles.page}>
              <PdfTemplateRender
                rowsTemplate={rowsTemplate}
                model={model}
                printOptions={printOptions}
              />
            </Page>
          </Document>
        }
      >
        {({ blob, url, loading, error }) => {
          // Do whatever you need with blob here
          return url ? (
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              <CustomIconButton
                style={{
                  marginRight: 100,
                }}
                disableRipple
                disableFocusRipple
                onClick={() => handlePrevButtonRef.current()}
              >
                <ArrowBackIosIcon />
              </CustomIconButton>
              <div style={{ boxShadow: '0 25px 50px 0 rgb(62 62 62 / 15%)' }}>
                <PDFViewer
                  document={{
                    url,
                  }}
                  page={page}
                  css="customViewer"
                  navigation={{
                    css: {
                      wrapper: 'customWrapper',
                    },
                    elements: {
                      previousPageBtn: ({ handlePrevClick }) => {
                        handlePrevButtonRef.current = handlePrevClick;
                        return <></>;
                      },
                      nextPageBtn: ({ handleNextClick }) => {
                        handleNextButtonRef.current = handleNextClick;
                        return <></>;
                      },
                      pages: () => <></>,
                    },
                  }}
                />
              </div>
              <CustomIconButton
                style={{ marginLeft: 100 }}
                disableRipple
                disableFocusRipple
                onClick={() => handleNextButtonRef.current()}
              >
                <ArrowForwardIosIcon />
              </CustomIconButton>
            </div>
          ) : (
            <></>
          );
        }}
      </BlobProvider>
    </>
  );
};
