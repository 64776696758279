import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getTravelsInfoQuery = /* GraphQL */ `
  query ParticipationTravelInfo($id: ID!) {
    getParticipation(id: $id) {
      id
      username
      familyName
      givenName
      email
      phone
      isSpeaker
      profile {
        id
        name
      }
      typology {
        id
        name
      }
      cluster
      isInvited
      status
      participationTravel(filter: { isDeleted: { ne: true } }) {
        items {
          id
          date
          arrivalDate
          isPublished
          publicationDate
          orderCode
          businessUnit
          pnrCode
          returnTrip
          cost
          note
          checkInLink
          ticketFileAttachments {
            items {
              id
              attachmentFile {
                id
                originalName
                name: originalName
                size
                extension
              }
            }
          }
          guests {
            items {
              id
              givenName
              familyName
              email
              phone
              participation {
                id
              }
            }
          }
          travelRoute {
            id
            startDate
            endDate
            terminalIn {
              name
            }
            terminalOut {
              name
            }
            travelType
            transportCode
            pnrCode
            occupation
          }
        }
      }
      transfer {
        id
        oneWayTo
        oneWayTime
        oneWayFrom
        oneWayPickUpLocation
        oneWayPickUpDestination
        oneWayDate
        returnTime
        returnFrom
        returnPickUpLocation
        returnPickUpDestination
        returnTo
        returnDate
        isPublished
      }
      participationServices {
        isTravelIncluded
        isTransferAIncluded
        isTransferRIncluded
        note
        personIncluded
        id
        codeEventOrder
        eventOrderId
        travelFacultyResponse
        travelConfigurationCompleted
      }
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateTransferMutation = /* GraphQL */ `
  mutation UpdateTransfer(
    $input: UpdateTransferInput!
    $condition: ModelTransferConditionInput
  ) {
    updateTransfer(input: $input, condition: $condition) {
      id
    }
  }
`;

const createTransferQuery = /* GraphQL */ `
  mutation CreateTransfer($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      id
    }
  }
`;

const createTravelMutation = /* GraphQL */ `
  mutation CreateTravelQuery($input: CreateTravelInput!) {
    createTravel(input: $input) {
      id
    }
  }
`;

const createTravelRouteMutation = /* GraphQL */ `
  mutation CreateTravelRoute($input: CreateTravelRouteInput!) {
    createTravelRoute(input: $input) {
      id
    }
  }
`;

const updateTravelRouteMutation = /* GraphQL */ `
  mutation UpdateTravelRoute($input: UpdateTravelRouteInput!) {
    updateTravelRoute(input: $input) {
      id
    }
  }
`;

const updateTravelMutation = /* GraphQL */ `
  mutation UpdateTravel(
    $input: UpdateTravelInput!
    $condition: ModelTravelConditionInput
  ) {
    updateTravel(input: $input, condition: $condition) {
      id
    }
  }
`;

const createTerminalMutation = /* GraphQL */ `
  mutation CreateTerminal($input: CreateTerminalInput!) {
    createTerminal(input: $input) {
      id
    }
  }
`;

const getEventOrderQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      eventOrder {
        items {
          id
          code
          codeName
          sponsor {
            id
            name
            isDeleted
          }
          delegation {
            id
            name
          }
        }
      }
    }
  }
`;

export const listTerminalsQuery = /* GraphQL */ `
  query ListTerminals {
    listTerminals {
      items {
        id
        name
      }
    }
  }
`;

const createParticipationServicesQuery = /* GraphQL */ `
  mutation createParticipationServicesMutation(
    $input: CreateParticipationServicesInput!
  ) {
    createParticipationServices(input: $input) {
      id
    }
  }
`;

const updateParticipationServicesQuery = /* GraphQL */ `
  mutation UpdateParticipationServices(
    $input: UpdateParticipationServicesInput!
    $condition: ModelParticipationServicesConditionInput
  ) {
    updateParticipationServices(input: $input, condition: $condition) {
      id
    }
  }
`;

const createParticipationAttachmentsMutation = /* GraphQL */ `
  mutation CreateParticipationAttachments(
    $input: CreateParticipationAttachmentsInput!
    $condition: ModelParticipationAttachmentsConditionInput
  ) {
    createParticipationAttachments(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteParticipationAttachmentsMutation = /* GraphQL */ `
  mutation DeleteParticipationAttachments(
    $input: DeleteParticipationAttachmentsInput!
    $condition: ModelParticipationAttachmentsConditionInput
  ) {
    deleteParticipationAttachments(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createTransfer = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: createTransferQuery, variables: { input } })
      .then((response) =>
        resolve({
          ...response?.data?.createTransfer,
        })
      )
      .catch((e) => {
        console.error('create-transfer', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const createTravel = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: createTravelMutation, variables: { input } })
      .then((response) =>
        resolve({
          ...response?.data?.createTravel,
        })
      )
      .catch((e) => {
        console.error('create-travel', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const createTerminal = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: createTerminalMutation, variables: { input } })
      .then((response) =>
        resolve({
          ...response?.data?.createTerminal,
        })
      )
      .catch((e) => {
        console.error('create-terminal', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const createTravelRoute = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: createTravelRouteMutation, variables: { input } })
      .then((response) =>
        resolve({
          ...response?.data?.createTravelRoute,
        })
      )
      .catch((e) => {
        console.error('create-travel-route', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateTravelRoute = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: updateTravelRouteMutation, variables: { input } })
      .then((response) =>
        resolve({
          ...response?.data?.updateTravelRoute,
        })
      )
      .catch((e) => {
        console.error('update-travel-route', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const getTravelInfo = (participationId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getTravelsInfoQuery,
      variables: { id: participationId },
    })
      .then((response) => {
        const travelFaculty =
          (response.data?.getParticipation &&
            response.data?.getParticipation.participationServices &&
            response.data?.getParticipation.participationServices
              .travelFacultyResponse &&
            JSON.parse(
              response?.data?.getParticipation.participationServices
                .travelFacultyResponse
            )) ||
          null;
        return resolve({
          ...response?.data?.getParticipation,
          travelFacultyResponse: travelFaculty,
        });
      })
      .catch((e) => {
        console.error('get-travel-info', e);
        reject();
      })
      .finally(hideLoader);
  });

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.updateParticipation;
        resolve(data);
      })
      .catch((e) => {
        console.error('travel-update-participation', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateTransfer = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateTransferMutation,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.updateTransfer;
        resolve(data);
      })
      .catch((e) => {
        console.error('travel-update-transfer', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateTravel = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateTravelMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.updateTravel);
      })
      .catch((e) => {
        console.error('update-travel', e);
        hideLoader();
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const listTerminals = (useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: listTerminalsQuery,
      variables: {},
    })
      .then((response) => {
        resolve(response?.data?.listTerminals?.items);
      })
      .catch((e) => {
        console.error('list-terminals', e);
        hideLoader();
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getEventOrder = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getEventOrderQuery, variables: { id } })
      .then((response) =>
        resolve({
          ...response?.data?.getEvent?.eventOrder,
        })
      )
      .catch((e) => {
        console.error('get-event-order', e);
        reject();
      })
      .finally(hideLoader);
  });

export const createTravelSettings = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: createParticipationServicesQuery,
      variables: { input },
    })
      .then((response) =>
        resolve({
          ...response?.data?.createParticipationServices,
        })
      )
      .catch((e) => {
        console.error('update-Participation-Service', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateTravelSettings = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateParticipationServicesQuery,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.updateTransfer;
        resolve(data);
      })
      .catch((e) => {
        console.error('update-Participation-Services', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const createParticipationAttachments = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createParticipationAttachmentsMutation,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.createParticipationAttachments;
        resolve(data);
      })
      .catch((e) => {
        console.error('create-Participation-attachments', e);
        reject(e);
      });
  });

export const deleteParticipationAttachments = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: deleteParticipationAttachmentsMutation,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.deleteParticipationAttachments;
        resolve(data);
      })
      .catch((e) => {
        console.error('create-Participation-attachments', e);
        reject(e);
      });
  });
