import React, { useState, useEffect } from 'react';
import useI18n from '../utils/i18n';

import {
  CustomStyledDialog,
  formControlStyle,
} from '../utils/CustomStyledDialog';

import Grid from '@material-ui/core/Grid';
import { AimTypography } from '../../../atoms/AimTypography';
import { AimSelect, AimSelectMenuItem } from '../../../atoms/AimSelect';
import { AimFileSelector } from '../../AimFileSelector';

export const ExportTemplateDialog = ({
  intl,
  exportTemplateDialog,
  onAgree,
  onClose,
  availableProfiles,
  cluster,
}) => {
  // Hooks
  const i18n = useI18n.exportDialog(intl);
  // const { cluster } = useParams();

  // States
  const [profile, setProfile] = useState(1);
  const [templateXlsx, setTemplateXlsx] = useState();

  //Effects
  useEffect(() => {
    if (availableProfiles && profile) {
      createTemplateFile();
    }
  }, [availableProfiles, profile]);

  const createTemplateFile = () => {
    const parsed = {
      originalName: `${getFileName(profile)}_template.xlsx`,
      extension: '.xlsx',
      size: null,
    };
    setTemplateXlsx(parsed);
  };

  // Functions
  const handleOnSubmit = () => {
    onAgree && onAgree(profile);
  };

  const getFileName = (profile) => {
    if (profile !== 1)
      return availableProfiles?.filter((p) => p.id === profile)[0].label;
    else return cluster;
  };

  return (
    <CustomStyledDialog
      title={i18n.title}
      open={exportTemplateDialog.isOpen}
      onClose={() => {
        onClose();
      }}
      onAgree={() => {
        handleOnSubmit();
      }}
      disableEnforceFocus
    >
      <Grid container>
        <Grid item xs={12}>
          <AimTypography variant="text">{i18n.subTitle}</AimTypography>
          <AimSelect
            name="profile"
            value={profile}
            onChange={(e) => setProfile(e.target.value)}
            formControlStyle={formControlStyle}
            label={i18n.profileLabel}
          >
            {availableProfiles &&
              availableProfiles.map((x, idx) => (
                <AimSelectMenuItem key={idx} value={x.id}>
                  {x.label}
                </AimSelectMenuItem>
              ))}
          </AimSelect>
        </Grid>
        {templateXlsx && (
          <Grid item xs={12}>
            <AimFileSelector
              readOnly={true}
              label={i18n.templateLabel}
              disable
              file={templateXlsx}
              maxFiles={1}
              formControlStyle={{
                ...formControlStyle,
                margin: '0 auto',
              }}
              dropzoneAdditionalProps={{ accept: '.xls, .xlsx' }}
            />
          </Grid>
        )}
      </Grid>
    </CustomStyledDialog>
  );
};
