import React from 'react';
import translation from './HeaderParticipationAssign/translation';

import { AimTypography } from '../atoms';
import { styled } from '../styled';
import { theme } from '../../theme';

const InnerContainer = styled('div')({
  // padding: 20,
});

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  paddingLeft: 30,
});

const HeaderContainer = styled('div')({
  backgroundColor: 'white',
  margin: '10px 0',
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: 30,
});

const SelectedParticipants = styled('div')({
  display: 'flex',
  backgroundColor: theme.colors.greyScale.grey2,
  alignItems: 'center',
});

const HeaderParticipationColumn = styled('div')({
  width: '10%',
});

const HeaderParticipation = ({ i18n, participant }) => {
  return (
    <>
      <Header>
        {Object.values(i18n).map((label, idx) => {
          let colWidth = '18%';
          if (idx === 3) colWidth = '28%';
          return (
            <HeaderParticipationColumn key={idx} style={{ width: colWidth }}>
              <AimTypography variant="columnHeader">{label}</AimTypography>
            </HeaderParticipationColumn>
          );
        })}
      </Header>
      <HeaderContainer>
        {Object.keys(i18n).map((key, idx) => {
          console.log('key ', key);
          let colWidth = '18%';
          if (idx === 3) colWidth = '28%';
          return (
            <HeaderParticipationColumn key={idx} style={{ width: colWidth }}>
              <AimTypography variant="text">{participant[key]}</AimTypography>
            </HeaderParticipationColumn>
          );
        })}
      </HeaderContainer>
    </>
  );
};
/**
 * Wraps faculty manages pages
 * @param  {Object} input - input
 * @param  {React.Component} input.children - The effective body of the page
 * @param  {string} input.title - The title of the page
 * @param  {function} input.onSave - Function triggered onSave
 * @param  {function} input.extractPax - Function to extract pax from this template (usefull for an external setState)
 * @param  {boolean} input.disableSaveBtn - Boolean to handle disabled on save btn
 *  *  * @example
 * <ManagePageTemplate
 *  title={'Just a simple title'}
 *  onSave={handleOnSave()}
 *  disableSaveBtn={true}>
 *    <AimTypography>This is a simple component</AimYypography>
 * </ManagePageTemplate>
 */
export const HeaderParticipationAssign = ({
  participations,
  hideParticipantInfo = false,
  intl,
}) => {
  //Hooks
  const i18n = translation.headerParticipationAssign(intl);

  return (
    <div>
      <InnerContainer>
        {!participations?.length ? (
          <AimTypography variant={'h3'} style={{ marginTop: 30 }}>
            {i18n.errors.noParticipant}
          </AimTypography>
        ) : (
          <>
            {!hideParticipantInfo ? (
              participations?.length === 1 ? (
                <HeaderParticipation
                  i18n={i18n.participant}
                  participant={participations[0]}
                />
              ) : (
                <SelectedParticipants style={{ paddingLeft: 20 }}>
                  <AimTypography variant={'textBold'}>
                    {i18n.paxMultiple} {' ' + participations?.length}
                  </AimTypography>
                </SelectedParticipants>
              )
            ) : null}
          </>
        )}
      </InnerContainer>
    </div>
  );
};
