import React from 'react';
import Divider from '@material-ui/core/Divider';
import { styled } from './../../styled';
import useSteps from './useSteps';
import { AimTypography } from './../../atoms/AimTypography';
import { theme } from '@aim/components';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  indexContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    // maxWicth: 200,
    gap: '4.5px',
    paddingRight: 15,
  },
  // indexItem: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   gap: '0.5rem',
  //   cursor: 'pointer !important',
  // },
  indexItemInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4.5px',
    alignItems: 'center',
  },
  slidesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 4,
  },
  slide: {
    padding: 15,
    background: '#fff',
    borderRadius: 4,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  slideFixedHeight: (variant) => ({
    padding: 15,
    minHeight: '50vh',
    overflow: 'auto',
    maxHeight: '50vh',
    background:
      variant === 'grey' ? theme.colors.greyScale.backgroundGrey : '#fff',
    borderRadius: 4,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  }),
  slideContent: {
    flex: 1,
  },
  controlsContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
  stepTitle: {
    color: '#000',
    fontSize: '0.875rem',
    fontWeight: '700',
    textAlign: 'right',
  },
  stepDot: {
    width: 12,
    height: 12,
    textAlign: 'center',
    margin: '12.5px 5px 5px 5px',
    borderRadius: '50%',
    backgroundColor: '#FFD400',
  },
  stepLink: (numberOfsteps) => ({
    height: numberOfsteps > 5 ? 25 : 35,
    width: 1,
    backgroundColor: '#84819A47',
  }),
};

const Container = ({ children }) => (
  <div style={styles.container}>{children}</div>
);

const IndexContainer = ({ children }) => (
  <div style={styles.indexContainer}>{children}</div>
);

const ItemDone = (numberOfsteps) => (
  <>
    <div style={{ ...styles.stepDot }} />
    <div
      style={{
        ...styles.stepLink(numberOfsteps),
        backgroundColor: '#84819A',
      }}
    />
  </>
);

const ItemCurrent = ({ isLast, fixedHeight, numberOfsteps }) => (
  <>
    <div style={styles.stepDot} />

    {!isLast && !fixedHeight && (
      <div
        style={{
          ...styles.stepLink(numberOfsteps),
          height: 105,
        }}
      />
    )}

    {fixedHeight && (
      <div
        style={{
          ...styles.stepLink(numberOfsteps),
          height: isLast ? 70 : 105,
          ...(isLast && { visibility: 'hidden' }),
        }}
      />
    )}
  </>
);

const ItemFuture = ({ isLast, numberOfsteps }) => (
  <>
    <div style={{ ...styles.stepDot, backgroundColor: 'gray' }} />
    {!isLast && <div style={styles.stepLink(numberOfsteps)} />}
  </>
);

const StyledIndexItem = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '0.5rem',
  cursor: 'pointer !important',
});

const IndexItem = ({
  title,
  stepIndex,
  currentStep,
  isLast,
  onClick,
  fixedHeight,
  numberOfsteps,
}) => (
  <StyledIndexItem onClick={onClick}>
    {/* <div style={styles.stepTitle}>{title}</div> */}
    {typeof title === 'string' ? <StepTitle>{title}</StepTitle> : title}
    <div style={styles.indexItemInnerContainer}>
      {stepIndex < currentStep ? (
        <ItemDone numberOfsteps={numberOfsteps} />
      ) : stepIndex === currentStep ? (
        <ItemCurrent
          numberOfsteps={numberOfsteps}
          fixedHeight
          {...{ isLast, fixedHeight }}
        />
      ) : (
        <ItemFuture numberOfsteps={numberOfsteps} isLast={isLast} />
      )}
    </div>
  </StyledIndexItem>
);

const SlidesContainer = ({ children }) => (
  <div style={styles.slidesContainer}>{children}</div>
);

export const StepDotTitle = ({ children }) => (
  <AimTypography variant="h6">{children}</AimTypography>
);

export const StepTitle = ({ children }) => (
  <AimTypography variant="h4" boxStyle={styles.stepTitle}>
    {children}
  </AimTypography>
);

export const Title = ({ children }) => (
  <AimTypography
    variant="h2"
    boxStyle={{ fontSize: '1.125rem', fontWeight: '700' }}
  >
    {children}
  </AimTypography>
);

export const StepDivider = styled(Divider)({
  marginTop: 25,
  marginBottom: 20,
});

const Slide = ({
  idx,
  children,
  index,
  controlsContainer,
  fixedHeight,
  variant,
}) =>
  idx === index ? (
    <div
      style={
        fixedHeight
          ? styles.slideFixedHeight(variant)
          : {
              ...styles.slide,
              background:
                variant === 'grey'
                  ? theme.colors.greyScale.backgroundGrey
                  : '#fff',
            }
      }
    >
      <div style={styles.slideContent}>{children}</div>
      {controlsContainer}
    </div>
  ) : null;

export const AimStepper = ({
  titles,
  stepperControls,
  onComplete,
  children,
  titlesObject, //added prop to leave titles backwards compatibility
  outsideStepperControls = false,
  fixedHeight = false,
  slideStyle = { minHeight: 400, overflow: 'auto' },
  variant = 'white',
}) => {
  const nextTitles = titlesObject
    ? titlesObject
    : titles.map((title) => ({ title: title, isHidden: false }));
  const visibleTitles = nextTitles.filter((t) => !t.isHidden);
  const config = { steps: visibleTitles.length };
  const {
    index,
    // step,
    isLastStep,
    navigation,
  } = useSteps(config);
  const { previous, next, go } = navigation;

  const currentStepperControls = stepperControls({
    previous,
    next,
    index,
    isLastStep,
  });

  if (onComplete && isLastStep) {
    onComplete();
  }

  // const handleChangeIndex = (i) => go(i);

  const ControlsContainer = () => (
    <div style={styles.controlsContainer}>{currentStepperControls}</div>
  );

  const renderChild = (child, idx) => (
    <Slide
      key={idx}
      idx={idx}
      index={index}
      variant={variant}
      fixedHeight={fixedHeight}
      {...(!outsideStepperControls && {
        controlsContainer: <ControlsContainer />,
      })}
    >
      {child}
    </Slide>
  );

  return (
    <>
      <Container>
        <IndexContainer>
          {visibleTitles.map(({ title }, idx) => (
            <IndexItem
              key={title?.props?.children || idx}
              title={title}
              stepIndex={idx}
              currentStep={index}
              isLast={idx === visibleTitles.length - 1}
              onClick={() => go(idx)}
              fixedHeight={fixedHeight}
              numberOfsteps={visibleTitles.length}
            />
          ))}
        </IndexContainer>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '80vw',
            backgroundColor:
              variant === 'grey'
                ? theme.colors.greyScale.backgroundGrey
                : 'white',
            ...slideStyle,
          }}
        >
          <SlidesContainer>
            {children
              .filter((c, idx) => !nextTitles?.[idx]?.isHidden)
              .map(renderChild)}
          </SlidesContainer>
        </div>
      </Container>
      {outsideStepperControls && <ControlsContainer />}
    </>
  );
};
