import React, { useState } from 'react';

import { AimTablePage } from '@aim/components';

import { translation } from './i18n';
import { format } from 'date-fns';

import { constants } from '@aim/common';

export const RoomDetail = ({
  intl,
  eventId,
  channelType,
  channelId,
  goBack,
}) => {
  const i18n = translation.roomDetail(intl);
  const [title, setTitle] = useState();

  const getDelegationsRoomDetailQuery = `
    query GetRoomDetail($eventId: ID!, $channelId: ID!) {
      getEvent(id: $eventId) {
        delegations(filter: { id: { eq: $channelId } }) {
          items {
            name
            participations {
              items {
                id
                givenName
                familyName
                email
                hotelRoomReservation {
                  startDate
                  endDate
                  escorts {
                    items {
                      id
                    }
                  }
                  availability {
                    roomsBusy
                    roomByDate {
                      room {
                        name
                        hotelVenture {
                          hotel {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const getParticipantsRoomDetailQuery = `
    query GetRoomDetail($eventId: ID!) {
      getEvent(id: $eventId) {
        participations( filter: { isDeleted: { ne: true }}) { 
          items {
            id
            givenName
            familyName
            email
            hotelRoomReservation {
              startDate
              endDate
              escorts {
                items {
                  id
                }
              }
              availability {
                roomsBusy
                roomByDate {
                  room {
                    name
                    hotelVenture {
                      hotel {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const onClickRowDelete = async () => {
    alert('delete');
    /* await hotelGqlHelper.update({ id: rowId, isDeleted: true }); */
  };

  const model = (loadData) => ({
    header: {
      title: () => `${i18n.tablePage.header.title} ${title}`,
      backButton: {
        onClick: goBack,
      },
      action: {},
    },
    dataLoader: {
      query:
        channelType === constants.Clusters.Pax.id ||
        channelType === constants.Clusters.Pax.idAllotment
          ? getParticipantsRoomDetailQuery
          : channelType === constants.Clusters.Delegations.id ||
            channelType === constants.Clusters.Delegations.idAllotment
          ? getDelegationsRoomDetailQuery
          : null,
      variables: {
        eventId: eventId,
        channelId: channelId,
      },
      transform: (data) => {
        const listParticipation =
          channelType === constants.Clusters.Delegations.id ||
          channelType === constants.Clusters.Delegations.idAllotment
            ? data?.getEvent?.delegations?.items[0]?.participations?.items
            : data?.getEvent?.participations?.items;
        setTitle(
          data?.getEvent?.delegations
            ? ` - ${data?.getEvent?.delegations?.items[0]?.name}`
            : ''
        );

        let participants = [];
        listParticipation?.map((item) => {
          if (item && item.hotelRoomReservation) {
            participants.push({
              id: item?.id,
              givenName: item.givenName,
              familyName: item.familyName,
              email: item?.email,
              hotel:
                item?.hotelRoomReservation.availability.roomByDate.room
                  .hotelVenture.hotel.name,
              room:
                item?.hotelRoomReservation.availability.roomByDate.room?.name,
              checkIn:
                item.hotelRoomReservation?.startDate &&
                format(
                  new Date(item.hotelRoomReservation.startDate),
                  'dd/MM/YYY'
                ),
              checkOut:
                item.hotelRoomReservation?.endDate &&
                format(
                  new Date(item.hotelRoomReservation.endDate),
                  'dd/MM/YYY'
                ),
              escorts: item.hotelRoomReservation?.escorts?.items.length,
            });
          }
        });

        return participants;
      },
    },
    table: {
      columnsTemplate: [
        {
          id: 'givenName',
        },
        {
          id: 'familyName',
        },
        {
          id: 'email',
        },
        {
          id: 'hotel',
        },
        {
          id: 'room',
        },
        {
          id: 'checkIn',
        },
        {
          id: 'checkOut',
        },
        {
          id: 'escorts',
        },
      ],
      rowAction: {
        // delete: {
        //   icon: <ClearIcon />,
        //   variant: 'redFill',
        //   dialog: 'delete',
        //   snackbar: 'delete',
        //   disabledWhen: (row) => !row.hotel,
        // },
      },
      dialog: {
        delete: {
          onAgree: async (row) => {
            await onClickRowDelete(row.id);
            await loadData();
          },
          onDisagree: () => {},
        },
      },
      snackbar: {
        delete: {},
      },
    },
  });

  return (
    <AimTablePage model={model} translation={i18n.tablePage} intl={intl} />
  );
};
