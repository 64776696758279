import React, { useEffect, useState } from 'react';
import { navigateToUrl } from 'single-spa';

import { appState } from '@aim/common';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

export const ContentPageTemplate = ({ children, intl }) => {
  const [containerSize, setContainerSize] = useState(
    appState.mainContainerSize.getValue()
  );
  const [linksHistory, setLinksHistory] = useState([]);

  useEffect(() => {
    const containerSizesubscription = appState.mainContainerSize.subscribe(
      setContainerSize
    );
    const linkHistorySubscription = appState.linksHistory.subscribe(
      setLinksHistory
    );

    return () => {
      containerSizesubscription.unsubscribe();
      linkHistorySubscription.unsubscribe();
    };
  }, []);

  return (
    <div
      style={{
        flex: 1,
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: 10,
        maxHeight: `calc(${containerSize.height}px - 103px)`,
        overflow: 'auto',
      }}
    >
      {linksHistory.length ? (
        <Breadcrumbs aria-label="breadcrumb">
          {linksHistory.map(({ link, label }) => (
            <Link
              key={link}
              style={{ cursor: 'pointer' }}
              color="inherit"
              onClick={() => navigateToUrl(link)}
            >
              {label(intl)}
            </Link>
          ))}
        </Breadcrumbs>
      ) : null}
      {children}
    </div>
  );
};
