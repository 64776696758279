import React from 'react';
import { styled } from './../styled';

const CustomToolBar = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const AimToolBar = React.forwardRef(
  ({ style, children, ...rest }, ref) => (
    <CustomToolBar style={style} {...rest} ref={ref}>
      {children}
    </CustomToolBar>
  )
);
