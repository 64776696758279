import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const scheduledS3ImportFileByEventIdSourceIsImportedQuery = /* GraphQL */ `
  query ScheduledS3ImportFileByEventIdSourceIsImported(
    $scheduledS3ImportFileEventId: ID!
    $source: String
  ) {
    scheduledS3ImportFileByEventIdSourceIsImported(
      scheduledS3ImportFileEventId: $scheduledS3ImportFileEventId
      sourceIsImported: { eq: { isImported: "false", source: $source } }
    ) {
      items {
        id
        data
        searchCategory
        searchKey
      }
    }
  }
`;

const scheduledS3ImportFileBySearchCategorySourceIsImportedQuery = /* GraphQL */ `
  query ScheduledS3ImportFileBySearchCategorySourceIsImported(
    $searchCategory: String!
    $source: String
  ) {
    scheduledS3ImportFileBySearchCategorySourceIsImported(
      searchCategory: $searchCategory
      sourceIsImported: { eq: { isImported: "false", source: $source } }
    ) {
      items {
        id
        data
        searchCategory
        searchKey
      }
    }
  }
`;

const scheduledS3ImportFileBySearchKeySourceIsImportedQuery = /* GraphQL */ `
  query ScheduledS3ImportFileBySearchKeySourceIsImported(
    $searchKey: String!
    $source: String
  ) {
    scheduledS3ImportFileBySearchKeySourceIsImported(
      searchKey: $searchKey
      sourceIsImported: { eq: { isImported: "false", source: $source } }
    ) {
      items {
        id
        data
        searchCategory
        searchKey
      }
    }
  }
`;

export const listEventScheduledS3ImportFile = ({ eventId, source }) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: scheduledS3ImportFileByEventIdSourceIsImportedQuery,
      variables: { scheduledS3ImportFileEventId: eventId, source },
    })
      .then((response) =>
        resolve(
          response.data.scheduledS3ImportFileByEventIdSourceIsImported.items
        )
      )
      .catch((e) => {
        console.error('list-event-scheduled-s3-import-file-error', e);
        reject();
      });
  });

export const listDelegationScheduledS3ImportFile = ({ delegationId, source }) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: scheduledS3ImportFileBySearchCategorySourceIsImportedQuery,
      variables: { searchCategory: delegationId, source },
    })
      .then((response) =>
        resolve(
          response.data.scheduledS3ImportFileBySearchCategorySourceIsImported
            .items
        )
      )
      .catch((e) => {
        console.error('list-delegation-scheduled-s3-import-file-error', e);
        reject();
      });
  });

export const listUsernameScheduledS3ImportFile = ({ participantId, source }) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: scheduledS3ImportFileBySearchKeySourceIsImportedQuery,
      variables: { searchKey: participantId, source },
    })
      .then((response) =>
        resolve(
          response.data.scheduledS3ImportFileBySearchKeySourceIsImported.items
        )
      )
      .catch((e) => {
        console.error('list-username-scheduled-s3-import-file-error', e);
        reject();
      });
  });
