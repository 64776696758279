import React from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import { createFormFields, createDefaultValues } from '../AimDynamicForm';
import { AimIconAndTextButton } from '../../atoms/AimIconAndTextButton';
import { AimTypography } from '../../atoms/AimTypography';
import { constants } from '@aim/common';

export const FieldDefitionRegistrationFieldFlat = (
  fieldDefinitions,
  cluster = constants.Clusters.Pax.id
) => {
  if (!fieldDefinitions) return [];
  // console.log(fieldDefinitions.items.filter((x) => !x.standard));

  const res2 = [];
  fieldDefinitions.forEach((fd) => {
    const fc = fd.contextsOfUse.items.find((fc) => fc.contextName === cluster);
    if (fc) {
      const newF = {
        ...fd,
        label: fd.label,
        isRequired: fc.isRequired,
        position: fc.position,
        // privacyFileName: fr.privacyFileName,
      };

      res2.push(newF);
    }
  });

  return res2.filter((x) => !!x);
};

export const FormViewer = ({
  fields,
  fieldValues = [],
  onSubmit,
  variant = 'white',
  intl,
}) => {
  console.log(
    ' createDefaultValues({ fields, fieldValues })',
    createDefaultValues({ fields, fieldValues })
  );
  const { control, errors, handleSubmit, register, setValue } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: createDefaultValues({ fields, fieldValues }),
  });

  const sendData = async (submittedData) => {
    if (onSubmit) onSubmit(submittedData);
  };

  return (
    <>
      <form onSubmit={handleSubmit(sendData)}>
        <Grid container>
          {fields &&
            createFormFields({
              fields,
              control,
              register,
              setValue,
              errors,
              variant,
              intl,
            })}
        </Grid>
        {onSubmit && (
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {Object.keys(errors).length > 0 && (
              <AimTypography variant="formError">
                There are validation errors, check all fields
              </AimTypography>
            )}
            <AimIconAndTextButton variant="greenFill" text="save" type="submit">
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        )}
      </form>
    </>
  );
};
