import React, { useRef } from 'react';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { useRichTextEditorContext, MenuButton } from 'mui-tiptap';

const MenuButtonUploadFromRepo = (props) => {
  const editor = useRichTextEditorContext();
  const buttonRef = useRef();
  return (
    <MenuButton
      buttonRef={buttonRef}
      tooltipLabel="Upload from repo"
      IconComponent={AllInboxIcon}
      selected={false}
      disabled={!editor?.isEditable}
      onClick={() =>
        // When clicking the button to open the bubble menu, we'll place the
        // menu below the button
        editor?.commands.openUploadFromRepoBubbleMenu({
          anchorEl: buttonRef.current,
          placement: 'bottom',
        })
      }
      {...props}
    />
  );
};

export default MenuButtonUploadFromRepo;
