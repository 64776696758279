import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { appState } from '@aim/common';
import InnerComponent from './InnerComponent';
import { styled } from './../../styled';
import { theme } from './../../../theme';

export const drawerWidth = 400;

// const CustomAppBar = styled('div')(
//   ({ theme }) =>
//     console.log(theme) || {
//       width: theme.spacing(2),
//     }
// );

const DrawerPaperPanel = styled('div')({
  display: 'flex',
  height: '100%',
});

const DrawerPaperContent = styled('div')({
  background: 'white',
  flexGrow: 1,
  width: drawerWidth,
  minHeight: '100%',
  height: 'fit-content',
  display: 'flex',
  borderRight: `12px solid ${theme.colors.primary.yellow}`,
});

const CustomButton = styled('div')({
  display: 'none', //COMMENT THIS TO RESTORE TOGGLE BUTTON
  height: 28,
  borderTop: '10px solid transparent',
  borderLeft: '22px solid ' + theme.colors.primary.yellow,
  position: 'relative',
  left: 0,
  top: 20,
  borderBottom: '10px solid transparent',
  cursor: 'pointer',
  zIndex: 2100,
});

const MyDrawer = ({ title, children, showOverflow }) => {
  const { isSidebarOpen } = appState;
  const [open, setOpen] = useState();

  const useStyles = makeStyles({
    drawerPaper: {
      ...(showOverflow && {
        overflowY: 'visible !important',
      }),
      background: 'transparent !important',
      width: drawerWidth + 12,
      borderRight: 0,
      display: 'flex',
      marginLeft: 120,
    },
  });

  const classes = useStyles(open);

  useEffect(() => {
    const subscription = isSidebarOpen.subscribe(setOpen);
    return () => subscription.unsubscribe();
  }, []);

  const handleDrawerClose = () => isSidebarOpen.next(false);

  return (
    <Drawer
      styled={{ width: drawerWidth + 12, flexShrink: 0 }}
      variant="persistent"
      transitionDuration={0}
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <DrawerPaperPanel>
        <DrawerPaperContent>
          <InnerComponent title={title}>{children}</InnerComponent>
        </DrawerPaperContent>
        <div>
          <CustomButton style={{ zIndex: 2100 }} onClick={handleDrawerClose}>
            <PlayArrowIcon className={classes.closeArrow} />
          </CustomButton>
        </div>
      </DrawerPaperPanel>
    </Drawer>
  );
};

export const AimDrawerLeft = ({ title, children, showOverflow }) =>
  !isMobile ? (
    <MyDrawer title={title} showOverflow={showOverflow}>
      {children}
    </MyDrawer>
  ) : null;
