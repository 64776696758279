export default function (intl) {
  return {
    page: {
      footer: {
        firstContainer: intl.formatMessage({
          description: 'description section',
          defaultMessage: 'description',
        }),
        secondContainer: intl.formatMessage({
          description: 'tags & link section',
          defaultMessage: 'tags & link',
        }),
      },
      labels: {
        link: intl.formatMessage({
          description: 'webcast link',
          defaultMessage: 'Link:',
        }),
        topic: intl.formatMessage({
          description: 'webcast topic',
          defaultMessage: 'Topic:',
        }),
        speakers: intl.formatMessage({
          description: 'webcast speakers',
          defaultMessage: 'Speakers:',
        }),
        tags: intl.formatMessage({
          description: 'webcast tags',
          defaultMessage: 'Tag:',
        }),
      },
    },
    toolbar: {
      buttons: {
        sendEmail: {
          tooltip: intl.formatMessage({
            description: 'send email',
            defaultMessage: 'Send Email',
          }),
        },
        exportExcel: {
          tooltip: intl.formatMessage({
            description: 'export excel',
            defaultMessage: 'Export Excel',
          }),
        },
        import: {
          tooltip: intl.formatMessage({
            description: 'import',
            defaultMessage: 'Import',
          }),
        },
        filter: {
          tooltip: intl.formatMessage({
            description: 'filter',
            defaultMessage: 'Filter',
          }),
        },
        add: {
          tooltip: intl.formatMessage({
            description: 'add new webcast',
            defaultMessage: 'Add new webcast',
          }),
        },
      },
    },
    card: {
      views: intl.formatMessage({
        description: 'views label',
        defaultMessage: 'Views',
      }),
      uploadDate: intl.formatMessage({
        description: 'upload date label',
        defaultMessage: 'Upload date',
      }),
      topic: intl.formatMessage({
        description: 'topic label',
        defaultMessage: 'Topic',
      }),
      speakers: intl.formatMessage({
        description: 'speakers label',
        defaultMessage: 'Speakers',
      }),
    },
    dialog: {
      title: intl.formatMessage({
        description: 'delete webcast title',
        defaultMessage: 'Delete webcast',
      }),
      message: intl.formatMessage({
        description: 'are you sure delete webcast message',
        defaultMessage: 'Are you sure?',
      }),
    },
  };
}
