import * as React from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
  useGridSelector,
  gridFilterModelSelector,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-pro';

const GridToolbarSaveFilters = React.forwardRef(
  function GridToolbarSaveFilterAndVisibleColumns(props, ref) {
    const { componentsProps = {}, tableName, ...other } = props;
    const LOCAL_STORAGE_NAME = `aim-mui-datagrid-${tableName}-filter-model`;
    const buttonProps = componentsProps.button || {};
    const [savedFilter, setSavedFilter] = React.useState(
      localStorage.getItem(LOCAL_STORAGE_NAME)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME))
        : undefined
    );
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

    // Disable the button if the corresponding is disabled
    if (rootProps.disableColumnFilter) {
      return null;
    }

    return (
      <rootProps.components.BaseTooltip
        title={savedFilter ? 'Remove Filter' : 'Save Filter'}
        enterDelay={1000}
        {...other}
        {...rootProps.componentsProps?.baseTooltip}
      >
        <rootProps.components.BaseButton
          ref={ref}
          size="small"
          aria-label="Save Filters"
          {...buttonProps}
          onClick={() => {
            if (!savedFilter) {
              localStorage.setItem(
                LOCAL_STORAGE_NAME,
                JSON.stringify(filterModel)
              );
              setSavedFilter(filterModel);
            } else {
              setSavedFilter();
              localStorage.removeItem(LOCAL_STORAGE_NAME);
              apiRef.current.setFilterModel({ items: [] });
            }
          }}
          {...rootProps.componentsProps?.baseButton}
          startIcon={savedFilter ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        >
          {savedFilter ? 'Remove Filter' : 'Save Filter'}
        </rootProps.components.BaseButton>
      </rootProps.components.BaseTooltip>
    );
  }
);

export { GridToolbarSaveFilters };
