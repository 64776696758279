import React from 'react';

import { styled } from './../../styled';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { appState } from '@aim/common';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const Header = styled('div')({
  display: 'flex',
  height: 91,
  width: '100%',
  borderTop: '1px solid #84819A47',
  borderBottom: '1px solid #84819A47',
});

const HeaderContent = styled('div')({
  margin: '0px 20px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const CustomIconButton = styled(IconButton)({
  border: '0 !important',
});

const BodyContainer = styled('div')({ margin: '0px 20px', flexGrow: 1 });

const InnerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

export default ({ title, children }) => (
  <Container>
    <Header>
      <HeaderContent>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
          }}
        >
          {title}
        </div>
        <CustomIconButton
          aria-label="close"
          size="small"
          onClick={() => appState.isSidebarOpen.next(false)}
        >
          <CloseIcon />
        </CustomIconButton>
      </HeaderContent>
    </Header>
    <BodyContainer>
      <InnerContainer>{children}</InnerContainer>
    </BodyContainer>
  </Container>
);
