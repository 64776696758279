import React, { useEffect, useState } from 'react';
import { navigateToUrl } from 'single-spa';

import Menu from '@material-ui/core/Menu';
import ProfileMenuItem from '@material-ui/core/MenuItem';

import { AimTypography } from '../atoms/AimTypography';
import { styled } from '../styled';
import { aws, appState } from '@aim/common';

export const Container = styled('div')(({ backgroundColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  alignItems: 'center',
  backgroundColor: backgroundColor,
}));

export const SubHeader = styled('div')({
  height: '66px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFD400',
});

export const Header = styled('div')({
  height: '98px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0 5px 5px -3px black',
  zIndex: 1,
  background: '#ffffff',
});

export const MenuItem = styled('div')({
  marginLeft: '20px',
  marginRight: '20px',
});
export const StyledProfileMenu = styled(Menu)({
  alignItems: 'flex-end',
  maxWidth: 180,
  '&.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
    background: 'red !important',
  },
});

export const ContentContainer = styled('div')({
  overflowY: 'auto',
  height: window.height <= 700 ? 'calc(100vh - 184px)' : '100%',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
});

export const ContentPageFrontendTemplate = ({
  children,
  eventId,
  greyishBg = false,
}) => {
  const [participation, setParticipation] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const loadMyAreaUrl = async () => {
    try {
      const user = appState.user.getValue();
      setParticipation(user?.userAndParticipation);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadMyAreaUrl();
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyArea = async () => {
    navigateToUrl(`/events/${eventId}/user/${participation.id}`);
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await aws.signOut();
    appState.user.next(null);
    navigateToUrl(`/events/${eventId}/login`);
  };

  const renderMenu = (
    <StyledProfileMenu
      disableAutoFocusItem
      anchorEl={anchorEl}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      id="user-menu-id"
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <ProfileMenuItem onClick={handleMyArea}>
        <AimTypography>My Area</AimTypography>
      </ProfileMenuItem>
      <ProfileMenuItem onClick={handleLogout}>
        <AimTypography>Logout</AimTypography>
      </ProfileMenuItem>
    </StyledProfileMenu>
  );

  return (
    <>
      <Container backgroundColor={greyishBg ? '#F8F6FA' : 'white'}>
        <ContentContainer>{children}</ContentContainer>
      </Container>
      {renderMenu}
    </>
  );
};
