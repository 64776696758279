import React, { useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import filesize from 'filesize';

import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';

import { styled } from './../styled';
import { theme } from './../../theme';
import { AimTypography, AimIconButton } from './../atoms';
import { utilities, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

//ICONS
import PdfIcon from './../../assets/images/pdfIcon.png';
import WordIcon from './../../assets/images/wordIcon.png';
import ExcelIcon from './../../assets/images/excel.png';

const DragAndDropContainer = styled('div')(({ variant }) => ({
  display: 'flex',
  alignItems: 'center',
  background:
    variant === 'white' ? 'white' : theme.colors.greyScale.backgroundGrey,
  borderRadius: 4,
  height: 48,
}));

const CustomFormLabel = styled(FormLabel)({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
});

export const AimFileSelector = ({
  selectFileLabel,
  onLoad,
  onUploadFile,
  onRemove,
  onDownload = null,
  file = null,
  dragDropStyleOverride = {},
  dropzoneAdditionalProps = {},
  formControlStyle = {},
  labelStyle = {},
  label = '',
  readOnly = false,
  skipImageAndSize = false,
  variant = 'grey',
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    disabled: readOnly || file,
    ...dropzoneAdditionalProps,
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      onUploadFile && onUploadFile(utilities.parseFile(file));
      hideLoader();
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections.length) {
      hideLoader();
    }
  }, [fileRejections]);

  useEffect(() => {
    onLoad && onLoad();
  }, []);

  const pdfTypes = ['.pdf'];
  const wordTypes = ['.doc', '.docx'];
  const excellTypes = ['.xls', '.xlsx'];
  const getImage = (ext) => {
    const style = { marginLeft: 16, verticalAlign: 'middle' };
    let icon;
    if (pdfTypes.includes(ext)) icon = PdfIcon;
    if (wordTypes.includes(ext)) icon = WordIcon;
    if (excellTypes.includes(ext)) icon = ExcelIcon;
    if (icon)
      return (
        <img
          src={icon}
          style={{
            width: 25,
            height: 25,
            marginRight: 10,
            ...style,
          }}
          alt="fileThumb"
        />
      );
    return <ImageIcon style={style} />;
  };

  return (
    <div style={{ padding: 8, ...formControlStyle }}>
      {label ? (
        <CustomFormLabel style={labelStyle}>{label}</CustomFormLabel>
      ) : null}
      {!file ? (
        <DragAndDropContainer variant={variant}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
            {...getRootProps({ className: 'dropzone' })}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  minHeight: 48,
                  alignItems: 'center',
                  justifyContent: 'center',
                  ...dragDropStyleOverride,
                }}
              >
                <div
                  className="container"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    alignItems: 'flex-end',
                    paddingRight: 10,
                  }}
                >
                  <input {...getInputProps()} />
                  <AimTypography
                    variant="h6"
                    margin="0"
                    style={{
                      color: theme.colors.secondary.lightBlue,
                      cursor: readOnly ? 'not-allowed' : 'pointer',
                    }}
                  >
                    {selectFileLabel}
                  </AimTypography>
                </div>
              </div>
            </div>
          </div>
        </DragAndDropContainer>
      ) : (
        <Grid
          container
          alignItems="center"
          style={{
            padding: '5px 0',
            borderRadius: 4,
            backgroundColor:
              variant === 'white'
                ? 'white'
                : theme.colors.greyScale.backgroundGrey,
          }}
        >
          <Grid item xs={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!skipImageAndSize && getImage(file.extension)}
              <AimTypography
                variant={'h4regular'}
                ellipsis
                style={{ width: '100%' }}
              >
                {file.originalName ? file.originalName : file.name}
              </AimTypography>
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right', paddingRight: 8 }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!skipImageAndSize && file.size ? (
                <AimTypography
                  variant={'h4regular'}
                  textAlign="right"
                  style={{ display: 'inline-block' }}
                >
                  {filesize(file.size)}
                </AimTypography>
              ) : null}
              {onDownload && file?.id ? (
                <AimIconButton
                  variant="lightBlueFill"
                  small="true"
                  onClick={() => onDownload(file)}
                >
                  <GetAppIcon />
                </AimIconButton>
              ) : null}
              {!readOnly ? (
                <AimIconButton
                  variant="redFill"
                  small="true"
                  onClick={() => onRemove(file)}
                >
                  <CloseIcon />
                </AimIconButton>
              ) : null}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
