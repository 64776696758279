import React, { useRef, useState, useEffect } from 'react';
import { debounce } from 'lodash';

import { geoDbHelper } from '@aim/common';
import { AimAutocompleteForm } from '@aim/components';
import Box from '@mui/material/Box';
const { getGeoDbCitiesByInput } = geoDbHelper;

export const CitiesAutocompleteByGeoDb = ({
  control,
  register,
  setValue,
  textfieldVariant,
  errors,
  name,
  label,
  formControlStyle,
  clearErrors,
  isRequired,
  addAsterisk,
  defaultValue,
  disabled,
  ...rest
}) => {
  const [citiesOptions, setCitiesOptions] = useState([]);

  const debouncedInputCities = useRef(
    debounce(async (value) => {
      const descriptions = await getGeoDbCitiesByInput(value);
      console.log('cities descr ', descriptions);
      setCitiesOptions(descriptions);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedInputCities.cancel();
    };
  }, [debouncedInputCities]);

  return (
    <AimAutocompleteForm
      disabled={disabled}
      defaultValue={defaultValue}
      control={control}
      register={register}
      setValue={setValue}
      textfieldVariant={textfieldVariant}
      errors={errors}
      options={citiesOptions}
      getOptionLabel={(option) => option?.label || ''}
      getOptionSelected={(opt, value) => opt.value === value}
      name={name}
      label={label}
      formControlStyle={formControlStyle}
      clearErrors={clearErrors}
      onInputChangeFn={async (value) => {
        debouncedInputCities(value);
      }}
      renderOption={(option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.country})
        </Box>
      )}
      isRequired={isRequired}
      filterOptions={(options) => options}
      limit={100}
      {...rest}
    />
  );
};

export const CitiesAutocompleteByGeoDbDynamic = ({
  control,
  register,
  setValue,
  textfieldVariant,
  errors,
  name,
  label,
  formControlStyle,
  clearErrors,
  isRequired,
  addAsterisk,
  defaultValue,
  disabled,
}) => {
  return (
    <CitiesAutocompleteByGeoDb
      disabled={disabled}
      defaultValue={defaultValue}
      control={control}
      register={register}
      setValue={setValue}
      textfieldVariant={textfieldVariant}
      errors={errors}
      getOptionLabel={(option) => option?.label || ''}
      getOptionSelected={(opt, value) => opt.value === value}
      name={name}
      label={label}
      formControlStyle={formControlStyle}
      clearErrors={clearErrors}
      isRequired={isRequired}
      filterOptions={(options) => options}
    />
  );
};
