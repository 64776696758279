import React from "react";
import { MegadraftIcons, insertDataBlock } from "megadraft";

export default function BlockButton(props) {
  const { onChange, editorState, className, title } = props;

  const onClick = (event) => {
    event.preventDefault();

    const data = { src: null, type: "image-embed", display: "medium" };

    onChange(insertDataBlock(editorState, data));
  };

  return (
    <button className={className} type="button" onClick={onClick} title={title}>
      <MegadraftIcons.ImageIcon className="sidemenu__button__icon" />
    </button>
  );
}
