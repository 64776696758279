import React from 'react';

import { AimImage, AimTypography } from './../atoms';

import { styled } from './../styled';
import { theme } from './../../theme';

const TextOverlay = styled('div')({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  left: 0,
  color: theme.colors.greyScale.white,
  padding: '1.5rem',
});

export const AimImageAndTextCard = ({
  title,
  // subTitle,
  image,
  titleVariant = 'h4',
  // subTitleVariant = 'h5Regular',
  size = 290,
  brightnessPercentage = 100,
  isMobile,
  style,
  titleOver = false,
  ...rest
}) => {
  const { innerWidth: pageWidth } = window;
  const imageWidth = pageWidth - 50;

  return (
    <div
      style={{
        width: isMobile ? imageWidth : size,
        height: isMobile ? imageWidth : size,
        position: 'relative',
        margin: 7.5,
        ...style,
      }}
      {...{ ...rest }}
    >
      {image && (
        <AimImage
          src={image}
          alt={title}
          style={{
            width: isMobile ? imageWidth : size,
            height: isMobile ? imageWidth : size,
            objectFit: 'cover',

            filter: `brightness(${brightnessPercentage.toString()}%)`,
          }}
        />
      )}
      {titleOver ? (
        <TextOverlay>
          <AimTypography margin={0} variant={titleVariant}>
            {title}
          </AimTypography>
        </TextOverlay>
      ) : (
        <AimTypography variant={titleVariant} margin={0}>
          {title}
        </AimTypography>
      )}
    </div>
  );
};
