import React, { useState, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Controller } from 'react-hook-form';
import {
  AimIconButton,
  AimTextFieldForm,
  AimRadioButton,
  AimFormControlLabel,
  AimRadioForm,
  AimTypography,
  AimAutocomplete,
} from '@aim/components';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { getParticipation, listParticipations } from './AssignTravelGqlHelper';

const FieldArray = ({
  participantId,
  isMobileOnly,
  control,
  setValue,
  register,
  errors,
  clearErrors,
  guests,
  watch,
  getValues,
  eventId,
  i18n,
}) => {
  const formControlStyle = { width: 'calc(100% - 5px)' };
  const [participation, setParticipation] = useState();
  const [participations, setParticipations] = useState([]);

  const { fields, remove, append } = useFieldArray({
    control,
    name: `guests`,
    keyName: 'fieldId',
  });

  useEffect(() => {
    const fetchParticipation = async () => {
      const respPax = await getParticipation(participantId);
      setParticipation(respPax);
    };
    fetchParticipation();
  }, []);

  useEffect(() => {
    const fetchParticipations = async () => {
      const participations = await listParticipations(eventId);
      setParticipations(
        participations.map((p) => ({
          value: p.id,
          participationData: p,
          label: `${p.givenName} ${p.familyName} <${p.email}>`,
        }))
      );
    };
    fetchParticipations();
  }, []);

  const checkRadio = (e, index) => {
    if (e.target.value === '1') {
      setValue(`guests[${index}].givenName`, participation.givenName);
      setValue(`guests[${index}].familyName`, participation.familyName);
      setValue(`guests[${index}].email`, participation.email);
      setValue(`guests[${index}].phone`, participation.phone || '');
      setValue(`guests[${index}].participationId`, participation.id);
    } else {
      setValue(`guests[${index}].givenName`, '');
      setValue(`guests[${index}].familyName`, '');
      setValue(`guests[${index}].email`, '');
      setValue(`guests[${index}].phone`, '');
    }
  };

  const onSelect = (index, participationData) => {
    setValue(`guests[${index}].givenName`, participationData.givenName);
    setValue(`guests[${index}].familyName`, participationData.familyName);
    setValue(`guests[${index}].email`, participationData.email);
    setValue(`guests[${index}].phone`, participationData.phone || '');
    setValue(`guests[${index}].participationId`, participationData.id);
  };

  return (
    <>
      <AimTypography variant="text">{'GUESTS'}</AimTypography>

      {fields.map((field, k) => {
        const radioChoice = watch(`guests[${k}].radioChoice`);

        return (
          <Grid
            item
            xs={12}
            key={field.fieldId}
            style={{
              backgroundColor: '#F8F6FA',
              padding: '10px 10px 10px 10px',
              borderRadius: 4,
              marginTop: 8,
              marginBottom: 8,
              height: 200,
            }}
          >
            <Grid
              container
              // style={{
              //   display: k > 0 ? 'none' : 'block',
              // }}
            >
              <AimRadioForm
                control={control}
                radioVariant="white"
                name={`guests[${k}].radioChoice`}
                labelPlacement="right"
                formControlWidth="calc(100% - 10px)"
                defaultValue={field.radioChoice}
                onClick={(e) => checkRadio(e, k)}
              >
                <AimFormControlLabel
                  key={1}
                  value="1"
                  control={<AimRadioButton />}
                  label={i18n.guests.guestIsSelectedParticipant}
                />
                <AimFormControlLabel
                  key={2}
                  value="2"
                  control={<AimRadioButton />}
                  label={i18n.guests.guestIsNotRegistered}
                />
                <AimFormControlLabel
                  key={3}
                  value="3"
                  control={<AimRadioButton />}
                  label={i18n.guests.guestIsRegisteredParticipant}
                />
              </AimRadioForm>
            </Grid>
            {radioChoice === '3' && (
              <Grid>
                <Controller
                  control={control}
                  name={`guests[${k}].autocompleteParticipationSelected`}
                  defaultValue={field.autocompleteParticipationSelected}
                  render={(props) => (
                    <AimAutocomplete
                      textfieldVariant="white"
                      placeholder={
                        i18n.guests.writeNameSurnameOrEmailPlaceholder
                      }
                      value={props.value || ''}
                      options={participations}
                      filterOptions={(options) => options}
                      getOptionLabel={(option) => option?.label || ''}
                      getOptionSelected={(opt, value) =>
                        opt.value === value?.value
                      }
                      onChange={(_, value) =>
                        onSelect(k, value.participationData)
                      }
                      formControlStyle={{ width: 'calc(100% - 16px)' }}
                      // autoSelect={false}
                    ></AimAutocomplete>
                  )}
                />
              </Grid>
            )}
            <Grid container>
              <Grid item xs={!isMobileOnly ? 3 : 12}>
                <AimTextFieldForm
                  name={`guests[${k}].givenName`}
                  control={control}
                  defaultValue={field.givenName}
                  formControlStyle={formControlStyle}
                  label="Name"
                  textfieldVariant={`white`}
                  disabled={k == 0 && radioChoice == 1}
                />
              </Grid>
              <Grid item xs={!isMobileOnly ? 3 : 12}>
                <AimTextFieldForm
                  name={`guests[${k}].familyName`}
                  defaultValue={field.familyName}
                  control={control}
                  formControlStyle={formControlStyle}
                  label="Surname"
                  textfieldVariant={`white`}
                  disabled={k == 0 && radioChoice == 1}
                />
              </Grid>
              <Grid item xs={!isMobileOnly ? 3 : 12}>
                <AimTextFieldForm
                  name={`guests[${k}].email`}
                  defaultValue={field.email}
                  control={control}
                  formControlStyle={formControlStyle}
                  label="Email"
                  textfieldVariant={`white`}
                  disabled={k == 0 && radioChoice == 1}
                />
              </Grid>
              <Grid item xs={!isMobileOnly ? 2 : 12}>
                <AimTextFieldForm
                  name={`guests[${k}].phone`}
                  defaultValue={field.phone}
                  control={control}
                  formControlStyle={formControlStyle}
                  label="Phone"
                  textfieldVariant={`white`}
                  disabled={k == 0 && radioChoice == 1}
                />
              </Grid>
              <Grid
                xs={!isMobileOnly ? 1 : 12}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <AimIconButton
                  variant="redFill"
                  style={{
                    marginBottom: '8px !important',
                    border: 0,
                    color: '#FE0C4E',
                  }}
                  onClick={() => {
                    if (k === 0) {
                      setValue(`guests[${k}].radioChoice`, '2');
                    }
                    remove(k);
                  }}
                >
                  <ClearIcon />
                </AimIconButton>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid container>
        <AimIconButton variant="yellowFill" onClick={() => append({})}>
          <AddIcon />
        </AimIconButton>
      </Grid>
    </>
  );
};

export default FieldArray;
