import React from 'react';

import Grid from '@material-ui/core/Grid';
import { theme } from '../../theme';
import { AimTypography } from '@aim/components';

export const AimStats = ({
  data,
  textColor = theme.colors.primary.black,
  backColor = theme.colors.greyScale.grey2,
  isUpperCase,
  containerStyle,
  gridProps,
}) => {
  const innerGridProps = { xs: 12, sm: 6, ...gridProps };
  return (
    (data && data.length > 0 && (
      <div
        style={
          containerStyle || {
            backgroundColor: backColor,
            padding: '20px 20px 30px',
            margin: '10px 0px',
            borderRadius: 3,
          }
        }
      >
        <Grid container spacing={2}>
          {data.map((item, index) => {
            return (
              <Grid
                item
                {...innerGridProps}
                key={index}
                style={{ ...(innerGridProps.style || {}), padding: 0 }}
              >
                <div
                  style={{
                    // marginRight: index % 2 === 0 ? '2px' : 0,
                    // marginBottom: index % 2 === 0 ? '2px' : 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid white',
                    margin: '5px 10px',
                  }}
                >
                  <AimTypography
                    variant="h6"
                    margin={0}
                    style={{
                      textTransform: isUpperCase ? 'uppercase' : 'none',
                    }}
                  >
                    {item.title}
                  </AimTypography>
                  <AimTypography
                    variant="text"
                    margin={0}
                    style={{
                      color: textColor,
                    }}
                  >
                    {item.value}
                  </AimTypography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    )) ||
    null
  );
};
