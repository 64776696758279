import React from 'react';

import { styled } from '../styled';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { appState, constants } from '@aim/common';

const CustomFormControlLabel = styled(FormControlLabel)(
  ({ variantTemplate, style }) => ({
    fontFamily:
      variantTemplate === constants.Templates.FRONTEND ? 'hind' : 'Roboto',
    ...style,
  })
);

const variantTemplate = appState.template.getValue();

export const AimFormControlLabel = ({ children, ...rest }) => (
  <CustomFormControlLabel variantTemplate={variantTemplate} {...rest}>
    {children}
  </CustomFormControlLabel>
);
