export default {
  manualMailDialog: (intl) => ({
    sendMailTemplateDialog: {
      title: intl.formatMessage({
        description: 'send particant invitation mail dialog title',
        defaultMessage: 'Send Mail',
      }),
    },
    sendMailPreviewDialog: {
      title: intl.formatMessage({
        description: 'preview mail to send title',
        defaultMessage: 'Preview mail to send',
      }),
    },
    sendMailConfirmDialog: {
      confirmDialogTitle: intl.formatMessage({
        description: 'send mail confirm dialog title',
        defaultMessage: 'Send mail confirmation',
      }),
      confirmDialogMessage: intl.formatMessage({
        description: 'send mail confirm dialog message',
        defaultMessage: 'Are you sure you want to continue?',
      }),
    },
  }),
};
