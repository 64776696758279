import { Extension } from '@tiptap/core';

export const LineSpacing = Extension.create({
  name: 'lineSpacing',

  addOptions() {
    return {
      types: [],
      lineSpacings: ['1', '1.15', '1.5', '2'],
      defaultSpacing: '1',
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineSpacing: {
            default: this.options.defaultSpacing,
            parseHTML: (element) =>
              element.style.lineSpacing || this.options.defaultSpacing,
            renderHTML: (attributes) => {
              if (attributes.lineSpacing === this.options.defaultSpacing) {
                return {};
              }

              return { style: `line-height: ${attributes.lineSpacing}` };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLineSpacing: (spacing) => ({ commands }) => {
        if (!this.options.lineSpacings.includes(spacing)) {
          return false;
        }

        return this.options.types.every((type) =>
          commands.updateAttributes(type, { lineSpacing: spacing })
        );
      },

      unsetLineSpacing: () => ({ commands }) => {
        return this.options.types.every((type) =>
          commands.resetAttributes(type, 'lineSpacing')
        );
      },
    };
  },

  //   addKeyboardShortcuts() {
  //     return {
  //       'Mod-Shift-l': () => this.editor.commands.setTextAlign('left'),
  //       'Mod-Shift-e': () => this.editor.commands.setTextAlign('center'),
  //       'Mod-Shift-r': () => this.editor.commands.setTextAlign('right'),
  //       'Mod-Shift-j': () => this.editor.commands.setTextAlign('justify'),
  //     };
  //   },
});
