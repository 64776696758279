import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { styled } from './../styled';
import { theme } from './../../theme';

import { appState, constants } from '@aim/common';

const StyledSwitch = styled(Switch)(({ variant }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 2,
  },
  '& .Mui-checked': {
    transform: 'translateX(12px)',
  },
  '& .MuiSwitch-thumb': {
    color: theme.colors.greyScale.white,
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  ...variant,
}));

const CustomFormControlLabel = styled(FormControlLabel)(
  ({ labelPlacement }) => ({
    marginBottom: 5,
    paddingBottom: 0,
    color: 'black',
    fontWeight: 'bold',
    fontSize: 14,
    '& .MuiFormControlLabel-label': {
      paddingRight: labelPlacement === 'start' ? 10 : 0,
      paddingLeft: labelPlacement === 'end' ? 10 : 0,
    },
  })
);

const variantsBACKEND = {
  yellow: {
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${theme.colors.primary.yellow}`,
      opacity: 1,
    },
    '& .Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      backgroundColor: theme.colors.greyScale.grey3,
      opacity: 1,
    },
  },
};

const buildStyle = (color, backgroundColor, circleColor) => ({
  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: color,
    opacity: 1,
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    color: `white !important`,
    opacity: 1,
  },
  '& .MuiSwitch-thumb': {
    color: circleColor,
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    backgroundColor: backgroundColor,
    opacity: 1,
  },
});

const variantsFRONTEND = ({ eventConfiguration }) => ({
  primary: buildStyle(
    eventConfiguration.primaryColor,
    theme.colors.greyScale.backgroundGrey,
    'white'
  ),
  secondary: buildStyle(
    eventConfiguration.primaryColor,
    'white',
    theme.colors.greyScale.grey1
  ),
});

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const variantTemplate = appState.template.getValue();

// TODO delete when restyle is completed
const variantMap = {
  yellow: 'primary',
};

const getVariant = ({ eventConfiguration, variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[variantTemplate]({ eventConfiguration });
    return template[variant] || template[variantMap[variant]];
  }
};

export const AimSwitch = ({
  label,
  labelPlacementLeft,
  labelPlacement,
  variant = variantTemplate === constants.Templates.BACKEND
    ? 'yellow'
    : 'primary',
  ...rest
}) => {
  const [eventConfiguration, setEventConfiguration] = useState({});
  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  if (!label) {
    return (
      <StyledSwitch
        disableRipple
        {...rest}
        variant={getVariant({ variant, eventConfiguration })}
      />
    );
  }

  return (
    <CustomFormControlLabel
      control={
        <StyledSwitch
          disableRipple
          {...rest}
          variant={getVariant({ variant, eventConfiguration })}
        />
      }
      label={label}
      labelPlacement={labelPlacement || (labelPlacementLeft ? 'start' : 'end')}
    />
  );
};

export const AimSwitchForm = ({ control, name, label, onChange, ...rest }) => (
  <Controller
    control={control}
    name={name}
    render={(props) => (
      <div style={{ padding: 8 }}>
        <AimSwitch
          label={label}
          checked={props.value}
          onChange={(e) =>
            onChange
              ? props.onChange(onChange(e.target.checked))
              : props.onChange(e.target.checked)
          }
          {...rest}
        />
      </div>
    )}
  />
);

// export const AimSwitchForm = ({ control, name, defaultValue }) => (
//   <Controller
//     control={control}
//     name={name}
//     defaultValue={defaultValue}
//     render={(props) => (
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'start',
//           alignItems: 'center',
//           ...valueLabelStyle,
//         }}
//       >
//         <AimSwitch
//           onChange={(e) => {
//             if (customOnChange) customOnChange(e.target.checked);
//             props.onChange(e.target.checked);
//           }}
//           name={name || key}
//           checked={props.value || defaultValue}
//         />
//         <AimTypography variant="text">
//           {vlabel[props.value ? 1 : 0]}
//         </AimTypography>
//       </div>
//     )}
//     rules={{
//       required: { isRequired },
//     }}
//   />
// );
