import React, { useState, useEffect } from 'react';
import { appState } from '@aim/common';

export const CustomIntl = (intl) => {
  const [isTranslation, setIsTranslation] = useState();

  useEffect(() => {
    const subId = appState.isI18n.subscribe(setIsTranslation);
    return () => subId.unsubscribe();
  });

  const listener = function (event) {
    if (event.ctrlKey && event.altKey && event.key === '1') {
      appState.isI18n.next(!isTranslation);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', listener);

    return () => document.removeEventListener('keyup', listener);
  });

  const wIntl = (data) => {
    const output = intl.formatMessage(data);
    if (!isTranslation) return output;

    return (
      <span data-i18n={data.id} style={{ border: '2px solid red' }}>
        {output + ' <'}
        <span style={{ color: 'red' }}>{data.id}</span>
        {'>'}
      </span>
    );
  };

  return {
    formatMessage: wIntl,
  };
};
