import { constants } from '@aim/common';
import React, { useState, useEffect } from 'react';
import { getEmailTemplates } from './gqlHelper';
import { ManualMailConfirmDialog } from './ManualMailConfirmDialog';
import { ManualMailPreviewDialog } from './ManualMailPreviewDialog';
import { ManualMailTemplateDialog } from './ManualMailTemplateDialog';
import translations from './translations';

export const ManualMailDialog = ({
  dialog,
  setDialog,
  intl,
  eventId,
  templateType = constants.EmailTemplateTypes.PARTICIPATION.key,
}) => {
  const i18n = translations.manualMailDialog(intl);
  const [mailTemplates, setMailTemplates] = useState([]);

  useEffect(() => {
    getEmailTemplates({ eventId, templateType }).then(setMailTemplates);
  }, []);

  return (
    <>
      <ManualMailTemplateDialog
        templateType={templateType}
        dialog={dialog}
        setDialog={setDialog}
        mailTemplates={mailTemplates}
        i18n={i18n}
        intl={intl}
        eventId={eventId}
        openPreviewDialog={(resp) => {
          setDialog({
            isOpen: true,
            active: 'preview',
            body: resp.body,
            template: resp.template,
          });
        }}
      />
      <ManualMailPreviewDialog
        dialog={dialog}
        setDialog={setDialog}
        i18n={i18n}
        intl={intl}
        eventId={eventId}
        openConfirmDialog={(resp) => {
          setDialog({
            isOpen: true,
            active: 'confirm',
            title: i18n.sendMailConfirmDialog.confirmDialogTitle,
            message: i18n.sendMailConfirmDialog.confirmDialogMessage,
            body: resp.body,
          });
        }}
      />
      <ManualMailConfirmDialog
        dialog={dialog}
        setDialog={setDialog}
        eventId={eventId}
        intl={intl}
      />
    </>
  );
};
