import React, { useState, useRef } from 'react';

import PDFViewer from 'mgr-pdf-viewer-react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { AimIconAndTextButton } from '../atoms/AimIconAndTextButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import './AimUrlPdfViewer/PDFViewer.css';
import { styled } from '../styled';

const CustomIconButton = styled(IconButton)({
  '&:hover': {
    background: 'transparent !important',
  },
});

export const AimUrlPdfViewer = ({ url, showDownload = true }) => {
  const [page, setPage] = useState(1);
  const handleNextButtonRef = useRef(null);
  const handlePrevButtonRef = useRef(null);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
      {showDownload && (
        <AimIconAndTextButton
          text="Download"
          style={{ width: 'fit-content', alignSelf: 'center' }}
          onClick={() => window.open(url, '_blank')}
        >
          <GetAppIcon />
        </AimIconAndTextButton>
      )}
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CustomIconButton
          style={{
            marginRight: 100,
          }}
          disableRipple
          disableFocusRipple
          onClick={() => handlePrevButtonRef.current()}
        >
          <ArrowBackIosIcon />
        </CustomIconButton>
        <div style={{ boxShadow: '0 25px 50px 0 rgb(62 62 62 / 15%)' }}>
          <PDFViewer
            document={{
              url,
            }}
            page={page}
            css="customViewer"
            navigation={{
              css: {
                wrapper: 'customWrapper',
              },
              elements: {
                previousPageBtn: ({ handlePrevClick }) => {
                  handlePrevButtonRef.current = handlePrevClick;
                  return <></>;
                },
                nextPageBtn: ({ handleNextClick }) => {
                  handleNextButtonRef.current = handleNextClick;
                  return <></>;
                },
                pages: () => <></>,
              },
            }}
          />
        </div>
        <CustomIconButton
          style={{ marginLeft: 100 }}
          disableRipple
          disableFocusRipple
          onClick={() => handleNextButtonRef.current()}
        >
          <ArrowForwardIosIcon />
        </CustomIconButton>
      </div>
    </div>
  );
};
