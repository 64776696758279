import React, { useEffect, useState, useRef } from 'react';
import { PromisePool } from '@supercharge/promise-pool';

import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';

import translation from './TravelTransferPage/translation';
import { styled } from '../styled';

import { AimIconAndTextButton } from '../atoms';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';

import Tooltip from '@material-ui/core/Tooltip';

import {
  createTransfer,
  getTravelInfo,
  updateParticipation,
  updateTransfer,
  updateTravel,
  listTerminals,
  createTerminal,
  createTravelRoute,
  updateTravelRoute,
  createTravel,
  getEventOrder,
  createTravelSettings,
  updateTravelSettings,
  createParticipationAttachments,
  deleteParticipationAttachments,
} from './TravelTransferPage/travelTransferGqlHelper';
import {
  updateGuest,
  createGuest,
  deleteGuest,
} from '../bundles/AimParticipation/gqlHelper';

import { TransferAssign } from './TravelTransferPage/components/Transfer/Transfer';
import { AssignTravel } from './TravelTransferPage/components/AssignTravel/AssignTravel';
import { GeneralInfo } from './TravelTransferPage/components/GeneralInfo/GeneralInfo';
import { theme } from '../../theme';
import { AimTypography } from '../atoms/AimTypography';
import { AimTitleAndButtons } from '../bundles/AimTitleAndButtons';
import { Travel } from './TravelTransferPrefs/components/Travel';
import { Transfer } from './TravelTransferPrefs/components/Transfer';
import { AimTextFieldForm } from '../atoms/AimTextField';
import { AimTextField } from '../atoms/AimTextField';
import { AimSnackbar, AimSnackbarSeverity } from '../atoms/AimSnackbar';
import { AimDialog } from '../atoms/AimDialog';
import { appState, constants, fileHelper, utilities } from '@aim/common';

import { AimSwitch } from '../atoms/AimSwitch';
import { HeaderParticipationAssign } from './HeaderParticipationAssign';
import { orderBy } from 'lodash';
import { publishPaxServiceHelper } from './PublishPaxServiceDialog';

//Constants
const formControlStyle = { width: 'calc(100% - 15px)' };

const InnerContainer = styled('div')({
  padding: 20,
});

const Container = styled('div')({
  backgroundColor: 'white',
  margin: '10px 0px',
  display: 'flex',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});
const FlagContainer = styled('div')({
  display: 'flex',
  margin: 20,
});
const SelectedParticipants = styled('div')({
  display: 'flex',
  backgroundColor: theme.colors.greyScale.grey2,
  alignItems: 'center',
});

export const TravelTransferPage = ({
  goBack,
  intl,
  participations,
  eventId,
}) => {
  const {
    updateTravelOrTransferPublishStatus,
    publishServiceTranslation,
  } = publishPaxServiceHelper;
  const { decodeDbNumber, encodeDbNumber } = utilities;
  const i18n = translation.TravelTransfer(intl);
  const i18nPublishService = publishServiceTranslation.PublishServices(intl);
  const [dataTravel, setDataTravel] = useState();
  const [businessUnits, setBusinessUnits] = useState([]);
  const [travels, setTravels] = useState([]);
  const [terminals, setTerminals] = useState();
  const [checkAuth, setCheckAuth] = useState();
  const [checkAgency, setCheckAgency] = useState();
  const [checkAuto, setCheckAuto] = useState();
  const [noteDefaultValue, setNoteDefaultValue] = useState();
  const [publicationData, setPublicationData] = useState({ dialogOpen: false });

  // const travelRouteAddedInMultipleAssignRef = useRef();
  // const terminalInAddedInMultipleAssignRef = useRef();
  // const terminalOutAddedInMultipleAssignRef = useRef();

  const _defaultValues = {
    businessUnit: undefined,
    orderCode: undefined,
    travelIncluded: 'no',
    transferIncluded: 'no',
    personIncluded: 'no',
    travelConfigurationCompleted: false,
    note: null,
  };
  const { control, handleSubmit, setValue, reset, errors } = useForm({
    shouldUnregister: false,
    defaultValues: _defaultValues,
  });

  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [user, setUser] = useState();

  const participationId = participations[0]?.id;
  const isMultiple = participations.length > 1;

  const onSaveAllTransfers = async (data) => {
    try {
      await PromisePool.for(participations || [])
        .withConcurrency(5)
        .onTaskStarted((_, pool) => {
          appState.loaderValue.next(pool.processedPercentage());
        })
        .handleError(async (error) => {
          console.error('💥 ERROR: ', error);
          throw error;
        })
        .process(async ({ id: pId, participationParticipationServicesId }) => {
          // if punctual assignment from rows actions in datagrid participationParticipationServicesId undefined
          if (!participationParticipationServicesId) {
            participationParticipationServicesId =
              dataTravel?.participationServices?.id;
          }
          let input = {
            oneWayFrom: data?.oneWayFrom,
            oneWayPickUpDestination: data?.oneWayPickUpDestination,
            oneWayPickUpLocation: data?.oneWayPickUpLocation,
            oneWayTime: data?.oneWayTime,
            oneWayTo: data?.oneWayTo,
            oneWayDate: data?.oneWayDate,
            returnFrom: data?.returnFrom,
            returnPickUpDestination: data?.returnPickUpDestination,
            returnPickUpLocation: data?.returnPickUpLocation,
            returnTime: data?.returnTime,
            returnTo: data?.returnTo,
            returnDate: data?.returnDate,
            transferParticipationId: pId,
            isDeleted: 'false',
            eventId,
          };
          const travelInfo = await getTravelInfo(pId);
          if (travelInfo?.transfer?.id) {
            await updateTransfer({ ...input, id: travelInfo?.transfer?.id });
          } else {
            const transferResult = await createTransfer(input);
            if (transferResult?.id) {
              const participationInput = {
                id: pId,
                participationTransferId: transferResult?.id,
              };

              await updateParticipation(participationInput);
            }
          }

          // general info
          input = {
            eventId,
            isTravelIncluded: data?.travelIncluded === 'yes' ? true : undefined,
            isTransferAIncluded:
              data?.transferAIncluded === 'yes' ? true : undefined,
            isTransferRIncluded:
              data?.transferRIncluded === 'yes' ? true : undefined,
            note: data?.note ? JSON.stringify(data.note) : undefined,
            eventOrderId: data?.businessUnit ? data?.businessUnit : undefined,
            codeEventOrder: data?.orderCode ? data?.orderCode : undefined,
            id: participationParticipationServicesId,
            travelConfigurationCompleted: data?.travelConfigurationCompleted,
          };
          await updateTravelSettings(input);
        });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.page.snackbar.success,
      });
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.snackbar.error,
      });
    }
  };

  const getData = async () => {
    const travelInfo = await getTravelInfo(participationId);
    if (travelInfo?.travelFacultyResponse) {
      const response = [
        {
          ...travelInfo.travelFacultyResponse,

          checkedBaggage:
            travelInfo.travelFacultyResponse?.prefsCheckedBaggage === 'yes'
              ? i18n.page.yes
              : travelInfo.travelFacultyResponse?.prefsCheckedBaggage === 'no'
              ? i18n.page.no
              : i18n.page.noAnswer,
          issueTickets:
            travelInfo.travelFacultyResponse?.prefsIssueTickets === 'yes'
              ? i18n.page.yes
              : travelInfo.travelFacultyResponse?.prefsIssueTickets === 'no'
              ? i18n.page.no
              : i18n.page.noAnswer,
          proposal:
            travelInfo.travelFacultyResponse?.prefsProposal === 'yes'
              ? i18n.page.yes
              : travelInfo.travelFacultyResponse?.prefsProposal === 'no'
              ? i18n.page.no
              : i18n.page.noAnswer,
          uploadByDocId:
            travelInfo.travelFacultyResponse?.prefsDocBookByAuth?.length > 0
              ? i18n.page.yes
              : i18n.page.no,
          uploadForPurchase:
            travelInfo.travelFacultyResponse?.prefsDocBookByAgency?.length > 0
              ? i18n.page.yes
              : i18n.page.no,
          uploadForAutoBooking:
            travelInfo.travelFacultyResponse?.prefsDocBookByMyself?.length > 0
              ? i18n.page.yes
              : i18n.page.no,
          preferences: !travelInfo.travelFacultyResponse
            ? i18n.page.noAnswer
            : travelInfo.travelFacultyResponse?.bookByAuth
            ? i18n.page.authBooking
            : travelInfo.travelFacultyResponse?.bookByAgency
            ? i18n.page.useAgencyToBook
            : i18n.page.autonomousBooking,
        },
      ];
      setCheckAuth(travelInfo.travelFacultyResponse?.bookByAuth);
      setCheckAgency(travelInfo.travelFacultyResponse?.bookByAgency);
      setCheckAuto(travelInfo.travelFacultyResponse?.bookByMyself);
      reset(response[0]);
    } else {
      reset({
        preferences: i18n.page.noAnswer,
      });
    }
    if (travelInfo?.participationTravel) {
      const fetchedTravels = travelInfo.participationTravel.items;
      const assignedTravels = fetchedTravels.map((travel) => {
        return {
          id: travel.id,
          pnrCode: travel.pnrCode,
          businessUnit: travel.businessUnit,
          orderCode: travel.orderCode,
          note: travel.note,
          returnTrip: travel.returnTrip === 'true',
          date: travel.date,
          arrivalDate: travel.arrivalDate,
          publicationDate: travel.publicationDate,
          isPublished: travel.isPublished,
          ticketStatus: travel.ticketStatus,
          travelType: travel.travelRoute.travelType,
          transportCode: travel.travelRoute.transportCode,
          startDate: travel.travelRoute.startDate,
          endDate: travel.travelRoute.endDate,
          terminalIn: travel.travelRoute.terminalIn?.name,
          terminalOut: travel.travelRoute.terminalOut?.name,
          routeId: travel.travelRoute.id,
          occupation: travel.travelRoute.occupation,
          groupFight: {
            date: travel.travelRoute.date,
            pnrCode: travel.travelRoute.pnrCode,
            costPerPerson: travel.travelRoute.costPerPerson,
          },
          cost: decodeDbNumber(travel.cost),
          checkInLink: travel.checkInLink,
          files: travel.ticketFileAttachments.items.map((ta) => ({
            ...ta.attachmentFile,
            participationAttachmentsId: ta.id,
          })),
          guests:
            travel.guests?.items?.map((item) => {
              const p = item.participation;
              const isParticipantSelectedForAssignment =
                item.participation?.id === travelInfo.id;
              return {
                ...item,
                radioChoice: p?.id
                  ? isParticipantSelectedForAssignment
                    ? '1'
                    : '3'
                  : '2',
                ...(item.participation && {
                  participationId: item.participation.id,
                }),
                ...(item.participation && {
                  autocompleteParticipationSelected: {
                    value: item.participation.id,
                    participationData: { ...item, id: item.participation.id },
                    label: `${item.givenName} ${item.familyName} <${item.email}>`,
                  },
                }),
              };
            }) || [],
        };
      });

      setTravels(fetchTravels(assignedTravels));
    }

    if (travelInfo?.participationServices) {
      const service = travelInfo?.participationServices;
      setValue('transferAIncluded', service.isTransferAIncluded ? 'yes' : 'no');
      setValue('transferRIncluded', service.isTransferRIncluded ? 'yes' : 'no');
      setValue('travelIncluded', service.isTravelIncluded ? 'yes' : 'no');
      setValue('personIncluded', service.personIncluded ? 'yes' : 'no');
      setValue('orderCode', service.codeEventOrder);
      setValue('businessUnit', service.eventOrderId);
      setValue('note', service.note ? JSON.parse(service.note) : '');
      setValue(
        'travelConfigurationCompleted',
        service.travelConfigurationCompleted
      );
      setNoteDefaultValue(service.note ? JSON.parse(service.note) : null);
    }

    if (travelInfo?.transfer) {
      const transfer = travelInfo?.transfer;

      setValue('oneWayFrom', transfer?.oneWayFrom);
      setValue('oneWayPickUpDestination', transfer?.oneWayPickUpDestination);
      setValue('oneWayPickUpLocation', transfer?.oneWayPickUpLocation);
      setValue('oneWayTo', transfer?.oneWayTo);
      setValue('oneWayTime', transfer?.oneWayTime);
      setValue('returnFrom', transfer?.returnFrom);
      setValue('returnPickUpDestination', transfer?.returnPickUpDestination);
      setValue('returnPickUpLocation', transfer?.returnPickUpLocation);
      setValue('returnTime', transfer?.returnTime);
      setValue('returnTo', transfer?.returnTo);
      setValue('oneWayDate', transfer?.oneWayDate);
      setValue('returnDate', transfer?.returnDate);
    }

    setDataTravel(travelInfo);
  };

  useEffect(() => {
    const subscription = appState.user.subscribe(setUser);
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      await getEventOrder(eventId).then((res) => {
        const eventOrdersList = res?.items;

        const eventOrders = eventOrdersList.filter(
          (x) =>
            x?.codeName ||
            (x?.sponsor &&
              x?.sponsor?.isDeleted !== 'true' &&
              x?.sponsor?.isDeleted !== true) ||
            x?.delegation
        );

        const businessUnit = eventOrders?.map((el) => {
          return {
            type:
              el?.codeName ||
              (el?.sponsor ? 'sponsor' : el?.delegation ? 'delegation' : ''),
            value: el?.id,
            code: el.code || '0',
            label:
              el?.codeName ||
              (el?.sponsor
                ? el?.sponsor?.name
                : el?.delegation
                ? el?.delegation?.name
                : ''),
          };
        });

        const labelSorter = (bu) => bu.label.toLowerCase();
        const nextBusinessUnit = orderBy(businessUnit, [labelSorter], ['asc']);
        setBusinessUnits(nextBusinessUnit);
      });
    };

    fetchBusinessUnits();
  }, []);

  useEffect(() => {
    if (!participationId || participations?.length > 1) return;
    getData();
  }, [participationId]);

  useEffect(() => {
    const fetchTerminals = async () => {
      const terminalList = await listTerminals();
      setTerminals(terminalList);
    };

    fetchTerminals();
  }, []);

  useEffect(() => {
    if (publicationData.travel) {
      addPublicationDateOfTravel(true);
    }
  }, [publicationData]);

  const fetchTravels = (travels) => {
    if (travels && travels.length > 0) {
      // order by A/R and date, highlight last of A and first of R
      const aList = travels
        .filter((assign) => !assign.returnTrip)
        .sort(
          (a, b) =>
            new Date(`${a.arrivalDate}T${a.endDate}`) -
            new Date(`${b.arrivalDate}T${b.endDate}`)
        )
        .map((i, idx, array) => ({
          ...i,
          highlight: idx === array.length - 1,
        }));

      const rList = travels
        .filter((assign) => assign.returnTrip)
        .sort(
          (a, b) =>
            new Date(`${a.date}T${a.startDate}`) -
            new Date(`${b.date}T${b.startDate}`)
        )
        .map((i, idx) => ({
          ...i,
          highlight: idx === 0,
        }));
      return [...aList, ...rList];
    }
    return travels;
  };

  const handlePublishTravelOrTransferStatus = async (service, isTravel) => {
    try {
      appState.isLoader.next(true);
      await updateTravelOrTransferPublishStatus(
        [service.id],
        service.isPublished !== 'true',
        isTravel
      );
      await getData();
    } catch (error) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18nPublishService.page.snackbar.error.publish,
      });
    } finally {
      appState.isLoader.next(false);
    }
  };

  const updateTravelFiles = async ({
    files = [],
    filesToRemove = [],
    travelId,
    participationId,
  }) => {
    const s3Folder = `events/${eventId}/participations/${participationId}/`;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.id) {
        const originalName = file.name;
        const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
        const extension = matchExtension ? matchExtension[0] : '';
        const fileData = {
          originalName: originalName,
          extension,
          size: file.size,
        };
        const fileId = await fileHelper.createFile({
          dirPath: s3Folder,
          fileData,
          localFile: file,
          useLoader: false,
        });

        await createParticipationAttachments({
          participationAttachmentsParticipationTravelId: participationId,
          travelTicketFileAttachmentsId: travelId,
          participationAttachmentsAttachmentFileId: fileId,
        });
      }
    }
    for (let i = 0; i < filesToRemove.length; i++) {
      const file = filesToRemove[i];
      if (file.id) {
        await fileHelper.deleteFile({
          dirPath: s3Folder,
          fileData: file,
          useLoader: false,
        });

        await deleteParticipationAttachments({
          id: file.participationAttachmentsId,
        });
      }
    }
  };

  const addPublicationDateOfTravel = async (noPublicationDate) => {
    // add publication date to travel
    const { travel } = publicationData;
    const date = noPublicationDate ? undefined : publicationData.date;
    let isUpdating = false;
    let nextTravel = {
      ...travel,
      publicationDate: date, // || format(new Date(), 'yyyy-MM-dd'),
    };
    let nextTravels = [];
    if (travel.id) {
      // edit
      nextTravels = [...travels.filter((t) => t.id !== travel.id), travel];
      isUpdating = true;
    } else {
      // add
      nextTravels = [...travels, nextTravel];
    }

    const proceed = isMultiple
      ? !(await multipleOccupationErrorChecker(nextTravels))
      : !(await singleOccupationErrorChecker(nextTravels));

    if (!proceed) {
      return;
    }
    appState.isLoader.next(true);

    // check if route terminals are already existing,
    // for massive assignment, if I insert a new terminal in and/or terminal out, I have to insert it/them only once

    let terminalInId;
    let terminalOutId;
    if (
      travel.travelType !== constants.TravelTypes.PERSONALTRANSPORT.id &&
      travel.travelType !== constants.TravelTypes.OTHER.id
    ) {
      const respTerminalIds = await getTerminalIds(travel);
      terminalInId = respTerminalIds.terminalInId;
      terminalOutId = respTerminalIds.terminalOutId;
    }

    let travelRouteId;
    if (!travel.routeId) {
      // create route
      // for massive assignment, if I insert a new route I have to insert it only once
      const resCreateTravelRoute = await createTravelRoute({
        travelType: travel.travelType,
        transportCode: travel.transportCode,
        startDate: travel.startDate,
        endDate: travel.endDate,
        ...(travel.travelType !== constants.TravelTypes.PERSONALTRANSPORT.id &&
          travel.travelType !== constants.TravelTypes.OTHER.id && {
            travelRouteTerminalInId: terminalInId, //undefined if PERSONALTRANSPORT or OTHER, so removed
            travelRouteTerminalOutId: terminalOutId, //undefined if PERSONALTRANSPORT or OTHER, so removed
            travelRouteEventId: eventId,
          }),
      });
      travelRouteId = resCreateTravelRoute.id;
    } else {
      travelRouteId = travel.routeId;
      await updateTravelRoute({
        id: travel.routeId,
        travelType: travel.travelType,
        transportCode: travel.transportCode,
        startDate: travel.startDate,
        endDate: travel.endDate,
        travelRouteTerminalInId: terminalInId, //undefined if PERSONALTRANSPORT or OTHER, so removed
        travelRouteTerminalOutId: terminalOutId, //undefined if PERSONALTRANSPORT or OTHER, so removed
      });
    }

    if (isUpdating) {
      if (!isMultiple) {
        await updateTravelFn({
          singleTravel: nextTravel,
          participationId,
          travelRouteId,
        });
      } else {
        await PromisePool.for(participations || [])
          .withConcurrency(5)
          .onTaskStarted((_, pool) => {
            appState.loaderValue.next(pool.processedPercentage());
          })
          .handleError(async (error) => {
            console.error('💥 ERROR: ', error);
            throw error;
          })
          .process(async ({ id: elId }) => {
            updateTravelFn({
              singleTravel: nextTravel,
              participationId: elId,
              travelRouteId,
            });
          });
      }
    } else {
      if (!isMultiple) {
        await saveTravelFn({
          singleTravel: nextTravel,
          participationId,
          travelRouteId,
        });
      } else {
        await PromisePool.for(participations || [])
          .withConcurrency(5)
          .onTaskStarted((_, pool) => {
            appState.loaderValue.next(pool.processedPercentage());
          })
          .handleError(async (error) => {
            console.error('💥 ERROR: ', error);
            throw error;
          })
          .process(async ({ id: elId }) => {
            saveTravelFn({
              singleTravel: nextTravel,
              participationId: elId,
              travelRouteId,
            });
          });
      }
    }

    if (isMultiple) {
      goBack();
    }

    setTravels(fetchTravels(nextTravels));
    await getData();
    appState.isLoader.next(false);
  };

  const addTravel = (data, selectedRoute, index) => {
    const currentTravel = {
      ...data,
      selectedRoute,
      returnTrip: data.returnTrip === 'R',
    };

    // open dialog with publication date
    setPublicationData({
      travel: currentTravel,
      date: currentTravel.publicationDate || format(new Date(), 'yyyy-MM-dd'),
      index,
      dialogOpen: false,
    });
  };

  const removeTravel = async (row) => {
    if (row.id) {
      try {
        await updateTravel({ id: row.id, isDeleted: true }).then(() =>
          getData()
        );
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.page.snackbar.success,
        });
      } catch (e) {
        console.error(e);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.page.snackbar.error,
        });
      }
    } else {
      const travelsToUpdate = [...travels];
      travelsToUpdate.splice(row.index);
      setTravels(fetchTravels(travelsToUpdate));
    }
  };

  const addGuests = async (travel, travelId) => {
    const travelOriginal = travels.find((x) => x.id === travelId);
    const guestsRemoved = travelOriginal?.guests.filter(
      (x) => !travel.guests.find((g) => g.id === x.id)
    );
    if (guestsRemoved?.length) {
      for (let index = 0; index < guestsRemoved.length; index++) {
        const guest = guestsRemoved[index];
        await deleteGuest({ id: guest.id });
      }
    }
    for (let index = 0; index < travel.guests.length; index++) {
      const guest = travel.guests[index];
      const guestInput = {
        ...guest,
        ...(guest.participationId && {
          guestParticipationId: guest.participationId,
        }),
        // ...(guest.radioChoice === '1' && {
        //   guestParticipationId: participationId, // occhio che è molto pericolosa sta cosa..
        // }),
        travelGuestsId: travelId,
      };
      delete guestInput.radioChoice;
      delete guestInput.participationId;
      delete guestInput.participation;
      delete guestInput.autocompleteParticipationSelected;
      if (guestInput.id) {
        await updateGuest(guestInput);
      } else {
        if (guestInput.id) delete guestInput.id;
        await createGuest(guestInput);
      }
    }
  };

  const formatTravelInput = ({
    travel,
    travelRouteId,
    travelParticipationId,
  }) => {
    return {
      date: travel.date,
      arrivalDate: travel.arrivalDate,
      publicationDate: travel.publicationDate,
      pnrCode: travel.pnrCode,
      businessUnit: travel.businessUnit,
      orderCode: travel.orderCode,
      returnTrip: (travel.returnTrip || travel.returnTrip === 'R').toString(),
      travelTravelRouteId: travelRouteId,
      travelParticipationId,
      cost: encodeDbNumber(travel.cost),
      note: travel.note,
      travelEventId: eventId,
      checkInLink: travel.checkInLink,
    };
  };

  const getTerminalIds = async (travel) => {
    const existingTerminalIn = terminals.find(
      (terminal) =>
        terminal.name?.toUpperCase().trim() ===
        travel.terminalIn?.toUpperCase().trim()
    );
    const existingTerminalOut = terminals.find(
      (terminal) =>
        terminal.name?.toUpperCase().trim() ===
        travel.terminalOut?.toUpperCase().trim()
    );

    let terminalInId;
    let name = travel.terminalIn?.toUpperCase().trim();

    // test error notification, aimdialog could be the problem
    // throw new Error('messaggio di errore elegante 👗 👫 ');

    if (!existingTerminalIn) {
      if (name != '') {
        // create terminal in
        const resCreateTerminalIn = await createTerminal({
          name,
        });
        terminalInId = resCreateTerminalIn.id;
      }
    } else {
      terminalInId = existingTerminalIn.id;
    }

    let terminalOutId;
    name = travel.terminalOut?.toUpperCase().trim();
    if (!existingTerminalOut) {
      if (name !== '') {
        // create terminal out
        const resCreateTerminalOut = await createTerminal({
          name,
        });
        terminalOutId = resCreateTerminalOut.id;
      }
    } else {
      terminalOutId = existingTerminalOut.id;
    }
    return { terminalInId, terminalOutId };
  };

  const createNewTravel = async ({
    travel,
    participationId,
    travelRouteId,
  }) => {
    // Check which travels have a routeid
    let respTravel;
    if (travelRouteId) {
      const input = formatTravelInput({
        travel,
        travelRouteId: travelRouteId,
        travelParticipationId: participationId,
      });
      try {
        respTravel = await createTravel(input);
        if (!isMultiple) await addGuests(travel, respTravel.id);
      } catch (e) {
        console.error(e);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.page.snackbar.error,
        });
      }
    } else {
      try {
        // create travel
        const input = formatTravelInput({
          travel,
          travelRouteId,
          travelParticipationId: participationId,
        });

        respTravel = await createTravel(input);
        if (!isMultiple) await addGuests(travel, respTravel.id);
      } catch (e) {
        console.error(e);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.page.snackbar.error,
        });
      }
    }
    await updateTravelFiles({
      files: travel.files,
      travelId: respTravel?.id,
      participationId: participationId,
    });
  };

  const singleOccupationErrorChecker = async (travels) => {
    let occupationError = false;
    const newOccupations_groupFlight = travels.reduce((res, curr) => {
      res +=
        curr.travelType === constants.TravelTypes.GROUPFLIGHT.id && !curr.id
          ? (curr.guests && curr.guests.length) || 1
          : 0;
      return res;
    }, 0);
    if (newOccupations_groupFlight > 0) {
      occupationError = travels.find((t) => {
        const availability =
          t.selectedRoute?.occupation -
          t.selectedRoute?.travel?.items?.length -
          newOccupations_groupFlight;
        return (
          t.travelType === constants.TravelTypes.GROUPFLIGHT.id &&
          availability < 0
        );
      });
    }

    if (occupationError) {
      const type = Object.values(constants.TravelTypes)
        .find((x) => x.id === occupationError.travelType)
        .label(intl);
      const date = format(new Date(occupationError.date), 'dd/MM/yyyy');
      const errorMsg = `${i18n.page.snackbar.maxOccupationError} for date: ${date} \n type:${type} \n ${occupationError.terminalIn}-${occupationError.terminalOut}`;
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: errorMsg,
      });
    }

    return occupationError;
  };

  const multipleOccupationErrorChecker = async (travels) => {
    const occupationError = travels.find((t) => {
      const availability =
        t.selectedRoute?.occupation - t.selectedRoute?.travel?.items?.length;

      return (
        t.travelType === constants.TravelTypes.GROUPFLIGHT.id &&
        availability &&
        participations.length > availability
      );
    });

    if (occupationError) {
      const type = Object.values(constants.TravelTypes)
        .find((x) => x.id === occupationError.travelType)
        .label(intl);
      const date = format(new Date(occupationError.date), 'dd/MM/yyyy');
      const errorMsg = `${i18n.page.snackbar.maxOccupationError} for date: ${date} \n type:${type} \n ${occupationError.terminalIn}-${occupationError.terminalOut}`;
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: errorMsg,
      });
    }

    return occupationError;
  };

  const saveTravelFn = async ({
    singleTravel,
    participationId,
    travelRouteId,
  }) => {
    // copied and pasted this from the previous saveTravels function

    try {
      // new travel and route
      await createNewTravel({
        travel: singleTravel,
        participationId,
        travelRouteId,
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.page.snackbar.success,
      });
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.snackbar.error,
      });
    }
  };

  const updateTravelFn = async ({
    singleTravel,
    participationId,
    travelRouteId,
  }) => {
    // copied and pasted this from the previous saveTravels function
    if (singleTravel.id) {
      // Update travel
      // Check which travels has a routeId
      if (travelRouteId) {
        const input = {
          ...formatTravelInput({
            travel: singleTravel,
            travelRouteId: travelRouteId,
            travelParticipationId: participationId,
          }),
          id: singleTravel.id,
        };

        try {
          await updateTravel(input);
          if (!isMultiple) {
            await addGuests(singleTravel, input.id);
          }
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.page.snackbar.success,
          });
        } catch (e) {
          console.error(e);
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.page.snackbar.error,
          });
        }
      } else {
        try {
          // new travel and route
          await createNewTravel({
            travel: singleTravel,
            participationId,
            travelRouteId,
          });
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.page.snackbar.success,
          });
        } catch (e) {
          console.error(e);
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.page.snackbar.error,
          });
        }
      }
      await updateTravelFiles({
        files: singleTravel.files,
        filesToRemove: singleTravel.filesToRemove,
        travelId: singleTravel.id,
        participationId,
      });
    }
    return;
  };

  return (
    <InnerContainer>
      <Tooltip title={i18n.page.backButton.tooltip}>
        <AimIconAndTextButton
          variant="text"
          style={{ padding: 0 }}
          text={i18n.page.backButton.label}
          onClick={goBack}
        >
          <ArrowBack />
        </AimIconAndTextButton>
      </Tooltip>
      <AimTitleAndButtons title={i18n.page.title} />
      {participations?.length === 0 && (
        <AimTypography variant={'h3'} style={{ marginTop: 30 }}>
          {i18n.page.noParticipant}
        </AimTypography>
      )}

      <HeaderParticipationAssign
        intl={intl}
        participations={
          participations?.length === 1 && dataTravel
            ? [
                {
                  id: dataTravel.id,
                  username: dataTravel.username,
                  givenName: dataTravel.givenName,
                  familyName: dataTravel.familyName,
                  email: dataTravel.email,
                  phone: dataTravel.phone,
                  profile: dataTravel.profile?.name,
                  typology: dataTravel.typology?.name,
                  cluster:
                    dataTravel.cluster &&
                    Object.values(constants.Clusters)
                      .find((x) => x.id === dataTravel.cluster)
                      .label(intl),
                  invited: dataTravel.isInvited,
                  status: dataTravel.status,
                  isDelegations:
                    dataTravel.cluster &&
                    dataTravel.cluster === constants.Clusters.Delegations.id,
                },
              ]
            : participations
        }
      />

      <form>
        {participations?.length !== 0 && (
          <Container style={{ flexDirection: 'column' }}>
            {participations?.length === 1 && (
              <>
                <GeneralInfo
                  i18n={i18n}
                  control={control}
                  // data={dataTravel}
                  businessUnitItems={businessUnits}
                  formControlStyle={formControlStyle}
                  errors={errors}
                  setValue={setValue}
                  noteDefaultValue={noteDefaultValue}
                />
                {dataTravel &&
                  dataTravel.cluster &&
                  dataTravel.cluster !== constants.Clusters.Delegations.id && (
                    <Grid item xs={12}>
                      <AimTextFieldForm
                        control={control}
                        style={{ backgroundColor: 'white' }}
                        name="preferences"
                        placeholder=""
                        label={i18n.page.generalPreferences}
                        formControlStyle={formControlStyle}
                        disabled={true}
                      />
                    </Grid>
                  )}
                {checkAuth && (
                  <>
                    <Travel
                      formControlStyle={formControlStyle}
                      control={control}
                      intl={intl}
                      isEditable={false}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <AimTextFieldForm
                          control={control}
                          style={{ backgroundColor: 'white' }}
                          name="checkedBaggage"
                          placeholder=""
                          label={i18n.page.consentForTheCheckedBaggage}
                          formControlStyle={formControlStyle}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <AimTextFieldForm
                          control={control}
                          style={{ backgroundColor: 'white' }}
                          name="issueTickets"
                          placeholder=""
                          label={i18n.page.issueTickets}
                          formControlStyle={formControlStyle}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <AimTextFieldForm
                          control={control}
                          style={{ backgroundColor: 'white' }}
                          name="proposal"
                          placeholder=""
                          label={i18n.page.proposal}
                          formControlStyle={formControlStyle}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>

                    <Transfer
                      formControlStyle={formControlStyle}
                      control={control}
                      intl={intl}
                      isEditable={false}
                    />
                  </>
                )}
                <Grid container spacing={2}>
                  {checkAuth && (
                    <Grid item xs={12} sm={6} md={3}>
                      <AimTextFieldForm
                        control={control}
                        style={{ backgroundColor: 'white' }}
                        name="uploadByDocId"
                        placeholder=""
                        label={i18n.page.uploadByDocId}
                        formControlStyle={formControlStyle}
                        disabled={true}
                      />
                    </Grid>
                  )}
                  {checkAgency && (
                    <Grid item xs={12} sm={6} md={3}>
                      <AimTextFieldForm
                        control={control}
                        style={{ backgroundColor: 'white' }}
                        name="uploadForPurchase"
                        placeholder=""
                        label={i18n.page.uploadForPurchase}
                        formControlStyle={formControlStyle}
                        disabled={true}
                      />
                    </Grid>
                  )}
                  {checkAuto && (
                    <Grid item xs={12} sm={6} md={3}>
                      <AimTextFieldForm
                        control={control}
                        style={{ backgroundColor: 'white' }}
                        name="uploadForAutoBooking"
                        placeholder=""
                        label={i18n.page.uploadForAutoBooking}
                        formControlStyle={formControlStyle}
                        disabled={true}
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            <AssignTravel
              {...{
                i18n,
                control,
                intl,
                addTravel,
                removeTravel,
                travels,
                isMultiple,
                handlePublishTravelStatus: (service) =>
                  handlePublishTravelOrTransferStatus(service, true),
                participantId: participationId,
                eventId,
                businessUnitItems: businessUnits,
                // saveTravels,
                // saveMultipleTravels,
                // participantIdList,
                dataTravel,
                reloadTravels: getData,
                setSnackbar,
              }}
            />
            <TransferAssign
              i18n={i18n}
              formControlStyle={formControlStyle}
              control={control}
              intl={intl}
              dataTravel={dataTravel}
              handlePublishTransferStatus={(service) =>
                handlePublishTravelOrTransferStatus(service, false)
              }
            />
            <FlagContainer>
              <Controller
                control={control}
                name="travelConfigurationCompleted"
                render={(props) => (
                  <AimSwitch
                    label={i18n.page.travelConfigurationCompleted}
                    checked={props.value}
                    onChange={(e) => {
                      props.onChange(e.target.checked);
                    }}
                  />
                )}
              />
            </FlagContainer>
            <ButtonContainer>
              <AimIconAndTextButton
                onClick={goBack}
                variant="greyFill"
                text={i18n.page.cancel}
                type="submit"
              >
                <ReplayIcon />
              </AimIconAndTextButton>
              <AimIconAndTextButton
                text={i18n.page.save}
                variant="greenFill"
                onClick={handleSubmit(onSaveAllTransfers)}
              >
                <CheckIcon />
              </AimIconAndTextButton>
            </ButtonContainer>
          </Container>
        )}
      </form>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </InnerContainer>
  );
};
