import React, { useState, useEffect } from 'react';

import { styled } from './../styled';
import ChipInput from 'material-ui-chip-input';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { theme } from './../../theme';
import { appState, constants } from '@aim/common';
import { AimIconButton } from './AimIconButton';

const CustomFormControl = styled(FormControl)({
  margin: theme.spacing(1),
  minWidth: 120,
  flex: 1,
});

const CustomChipInput = styled(ChipInput)(({ textfieldvariant, rows }) => ({
  ...textfieldvariant,
  height: 40 * rows + 2 + 8, //to fix as textfields
  paddingLeft: 10,
  paddingRight: 10,
  borderRadius: 4,
  '&.WAMuiChipInput': {
    marginBottom: '0px !important',
    background: 'lime',
  },
}));

const CustomFormLabel = styled(FormLabel)(({ variantTemplate }) => ({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
  fontFamily:
    variantTemplate === constants.Templates.FRONTEND ? 'hind' : 'Roboto',
  '&.MuiFormLabel-root.Mui-focused': {
    color: 'black',
  },
}));

const CustomChip = styled(Chip)({
  margin: 4,
  background: theme.colors.primary.yellow + '5C',
});

const formControlLabelPlacementTop = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const variantsBACKEND = {
  grey: {
    backgroundColor: theme.colors.greyScale.backgroundGrey,
  },
  white: {
    backgroundColor: 'white',
  },
};

const buildStyle = (backgroundColor, color) => ({
  backgroundColor,
  overflow: 'hidden',
  '& .MuiChip-root': {
    color: theme.colors.primary.white,
    background: color,
  },
});

const variantsFRONTEND = ({ eventConfiguration }) => ({
  primary: buildStyle(
    theme.colors.greyScale.backgroundGrey,
    eventConfiguration.primaryColor
  ),
  secondary: buildStyle(
    theme.colors.primary.white,
    eventConfiguration.primaryColor
  ),
});

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const variantTemplate = appState.template.getValue();

// TODO delete when restyle is completed
const variantMap = {
  grey: 'primary',
  white: 'secondary',
};

const getVariant = ({ eventConfiguration, variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[variantTemplate]({ eventConfiguration });
    return template[variant] || template[variantMap[variant]];
  }
};

const getDefaultVariant = (eventConfiguration) =>
  variantTemplate === constants.Templates.BACKEND
    ? 'grey'
    : eventConfiguration.background === theme.colors.primary.white
    ? 'primary'
    : 'secondary';

export const AimChipInput = ({
  label,
  displayLabel = true,
  formControlStyle,
  renderLabelField = 'value',
  textfieldVariant,
  labelPlacementLeft,
  addButton,
  maximumNumberOfElements = 99,
  elements,
  ...rest
}) => {
  const [eventConfiguration, setEventConfiguration] = useState({});

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  const nextTextFieldVariant =
    textfieldVariant || getDefaultVariant(eventConfiguration);
  console.log('textFieldVariant', nextTextFieldVariant);

  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };

  const labelStyle = labelPlacementLeft ? formLabelLabelPlacementTop : {};

  return (
    <CustomFormControl style={controlStyle}>
      {displayLabel ? (
        <CustomFormLabel style={labelStyle} variantTemplate={variantTemplate}>
          {label}
        </CustomFormLabel>
      ) : (
        <></>
      )}
      <CustomChipInput
        {...rest}
        textfieldvariant={{
          ...getVariant({ variant: nextTextFieldVariant, eventConfiguration }),
        }}
        variant="standard"
        disableUnderline={true}
        chipRenderer={({ handleDelete, ...others }, key) => {
          return (
            <CustomChip
              key={key}
              label={others[renderLabelField]}
              onDelete={handleDelete}
              deleteIcon={
                <ClearIcon
                  style={{
                    color: theme.colors.secondary.red,
                    fontSize: '0.875rem',
                  }}
                />
              }
            />
          );
        }}
        inputProps={{
          style: {
            marginTop: 8,
            marginBottom: 8,
            paddingLeft: 10,
            paddingRight: 10,
          },
        }}
      />
      {addButton && (
        // <div
        //   // onClick={() => {}}
        //   style={{
        //     backgroundColor: 'grey',
        //     position: 'absolute',
        //     right: 10,
        //     top: '50%',
        //     cursor: 'pointer',
        //   }}
        // >
        //   {endAdornment}
        // </div>
        <AimIconButton
          style={{
            backgroundColor:
              elements?.length >= maximumNumberOfElements
                ? 'grey'
                : eventConfiguration.primaryColor || 'yellow',
            position: 'absolute',
            right: 10,
            bottom: 1,
            ...(elements?.length < maximumNumberOfElements
              ? {
                  cursor: 'pointer',
                }
              : { cursor: 'default' }),
            height: '50%',
          }}
        >
          <AddIcon />
        </AimIconButton>
      )}
    </CustomFormControl>
  );
};

export const AimChipInputForm = ({
  control,
  name,
  errors,
  defaultValue,
  ...rest
}) => (
  <>
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={(props) => {
        console.log('props', props.value);
        return (
          <AimChipInput
            value={props.value}
            onAdd={(chip) =>
              console.log('onAdd', [...props.value, chip]) ||
              props.onChange([...props.value, chip])
            }
            onDelete={(chip) =>
              console.log(
                'onDelete',
                props.value.filter((x) => x !== chip)
              ) || props.onChange(props.value.filter((x) => x !== chip))
            }
            {...rest}
          ></AimChipInput>
        );
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p>{message || 'Error'}</p>}
      />
    )}
  </>
);
