export default function (intl) {
  return {
    yes: intl.formatMessage({
      description: 'yes button',
      defaultMessage: 'yes',
    }),
    no: intl.formatMessage({
      description: 'no button',
      defaultMessage: 'No',
    }),
  };
}
