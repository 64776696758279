import React from 'react';
import Grid from '@material-ui/core/Grid';

import {
  AimTextFieldForm,
  AimRadioDynamic,
  AimSelectDynamic,
  AimCheckboxDynamic,
  AimAutocompleteDynamic,
  CitiesAutocompleteByGeoDbDynamic,
  CountriesAutocompleteByGeoDbDynamic,
  // RegionsAutocompleteByGeoDbDynamics,
} from '../atoms';

import { AimDragDropFileUploaderDynamic } from './AimDragDropFileUploader';

const formControlStyle = { width: 'calc(100% - 10px)' };

export const createDefaultValues = ({ fields, fieldValues }) =>
  fields.reduce((res, curr) => {
    const fieldValue = fieldValues.find(
      (fv) =>
        (fv?.fieldDefinition?.key || fv?.fieldDefinition?.id) ===
        (curr.key || curr.id)
    );
    let value;
    if (fieldValue?.value) {
      value = JSON.parse(fieldValue.value);
      if (curr.controlType === 'upload') {
        value = value.map((v) => ({ ...v, name: v?.originalName }));
      }
    }
    return {
      ...res,
      [curr.id]:
        fieldValue && fieldValue.value
          ? value
          : curr.controlType === 'multiselect' || curr.controlType === 'upload'
          ? []
          : '',
    };
  }, {});

const renderFormComponent = {
  text: AimTextFieldForm,
  select: AimSelectDynamic,
  checkbox: AimCheckboxDynamic,
  date: AimTextFieldForm,
  number: AimTextFieldForm,
  multiselect: AimSelectDynamic,
  radio: AimRadioDynamic,
  selectautocomplete: AimAutocompleteDynamic,
  autocompletecity: CitiesAutocompleteByGeoDbDynamic,
  autocompletecountries: CountriesAutocompleteByGeoDbDynamic,
  // autocompleterigions: RegionsAutocompleteByGeoDbDynamics,
  upload: AimDragDropFileUploaderDynamic,
};

export const createFormFields = ({
  prefix: fieldPrefix,
  fields,
  control,
  register,
  setValue,
  getValues,
  gridInputProps,
  errors,
  variant = 'white',
  intl,
  clearErrors,
}) =>
  // eslint-disable-next-line unused-imports/no-unused-vars
  fields.map(
    ({
      controlType,
      prefix,
      isRequired,
      formControlStyle: nextFormControlStyle,
      gridItemProps = {},
      dirPath,
      ...f
    }) => {
      const nextPrefix = prefix || fieldPrefix;
      const CustomComponent = renderFormComponent[controlType.toLowerCase()]({
        control,
        errors,
        register,
        setValue,
        getValues,
        variant,
        textfieldVariant: variant,
        selectVariant: variant,
        formControlStyle: nextFormControlStyle
          ? { ...formControlStyle, ...nextFormControlStyle }
          : formControlStyle,
        name: nextPrefix ? `${nextPrefix}.${f.id}` : f.id,
        ...f,
        isRequired: isRequired && !f.disabled,
        type: controlType,
        intl,
        dirPath,
        clearErrors,
      });

      let gridProps = { xs: 12, sm: 6 };
      if (controlType === 'upload') {
        gridProps = { xs: 12, sm: 12 };
      } else if (controlType === 'checkbox') {
        gridProps = { style: { flex: 1 } };
      }

      return (
        <Grid
          key={`grid-form-item-${f.id}`}
          item
          {...(gridInputProps || gridProps)}
          {...gridItemProps}
        >
          {React.cloneElement(CustomComponent)}
        </Grid>
      );
    }
  );
export const createFormFieldsBlocks = ({
  fields,
  control,
  register,
  setValue,
  getValues,
  gridInputProps,
  errors,
  variant = 'white',
  intl,
  clearErrors,
  block,
  standardFieldsWatch,
  customFieldsWatch,
}) =>
  // eslint-disable-next-line unused-imports/no-unused-vars
  fields.map(
    (
      { controlType, contextsOfUse, prefix, isRequired, dirPath, ...f },
      idx
    ) => {
      if (idx > 0 && block.firstField) {
        const fieldsValues = { ...standardFieldsWatch, ...customFieldsWatch };
        const value = fieldsValues[fields?.[0]?.id];
        if (!value) return null;
      }
      const CustomComponent = renderFormComponent[controlType.toLowerCase()]({
        control,
        errors,
        register,
        setValue,
        getValues,
        variant,
        textfieldVariant: variant,
        selectVariant: variant,
        formControlStyle,
        name: prefix ? `${prefix}.${f.id}` : f.id,
        ...f,
        isRequired: isRequired && !f.disabled,
        type: controlType,
        intl,
        dirPath,
        clearErrors,
      });

      let gridProps = { xs: 12, sm: 6 };
      if (controlType === 'upload') {
        gridProps = { xs: 12, sm: 12 };
      } else if (controlType === 'checkbox') {
        gridProps = { style: { flex: 1 } };
      }

      return (
        <Grid
          key={`grid-form-item-${f.id}`}
          item
          {...(gridInputProps || gridProps)}
        >
          {React.cloneElement(CustomComponent)}
        </Grid>
      );
    }
  );
