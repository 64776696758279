import React from 'react';

import { AimTypography } from '../../atoms';

import { styled } from '../../styled';
import { theme } from '../../../theme';

const ItemContainer = styled('div')({
  display: 'flex',
  background: theme.colors.greyScale.white,
  marginTop: 10,
});

const FieldValueContainer = styled('div')(({ count }) => ({
  padding: 5,
  flex: 1,
  minWidth: `calc(100% / ${count})`,
  maxWidth: `calc(100% / ${count})`,
}));

const AuthorItem = ({ fields, count }) => {
  return (
    <ItemContainer>
      {fields.map(
        ({ name, value }, idx) =>
          name !== 'Email' && (
            <FieldValueContainer key={idx} count={count}>
              <AimTypography variant="text">
                <b>{name}</b>
              </AimTypography>
              <AimTypography variant="text">
                {value?.value || value}
              </AimTypography>
            </FieldValueContainer>
          )
      )}
    </ItemContainer>
  );
};

export default AuthorItem;
