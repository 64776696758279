import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AimTypography, AimSelect, AimSelectMenuItem } from '@aim/components';

import Grid from '@material-ui/core/Grid';

/**
 * Component usefull to map Fields and Template given fields
 * @param  {Object} input
 * @param  {![{name: '<standardFieldname>' | '<customFieldname>'| '<paramKey>', type: 'standard'|'custom'|'params', isRequired: boolean }]} input.fields
 */
export const AimFieldsMapper = ({
  fields,
  options,
  preventUnmapped = false,
  control,
  errors,
}) => {
  if (!fields) throw new Error('Undefined fields');

  const [state, setState] = useState();

  useEffect(() => {
    if (fields) {
      const _fields = fields.map((f) => {
        const _options = options.map((o) => ({
          ...o,
          target: f,
        }));
        return {
          value: f,
          options: _options,
        };
      });
      setState(_fields);
    }
  }, []);

  return (
    <>
      {state &&
        state.map((field, idx) => {
          return (
            <Grid item sx={12} key={idx}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '50%' }}>
                  <AimTypography variant="textBold">
                    {field.value.charAt(0).toUpperCase() + field.value.slice(1)}
                  </AimTypography>
                </div>
                <div style={{ width: '50%' }}>
                  <Controller
                    rules={{
                      required: {
                        value: preventUnmapped,
                        message: 'error',
                      },
                    }}
                    render={(props) => (
                      <AimSelect
                        selectVariant="grey"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        formControlStyle={{ width: 'calc(100% - 16px)' }}
                      >
                        {field.options.map((option, idx) => (
                          <AimSelectMenuItem key={idx} value={option}>
                            {option.name}
                          </AimSelectMenuItem>
                        ))}
                      </AimSelect>
                    )}
                    name={`mappedFields[${idx}]`}
                    control={control}
                  />
                  <AimTypography variant="formError">
                    {errors?.mappedFields?.[idx]?.message}
                  </AimTypography>
                </div>
              </div>
            </Grid>
          );
        })}
    </>
  );
};
