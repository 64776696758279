import React, { useEffect, useState } from 'react';

import {
  AimTypography,
  AimSelectForm,
  AimTextFieldForm,
  AimSelectMenuItem,
} from '../../../atoms';
import { theme } from '../../../../theme';
import Grid from '@material-ui/core/Grid';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { constants } from '@aim/common';
import translation from '../translation';
// import { listTerminals } from './../travelTransferGqlHelper';

export const Transfer = ({ formControlStyle, control, intl, isEditable }) => {
  const i18n = translation.TravelTransfer(intl);
  const transportTypes = Object.values(constants.DestinationTravelTypes);
  // const [terminals, setTerminals] = useState();

  // useEffect(() => {
  //   const fetchTerminals = async () => {
  //     const terminalList = await listTerminals();
  //     console.log('terminalList ', terminalList);
  //     setTerminals(terminalList);
  //   };

  //   fetchTerminals();
  // }, []);

  return (
    <>
      <AimTypography variant="text">{i18n.page.transfer.title}</AimTypography>
      <div
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          margin: 10,
        }}
      >
        <AimTypography variant="text">
          {i18n.page.transfer.oneWay}
        </AimTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              type="date"
              name="prefsTransferOneWayDate"
              placeholder="dd/mm/yyyy"
              label={i18n.page.transfer.transferDate}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferOneWayTime"
              type="time"
              placeholder="HH:mm"
              label={i18n.page.transfer.pickUpTime}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimSelectForm
              style={{
                backgroundColor: isEditable
                  ? 'white'
                  : theme.colors.greyScale.backgroundGrey,
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferOneWayFrom"
              label={i18n.page.transfer.from}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            >
              {transportTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferOneWayPickUpLocation"
              label={i18n.page.transfer.pickUpPlace}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimSelectForm
              style={{
                backgroundColor: isEditable
                  ? 'white'
                  : theme.colors.greyScale.backgroundGrey,
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferOneWayTo"
              label={i18n.page.transfer.to}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            >
              {transportTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferOneWayPickUpDestination"
              label={i18n.page.transfer.pickUpDestination}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          margin: 10,
        }}
      >
        <AimTypography variant="text">
          {i18n.page.transfer.return}
        </AimTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              type="date"
              name="prefsTransferReturnDate"
              placeholder="dd/mm/yyyy"
              label={i18n.page.transfer.returnDate}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferReturnTime"
              type="time"
              placeholder="HH:mm"
              label={i18n.page.transfer.pickUpTime}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimSelectForm
              style={{
                backgroundColor: isEditable
                  ? 'white'
                  : theme.colors.greyScale.backgroundGrey,
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferReturnFrom"
              label={i18n.page.transfer.from}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            >
              {transportTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferReturnPickUpLocation"
              label={i18n.page.transfer.pickUpPlace}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimSelectForm
              style={{
                backgroundColor: isEditable
                  ? 'white'
                  : theme.colors.greyScale.backgroundGrey,
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferReturnTo"
              label={i18n.page.transfer.to}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            >
              {transportTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTransferReturnPickUpDestination"
              label={i18n.page.transfer.pickUpDestination}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
