import React from 'react';
import { useAimMediaQuery } from '../hooks/useAimMediaQuery';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles({
  paginationToolbar: {
    paddingLeft: (props) => (props.isMobile ? 0 : 16),
    paddingRight: (props) => (props.isMobile ? 0 : 16),
  },
});

export const AimPagination = ({
  elementsCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
}) => {
  const { isMobile, isMobileOnly } = useAimMediaQuery();
  const classes = useStyles({ isMobile, isMobileOnly });
  const pageUpperLimit = () => {
    return (page + 1) * rowsPerPage > elementsCount
      ? elementsCount
      : (page + 1) * rowsPerPage;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TablePagination
          classes={{ toolbar: classes.paginationToolbar }}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={elementsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      {!isMobile && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <>
            <Typography variant="body2">{`Show from ${
              page * rowsPerPage + 1
            } to ${pageUpperLimit()} of ${elementsCount}`}</Typography>
            <Pagination
              count={Math.ceil(elementsCount / rowsPerPage)}
              page={page + 1}
              onChange={(e, newPage) => handleChangePage(e, newPage - 1)}
              variant="outlined"
              shape="rounded"
            />
          </>
        </div>
      )}
    </div>
  );
};
