import React, { useState, useRef } from 'react';
import format from 'date-fns/format';

import { translation } from './translation';

// import EditIcon from '@material-ui/icons/Edit';
// import ClearIcon from '@material-ui/icons/Clear';
// import AddIcon from '@material-ui/icons/Add';
// import LockIcon from '@material-ui/icons/Lock';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { constants, utilities } from '@aim/common';

import { listParticipantsByEventId } from './tableGqlHelper';
import { AimSnackbar } from '../../atoms/AimSnackbar';
import { AimTablePage } from '../../bundles/AimTablePage/AimTablePage';
import BarChartIcon from '@material-ui/icons/BarChart';

const { formatNumber, decodeDbNumber } = utilities;

export const CancellationsTable = ({
  eventId,
  productId,
  serviceType,
  intl,
  history,
  backUrl,
  ticketUrl,
  summeryOnClick,
}) => {
  const i18n = translation.purchasesPage(intl);
  const loadDataRef = useRef();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const getCancellationsListQuery = /* GraphQL */ `
    query getProductsByServiceIdServiceType(
      $serviceId: String
      $serviceType: String
    ) {
      products: productByServiceIdServiceType(
        serviceId: $serviceId
        serviceType: { eq: $serviceType }
      ) {
        items {
          id
          productId
          clientId
          clientType
          serviceId
          serviceType
          createdAt
          billing {
            id
            invoiceTo
            name
            address
            city
            postalCode
            region
            province
            country
            countryIsoCode
            vatCode
            taxCode
            reference
            email
            pec
            phone
            invoiceToPublicAuthority
            uniqueCode
            ipaCode
            isSplitPayment
            isVatEvent
          }
          payment {
            id
            paymentId
            paymentType
            paymentIdMonetaOnLine
            paymentIdIngenico
            paymentIdGpWebpay
            paymentError
            amount
            paymentStatus
            createdAt
            updatedAt
            isDeleted
            BillingInformation {
              id
              invoiceTo
              name
              address
              city
              postalCode
              region
              province
              country
              countryIsoCode
              vatCode
              taxCode
              reference
              email
              pec
              phone
              invoiceToPublicAuthority
              uniqueCode
              ipaCode
              isSplitPayment
              isVatEvent
            }
          }
        }
      }
    }
  `;

  const getPurchasesByProductIdList = /* GraphQL */ `
    query getProductByProductId($productId: String) {
      products: productByProductId(productId: $productId) {
        items {
          id
          productId
          clientId
          clientType
          serviceId
          serviceType
          createdAt
          billing {
            id
            invoiceTo
            name
            address
            city
            postalCode
            region
            province
            country
            countryIsoCode
            vatCode
            taxCode
            reference
            email
            pec
            phone
            invoiceToPublicAuthority
            uniqueCode
            ipaCode
            isSplitPayment
            isVatEvent
          }
          payment {
            id
            paymentId
            paymentType
            paymentIdMonetaOnLine
            paymentIdIngenico
            paymentIdGpWebpay
            paymentError
            amount
            paymentStatus
            createdAt
            updatedAt
            isDeleted
            BillingInformation {
              id
              invoiceTo
              name
              address
              city
              postalCode
              region
              province
              country
              countryIsoCode
              vatCode
              taxCode
              reference
              email
              pec
              phone
              invoiceToPublicAuthority
              uniqueCode
              ipaCode
              isSplitPayment
              isVatEvent
            }
          }
        }
      }
    }
  `;

  const model = (loadData) => {
    loadDataRef.current = loadData;
    return {
      header: {
        title: () => i18n.aimTablePage.header.title,
        backButton: {
          onClick: () => history.push(backUrl),
        },
        action: {
          summary: {
            tooltip: 'summary',
            variant: 'violet',
            onClick: () => {
              summeryOnClick();
            },
            icon: <BarChartIcon />,
          },
        },
      },
      dataLoader: {
        query: productId
          ? getPurchasesByProductIdList
          : getCancellationsListQuery,
        variables: productId
          ? { productId }
          : {
              serviceId: eventId,
              serviceType,
            },
        transform: async (data) => {
          const participants = await listParticipantsByEventId(eventId);
          // const reservations = await listHotelReservationsByEventId(eventId);

          const validCancellations = data?.products?.items.filter(
            (product) => !!product?.payment?.isDeleted
          );

          const cancellations = validCancellations?.map((item) => {
            const billing = item?.payment?.BillingInformation || item?.billing;
            const payment = item?.payment;
            const participant = participants.find(
              (p) => p.id === item.clientId
            );
            const countryJson = billing?.countryIsoCode
              ? constants.countriesIsoCodesJson.find(
                  (cc) => cc.alpha2 === billing?.countryIsoCode
                )
              : {};

            const creationDate = payment?.createdAt
              ? format(new Date(payment?.createdAt), 'dd/MM/yyyy hh:mm:ss')
              : '-';
            const lastUpdateDate = payment?.updatedAt
              ? format(new Date(payment?.updatedAt), 'dd/MM/yyyy hh:mm:ss')
              : '-';

            return {
              participantName: `${participant?.givenName} ${participant?.familyName}`,
              participantEmail: participant?.email,
              participant,
              id: item?.id,
              creationDate,
              lastUpdateDate,
              billingEmail: billing?.email,
              pec: billing?.pec,
              invoiceTo:
                i18n.aimTablePage.table.invoiceToTypes[billing?.invoiceTo],
              billingName: billing?.name,
              address: billing?.address,
              postalCode: billing?.postalCode,
              city: billing?.city,
              province: billing?.province,
              region: billing?.region,
              country: billing?.country,
              countryIsoCode: billing?.countryIsoCode,
              countryIsoAlpha3: countryJson?.alpha3 || '',
              isItaly: billing?.countryIsoCode === 'IT' ? 'Yes' : 'no',
              vatCodeOr99Cee: countryJson?.isCee
                ? billing?.vatCode || '99999999999'
                : '',
              isNoCee99: !countryJson?.isCee ? '99999999999' : '',
              vatCode: billing?.vatCode,
              taxCode: billing?.taxCode,
              phone: billing?.phone,
              invoiceToPublicAuthority: billing?.invoiceToPublicAuthority
                ? i18n.aimTablePage.table.yes
                : i18n.aimTablePage.table.no,
              uniqueCode: billing?.uniqueCode,
              ipaCode: billing?.ipaCode,
              isSplitPayment: billing?.isSplitPayment
                ? i18n.aimTablePage.table.yes
                : i18n.aimTablePage.table.no,
              isVatEvent: billing?.isVatEvent
                ? i18n.aimTablePage.table.yes
                : i18n.aimTablePage.table.no,
              amount: formatNumber(decodeDbNumber(payment?.amount)),
              vatRateCode: '22',
              netAmount: payment?.amount
                ? formatNumber((decodeDbNumber(payment?.amount) / 122) * 100)
                : null,
              vat: payment?.amount
                ? formatNumber((decodeDbNumber(payment?.amount) / 122) * 22)
                : null,
              amountValue: payment?.amount,
              paymentId: payment?.id,
              paymentNanoId: payment?.paymentId,
              paymentType:
                i18n.aimTablePage.table.paymentTypes[payment?.paymentType]
                  ?.label,
              paymentStatusValue: payment?.paymentStatus,
              paymentStatus:
                payment?.paymentType === constants.PaymentTypes.CreditCard ||
                payment?.paymentType === constants.PaymentTypes.Free
                  ? constants.PaymentStatuses.DONE.label(intl)
                  : payment?.paymentStatus !==
                    constants.PaymentStatuses.DONE.key
                  ? constants.PaymentStatuses.PENDING.label(intl)
                  : constants.PaymentStatuses.DONE.label(intl),
            };
          });
          return cancellations?.filter((p) => p.participant) || [];
        },
      },
      table: {
        rowAction: {
          view: {
            icon: <VisibilityIcon />,
            disabledWhen: (row) => row?.locked,
            variant: 'lightBlueFill',
            onClick: (row) =>
              history.push(`${ticketUrl}/${row?.id}/ticket-info`),
          },
        },
        columnsTemplate: [
          {
            id: 'paymentNanoId',
          },
          {
            id: 'participantName',
          },
          {
            id: 'participantEmail',
          },
          { id: 'creationDate' },
          { id: 'lastUpdateDate' },
          {
            id: 'invoiceTo',
          },
          {
            id: 'billingName',
          },
          {
            id: 'address',
          },
          {
            id: 'postalCode',
          },
          {
            id: 'city',
          },
          { id: 'province' },
          { id: 'region' },
          {
            id: 'country',
          },
          {
            id: 'countryIsoCode',
          },
          {
            id: 'countryIsoAlpha3',
          },
          {
            id: 'isItaly',
          },
          {
            id: 'vatCodeOr99Cee',
          },
          {
            id: 'isNoCee99',
          },
          {
            id: 'billingEmail',
          },
          { id: 'pec' },
          {
            id: 'vatCode',
          },
          {
            id: 'taxCode',
          },
          {
            id: 'phone',
          },
          {
            id: 'invoiceToPublicAuthority',
          },
          {
            id: 'uniqueCode',
          },
          {
            id: 'ipaCode',
          },
          {
            id: 'isSplitPayment',
          },
          {
            id: 'isVatEvent',
          },
          {
            id: 'vatRateCode',
          },
          { id: 'netAmount' },
          { id: 'vat' },
          {
            id: 'amount',
          },
          {
            id: 'paymentType',
          },
          {
            id: 'paymentStatus',
          },
        ],
        filters: [
          {
            column: 'paymentNanoId',
            type: 'string',
          },
          {
            column: 'participantName',
            type: 'string',
          },
          {
            column: 'participantEmail',
            type: 'string',
          },
          {
            column: 'invoiceTo',
            type: 'select',
          },
          {
            column: 'billingName',
            type: 'string',
          },
          {
            column: 'paymentType',
            type: 'select',
          },
          {
            column: 'paymentStatus',
            type: 'select',
          },
        ],
      },
      snackbar: {
        delete: {},
        editPayment: {},
      },
    };
  };

  return (
    <>
      <AimTablePage
        model={model}
        translation={i18n.aimTablePage}
        // pageSize={[10, 25, 50]}
        intl={intl}
      />
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};
