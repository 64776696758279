import { aws, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const updatePaymentMutation = /* GraphQL */ `
  mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      id
    }
  }
`;

export const updatePayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: updatePaymentMutation,
      variables: { input },
    })
      .then(resolve)
      .catch((e) => {
        console.error('update-payment', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });
