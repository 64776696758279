import React, { useState, cloneElement, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { AimCheckbox } from '../atoms/AimCheckbox';
import { theme } from '../../theme';
import './tableHeader.css';
import { AimTableCell, AimTableRow } from './AimTable';
import { appState } from '@aim/common';

function descendingComparator(a, b, orderBy) {
  const notNullBOrderBy = b[orderBy] ? b[orderBy] : '';
  const notNullAOrderBy = a[orderBy] ? a[orderBy] : '';

  if (notNullBOrderBy < notNullAOrderBy) {
    return -1;
  }
  if (notNullBOrderBy > notNullAOrderBy) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const VisuallyHidden = styled('span')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
});

function EnhancedTableHead({
  onCheckAllClick,
  order,
  orderBy,
  numChecked,
  rowCount,
  onRequestSort,
  headCells,
  notCheckable,
  sortArrowDown,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <AimTableRow>
        {!notCheckable && (
          <AimTableCell style={{ border: 0 }} padding="checkbox">
            <AimCheckbox
              indeterminate={numChecked > 0 && numChecked < rowCount}
              checked={rowCount > 0 && numChecked === rowCount}
              onChange={onCheckAllClick}
            />
          </AimTableCell>
        )}
        {headCells.map((headCell) => (
          <AimTableCell
            style={headCell.style || { border: 0 }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {!headCell.hideLabel ? (
              <>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  hideSortIcon={sortArrowDown}
                  style={headCell.tableSortLabelStyle}
                >
                  {headCell.label}
                  {!sortArrowDown && orderBy === headCell.id ? (
                    <VisuallyHidden>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </VisuallyHidden>
                  ) : null}
                </TableSortLabel>
              </>
            ) : null}
          </AimTableCell>
        ))}
      </AimTableRow>
    </TableHead>
  );
}

const CustomToolbar = styled(Toolbar)({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
});

const EnhancedTableToolbar = ({ title, numChecked }) => (
  <CustomToolbar>
    {numChecked > 0 ? (
      <Typography color="inherit" variant="subtitle1" component="div">
        {numChecked} selected
      </Typography>
    ) : (
      <Typography variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
    )}
  </CustomToolbar>
);

export const AimTableNoPaging = ({
  notCheckable,
  headCells,
  rows,
  children: Row,
  tableContainerMaxHeight,
  selected,
  setSelected,
  rowKey,
  hideToolbar = true,
  hideEmptyRow = false,
  sortArrowDown = false,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();
  const [checked, setChecked] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(5);

  useEffect(() => {
    if (rows) setRowsPerPageState(rows?.length || 5);
  }, [rows]);

  const TableRoot = styled('div')({
    flex: 1,
    maxWidth: isMobile
      ? `calc(${appState.mainContainerSize.getValue().width}px - 60px)`
      : `calc(${appState.mainContainerSize.getValue().width}px - 180px)`,
  });

  const CustomTableContainer = styled(TableContainer)({
    maxHeight: tableContainerMaxHeight,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCheckAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[rowKey]);
      setChecked(newSelecteds);
      return;
    }
    setChecked([]);
  };

  const handleSelectedClick = (event, row) => {
    if (selected && selected[rowKey] === row[rowKey]) {
      setSelected(null);
    } else {
      setSelected(row);
    }
    event.stopPropagation();
  };

  const handleCheck = (event, name) => {
    const selectedIndex = checked.indexOf(name);
    let newChecked = [];

    if (selectedIndex === -1) {
      newChecked = newChecked.concat(checked, name);
    } else if (selectedIndex === 0) {
      newChecked = newChecked.concat(checked.slice(1));
    } else if (selectedIndex === checked.length - 1) {
      newChecked = newChecked.concat(checked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newChecked = newChecked.concat(
        checked.slice(0, selectedIndex),
        checked.slice(selectedIndex + 1)
      );
    }

    setChecked(newChecked);
    event.stopPropagation();
  };

  const isChecked = (name) => checked.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPageState -
    Math.min(rowsPerPageState, rows.length - page * rowsPerPageState);

  return (
    <TableRoot>
      {!hideToolbar && <EnhancedTableToolbar numChecked={checked.length} />}
      <CustomTableContainer>
        <Table
          stickyHeader
          style={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          border-bottom="none"
        >
          <EnhancedTableHead
            numChecked={checked.length}
            order={order}
            orderBy={orderBy}
            onCheckAllClick={handleCheckAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            notCheckable={notCheckable}
            sortArrowDown={sortArrowDown}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(
                page * rowsPerPageState,
                page * rowsPerPageState + rowsPerPageState
              )
              .map((row, index) => {
                const rowCloned = cloneElement(Row, {
                  row,
                  rowKey,
                  index,
                  isChecked,
                  handleCheck,
                  handleSelectedClick,
                });
                return (
                  <React.Fragment key={row[rowKey]}>
                    {rowCloned}
                    {!hideEmptyRow && (
                      <TableRow
                        colSpan={headCells.length}
                        style={{
                          background: '#F8F6FA',
                          height: isMobile ? 3 : 7,
                          borderBottom: 'none',
                        }}
                      ></TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            {!hideEmptyRow && emptyRows > 0 && (
              <AimTableRow
                style={{
                  height: 0 * emptyRows,
                  borderBottom: 'none',
                }}
              >
                <AimTableCell
                  colSpan={
                    notCheckable ? headCells.length : headCells.length + 1
                  }
                />
              </AimTableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
    </TableRoot>
  );
};
