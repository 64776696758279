import { useState, useRef } from 'react';

export const useModel = () => {
  const [header, setHeader] = useState();
  const [dataLoader, setDataLoader] = useState();
  const [card, setCard] = useState({});
  const preTableComponentRef = useRef();
  const [table, setTable] = useState({});
  const [dialog, setDialog] = useState();
  const [snackbar, setSnackbar] = useState();
  const [tableState, setTablestate] = useState();

  // vanno splittati i set per ridurre i render ma non avevo tempo ora :)
  const setNewModel = ({
    header: nextHeader,
    dataLoader: nextdataLoader,
    card: nextCard = {},
    preTableComponent: nextPreTableComponent,
    table: nextTable = {},
    dialog: nextDialog,
    snackbar: nextSnackbar,
    tableState: nextTableState,
  }) => {
    setHeader(nextHeader);
    !dataLoader && setDataLoader(nextdataLoader);
    setCard(nextCard);
    preTableComponentRef.current = nextPreTableComponent;
    setTable(nextTable);
    setDialog(nextDialog);
    setSnackbar(nextSnackbar);
    setTablestate(nextTableState);
  };

  return {
    header,
    dataLoader,
    card,
    preTableComponent: preTableComponentRef.current,
    table,
    dialog,
    snackbar,
    tableState,
    setNewModel,
  };
};
