import React from 'react';
import { AimTypography } from '@aim/components';

const ColumnHeaderBase = ({
  items,
  propName,
  headerFormatter,
  columnHeaderStyle,
  alignColumnHeaders,
  totalColumns,
}) => {
  console.log('columnHeaderStyle', columnHeaderStyle);
  const headers = items?.length
    ? items.map((item) => {
        const value = headerFormatter
          ? headerFormatter(item[propName])
          : item[propName];

        return (
          <AimTypography
            key={`header-${item.id}`}
            noWrap
            variant="textBold"
            style={columnHeaderStyle}
            boxStyle={{ textAlign: alignColumnHeaders }}
          >
            {value}
          </AimTypography>
        );
      })
    : [];

  const totalHeaders = totalColumns?.length
    ? totalColumns.map((column) => {
        return (
          <AimTypography
            key={`header-${column.header}`}
            noWrap
            variant="textBold"
            style={columnHeaderStyle}
            boxStyle={{ textAlign: alignColumnHeaders }}
          >
            {column.header}
          </AimTypography>
        );
      })
    : [];
  return [...headers, ...totalHeaders];
};

const ColumnHeader = React.memo(ColumnHeaderBase, () => true);

export default ColumnHeader;
