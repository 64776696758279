import * as React from 'react';
import { useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

const GridToolbarSaveUsernames = React.forwardRef(
  function GridToolbarSaveUsernamesAndVisibleColumns(props, ref) {
    const { tableName, ...other } = props;
    const LOCAL_STORAGE_NAME = `aim-mui-datagrid-${tableName}-ids`;
    const LOCAL_STORAGE_FILTER = `aim-mui-datagrid-${tableName}-filter-model`;
    const [savedUsernames, setSavedUserNames] = React.useState(
      localStorage.getItem(LOCAL_STORAGE_NAME)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME))
        : undefined
    );
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();

    if (rootProps.disableColumnFilter) {
      return null;
    }

    const saveUsernames = () => {
      const currentRows = apiRef.current.getVisibleRowModels();
      const ids = [];
      currentRows.forEach((cR) => {
        ids.push(cR.id);
      });

      if (!savedUsernames) {
        localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(ids));
        setSavedUserNames(ids);
      } else {
        setSavedUserNames();
        localStorage.removeItem(LOCAL_STORAGE_NAME);
        const filters = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTER));
        apiRef.current.setRows(props.rows);
        if (filters) {
          apiRef.current.setFilterModel(filters);
        }
      }
    };

    return (
      <rootProps.components.BaseTooltip
        title={savedUsernames ? 'Unlock Rows' : 'Lock Rows'}
        enterDelay={1000}
        {...other}
        {...rootProps.componentsProps?.baseTooltip}
      >
        <rootProps.components.BaseButton
          ref={ref}
          size="small"
          aria-label="Lock Rows"
          onClick={() => saveUsernames()}
          startIcon={savedUsernames ? <LockIcon /> : <LockOpenOutlinedIcon />}
        >
          {savedUsernames ? 'Unlock Rows' : 'Lock Rows'}
        </rootProps.components.BaseButton>
      </rootProps.components.BaseTooltip>
    );
  }
);

export { GridToolbarSaveUsernames };
