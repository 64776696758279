import React from 'react';
import { AimTypography } from '@aim/components';

const TotalColumnBase = ({ fn, matrix, rowIdx, totalColumnStyle }) => {
  const value = fn({ row: matrix[rowIdx].items, rows: matrix, matrix, rowIdx });
  const style = totalColumnStyle
    ? typeof totalColumnStyle === 'object'
      ? totalColumnStyle
      : totalColumnStyle(value)
    : {};

  return (
    <AimTypography
      key={`total-${rowIdx}`}
      noWrap
      style={style}
      boxStyle={{ textAlign: 'center' }}
    >
      {value}
    </AimTypography>
  );
};

const TotalColumn = React.memo(
  TotalColumnBase,
  (prevProps, nextProps) => prevProps.lastUpdate !== nextProps.lastUpdate
);

export default TotalColumn;
