import { useState, useEffect, useCallback, useRef } from 'react';
import { nanoid } from 'nanoid';

import { appState } from '@aim/common';
import { useStateWithPromise } from '.';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createHandlerSetter = (handlerValue) => {
  const handlerRef = useRef(handlerValue);

  // since useRef accepts an initial-value only, this is needed to make sure
  handlerRef.current = handlerValue;

  const setHandler = useCallback((nextCallback) => {
    handlerRef.current = nextCallback;
  });

  return [handlerRef, setHandler];
};

const useChat = ({
  client,
  userUuid,
  userMetadata,
  presChannel,
  msgChannel,
  channelGroups,
  isOnline,
  onSignal,
  onJoin,
  onLeave,
  onMembershipChanged,
}) => {
  const [isMsgSubscribed, setIsMsgSubscribed] = useState(false);
  const [isPresSubscribed, setIsPresSubscribed] = useState(false);
  const [isStateUserSet, setIsStateUserSet] = useState(false);
  const [messages, setMessages] = useState([]);
  const [occupants, setOccupants] = useState([]);
  const [occupancy, setOccupancy] = useState(0);
  const [channels, setChannels] = useState([]);
  const [isAllMessagesReaded, setIsAllMessagesReaded] = useStateWithPromise(
    false
  );

  // set signal callback
  const [onSignalRef, setOnSignalRef] = createHandlerSetter(onSignal);

  useEffect(() => {
    setOnSignalRef(onSignal);
  }, [onSignal]);

  // presence channel effects
  useEffect(() => {
    if (!channelGroups || !channelGroups.length) return;
    listAllChannelsFromGroups().then((newChannels) => {
      if (JSON.stringify(channels) !== JSON.stringify(newChannels)) {
        setChannels(newChannels);
      }
    });
  }, [channelGroups]);

  useEffect(() => {
    if (!presChannel || !isStateUserSet) return;
    client.hereNow(
      {
        channels: [presChannel],
        includeState: true,
        includeUUIDs: true,
      },
      (status, response) => {
        if (!response) return;
        const nextOccupants = response.channels[presChannel].occupants.filter(
          (o) => o.state.isOnline === true
        );
        setOccupants(nextOccupants);
        setOccupancy(nextOccupants?.length ?? 0);
      }
    );
  }, [presChannel, isStateUserSet]);

  useEffect(() => {
    if (!presChannel) return;
    const actions = {
      join: ({ occupancy }) => setOccupancy(occupancy),
      leave: ({ occupancy }) => setOccupancy(occupancy),
      timeout: ({ occupancy }) => setOccupancy(occupancy),
      interval: ({ occupancy }) => setOccupancy(occupancy),
      'state-change': ({ uuid, state, occupancy }, currOccupants) => {
        const nextOccupants = currOccupants.filter((o) => o.uuid !== uuid);
        if (state.isOnline) {
          nextOccupants.push({ uuid, state });
        }
        setOccupancy(occupancy);
        return nextOccupants;
      },
    };

    const listener = {
      presence: (p) => {
        if (p.action !== 'state-change') {
          actions?.[p?.action]?.(p);
        } else {
          actions?.[p?.action] &&
            setOccupants((currOccupants) =>
              actions[p.action](p, currOccupants)
            );
        }
      },
    };

    client.addListener(listener);
    client.subscribe({
      channels: [presChannel],
      withPresence: true,
    });
    setIsPresSubscribed(true);

    return () => {
      client.setState(
        {
          state: { isOnline: false, user: userMetadata },
          channels: [presChannel],
        },
        (status, response) => {
          if (status.error) {
            console.error(status, response);
            return;
          }
        }
      );

      client.removeListener(listener);
      client.unsubscribe({ channels: [presChannel] });
      setIsPresSubscribed(false);
    };
  }, [presChannel]);

  // message channel effects
  useEffect(() => {
    if (!isMsgSubscribed) return;

    client.fetchMessages(
      { channels: [msgChannel], count: 25 },
      (status, response) => {
        if (status.error) return;
        const nextMessages = response?.channels[msgChannel];
        if (nextMessages?.length < 25) setIsAllMessagesReaded(true);
        setMessages(nextMessages || []);
      }
    );
  }, [msgChannel, isMsgSubscribed]);

  useEffect(() => {
    if (!msgChannel) return;
    showLoader();
    const listener = {
      status: function (statusEvent) {
        if (statusEvent.category === 'PNConnectedCategory') {
          var newState = {
            new: 'state',
          };
          client.setState(
            {
              state: newState,
            },
            function () {
              console.log('Chat Subscribed');
            }
          );
        }
      },
      message: (m) => {
        if (m) {
          setMessages((currMsgs) => {
            return [...currMsgs, m];
          });
        }
      },
      signal: (s) => {
        s && onSignalRef?.current && onSignalRef?.current(s);
      },
    };

    const timetoken = pubNubTimeToken();
    client.addListener(listener);
    client.subscribe({
      channels: [msgChannel],
      timetoken: timetoken,
    });
    setMemberships([msgChannel], userUuid)
      .then(() => {
        checkUnreadedMessages();
        hideLoader();
        setIsAllMessagesReaded(false).then(() => {
          setIsMsgSubscribed(true);
        });
      })
      .catch(() => hideLoader());

    // action when i join
    onJoin?.();

    return () => {
      // action when i leave
      onLeave?.();

      client.removeListener(listener);
      client.unsubscribe({ channels: [msgChannel] });
      setIsMsgSubscribed(false);
      setMemberships([msgChannel], userUuid).then(() => {
        checkUnreadedMessages();
      });
    };
  }, [msgChannel]);

  useEffect(() => {
    if (!userUuid || !onMembershipChanged) return;

    const listener = {
      objects: (data) => onMembershipChanged?.(),
    };

    client.addListener(listener);
    client.subscribe({
      channels: [userUuid],
    });

    return () => {
      client.removeListener(listener);
      client.unsubscribe({ channels: [userUuid] });
    };
  }, [userUuid]);

  useEffect(() => {
    if (!presChannel || !isPresSubscribed) return;
    client.setState(
      {
        state: { isOnline: isOnline, user: userMetadata },
        channels: [presChannel],
      },
      (status, response) => {
        if (status.error) {
          console.error(status, response);
          return;
        }
        setIsStateUserSet(true);
      }
    );
  }, [presChannel, isOnline, userMetadata, isPresSubscribed]);

  const listAllChannelsFromGroups = async () => {
    const channels = await Promise.all([
      ...channelGroups.map(async (cg) => {
        const groupChannels = await listGroupChannels(cg);
        return groupChannels;
      }),
    ]);
    return channels.flat();
  };

  const pubNubTimeToken = () => {
    const timetoken = Math.floor(new Date() * 10000);
    return timetoken;
  };

  const publish = async (text, callback) => {
    console.log('pub');
    const publish = await client.publish(
      {
        channel: [msgChannel],
        message: {
          id: nanoid(),
          author: userUuid,
          content: text,
        },
      },
      async (status, response) => {
        callback?.({ status, response });
      }
    );
    console.log('publish', publish);
    await setMemberships([msgChannel], userUuid);
  };

  const publishSignal = (text, callback) => {
    client.signal(
      {
        channel: [msgChannel],
        message: text,
      },
      (status, response) => {
        callback?.({ status, response });
      }
    );
  };

  const listGroupChannels = async (groupName, withMetadata = false) => {
    let outChannels = [];
    const response = await client.channelGroups.listChannels({
      channelGroup: groupName,
    });
    if (withMetadata) {
      outChannels = await Promise.all([
        ...response.channels.map(async (c) => {
          try {
            const { data } = await client.objects.getChannelMetadata({
              channel: c,
            });
            return data;
          } catch (err) {
            console.error(err);
            return { id: c };
          }
        }),
      ]);
    } else {
      outChannels = response.channels.map((c) => ({ id: c }));
    }
    return outChannels;
  };

  const fetchOlderMessages = () => {
    client.fetchMessages(
      { channels: [msgChannel], count: 25, start: messages[0].timetoken },
      (status, response) => {
        if (status.error) return;
        const nextMessages = response?.channels[msgChannel];
        if (nextMessages) {
          if (nextMessages.length < 25) setIsAllMessagesReaded(true);
          setMessages([...nextMessages, ...messages]);
        }
      }
    );
  };

  const setMemberships = async (channels, innerUserUuid) => {
    const timetoken = pubNubTimeToken();
    const memberships = await client.objects.setMemberships({
      uuid: innerUserUuid,
      channels: channels.map((c) => ({
        id: c,
        custom: { lastReadTimetoken: timetoken },
      })),
    });
    return memberships;
  };

  const setMembershipsIfMissing = async (channels, innerUserUuid) => {
    const resp = await client.objects.getMemberships({
      uuid: userUuid,
      channels: channels,
      include: {
        customFields: true,
      },
    });
    const missingMemberships = channels.filter(
      (c) => !resp?.data?.map((m) => m.channel.id).includes(c)
    );
    if (missingMemberships.length) {
      await setMemberships(missingMemberships, innerUserUuid);
    }
  };

  const addChannelsToGroup = async (groupName, channels, innerUserUuid) => {
    const status = await client.channelGroups.addChannels({
      channels: channels,
      channelGroup: groupName,
    });
    if (status.error) {
      console.error('addChannels status: ', status);
    }
    await setMemberships(channels, innerUserUuid);
  };

  const checkUnreadedMessages = async () => {
    try {
      if (!userUuid) return;
      const resp = await client.objects.getMemberships({
        uuid: userUuid,
        channels: channels,
        include: {
          customFields: true,
        },
      });
      if (resp.data.length) {
        const response = await client.messageCounts({
          channels: resp.data.map((c) => c.channel.id),
          channelTimetokens: resp.data.map((c) => c.custom?.lastReadTimetoken),
        });
        appState.chat.unreadedMessages.next([
          ...Object.entries(response.channels).map(([id, newMessages]) => ({
            id,
            newMessages,
          })),
        ]);
      }
    } catch (err) {
      console.error('error', err);
    }
  };

  const setChannelMetadata = async (channel, data) => {
    const respt = await client.objects.setChannelMetadata({
      channel,
      data,
    });
    return respt;
  };

  return {
    messages,
    occupancy,
    occupants,
    isAllMessagesReaded,
    isMsgSubscribed,
    publish,
    publishSignal,
    addChannelsToGroup,
    listGroupChannels,
    checkUnreadedMessages,
    setChannelMetadata,
    setMemberships,
    setMembershipsIfMissing,
    fetchOlderMessages,
  };
};

export { useChat };
