import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventDetailsByClustersQuery = /* GraphQL */ `
  query GetEventDetais($id: ID!, $filter: ModelProfileFilterInput) {
    getEvent(id: $id) {
      fee {
        feeDateRanges {
          items {
            id
            start
            end
            label
          }
        }
      }
      participationTypologies {
        items {
          id
          name
        }
      }
      preTitles {
        items {
          id
          key
          title
        }
      }
      profiles(filter: $filter) {
        items {
          id
          name
          cluster
          createdBy
          isPublic
          category {
            id
            name
          }
          participations(filter: { isDeleted: { ne: true } }) {
            items {
              id
            }
          }
          profileFeeBrackets {
            items {
              id
              priceOnAir
              priceOnSite
              profileFeeConfiguration {
                id
                isActive
              }
              feeBracket {
                id
                feeDateRange {
                  id
                }
                feeBracketModel {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getEventDetailsByClusters = (eventId, clusters) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getEventDetailsByClustersQuery,
      variables: {
        id: eventId,
        filter: {
          isDeleted: { ne: true },
          or: clusters.map((c) => ({ cluster: { eq: c } })),
        },
      },
    })
      .then((response) => {
        resolve(response.data.getEvent);
      })
      .catch((e) => {
        console.error('get-event-details', e);
        reject();
      });
  });
