import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import DoneIcon from '@material-ui/icons/Done';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import { styled } from './../styled';
import { AimIconAndTextButton } from './AimIconAndTextButton';
import { appState } from '@aim/common';

const StyledAgreeButton = styled(AimIconAndTextButton)({
  justifyContent: 'center',
});

const StyledCancelButton = styled(AimIconAndTextButton)({
  justifyContent: 'center',
});

const StyledDialogTitle = styled(DialogTitle)({
  padding: 0,
});

const StyledIconButton = styled(IconButton)({
  padding: 5,
});

const StyledDialogActions = styled(DialogActions)({
  padding: 0,
});

const StyledDialogContent = styled(DialogContent)({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 20,
  paddingBottom: 20,
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#alert-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const getAgreeIcon = (variant) => {
  if (variant === 'greenFill') {
    return <DoneIcon />;
  } else {
    return <CloseIcon />;
  }
};

export const AimDialog = ({
  open,
  onClose,
  title,
  children,
  disagreeText = 'cancel',
  onDisagree,
  agreeText = 'apply',
  agreeVariant = 'greenFill',
  onAgree,
  onAgreeDisabled,
  hideAgreeButton,
  hideCancelButton,
  disableOnClose,
  disableCloseOnDisagree = false,
  customActions,
  hideDivider = false,
  disableEnforceFocus = true,
  disableScrollLock = true,
  ...rest
}) => {
  const handleOverflowBody = () => {
    const bodyStatus = document.body.getAttribute('style');
    if (bodyStatus.includes('overflow: hidden')) {
      document.body.style.overflow = 'visible';
      document.body.style.padding = 0;
    }
  };

  const handleClose = (e) => {
    handleOverflowBody();
    onClose && onClose(e);
  };

  const handleAgree = async (e) => {
    onAgree && (await onAgree());
    if (!disableOnClose) {
      handleClose(e);
    }
  };

  const handleDisagree = async (e) => {
    onDisagree && (await onDisagree());
    !disableCloseOnDisagree && handleClose(e);
  };

  return (
    <Dialog
      disableScrollLock
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperComponent={PaperComponent}
      PaperProps={{
        style: {
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 20,
        },
      }}
      {...rest}
      style={{
        ...rest.style,
        transform: `scale(${appState.mainContainerZoom.getValue()})`,
        transformOrigin: 'top left',
        width: appState.mainContainerSize.getValue().width,
        height: appState.mainContainerSize.getValue().height,
      }}
    >
      <StyledDialogTitle id="alert-dialog-title">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'grabbing',
          }}
        >
          <div>{title}</div>
          <div tabIndex={0} role="button" onClick={handleClose}>
            <StyledIconButton>
              <CloseIcon />
            </StyledIconButton>
          </div>
        </div>
      </StyledDialogTitle>
      {!hideDivider && <Divider />}
      <StyledDialogContent>
        {typeof children === 'string' ? (
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        ) : (
          children
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        {!customActions ? (
          <>
            {hideCancelButton ? null : (
              <StyledCancelButton
                isLowerCase
                onClick={handleDisagree}
                variant="greyFill"
                text={disagreeText}
              >
                <ReplayIcon />
              </StyledCancelButton>
            )}
            {hideAgreeButton ? null : (
              <StyledAgreeButton
                isLowerCase
                onClick={handleAgree}
                variant={agreeVariant}
                disabled={onAgreeDisabled}
                autoFocus
                text={agreeText}
              >
                {getAgreeIcon(agreeVariant)}
              </StyledAgreeButton>
            )}
          </>
        ) : (
          customActions
        )}
      </StyledDialogActions>
    </Dialog>
  );
};

export const AimDialogForm = ({
  children,
  title,
  disagreeText = 'cancel',
  onDisagree,
  onClose,
  agreeText = 'apply',
  agreeVariant = 'greenFill',
  onAgree,
  onAgreeDisabled,
  hideAgreeButton,
  hideCancelButton,
  disableOnClose,
  customActions,
  hideDivider = false,
  handleSubmit,
  ...rest
}) => {
  const handleOverflowBody = () => {
    const bodyStatus = document.body.getAttribute('style');
    if (bodyStatus.includes('overflow: hidden')) {
      document.body.style.overflow = 'visible';
      document.body.style.padding = 0;
    }
  };

  const handleClose = () => {
    handleOverflowBody();
    onClose && onClose();
  };

  const handleAgree = async (data) => {
    const res = await (onAgree && onAgree(data));
    if (!disableOnClose && res !== 'invalid') {
      handleClose();
    }
  };

  const handleDisagree = () => {
    onDisagree && onDisagree();
    handleClose();
  };

  return (
    <Dialog
      disableEnforceFocus
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 20,
        },
      }}
      PaperComponent={PaperComponent}
      {...rest}
    >
      <form onSubmit={handleSubmit(handleAgree)}>
        <StyledDialogTitle id="alert-dialog-title">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'grabbing',
            }}
          >
            <div>{title}</div>
            <div tabIndex={0} role="button" onClick={handleClose}>
              <StyledIconButton>
                <CloseIcon />
              </StyledIconButton>
            </div>
          </div>
        </StyledDialogTitle>
        {!hideDivider && <Divider />}
        <StyledDialogContent>
          {typeof children === 'string' ? (
            <DialogContentText id="alert-dialog-description">
              {children}
            </DialogContentText>
          ) : (
            children
          )}
        </StyledDialogContent>
        <StyledDialogActions>
          {!customActions ? (
            <>
              {hideCancelButton ? null : (
                <StyledCancelButton
                  isLowerCase
                  onClick={handleDisagree}
                  variant="greyFill"
                  text={disagreeText}
                >
                  <ReplayIcon />
                </StyledCancelButton>
              )}
              {hideAgreeButton ? null : (
                <StyledAgreeButton
                  isLowerCase
                  // onClick={handleAgree}
                  variant={agreeVariant}
                  disabled={onAgreeDisabled}
                  autoFocus
                  text={agreeText}
                  type="submit"
                >
                  {getAgreeIcon(agreeVariant)}
                </StyledAgreeButton>
              )}
            </>
          ) : (
            customActions
          )}
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};
