import React from 'react';
import Grid from '@material-ui/core/Grid';
import { AimTypography } from '@aim/components';

const RowHeaderBase = ({ name, rowIdx, rowHeaderStyle, alignRowHeaders }) => (
  <Grid key={`rowHeader-${rowIdx}`} item xs={2}>
    <AimTypography
      noWrap
      variant="textBold"
      style={rowHeaderStyle}
      boxStyle={{ textAlign: alignRowHeaders }}
    >
      {name}
    </AimTypography>
  </Grid>
);

const RowHeader = React.memo(RowHeaderBase, () => true);

export default RowHeader;
