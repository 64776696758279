import React from 'react';

import {
  AimTypography,
  AimRadioButton,
  AimFormControlLabel,
  AimRadioForm,
  AimRichTextForm,
  AimSelectForm,
  AimSelectMenuItem,
  AimTextFieldForm,
} from '../../../../atoms';

import Grid from '@material-ui/core/Grid';

// import { useDataHelper } from '../../../../../utils/hooks/dataHelper';
// const { parseRichText } = useDataHelper();

export const GeneralInfo = ({
  i18n,
  control,
  formControlStyle,
  // data,
  businessUnitItems,
  errors,
  setValue,
  noteDefaultValue,
}) => {
  const valueType = [
    { value: 'yes', label: i18n.page.generalInfo.yes },
    { value: 'no', label: i18n.page.generalInfo.no },
  ];
  // const [businessUnit, setBusinessUnit] = useState();
  // const generalInfo = data?.participationServices;
  const gridStyle = { marginRight: '80px' };

  // useEffect(() => {
  //   const currentBusinessUnit = businessUnits.find(
  //     (unit) => unit.value === `${data?.participationServices?.eventOrderId}`
  //   );
  //   setBusinessUnit(currentBusinessUnit?.label);
  // }, [businessUnits]);

  return (
    <>
      <AimTypography variant="text">
        {i18n.page.generalInfo.title}
      </AimTypography>
      <Grid container>
        <Grid item style={gridStyle}>
          <AimRadioForm
            control={control}
            name="travelIncluded"
            label={i18n.page.generalInfo.travel}
          >
            {valueType.map(({ label, value }) => {
              return (
                <AimFormControlLabel
                  key={value}
                  value={value}
                  control={<AimRadioButton />}
                  label={label}
                  formControlStyle={formControlStyle}
                />
              );
            })}
          </AimRadioForm>
        </Grid>
        <Grid item style={gridStyle}>
          <AimRadioForm
            control={control}
            name="personIncluded"
            label={i18n.page.generalInfo.accompanying}
          >
            {valueType.map(({ label, value }) => (
              <AimFormControlLabel
                key={value}
                value={value}
                control={<AimRadioButton />}
                label={label}
                formControlStyle={formControlStyle}
                // checked={
                //   (generalInfo?.personIncluded && value === 'yes') ||
                //   (!generalInfo?.personIncluded && value === 'no')
                // }
              />
            ))}
          </AimRadioForm>
        </Grid>
        <Grid item style={gridStyle}>
          <AimRadioForm
            control={control}
            name="transferAIncluded"
            label={i18n.page.generalInfo.transferA}
          >
            {valueType.map(({ label, value }) => (
              <AimFormControlLabel
                key={value}
                value={value}
                control={<AimRadioButton />}
                label={label}
                formControlStyle={formControlStyle}
              />
            ))}
          </AimRadioForm>
        </Grid>
        <Grid item style={gridStyle}>
          <AimRadioForm
            control={control}
            name="transferRIncluded"
            label={i18n.page.generalInfo.transferR}
          >
            {valueType.map(({ label, value }) => (
              <AimFormControlLabel
                key={value}
                value={value}
                control={<AimRadioButton />}
                label={label}
                formControlStyle={formControlStyle}
              />
            ))}
          </AimRadioForm>
        </Grid>
        <Grid item style={gridStyle}>
          <AimSelectForm
            control={control}
            name="businessUnit"
            label={i18n.page.generalInfo.businessUnit}
            formControlStyle={{ minWidth: 320 }}
            onChange={(value) => {
              const found = businessUnitItems.find((b) => b.value === value);
              setValue('orderCode', found.code);
              return value;
            }}
            isRequired
            errors={errors}
          >
            {businessUnitItems.map(({ value, label }) => {
              return (
                <AimSelectMenuItem key={value} value={value}>
                  <AimTypography
                    ellipsis
                    style={{ width: '99%' }}
                    boxStyle={{ maxWidth: '100%' }}
                  >
                    {label}
                  </AimTypography>
                </AimSelectMenuItem>
              );
            })}
          </AimSelectForm>
        </Grid>
        <Grid item style={gridStyle}>
          <AimTextFieldForm
            control={control}
            name="orderCode"
            label={i18n.page.generalInfo.orderCode}
            formControlStyle={formControlStyle}
            defaultValue={''}
            disabled
            isRequired
            errors={errors}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {noteDefaultValue !== undefined && (
            <AimRichTextForm
              control={control}
              label={i18n.page.generalInfo.travelPreferences}
              formControlStyle={{ ...formControlStyle, marginBottom: 40 }}
              name="note"
              defaultValue={noteDefaultValue}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
