export const getEmailRegexError = (email) => {
  const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let found = false;
  if (typeof email === 'string') {
    found = email.match(emailRegex);
  }
  if (!found) return `${email} is not a valid email address`;
  return null;
};

export const getOnlyCharRegexError = (value, field) => {
  const onlyCharRegex = /[a-zA-Z]+/;
  let found = false;
  if (typeof value === 'string') {
    found = value.match(onlyCharRegex);
  }
  if (!found) return `${field} can contain only characters`;
  return null;
};
