const translation = {
  orchideaImprtDialog: (intl) => ({
    title: intl.formatMessage({
      description: 'Orchidea import dialog title',
      defaultMessage: 'Import travels',
    }),
    username: intl.formatMessage({
      description: 'Orchidea import dialog ID colunm',
      defaultMessage: 'ID',
    }),
    ourGivenName: intl.formatMessage({
      description: 'Orchidea import dialog name (orchidea name) column',
      defaultMessage: 'Name (Orchidea Name)',
    }),
    ourFamilyName: intl.formatMessage({
      description: 'Orchidea import dialog surname (orchidea surname) column',
      defaultMessage: 'Surname (Orchidea Surname)',
    }),
    ar: intl.formatMessage({
      description: 'Orchidea import dialog a/r column',
      defaultMessage: 'A/R',
    }),
    departureDate: intl.formatMessage({
      description: 'Orchidea import dialog departure date column',
      defaultMessage: 'Departure date',
    }),
    start: intl.formatMessage({
      description: 'Orchidea import dialog Departure time column',
      defaultMessage: 'Departure time',
    }),
    terminalIn: intl.formatMessage({
      description: 'Orchidea import dialog from column',
      defaultMessage: 'From',
    }),
    arrivalDate: intl.formatMessage({
      description: 'Orchidea import dialog arrival date column',
      defaultMessage: 'Arrival date',
    }),
    end: intl.formatMessage({
      description: 'Orchidea import dialog arrival time column',
      defaultMessage: 'Arrival time',
    }),
    terminalOut: intl.formatMessage({
      description: 'Orchidea import dialog to column',
      defaultMessage: 'To',
    }),
    import: intl.formatMessage({
      description: 'Orchidea import dialog import button text',
      defaultMessage: 'Import',
    }),
    success: intl.formatMessage({
      description: 'travels sync triggered message',
      defaultMessage: 'The selected travels sync triggered!',
    }),
    error: intl.formatMessage({
      description: 'error during travel import',
      defaultMessage: 'An error, occured during import, please try again',
    }),
  }),
};

export default translation;
