import React from 'react';
import { styled } from '../styled';
import { groupBy, sortBy } from 'lodash';

const Blocks = styled('div')(({ blocks }) => {
  return {
    margin: '0 0 24px 0',
    display: 'flex',
    flexDirection: blocks.length > 1 ? 'row' : 'column',
    ...(blocks.length === 3 && { justifyContent: 'space-between' }),

    ...(blocks.length === 2 &&
      blocks.findIndex((block) => block.alignment === 'left') > -1 &&
      blocks.findIndex((block) => block.alignment === 'right') > -1 && {
        justifyContent: 'space-between',
      }),

    ...(blocks.length === 2 &&
      blocks.findIndex((block) => block.alignment === 'center') > -1 &&
      blocks.findIndex((block) => block.alignment === 'right') > -1 && {
        justifyContent: 'right',
      }),

    ...(blocks.length === 2 &&
      blocks.findIndex((block) => block.alignment === 'left') > -1 &&
      blocks.findIndex((block) => block.alignment === 'center') > -1 && {
        justifyContent: 'left',
      }),
  };
});

const Block = styled('div')({
  color: '#000', // '#fff',
  display: 'block',
  padding: '24px 0px',
  textAlign: 'center',
  // borderStyle: 'solid',
  // borderWidth: '1px',
});

const Row = styled('div')({
  // width: '33%',
});
const BlockContainer = styled('div')({
  margin: '0 0 24px 0',
  // width: '33%',
});

const alignmentPosition = {
  left: {
    index: 0,
  },
  center: {
    index: 1,
  },
  right: {
    index: 2,
  },
};

const mappingAlignment = (blocks) =>
  blocks.map((block) => ({
    ...block,
    ...alignmentPosition[block.alignment],
  }));

export const LetterLayoutTemplate = ({
  headerBlocks,
  footerBlocks,
  children,
}) => {
  const nextHeaderBlocks = groupBy(headerBlocks, 'row');
  const nextFooterBlocks = groupBy(footerBlocks, 'row');

  console.log('nextHeaderBlocks ', nextHeaderBlocks);
  console.log('nextFooterBlocks ', nextFooterBlocks);
  return (
    <>
      {Object.entries(nextHeaderBlocks)?.map(([key, values]) => {
        let blocks = mappingAlignment(values);

        blocks = sortBy(blocks, 'index');

        return (
          <Row key={key}>
            <Blocks blocks={values}>
              {blocks.map((block, index) => (
                <BlockContainer
                  key={`${key}${index}`}
                  style={{
                    ...(values?.length === 1 && {
                      alignSelf:
                        block.alignment === 'center'
                          ? 'center'
                          : block.alignment === 'right'
                          ? 'flex-end'
                          : 'flex-start',
                    }),
                    width: `${block.size}%`,
                    paddingRight: index < blocks.length ? 20 : 0,
                  }}
                >
                  <Block>
                    <img
                      src={block.imageUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        objectFit: 'cover',
                      }}
                    />
                    <label>{block?.title}</label>
                  </Block>
                </BlockContainer>
              ))}
            </Blocks>
          </Row>
        );
      })}

      {children}
      {Object.entries(nextFooterBlocks)?.map(([key, values]) => {
        let blocks = mappingAlignment(values);
        blocks = sortBy(blocks, 'index');
        // console.log('blocksSorted ', blocks);
        return (
          <Row key={key}>
            <Blocks blocks={values} style={{ clear: 'both', marginBottom: 0 }}>
              {blocks.map((block, index) => (
                <BlockContainer
                  key={`${key}${index}`}
                  style={{
                    ...(values?.length === 1 && {
                      alignSelf:
                        block.alignment === 'center'
                          ? 'center'
                          : block.alignment === 'right'
                          ? 'flex-end'
                          : 'flex-start',
                    }),
                    width: `${block.size}%`,
                    paddingRight: index < blocks.length ? 20 : 0,
                  }}
                >
                  <Block>
                    <img
                      src={block.imageUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        objectFit: 'cover',
                      }}
                    />
                    <label>{block?.title}</label>
                  </Block>
                </BlockContainer>
              ))}
            </Blocks>
          </Row>
        );
      })}
    </>
  );
};
