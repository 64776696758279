import React, { useState, useEffect, useRef, useMemo } from 'react';

import { format } from 'date-fns';
import { debounce } from 'lodash';

import { AimTypography } from '../atoms/AimTypography';
// import { styled } from '../styled';
import { theme } from '../../theme';
import { utcToZonedTime } from 'date-fns-tz';
import { appState, constants } from '@aim/common';
import { AimDataGrid } from './AimDataGrid/AimDataGrid';
import { useGridApiContext } from '@mui/x-data-grid-pro';
// import Tooltip from '@material-ui/core/Tooltip';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { AimIconButton, AimSnackbar, AimSnackbarSeverity } from '../atoms';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { PaymentDialog } from './aimPurchasesDatagrid/PaymentDialog';
import { updatePayment } from './aimPurchasesDatagrid/gqlHelper';

const formatToFractionDigits = (numberToConvert) => {
  return numberToConvert.toLocaleString('it-IT', {
    minimumFractionDigits: 2,
  });
};

export const AimPurchasesDatagrid = ({
  purchases,
  i18n,
  intl,
  columnsToHide,
  onUpdateVisibleAndCheckedRows,
  isBackoffice,
  reloadData,
}) => {
  const tableRowsRef = useRef({
    checkedRows: [],
    filteredRows: [],
    rawData: {},
    header: [],
  });

  //States
  const [paymentDialog, setPaymentDialog] = useState({
    isOpen: false,
  });
  const [currentTimezone, setCurrentTimezone] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  useEffect(() => {
    return () => {
      debouncedSelectedVisibleRows.cancel();
    };
  }, [debouncedSelectedVisibleRows]);

  useEffect(() => {
    const subscriptionEvent = appState.eventInfo.subscribe((info) => {
      setCurrentTimezone(info.timezone);
    });
    return () => {
      subscriptionEvent.unsubscribe();
    };
  }, []);

  const customDateFormat = (date) =>
    format(utcToZonedTime(new Date(date), currentTimezone), 'dd/MM/yyyy');

  const rows = useMemo(
    () =>
      purchases.reduce((res, { payment, ...rest }) => {
        console.log(
          'payment?.paymentStatus || constants.PaymentStatuses.PENDING.key',
          payment?.paymentStatus || constants.PaymentStatuses.PENDING.key
        );
        const nextPurchase = {
          ...rest,
          paymentTypeLabel: rest.paymentTypeLabel,
          paymentType: payment?.paymentType,
          paymentStatus:
            payment?.paymentStatus || constants.PaymentStatuses.PENDING.key,
          paymentId: payment?.id,
          paidFee: rest.paidFee,
          totalFee: rest.totalFee,
          totalAmount: rest.totalFee,
          netTotal: rest.netTotal,
          sponsorName: rest.sponsor?.name,
          packageName: rest.packageName || '',
          eventId: rest.eventId,
          date: rest.date,
          hierarchy: [rest.purchaseId],
        };
        const nextBuyOperations = rest.buyOperations.items.map(
          (buyOperation) => {
            const nextBuyOperation = {
              // ...buyOperation,
              id: buyOperation.id,
              sponsorName: rest.sponsor?.name,
              packageName: '',
              eventId: rest.eventId,
              date: rest.date,
              hierarchy: [rest.purchaseId, buyOperation.bookingId],
              serviceType: buyOperation.serviceType,
              // itemId: buyOperation.bookingId,
              item: buyOperation.instance,
              stand: buyOperation.stand,
              unitaryCost: buyOperation.parsedUnitaryCost,
              unitaryPrice: buyOperation.parsedUnitaryPrice,
              unitaryVatRate: buyOperation.parsedUnitaryVatRate,
              unitaryVatAmount: buyOperation.parsedUnitaryVatAmount,
              quantity: buyOperation.quantity,
              totalCost: buyOperation.parsedTotalCost,
              totalNetPrice: buyOperation.parsedTotalNetPrice,
              totalVat: buyOperation.parsedTotalVatAmount,
              totalPriceVatIncluded: buyOperation.parsedTotalPriceVatIncluded,
            };
            delete nextBuyOperation.sponsorListPurchase;
            return nextBuyOperation;
          }
        );

        delete nextPurchase.buyOperations;
        delete nextPurchase.sponsor;
        delete nextPurchase.billing;
        // delete nextPurchase.payment;
        return [...res, nextPurchase, ...nextBuyOperations];
      }, []),
    [purchases]
  );

  const renderStatus = (status) => {
    const nextValue = Object.values(constants.PaymentStatuses)
      .find((s) => s.key === status)
      ?.label(intl);
    return (
      <div>
        {status && nextValue && (
          <FiberManualRecordIcon
            style={{
              color:
                status === constants.PaymentStatuses.DONE.key
                  ? '#4CBB17'
                  : '#FFEA00',
              fontSize: '0.8rem',
              marginRight: '10px',
            }}
          />
        )}
        {nextValue}
      </div>
    );
  };

  // datagrid columns
  const columns = [
    // {
    //   field: 'purchaseId',
    //   headerName: i18n.table.columns.purchaseCode,
    //   minWidth: 100,
    // },
    {
      field: 'sponsorName',
      headerName: i18n.table.columns.sponsor,
      minWidth: 200,
    },
    // {
    //   field: 'package',
    //   headerName: i18n.table.columns.package,
    //   width: 100,
    // },
    {
      field: 'date',
      headerName: i18n.table.columns.orderDate,
      width: 100,
      valueFormatter: ({ value }) => (value ? customDateFormat(value) : ''),
    },
    {
      field: 'netTotal',
      headerName: i18n.table.columns.netTotal,
      width: 100,
      valueGetter: ({ row }) => Number(row.netTotal),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    // {
    //   field: 'vatPercentage',
    //   headerName: i18n.table.columns.vatPercentage,
    //   width: 100,
    //   valueGetter: ({ row }) => Number(row.vatPercentage),
    //   valueFormatter: ({ value }) =>
    //     value >= 0 ? formatToFractionDigits(value) : '',
    // },
    {
      field: 'vatAmount',
      headerName: i18n.table.columns.vatAmount,
      width: 100,
      valueGetter: ({ row }) => Number(row.vatAmount),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    {
      field: 'totalAmount',
      width: 120,
      headerName: i18n.table.columns.totalAmount,
      valueGetter: ({ row }) => Number(row.totalAmount),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    {
      field: 'paymentTypeLabel',
      headerName: i18n.table.columns.paymentMethod,
      minWidth: 150,
    },
    {
      field: 'paymentStatus',
      headerName: i18n.table.columns.paymentStatus,
      minWidth: 150,
      renderCell: ({ value }) => {
        return renderStatus(value);
      },
    },
    {
      field: 'serviceType',
      headerName: i18n.table.subColumns.serviceType,
      minWidth: 180,
    },
    // {
    //   field: 'itemId',
    //   headerName: i18n.table.subColumns.itemId,
    //   minWidth: 180,
    // },
    {
      field: 'item',
      headerName: i18n.table.subColumns.item,
      minWidth: 180,
    },
    {
      field: 'stand',
      headerName: i18n.table.subColumns.stand,
      minWidth: 180,
    },
    ...(columnsToHide?.includes('unitaryCost')
      ? []
      : [
          {
            field: 'unitaryCost',
            headerName: i18n.table.subColumns.unitaryCost,
            minWidth: 120,
            valueGetter: ({ row }) => Number(row.unitaryCost),
            valueFormatter: ({ value }) =>
              value >= 0 ? formatToFractionDigits(value) : '',
          },
        ]),
    {
      field: 'unitaryPrice',
      headerName: i18n.table.subColumns.unitaryPrice,
      minWidth: 120,
      valueGetter: ({ row }) => Number(row.unitaryPrice),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    {
      field: 'unitaryVatRate',
      headerName: i18n.table.subColumns.unitaryVatRate,
      minWidth: 80,
      valueGetter: ({ row }) => Number(row.unitaryVatRate),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    {
      field: 'unitaryVatAmount',
      headerName: i18n.table.subColumns.unitaryVatAmount,
      minWidth: 120,
      valueGetter: ({ row }) => Number(row.unitaryVatAmount),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    {
      field: 'quantity',
      headerName: i18n.table.subColumns.quantity,
      minWidth: 80,
    },
    ...(columnsToHide?.includes('totalCost')
      ? []
      : [
          {
            field: 'totalCost',
            headerName: i18n.table.subColumns.totalCost,
            minWidth: 120,
            valueGetter: ({ row }) => Number(row.totalCost),
            valueFormatter: ({ value }) =>
              value >= 0 ? formatToFractionDigits(value) : '',
          },
        ]),
    {
      field: 'totalNetPrice',
      headerName: i18n.table.subColumns.totalNetPrice,
      minWidth: 130,
      valueGetter: ({ row }) => Number(row.totalNetPrice),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    {
      field: 'totalVat',
      headerName: i18n.table.subColumns.totalVat,
      minWidth: 120,
      valueGetter: ({ row }) => Number(row.totalVat),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    {
      field: 'totalPriceVatIncluded',
      headerName: i18n.table.subColumns.totalPriceVatIncluded,
      minWidth: 200,
      valueGetter: ({ row }) => Number(row.totalPriceVatIncluded),
      valueFormatter: ({ value }) =>
        value >= 0 ? formatToFractionDigits(value) : '',
    },
    // {
    //   field: 'paidFee',
    //   headerName: i18n.table.columns.paidFee,
    //   minWidth: 100,
    //   valueFormatter: ({ value }) => utilities.formatNumber(value),

    // {
    //   field: 'totalFee',
    //   headerName: i18n.table.columns.totalFee,
    //   minWidth: 100,
    //   valueFormatter: ({ value }) => utilities.formatNumber(value),
    // },

    ...(isBackoffice
      ? [
          {
            field: 'actions',
            headerName: 'Actions', // non tradotto xk andrebbe nascosto, devo capire come si fa
            type: 'button',
            width: 100,
            hideSortIcons: true,
            // eslint-disable-next-line react/display-name
            renderCell: ({ rowNode, row }) =>
              rowNode.depth === 0 &&
              row.paymentType === constants.PaymentTypes.BankTransfer ? (
                <AimIconButton
                  small
                  variant="violetFill"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPaymentDialog({
                      row,
                      payment: {
                        id: row.paymentId,
                        totalPrice: formatToFractionDigits(row.totalAmount),
                        status: row.paymentStatusValue
                          ? row.paymentStatusValue
                          : row.paymentStatus.toLowerCase(),
                        paymentDate: row.lastUpdateDate,
                      },
                      isOpen: true,
                    });
                  }}
                >
                  <AccountBalanceWalletIcon />
                </AimIconButton>
              ) : null,
          },
        ]
      : []),
  ];

  function CustomGridTreeDataGroupingCell(props) {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();

    const handleClick = (event) => {
      if (rowNode.children?.length < 1) {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    if (props.rowNode.depth === 0) {
      return (
        <span
          style={{ display: 'flex', alignItems: 'end' }}
          onClick={(e) => handleClick(e, props)}
        >
          {rowNode.childrenExpanded ? (
            <ExpandMoreIcon style={{ color: theme.colors.greyScale.grey3 }} />
          ) : (
            <ExpandMoreIcon
              style={{
                color: theme.colors.greyScale.grey3,
                transform: 'rotate(-90deg)',
              }}
            />
          )}
          {props.row.hierarchy} ({rowNode.children?.length})
        </span>
      );
    }
    return <span></span>;
  }

  const renderCell = (params) => <CustomGridTreeDataGroupingCell {...params} />;
  const def = {
    headerName: '',
    minWidth: 400,
    renderCell: (params) => renderCell(params),
  };
  const getTreeDataPath = (row) => row.hierarchy;

  const updateVisibleAndCheckedRows = (data) => {
    const headerObjects = data.columns?.all
      .filter((key) => key !== '__check__' && key !== 'actions')
      .map((key) => data.columns?.lookup[key])
      .filter((x) => !x.hide);

    const header = headerObjects.map((x) => ({
      id: x.field,
      label: x.headerName,
    }));

    // const checkedRows =
    //   data.selection?.map((s) => data.rows.idRowsLookup[s]) || [];

    const filteredRows_old =
      Object.entries(data.filter?.visibleRowsLookup || {})
        ?.filter(([_, isVisible]) => isVisible)
        .map(([key]) => data.rows.idRowsLookup[key]) || [];

    const filteredRows =
      Object.entries(data.filter?.filteredRowsLookup || {})
        ?.filter(([_, isVisible]) => isVisible)
        .map(([key]) => data.rows.idRowsLookup[key]) || [];

    tableRowsRef.current = {
      filteredRows,
      rawData: data,
      header,
    };

    const visibleRowsLookup = data.filter?.visibleRowsLookup;
    const filteredRowsLookup = data.filter?.filteredRowsLookup;

    const countVisibleRowsLookup = Object.values(visibleRowsLookup).filter(
      (x) => x
    );
    const countFilteredRowsLookup = Object.values(filteredRowsLookup).filter(
      (x) => x
    );

    onUpdateVisibleAndCheckedRows(tableRowsRef.current);
  };

  //to prevent too many rerender
  const debouncedSelectedVisibleRows = useRef(
    debounce((data) => {
      updateVisibleAndCheckedRows(data);
    }, 1000)
  ).current;

  const updatePaymentStatus = async (submittedData) => {
    try {
      await updatePayment({
        id: paymentDialog.payment.id,
        paymentStatus: submittedData.status,
      });
      await reloadData();
      setPaymentDialog({
        payment: {},
        isOpen: false,
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.success,
      });
    } catch (err) {
      console.error('💥 ERROR: ', err);
      setPaymentDialog({
        isOpen: false,
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: `${i18n.snackbar.error}: ${err.message}`,
      });
    }
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          height: `calc(${
            appState.mainContainerSize.getValue().height
          }px - 350px)`,
          background: 'white',
        }}
      >
        {rows.length === 0 ? (
          <AimTypography variant="h5">{i18n.table.noData}</AimTypography>
        ) : (
          <AimDataGrid
            rows={rows}
            columns={columns}
            treeData
            onStateChange={(data) => debouncedSelectedVisibleRows(data)}
            getTreeDataPath={getTreeDataPath}
            pinnedColumns={{ left: [], right: ['actions'] }}
          />
        )}
      </div>
      {paymentDialog?.payment && (
        <PaymentDialog
          intl={intl}
          isOpen={paymentDialog.isOpen}
          dialogState={paymentDialog}
          handleConfirm={updatePaymentStatus}
          onClose={() => setPaymentDialog({ isOpen: false })}
          onDisagree={() => setPaymentDialog({ isOpen: false })}
        />
      )}
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};
