import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { AimIconButton } from '../atoms/AimIconButton';
import { AimTypography } from '../atoms/AimTypography';
import { AimTextField } from '../atoms/AimTextField';

import { styled } from '../styled';
import { theme } from '../../theme';

const StyledIconButton = styled(AimIconButton)({
  cursor: 'auto',
  color: theme.colors.greyScale.black,
});

const StyledContainer = styled('div')(({ inline }) => ({
  display: inline ? 'inline' : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const AimNumericInput = ({
  plusIconVariant = 'yellowFill',
  minusIconVariant = 'yellowFill',
  value,
  onClickPlus,
  onClickMinus,
  onChange,
  disabledPlus,
  disabledMinus,
  editableInput = false,
  inline = false,
  disabled,
  ...rest
}) => {
  return (
    <StyledContainer inline={inline}>
      <AimIconButton
        variant={minusIconVariant}
        small="true"
        onClick={() => (onClickMinus && onClickMinus()) || onChange(value - 1)}
        disabled={disabledMinus || disabled}
      >
        <RemoveIcon />
      </AimIconButton>
      {editableInput ? (
        <AimTextField
          formControlStyle={{ minWidth: 'initial', flex: 0, margin: 4 }}
          displayLabel={false}
          fieldStyle={{ height: 30, width: 30 }}
          hidetypeappearance={true}
          disabled={disabled}
          inputPropsStyle={{
            margin: 0,
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: 'center',
            background: theme.colors.greyScale.grey2,
            borderRadius: 4,
          }}
          customInputProps={{ min: 0 }}
          type={'number'}
          value={value}
          onChange={(e) => {
            onChange && onChange(parseInt(e.target.value));
          }}
          {...rest}
        />
      ) : (
        <StyledIconButton variant="greyFill" small="true">
          <AimTypography>{value}</AimTypography>
        </StyledIconButton>
      )}
      <AimIconButton
        variant={plusIconVariant}
        small="true"
        onClick={() => (onClickPlus && onClickPlus()) || onChange(value + 1)}
        disabled={disabledPlus || disabled}
      >
        <AddIcon />
      </AimIconButton>
    </StyledContainer>
  );
};
