import { Font, StyleSheet } from '@react-pdf/renderer';
import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
import RobotoBoldItalic from '../../../assets/fonts/Roboto-BoldItalic.ttf';
import RobotoRegularItalic from '../../../assets/fonts/Roboto-Italic.ttf';
import RobotoRegular from '../../../assets/fonts/Roboto.ttf';

// Font.register({
//   family: 'Manrope',
//   format: 'truetype',

//   src: manRope,
//   fontStyle: 'normal',
//   fontWeight: 'normal',
//   //   fonts: [],
// }),
// Font.register({
//   family: 'Manrope',
//   format: 'truetype',
//   src: manRope,
//   fontStyle: 'normal',
//   fontWeight: 'bold',
//   //   fonts: [],
// }),

Font.register({
  family: 'Roboto',
  fonts: [
    {
      fontStyle: 'italic',
      fontWeight: 400,
      src: RobotoRegularItalic,
    },
    {
      fontStyle: 'italic',
      fontWeight: 700,
      src: RobotoBoldItalic,
    },
    {
      fontStyle: 'normal',
      fontWeight: 400,
      src: RobotoRegular,
    },
    {
      fontStyle: 'normal',
      fontWeight: 700,
      src: RobotoBold,
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const globalStyles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    // flexDirection: 'column',
    // display: 'flex',
    // width: '21cm',
    padding: 30,
  },
});

export { globalStyles };
