import { nanoid } from 'nanoid';
import isEqual from 'date-fns/isEqual';

const sort = (list) => {
  const firstElementPropType = typeof list[0];
  if (typeof list[0].getMonth === 'function') {
    // is date
    list.sort((a, b) => a - b);
  } else if (
    firstElementPropType === 'number' ||
    firstElementPropType === 'string'
  ) {
    // is number or string
    list.sort((a, b) => (a > b ? 1 : -1));
  }
  return list;
};

const removeDuplicates = (list) => {
  const firstElementPropType = typeof list[0];
  if (typeof list[0].getMonth === 'function') {
    // is date
    const aux = [...new Set(list.map((date) => date.toString()))];
    return aux.map((i) => new Date(i));
  } else if (
    firstElementPropType === 'number' ||
    firstElementPropType === 'string'
  ) {
    // is number or string
    return [...new Set(list)];
  }
  return list;
};

const checkEquality = (a, b) => {
  const firstElementPropType = typeof a;
  if (typeof a.getMonth === 'function') {
    // is date
    return isEqual(a, b);
  } else if (
    firstElementPropType === 'number' ||
    firstElementPropType === 'string'
  ) {
    // is number or string
    return a === b;
  }
  return null;
};

const buildMatrix = ({ dataArray, sortRowsBy }) =>
  dataArray.reduce((res, curr) => {
    const key = curr[sortRowsBy];

    const collection = res.get(key);
    if (collection) {
      res.set(key, [...collection, curr]);
      return res;
    }
    res.set(key, [curr]);
    return res;
  }, new Map());

const sortMatrixRows = ({ map, sortRowsBy }) => {
  const keys = Array.from(map.keys());
  const sortedKeys = sort(keys, sortRowsBy);

  const matrixRowSorted = new Map();
  sortedKeys.forEach((k) => {
    const currentRow = map.get(k);
    matrixRowSorted.set(k, currentRow);
  });

  return matrixRowSorted;
};

const sortMatrix = ({ map, sortColumnsBy }) => {
  const keys = Array.from(map.keys());
  return keys.map((k) => sort(map.get(k), sortColumnsBy));
};

const normalizeMatrix = ({ map, sortRowsBy, sortColumnsBy }) => {
  const keys = Array.from(map.keys());

  const allColumnHeaders = keys.reduce((res, currK) => {
    const collection = map.get(currK);
    const rowColumnHeaders = collection.map((item) => item[sortColumnsBy]);

    return [...res, ...rowColumnHeaders];
  }, []);

  // const uniqColumnHeaders2 = [...new Set(allColumnHeaders)];
  const uniqColumnHeaders = removeDuplicates(allColumnHeaders);

  // console.log({ uniqColumnHeaders, uniqColumnHeaders2 });

  const sortedUniqColumnHeaders = sort(uniqColumnHeaders);

  const result = new Map();
  keys.forEach((k) => {
    const collection = map.get(k);
    const nextCollection = [];
    sortedUniqColumnHeaders.forEach((colHeader) => {
      const currentItem = collection.find((item) =>
        checkEquality(item[sortColumnsBy], colHeader)
      );
      const nextItem = currentItem ?? {
        id: nanoid(),
        [sortColumnsBy]: colHeader,
        [sortRowsBy]: k,
        isDummy: true,
      };
      nextCollection.push(nextItem);
    });
    result.set(k, nextCollection);
  });
  return result;
};

export const arrayToMatrix = ({ data, sortRowsBy, sortColumnsBy, isDebug }) => {
  // make a matrix with row sorted
  const matrixUnsorted = buildMatrix({ dataArray: data, sortRowsBy });
  isDebug && console.log({ matrixUnsorted });

  const matrixRowSorted = sortMatrixRows({ map: matrixUnsorted, sortRowsBy });
  isDebug && console.log({ matrixRowSorted });

  // fill gaps if item is missing in a row
  const matrixNormalized = normalizeMatrix({
    map: matrixRowSorted,
    sortRowsBy,
    sortColumnsBy,
  });
  isDebug && console.log({ matrixNormalized });

  // sort every row of the matrix
  const matrix = sortMatrix({ map: matrixNormalized, sortColumnsBy });
  isDebug && console.log({ matrix });

  const matrixReactHookForm = matrix.reduce(
    (res, curr) => [...res, { items: curr }],
    []
  );
  isDebug && console.log({ matrixReactHookForm });

  return matrixReactHookForm;
};

export const matrixToArray = (values) =>
  values.data.reduce((res, curr) => {
    const els = curr
      .filter((i) => !!i)
      .reduce((res, curr) => {
        const k = Object.keys(curr)[0];
        return { id: k, value: curr[k] };
      }, null);
    return [...res, els];
  }, []);
