export default {
  paymentDialog: (intl) => ({
    title: intl.formatMessage({
      description: 'sponsor update payment status dialog title',
      defaultMessage: 'Update payment status',
    }),
    totalPrice: intl.formatMessage({
      description: 'sponsor update payment status dialog total price label',
      defaultMessage: 'Total amount(€)',
    }),
    status: intl.formatMessage({
      description: 'sponsor update payment status dialog status label',
      defaultMessage: 'Status',
    }),
    buttons: {
      confirm: intl.formatMessage({
        description: 'sponsor update payment status dialog confirm button text',
        defaultMessage: 'Confirm',
      }),
      cancel: intl.formatMessage({
        description: 'sponsor update payment status dialog cancel button text',
        defaultMessage: 'Cancel',
      }),
    },
  }),
};
