import React from 'react';

const grabStyleWidth = (style) => {
  if (style.width) {
    return { width: style.width };
  } else if (style.minWidth) {
    return { minWidth: style.minWidth };
  }
};

const CellDummy = ({ key, item, children, dummyCellStyle }) => {
  let style = {};
  if (typeof children.props.style === 'object') {
    style = grabStyleWidth(children.props.style);
  } else {
    const processedStyle = children.props.style(item);
    style = grabStyleWidth(processedStyle);
  }

  return <div key={key} style={{ ...style, ...dummyCellStyle }} />;
};

const drawChildren = (adornment, item) => {
  if (!adornment.props.children) return;

  return Array.isArray(adornment.props.children)
    ? adornment.props.children.map((child) =>
        React.cloneElement(child, child.props, child.props.children(item))
      )
    : React.cloneElement(
        adornment,
        adornment.props,
        adornment.props.children(item)
      );
};

const CellValue = ({
  item,
  rowIdx,
  itemIdx,
  control,
  errors,
  // onChange,
  children,
  renderProp,
}) => [
  React.cloneElement(
    children,
    {
      key: `cellValue-${item.id}`,
      style: { display: 'none' },
      control,
      errors,
      defaultValue: item.id,
      name: `data[${rowIdx}].items[${itemIdx}].id`,
    },
    children.props.children ? children.props.children(item) : null //for AimTypography
  ),
  React.cloneElement(
    children,
    {
      key: `cellValueWithAdornment-${item.id}`,
      style:
        typeof children.props.style === 'object'
          ? children.props.style
          : children.props.style(item),
      control,
      errors,
      defaultValue: item[renderProp],
      // onChange: onChange,
      name: `data[${rowIdx}].items[${itemIdx}][${renderProp}]`,
      id: item.id ? item.id : null,
      endAdornment: children.props.endAdornment // for AimTinputForm
        ? drawChildren(children.props.endAdornment, item)
        : null,
      startAdornment: children.props.startAdornment // for AimTinputForm
        ? drawChildren(children.props.startAdornment, item)
        : null,
    },
    children.props.children ? children.props.children(item) : null //for AimTypography
  ),
];

const CellBase = ({
  item,
  itemIdx,
  rowIdx,
  dummyCellStyle,
  children,
  control,
  errors,
  // handleCellUpdate,
  renderProp,
}) =>
  item.isDummy ? (
    <div>
      <CellDummy
        key={`cellDummy-${item.id}`}
        item={item}
        dummyCellStyle={dummyCellStyle}
      >
        {children}
      </CellDummy>
    </div>
  ) : (
    <div>
      <CellValue
        key={`cellValue-${item.id}`}
        item={item}
        rowIdx={rowIdx}
        itemIdx={itemIdx}
        control={control}
        errors={errors}
        // onChange={handleCellUpdate}
        renderProp={renderProp}
      >
        {children}
      </CellValue>
    </div>
  );

const Cell = React.memo(CellBase, () => true);

export default Cell;
