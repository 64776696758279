import React from 'react';
import useI18n from './i18n';
import { AimDialog } from './../../atoms/AimDialog';
import { AimTypography } from './../../atoms/AimTypography';

export const AimConfirmDialog = ({
  dialog,
  setDialog,
  onAgree,
  intl,
  isOpen,
}) => {
  const i18n = useI18n(intl);

  return (
    <AimDialog
      title={dialog.title}
      open={isOpen !== undefined ? isOpen : dialog.isOpen}
      agreeText={dialog.agreeText || i18n.yes}
      disagreeText={dialog.disagreeText || i18n.no}
      onClose={() => setDialog({ isOpen: false })}
      onAgree={onAgree}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <AimTypography variant="body1">{dialog.message}</AimTypography>
      </div>
    </AimDialog>
  );
};
