import React from 'react';

import { styled } from './../styled';
import Radio from '@material-ui/core/Radio';

const CustomRadioButton = styled(Radio)({});

export const AimRadioButton = ({ children, style, ...rest }) => (
  <CustomRadioButton {...rest}>{children}</CustomRadioButton>
);
