import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  AimDialog,
  AimIconAndTextButton,
  AimTextFieldForm,
  AimSelectForm,
  AimSelectMenuItem,
} from '@aim/components';
import { constants } from '@aim/common';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import translation from './translation';

const formControlStyle = { width: 'calc(100% - 15px)' };

export const PaymentDialog = ({
  intl,
  isOpen,
  onClose,
  onDisagree,
  dialogState,
  handleConfirm,
}) => {
  const i18n = translation.paymentDialog(intl);

  if (!dialogState) return null;
  const { handleSubmit, control, getValues, watch, errors, reset } = useForm({
    shouldUnregister: false,
    defaultValues: {
      status: dialogState?.payment?.status,
      totalPrice: dialogState?.payment?.totalPrice,
      paidFee: dialogState.payment?.totalPrice,
      paymentDate: dialogState.payment?.paymentDate
        ?.split(' ')[0]
        .split('/')
        .reverse()
        .join('-'),
    },
  });

  const handleConfirmPayment = (submittedData) => {
    handleConfirm(submittedData);
  };

  useEffect(() => {
    if (dialogState.isOpen) reset();
  }, [dialogState.isOpen]);

  return (
    <AimDialog
      title={i18n.title}
      open={isOpen}
      onClose={onClose}
      onDisagree={onDisagree}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
    >
      <form onSubmit={handleSubmit(handleConfirmPayment)}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0 15px',
          }}
        >
          <AimTextFieldForm
            control={control}
            name="totalPrice"
            label={i18n.totalPrice}
            formControlStyle={formControlStyle}
            disabled
          />
        </div>
        {/* <div
        style={{ display: 'flex', alignItems: 'center', margin: '10px 0 15px' }}
      >
        <AimTextFieldForm
          control={control}
          name="paidFee"
          label={i18n.paidFee}
          formControlStyle={formControlStyle}
        />
      </div> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0 15px',
          }}
        >
          <AimSelectForm
            control={control}
            errors={errors}
            label={i18n.status}
            name="status"
            formControlStyle={formControlStyle}
            // hasNoneValue
          >
            {[
              constants.PaymentStatuses.PENDING,
              constants.PaymentStatuses.DONE,
            ].map((status) => {
              return (
                <AimSelectMenuItem key={status.key} value={status.key}>
                  {status.label(intl)}
                </AimSelectMenuItem>
              );
            })}
          </AimSelectForm>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <AimIconAndTextButton
            variant={'greyFill'}
            text={i18n.buttons.cancel}
            onClick={() => {
              onClose();
            }}
          >
            <ClearIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            variant={'greenFill'}
            text={i18n.buttons.confirm}
            type="submit"
            // onClick={() =>
            //   handleConfirm?.(
            //     getValues('totalPrice'),
            //     getValues('paidFee'),
            //     getValues('status')
            //   )
            // }
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </div>
      </form>
    </AimDialog>
  );
};
