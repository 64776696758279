import React from 'react';
import { AimTypography } from '@aim/components';
import Grid from '@material-ui/core/Grid';

const TotalRowBase = ({
  fn,
  matrix,
  rowIdx,
  totalCellSyle,
  alignColumnHeaders,
}) => {
  const columns = matrix[0].items.map((val, index) =>
    matrix.map((row) => row.items[index])
  );

  return (
    <Grid
      key={`rowItem-${rowIdx}`}
      item
      xs={10}
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      {columns.map((column, idx) => {
        const value = fn({ column, columns, matrix, columnIdx: idx });
        const style =
          typeof totalCellSyle === 'object'
            ? totalCellSyle
            : totalCellSyle(value);

        return (
          <AimTypography
            key={`total-${rowIdx}-${idx}`}
            noWrap
            style={style}
            boxStyle={{ textAlign: alignColumnHeaders }}
          >
            {value}
          </AimTypography>
        );
      })}
    </Grid>
  );
};

const TotalRow = React.memo(
  TotalRowBase,
  (prevProps, nextProps) => prevProps.lastUpdate !== nextProps.lastUpdate
);

export default TotalRow;
