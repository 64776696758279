import React, { cloneElement, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { styled } from './../styled';
import { theme } from './../../theme';
import { AimIconButton } from './../atoms/AimIconButton';
import CloseIcon from '@material-ui/icons/Close';
import { AimCheckbox } from './../atoms/AimCheckbox';
import { Virtuoso } from 'react-virtuoso';
import { useCallback } from 'react';
import { useEffect } from 'react';

const StyledList = styled(List)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export const AimListItem = styled(ListItem)(({ rowvariant }) => ({
  ...variants[rowvariant],
  marginTop: 5,
  marginBottom: 5,
}));

const StyledHeaderListItem = styled(ListItem)(
  ({ marginHeader, virtualized }) => ({
    marginLeft: marginHeader ? 5 : 0,
    marginRight: marginHeader ? 5 : 0,
    height: '20px !important',
    background: 'transparent',
    ...(virtualized && { paddingRight: '32px' }),
  })
);

const CustomScrollbars = styled(({ ...props }) => <div {...props} />)({
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: 6,
  },
  '&::-webkit-scrollbar-track': {
    background: '#ffffff',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(149, 152, 154, 0.43)',
    borderRadius: 5,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(149, 152, 154, 1)',
  },
});

const variants = {
  white: {
    background: theme.colors.greyScale.white,
  },
  grey: {
    background: theme.colors.greyScale.backgroundGrey,
  },
};

const ItemRow = ({
  Row,
  row,
  index,
  childrenProps,
  rowKey,
  checkable,
  isChecked,
  handleCheck,
  onDeleted,
  variant,
  deletable,
  deleteIconVariant,
  isDeleteSmall,
  itemStyle,
  customStyle,
  clickable,
  onClick,
}) => {
  const clonedRow = cloneElement(Row, {
    key: row[rowKey],
    row,
    index,
    ...childrenProps,
  });
  return (
    <AimListItem
      rowvariant={variant}
      style={{ ...itemStyle }}
      onClick={() => {
        clickable && onClick && onClick({ row });
      }}
    >
      {checkable ? (
        <AimCheckbox
          tabIndex={-1}
          onClick={(event) => handleCheck(event, row[rowKey])}
          checked={isChecked(row[rowKey])}
        />
      ) : null}
      <div
        style={{
          display: 'flex',
          flex: 1,
          ...customStyle,
        }}
      >
        {clonedRow}
      </div>
      {deletable && (
        <AimIconButton
          disabled={row.disabled}
          small={isDeleteSmall}
          variant={deleteIconVariant}
          onClick={(e) => onDeleted(e, row, index)}
        >
          <CloseIcon style={{ color: theme.colors.secondary.red }} />
        </AimIconButton>
      )}
    </AimListItem>
  );
};

const ChildrenRows = ({
  children: Row,
  rows,
  rowKey,
  clickable,
  checkable,
  isChecked,
  handleCheck,
  onDeleted,
  childrenProps,
  variant,
  deletable,
  isDeleteSmall,
  deleteIconVariant,
  itemStyle,
  customStyle,
  virtualized,
  listHeight,
  onClick,
}) => {
  return virtualized ? (
    <Virtuoso
      style={{ width: '100%', height: listHeight || 400 }}
      totalCount={rows.length}
      itemContent={(index) => {
        const row = rows[index];
        return (
          <ListItemText key={index}>
            <ItemRow
              {...{
                Row,
                row,
                childrenProps,
                index,
                rows,
                rowKey,
                checkable,
                isChecked,
                handleCheck,
                onDeleted,
                variant,
                deletable,
                isDeleteSmall,
                deleteIconVariant,
                itemStyle,
                customStyle,
                clickable,
                onClick,
              }}
            />
          </ListItemText>
        );
      }}
    />
  ) : (
    <ListItemText>
      {rows.map((row, index) => (
        <ItemRow
          key={index}
          {...{
            Row,
            row,
            childrenProps,
            index,
            rows,
            rowKey,
            checkable,
            isChecked,
            handleCheck,
            onDeleted,
            variant,
            deletable,
            isDeleteSmall,
            deleteIconVariant,
            itemStyle,
            customStyle,
            clickable,
            onClick,
          }}
        />
      ))}
    </ListItemText>
  );
};

const InnerHeaderRow = ({
  children,
  checkable,
  deletable,
  onCheckAllClick,
  numChecked,
  rowCount,
  headerStyle,
  marginHeader,
  virtualized,
}) => (
  <StyledHeaderListItem
    marginHeader={marginHeader}
    virtualized={virtualized}
    style={{ ...headerStyle }}
  >
    <ListItemText>
      <div style={{ display: 'flex' }}>
        {checkable ? (
          <AimCheckbox
            variant="white"
            tabIndex={-1}
            indeterminate={numChecked > 0 && numChecked < rowCount}
            checked={rowCount > 0 && numChecked === rowCount}
            onChange={onCheckAllClick}
          />
        ) : null}
        {children}
        {deletable && <div style={{ width: 48 }}></div>}
      </div>
    </ListItemText>
  </StyledHeaderListItem>
);

export const AimList = ({
  HeaderRow,
  rows,
  rowKey,
  checkable,
  checkedItems = [],
  onChecked,
  children: Row,
  onDeleted,
  childrenProps,
  variant = 'white',
  deletable = true,
  isDeleteSmall = false,
  deleteIconVariant = 'redFill',
  listHeight,
  customStyle,
  headerStyle,
  itemStyle,
  virtualized = false,
  marginHeader = true,
  clickable = false,
  onClick,
  style,
  noHeader = false,
  customScrollbarsStyle = null,
}) => {
  const [checked, setChecked] = useState(checkedItems || []);

  const handleCheck = (event, name) => {
    const selectedIndex = checked.indexOf(name);
    let newChecked = [];

    if (selectedIndex === -1) {
      newChecked = newChecked.concat(checked, name);
    } else if (selectedIndex === 0) {
      newChecked = newChecked.concat(checked.slice(1));
    } else if (selectedIndex === checked.length - 1) {
      newChecked = newChecked.concat(checked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newChecked = newChecked.concat(
        checked.slice(0, selectedIndex),
        checked.slice(selectedIndex + 1)
      );
    }

    setChecked(newChecked);
    onChecked && onChecked(newChecked);
    event.stopPropagation();
  };

  const handleCheckAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[rowKey]);
      setChecked(newSelecteds);
      onChecked && onChecked(newSelecteds);
      return;
    }
    setChecked([]);
    onChecked([]);
  };

  const isChecked = useCallback(
    (name) => checked.some((x) => x === name || x.id === name),
    [checked]
  );

  useEffect(() => {
    if (JSON.stringify(checked) !== JSON.stringify(checkedItems)) {
      setChecked(checkedItems);
    }
  }, [checkedItems]);

  return (
    <>
      {rows?.length !== 0 && (
        <StyledList style={style}>
          {!noHeader ? (
            <InnerHeaderRow
              checkable={checkable}
              deletable={deletable}
              rowCount={rows?.length}
              numChecked={checked.length}
              onCheckAllClick={handleCheckAllClick}
              headerStyle={headerStyle}
              marginHeader={marginHeader}
              virtualized={virtualized}
            >
              {HeaderRow}
            </InnerHeaderRow>
          ) : null}
          <CustomScrollbars
            style={{
              height: listHeight || 'auto',
              ...(customScrollbarsStyle && customScrollbarsStyle),
            }}
          >
            <ChildrenRows
              variant={variant}
              rows={rows}
              rowKey={rowKey}
              checkable={checkable}
              clickable={clickable}
              isChecked={isChecked}
              handleCheck={handleCheck}
              deletable={deletable}
              onDeleted={onDeleted}
              childrenProps={childrenProps}
              isDeleteSmall={isDeleteSmall}
              deleteIconVariant={deleteIconVariant}
              customStyle={customStyle}
              itemStyle={itemStyle}
              virtualized={virtualized}
              listHeight={listHeight}
              onClick={onClick}
            >
              {Row}
            </ChildrenRows>
          </CustomScrollbars>
        </StyledList>
      )}
    </>
  );
};
