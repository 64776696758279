import React from 'react';

import { aws } from '@aim/common';
import { AimConfirmDialog } from '../../bundles/AimConfirmDialog/AimConfirmDialog';

const ManualMailConfirmDialogComponent = ({
  dialog,
  setDialog,
  eventId,
  intl,
}) => {
  // Helpers
  const sendManualMail = async () => {
    const {
      trigger,
      participations = [],
      products = [],
      abstracts = [],
      sponsors = [],
      purchases = [],
      attachments,
      sender,
      replyTo,
      cc,
      bcc,
      skipPaxWithNoFiles,
    } = dialog.body;

    await aws.standardAPI.post('apiSendMail', '/sendMail/trigger', {
      body: {
        trigger,
        participations: participations.map(({ id }) => ({ id })),
        products: products.map(({ id }) => ({ id })),
        abstracts: abstracts.map(({ id }) => ({ id })),
        sponsors: sponsors.map(({ id }) => ({ id })),
        purchases: purchases.map(({ id }) => ({ id })),
        eventId,
        attachments,
        sender,
        replyTo,
        cc,
        bcc,
        skipPaxWithNoFiles,
      },
    });
    setDialog({ isOpen: false });
  };

  return (
    <AimConfirmDialog
      dialog={dialog}
      isOpen={dialog.active === 'confirm' && dialog.isOpen}
      setDialog={setDialog}
      onAgree={sendManualMail}
      intl={intl}
    />
  );
};

export const ManualMailConfirmDialog = React.memo(
  ManualMailConfirmDialogComponent
);
