import { aws } from '@aim/common';
const { API } = aws;

const getEmailsTemplatesQuery = /* GraphQL */ `
  query GetEmailsTemplates($id: ID!, $templateType: String!) {
    getEvent(id: $id) {
      id
      emailTemplates(filter: { type: { eq: $templateType } }) {
        items {
          id
          type
          name
          subject
          isActive
          body
          trigger
          greetings
          fieldMapping
        }
      }
    }
  }
`;
const getEventPreTitlesQuery = /* GraphQL */ `
  query GetEventPreTitles($id: ID!) {
    getEvent(id: $id) {
      id
      preTitles {
        items {
          id
          key
          preTitle
          title
        }
      }
    }
  }
`;

export const getEmailTemplates = ({ eventId, templateType }) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getEmailsTemplatesQuery,
      variables: { id: eventId, templateType },
    })
      .then((response) => {
        const nextTemplates = response.data.getEvent.emailTemplates.items.filter(
          (t) => t.trigger.startsWith('manual') && t.isActive === 'true'
        );
        resolve(nextTemplates);
      })
      .catch((e) => {
        console.error('list-event-email-templates', e);
        reject();
      });
  });

export const getPreTitles = (eventId) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getEventPreTitlesQuery,
      variables: { id: eventId },
    })
      .then((response) => resolve(response.data.getEvent.preTitles.items))
      .catch((e) => {
        console.error('list-event-pretitles', e);
        reject();
      });
  });
