export default {
  importDialog: (intl) => ({
    title: intl.formatMessage({
      description: 'massive Import',
      defaultMessage: 'Massive Import',
    }),
    subTitle: intl.formatMessage({
      description: 'massive Import subtitle',
      defaultMessage: 'Select the action to take',
    }),
    exportText: intl.formatMessage({
      description: 'massive Import exportText',
      defaultMessage: 'Export the template for insertion',
    }),
    importText: intl.formatMessage({
      description: 'massive Import importText',
      defaultMessage: 'Import data for insertion',
    }),
    exportTemplateWithRows: intl.formatMessage({
      description: 'export template with rows',
      defaultMessage: 'Export the template with rows',
    }),
  }),
  exportDialog: (intl) => ({
    title: intl.formatMessage({
      description: 'export title',
      defaultMessage: 'Export intervention templates',
    }),
    subTitle: intl.formatMessage({
      description: 'export subtitle',
      defaultMessage:
        'Information set: Select the profile if you want to import only participants from this cluster',
    }),
    profileLabel: intl.formatMessage({
      description: 'profile label',
      defaultMessage: 'Profile',
    }),
    selectText: intl.formatMessage({
      description: 'profile select',
      defaultMessage: 'Select a profile',
    }),
    templateLabel: intl.formatMessage({
      description: 'template label',
      defaultMessage: 'Template',
    }),
  }),
  statusDialog: (intl) => ({
    title: intl.formatMessage({
      description: 'stuts title',
      defaultMessage: 'Import Status',
    }),
    subTitle: intl.formatMessage({
      description: 'export subtitle',
      defaultMessage: 'Checking',
    }),
    button: intl.formatMessage({
      description: 'button',
      defaultMessage: 'See results',
    }),
  }),
};
