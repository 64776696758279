import * as React from 'react';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  gridColumnLookupSelector,
  useGridSelector,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-pro';

const GridToolbarSaveVisibleColumns = React.forwardRef(
  function GridToolbarSaveVisibleColumns(props, ref) {
    const { componentsProps = {}, tableName, ...other } = props;
    const LOCAL_STORAGE_NAME = `aim-mui-datagrid-${tableName}-saved-hidden-columns`;
    const buttonProps = componentsProps.button || {};
    const [hiddenColumns, setHiddenColumns] = React.useState(
      localStorage.getItem(LOCAL_STORAGE_NAME)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME))
        : undefined
    );
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const lookup = useGridSelector(apiRef, gridColumnLookupSelector);

    const isEmpty = (obj) => {
      return Object.keys(obj).length === 0;
    };

    return (
      <rootProps.components.BaseTooltip
        title={hiddenColumns ? 'clear Columns' : 'Save Columns'}
        enterDelay={1000}
        {...other}
        {...rootProps.componentsProps?.baseTooltip}
      >
        <rootProps.components.BaseButton
          ref={ref}
          size="small"
          aria-label="Save Visible Columns"
          {...buttonProps}
          onClick={() => {
            if (!hiddenColumns) {
              const nextHiddenColumns = Object.entries(lookup).reduce(
                (prev, [key, value]) =>
                  console.log('value', value) || value.hide
                    ? { ...prev, [key]: false }
                    : prev,
                {}
              );
              if (!isEmpty(nextHiddenColumns)) {
                localStorage.setItem(
                  LOCAL_STORAGE_NAME,
                  JSON.stringify(nextHiddenColumns)
                );
                setHiddenColumns(nextHiddenColumns);
              }
            } else {
              setHiddenColumns();
              localStorage.removeItem(LOCAL_STORAGE_NAME);
              apiRef.current.setColumnVisibilityModel({});
            }
          }}
          {...rootProps.componentsProps?.baseButton}
          startIcon={hiddenColumns ? <VisibilityIcon /> : <VisibilityOffIcon />}
        >
          {hiddenColumns ? 'Clear Columns' : 'Save Columns'}
        </rootProps.components.BaseButton>
      </rootProps.components.BaseTooltip>
    );
  }
);

export { GridToolbarSaveVisibleColumns };
