import React, { useCallback, useRef, useState, useEffect } from 'react';
import { flushSync } from 'react-dom';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import UploadFromRepoBubbleMenu from './UploadFromRepoBubbleMenu';

import { Lock, LockOpen, TextFields } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
} from 'mui-tiptap';
import EditorMenuControls from './EditorMenuControls';
import useExtensions from './useExtensions';
import { onImageUpload } from './tipTapRichTextUtilities';
import { appState, constants } from '@aim/common';
import { PureEditorContent } from '@tiptap/react';

PureEditorContent.prototype.maybeFlushSync = (fn) => {
  fn();
};

const CustomFormLabel = styled(FormLabel)(({ variantTemplate }) => ({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
  fontFamily:
    variantTemplate === constants.Templates.FRONTEND ? 'hind' : 'Roboto',
  '&.MuiFormLabel-root.Mui-focused': {
    color: 'black',
  },
}));

const fileListToImageFiles = (fileList) => {
  // You may want to use a package like attr-accept
  // (https://www.npmjs.com/package/attr-accept) to restrict to certain file
  // types.
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || '').toLowerCase();
    return mimeType.startsWith('image/');
  });
};

export const AimTipTapRichTextEditor = ({
  onChange,
  label,
  value,
  addAsterisk,
  eventId,
  disabled,
  height = '400px',
}) => {
  const [isEditorFullScreen, setIsEditorFullScreen] = useState(false);
  const extensions = useExtensions({
    placeholder: 'Add your own content here...',
    setIsEditorFullScreen,
    isEditorFullScreen,
  });
  const rteRef = useRef();
  const [showMenuBar, setShowMenuBar] = useState(true);
  const [submittedContent, setSubmittedContent] = useState('');

  useEffect(() => {
    // to prevent unnecessary re-renders, the editor content is already changes
    // used only for iniital value from forms because form value is async
    if (rteRef.current?.editor?.getHTML() !== value) {
      queueMicrotask(() => rteRef.current?.editor?.commands?.setContent(value));
    }
  }, [value]);

  const handleNewImageFiles = useCallback(async (files, insertPosition) => {
    if (!rteRef.current?.editor) {
      return;
    }

    const attributesForImageFiles = await Promise.all(
      files.map((file) => onImageUpload(file, eventId))
    );
    insertImages({
      images: attributesForImageFiles,
      editor: rteRef.current.editor,
      insertPosition,
    });
  }, []);

  // Allow for dropping images into the editor
  const handleDrop = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        console.error('not data transfer', event);
        return false;
      }
      const imageFiles = fileListToImageFiles(event.dataTransfer.files);
      if (imageFiles.length > 0) {
        const insertPosition = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, insertPosition);

        // Return true to treat the event as handled. We call preventDefault
        // ourselves for good measure.
        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  // Allow for pasting images
  const handlePaste = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = fileListToImageFiles(event.clipboardData.files);
      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        // Return true to mark the paste event as handled. This can for
        // instance prevent redundant copies of the same image showing up,
        // like if you right-click and copy an image from within the editor
        // (in which case it will be added to the clipboard both as a file and
        // as HTML, which Tiptap would otherwise separately parse.)
        return true;
      }

      // We return false here to allow the standard paste-handler to run.
      return false;
    },
    [handleNewImageFiles]
  );

  return (
    <>
      <CustomFormLabel variantTemplate={appState.template.getValue()}>
        {label}
        {addAsterisk && '*'}
      </CustomFormLabel>
      <Box
        sx={{
          // An example of how editor styles can be overridden. In this case,
          // setting where the scroll anchors to when jumping to headings. The
          // scroll margin isn't built in since it will likely vary depending on
          // where the editor itself is rendered (e.g. if there's a sticky nav
          // bar on your site).

          '& .MuiTiptap-FieldContainer-root': {
            background: 'white',
            ...(isEditorFullScreen
              ? {
                  width: '100%',
                  height: '100% !important',
                  position: 'fixed',
                  left: 0,
                  top: 0,
                  zIndex: 1000,
                }
              : {}),
          },
          '& .ProseMirror': {
            '& h1, & h2, & h3, & h4, & h5, & h6': {
              scrollMarginTop: showMenuBar ? 50 : 0,
            },
          },
          '& .MuiTiptap-RichTextContent-root': {
            overflow: 'auto',
            ...(isEditorFullScreen
              ? { height: 'calc(100% - 80px) !important' }
              : { height }),
          },
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          content={value}
          editable={!disabled}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          onUpdate={(editor) => {
            console.log('editor onUpdate', rteRef.current?.editor?.getHTML());
            onChange(rteRef.current?.editor?.getHTML());
          }}
          renderControls={() => <EditorMenuControls eventId={eventId} />}
          RichTextFieldProps={{
            // The "outlined" variant is the default (shown here only as
            // example), but can be changed to "standard" to remove the outlined
            // field border from the editor
            variant: 'outlined',
            MenuBarProps: {
              hide: !showMenuBar,
            },
            // Below is an example of adding a toggle within the outlined field
            // for showing/hiding the editor menu bar, and a "submit" button for
            // saving/viewing the HTML content
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <UploadFromRepoBubbleMenu eventId={eventId} />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>
    </>
  );
};

export const AimTipTapRichTextEditorForm = ({
  control,
  name,
  defaultValue = '',
  errors,
  isRequired,
  label,
  maxLength,
  minLength,
  validate,
  height = '200px',
  eventId = null,
  disabled,
}) => (
  <div style={{ padding: 8 }}>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        // <AimMarkdown
        //  height={height}
        //  onChange={({ text }) => props.onChange(text)}
        //  label={label}
        //  value={props.value}
        //  addAsterisk={isRequired}
        //  eventId={eventId}
        ///>
        <AimTipTapRichTextEditor
          onChange={(value) => props.onChange(value)}
          label={label}
          value={props.value}
          addAsterisk={isRequired}
          eventId={eventId}
          disabled={disabled}
        />
      )}
      rules={{
        required: isRequired,
        maxLength: maxLength,
        minLength: minLength,
        validate: validate,
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => {
          return (
            <div>
              <AimTypography variant="formError">
                {message ||
                  (errors?.[name]?.type === 'minLength'
                    ? `${label} is too short`
                    : errors?.[name]?.type === 'maxLength'
                    ? `${label} is too long`
                    : errors?.[name]?.type === 'validate'
                    ? `${label} is not valid`
                    : `${label} is required`)}
              </AimTypography>
            </div>
          );
        }}
      />
    )}
  </div>
);
