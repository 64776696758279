import React from 'react';
import { useFieldArray } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { DevTool } from '@hookform/devtools';

import { AimTypography } from '@aim/components';
import ColumnHeader from './ColumnHeader';
import RowHeader from './RowHeader';
import Row from './Row';
import TotalRowHeader from './TotalRowHeader';
import TotalRow from './TotalRow';

const AimGridFieldArrayBase = ({
  title,
  subtitle,
  control,
  errors,
  dataSettings,
  children,
  styles = {},
  isDebug = false,
}) => {
  // const [lastUpdate, setLastUpdate] = useState(Date.now());
  const { fields: matrix } = useFieldArray({
    control,
    name: 'data',
    keyName: 'rowId',
  });

  if (!matrix || !matrix.length) return null;

  const {
    sortRowsBy,
    sortColumnsBy,
    headerFormatter,
    renderProp,
    totalRows,
    totalColumns,
  } = dataSettings;

  const {
    panelStyle = {},
    rowStyle = {},
    columnHeaderStyle = {},
    rowHeaderStyle = {},
    totalRowHeaderStyle = {},
    totalRowStyle = {},
    totalCellSyle = {},
    totalColumnStyle = {},
    alignColumnHeaders = 'right',
    alignRowHeaders = 'right',
    dummyCellStyle = { margin: 3 },
  } = styles;

  // const handleCellUpdate = () => setLastUpdate(Date.now());

  return (
    <div
      style={{
        borderRadius: 4,
        padding: '20px 10px',
        background: 'white',
        overflow: 'auto',
        ...panelStyle,
      }}
    >
      {title && <AimTypography variant="h2">{title}</AimTypography>}
      {subtitle && <AimTypography variant="body1">{subtitle}</AimTypography>}
      {(title || subtitle) && <Divider style={{ padding: '0px 30px' }} />}
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item xs={2} />
          <Grid
            item
            xs={10}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ColumnHeader
              items={matrix[0].items}
              totalColumns={totalColumns}
              propName={sortColumnsBy}
              {...{ headerFormatter, columnHeaderStyle, alignColumnHeaders }}
            />
          </Grid>
        </Grid>
        <Grid container item>
          {matrix.map((row, idx) => (
            <Grid
              container
              item
              key={`rowContainer-${row.rowId}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <RowHeader
                rowIdx={row.rowId}
                name={row.items?.[0][sortRowsBy]}
                {...{ rowHeaderStyle, alignRowHeaders }}
              />
              <Row
                rowIdx={idx}
                {...{
                  control,
                  rowStyle,
                  dummyCellStyle,
                  children,
                  errors,
                  // handleCellUpdate,
                  renderProp,
                  totalColumns,
                  matrix,
                  totalColumnStyle,
                }}
              />
            </Grid>
          ))}
        </Grid>
        {totalRows && totalRows.length && (
          <Grid container item xs={12}>
            {totalRows.map((totalRow, idx) => (
              <Grid
                item
                xs={12}
                key={`totalRow-${idx}`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  ...totalRowStyle,
                }}
              >
                <TotalRowHeader
                  name={totalRow.header}
                  {...{ totalRowHeaderStyle, alignRowHeaders }}
                />
                <TotalRow
                  as={totalRow.as}
                  fn={totalRow.fn}
                  matrix={matrix}
                  rowIdx={idx}
                  {...{ totalCellSyle, alignColumnHeaders }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      {isDebug && <DevTool control={control} />}
    </div>
  );
};

const AimGridFieldArray = React.memo(AimGridFieldArrayBase, () => true);

export { AimGridFieldArray };

/**
 * Build a AIM table page from a model.
 * @typedef {string} title - The string containing the title for the grid section.
 * @typedef {string} control - react-hook-form control object (from useForm).
 * @typedef {string} errors - react-hook-form errors object (from useForm).
 * @typedef {object} styles - object containing styles for the table.
 * @typedef {object={}} styles.panelStyle - object containing styles for the table panel.
 * @typedef {object={}} styles.rowStyle - object containing styles for the table row.
 * @typedef {object={}} styles.columnHeaderStyle - object containing styles for the table column header.
 * @typedef {object={}} styles.rowHeaderStyle - object containing styles for the table row header.
 * @typedef {object={}} styles.totalRowHeaderStyle - object containing styles for the table total row header.
 * @typedef {object={}} styles.totalRowStyle - object containing styles for the table total row.
 * @typedef {object|function(value)={}} styles.totalCellSyle - object containing styles for the single item of a total row or function that gives you the result of `dataSettings.totalRows[].fn` as parameter
 * @property {string=right} styles.alignColumnHeaders - The string containing the alignment of the column headers.
 * @property {string=right} styles.alignRowHeaders - The string containing the alignment of the row headers.
 * @property {object={ margin: 3 }} styles.dummyCellStyle - The object containing the styles for the dummy cell.
 * @typedef {Object} dataSettings - The model responsible for the data.
 * @property {object} dataSettings.dataMatrix - The object containing the data matrix rowsxitems.
 * @property {string} dataSettings.sortRowsBy - The string containing the property name for the sorting of the rows.
 * @property {string} dataSettings.sortColumnsBy - The string containing the property name for the sorting of the columns.
 * @property {string} dataSettings.headerFormatter - a function that formats the header value.
 * @property {object[]} dataSettings.totalRows - array of definitions for total rows
 * @property {string} dataSettings.totalRows[].header - string for the total row header title.
 * @property {function({column, columns, matrix})} dataSettings.totalRows[].fn - function to calculate value of the single item of the total row.
 * @typedef {AimTypography|AimInputForm} children - The react component to use for the grid items.
 * @return {ReactComponent}
 */
