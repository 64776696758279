import React, { useState, useRef, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import differenceBy from 'lodash.differenceby';
import ImageButton from './megadraft-image-embed-plugin/ImageButton';
import ImageBlock from './megadraft-image-embed-plugin/ImageBlock';

import {
  MegadraftEditor,
  editorStateFromRaw,
  editorStateToJSON,
  DraftJS,
} from 'megadraft';
import baseActions from 'megadraft/lib/actions/default';

import imagePlugin from './megadraft-image-embed-plugin';

import 'megadraft/dist/css/megadraft.css';
import './index.css';
import { useEffect } from 'react';
import { theme } from '../../../theme';

const { Modifier, EditorState } = DraftJS;
const plugins = [/*...basePlugins, */ imagePlugin];
const filterLists = (i) => i.label === 'UL' || i.label === 'OL';
const actions = baseActions.filter(filterLists); // [underlineAction, ...baseActions];

export const AimMegadraft = ({
  readOnly,
  onChange,
  value,
  variant = 'white',
  marginLeft = 80,
}) => {
  const [editorState, setEditorState] = useState(editorStateFromRaw(value));

  const onDataChange = (nextEditorState) => {
    console.log('nextEditorState', nextEditorState);
    setEditorState(nextEditorState);

    if (!onChange) return;
    const content = editorStateToJSON(nextEditorState);
    onChange(content);
  };

  let backgroundColor;
  switch (variant) {
    case 'white':
      backgroundColor = 'white';
      break;
    case 'grey':
      backgroundColor = theme.colors.greyScale.backgroundGrey;
      break;
    case 'transparent':
      backgroundColor = 'transparent';
      break;
    default:
      backgroundColor = 'white';
      break;
  }

  return (
    <div
      style={{
        marginLeft,
        background: backgroundColor,
      }}
    >
      <MegadraftEditor
        readOnly={readOnly}
        editorState={editorState}
        onChange={onDataChange}
        plugins={plugins}
        actions={readOnly ? [] : actions}
        hideSidebarOnBlur
        movableBlocks={readOnly ? false : true}
      />
    </div>
  );
};

export const AimMegadraftToJSON = editorStateToJSON;
export const AimMegadraftFromRaw = editorStateFromRaw;

export const useAimMegadraft = () => {
  const [editorState, setEditorState] = useState(editorStateFromRaw(null));

  const AimMegadraftBase = ({
    readOnly = false,
    onChange,
    variant = 'white',
  }) => {
    const onDataChange = (nextEditorState) => {
      setEditorState(nextEditorState);

      if (!onChange) return;
      const content = editorStateToJSON(nextEditorState);
      onChange(content);
    };

    return (
      <div
        style={{
          marginLeft: 80,
          background:
            variant === 'white'
              ? 'white'
              : theme.colors.greyScale.backgroundGrey,
        }}
      >
        <MegadraftEditor
          readOnly={readOnly}
          editorState={editorState}
          onChange={onDataChange}
          plugins={plugins}
          actions={actions}
          hideSidebarOnBlur
          movableBlocks
        />
      </div>
    );
  };

  const paste = useCallback(
    (char) => {
      const selection = editorState.getSelection();
      const map = selection['_map'];
      const focusKey = map.get('focusKey');
      const focusOffset = map.get('focusOffset');

      const eState = JSON.parse(editorStateToJSON(editorState));
      const { blocks } = eState;
      // get block to change
      const block = blocks.find((b) => b.key === focusKey);
      // insert char into position
      const nextText =
        block.text.slice(0, focusOffset) + char + block.text.slice(focusOffset);
      // create the updated block
      const nextBlock = { ...block, text: nextText };
      // substitute the old version of the block
      // with the new one
      const nextBlocks = eState.blocks.map((obj) =>
        obj.key === focusKey ? nextBlock : obj
      );
      // build the new editor state
      const nextEditorState = { ...eState, blocks: nextBlocks };

      setEditorState(editorStateFromRaw(nextEditorState));
    },
    [editorState]
  );

  return {
    AimMegadraft: React.memo(AimMegadraftBase),
    paste,
  };
};

const filterImages = (object) =>
  object?.blocks?.filter((b) => b.data.type === 'image-embed');

export const AimMegadraftForm = ({
  name,
  control,
  readOnly,
  onFileChange,
  variant,
  preventOnChange = false,
}) => {
  const isFirstRender = useRef(true);
  let editorState;

  const differences = useCallback(
    (editorState, e) => {
      const prevImages = filterImages(
        JSON.parse(editorStateToJSON(editorState))
      );
      const nextImages = filterImages(JSON.parse(editorStateToJSON(e)));

      if (!prevImages || !nextImages) return { added: [], removed: [] };

      const added = differenceBy(nextImages, prevImages, 'data.src').filter(
        (i) => i.data.src
      );

      const removed = differenceBy(prevImages, nextImages, 'key');

      if (!added.length && !removed.length) return;

      onFileChange({ added, removed });
    },
    [onFileChange]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={(props) => {
        if (!isFirstRender.current) {
          editorState = props.value;
        } else {
          editorState = editorStateFromRaw(props.value);
          isFirstRender.current = false;
        }

        return (
          <div style={{ marginLeft: 80 }}>
            <MegadraftEditor
              variant={variant}
              readOnly={readOnly}
              editorState={editorState}
              onChange={(e) => {
                if (preventOnChange) return;
                differences(editorState, e);
                props.onChange(e);
              }}
              plugins={plugins}
              actions={actions}
              hideSidebarOnBlur
              movableBlocks
            />
          </div>
        );
      }}
    />
  );
};

export const useAimMegadraftForm = ({
  name,
  control,
  readOnly,
  variant = 'white',
  defaultValue = { blocks: [], entityMap: {} },
  extensions = [],
  filesMaxSize,
  maxFiles,
  //updateImagesCounterRef is used for cases with multiple megadraft form and max files unique
  updateImagesCounterRef,
  onFileTooBig,
  onTooManyFiles,
  onExtensionNotAllowed,
}) => {
  const editorStateRef = useRef();
  const defaultEditorStateRef = useRef(
    Array.isArray(defaultValue?.blocks)
      ? editorStateFromRaw(defaultValue)
      : defaultValue
  );

  const AimMegadraftBase = ({
    onFileChange,
    onTextChange,
    isOnChangeDisabled,
  }) => {
    //to fire counter on existent data
    useEffect(() => {
      onTextChange(getFullText(defaultEditorStateRef.current));
    }, []);

    // const differences = useCallback(
    //   (e) => {
    //     const prevImages = filterImages(defaultValueRef.current);
    //     const nextImages = filterImages(JSON.parse(editorStateToJSON(e)));

    //     if (!prevImages || !nextImages) return;

    //     const added = differenceBy(nextImages, prevImages, 'data.src').filter(
    //       (i) => i.data.src
    //     );

    //     const removed = differenceBy(prevImages, nextImages, 'key');

    //     if (!added.length && !removed.length) return;

    //     onFileChange && onFileChange({ added, removed });
    //   },
    //   [onFileChange]
    // );

    const manualAddPastedText = (pastedText, editorState, props) => {
      const contentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        pastedText,
        editorState.getCurrentInlineStyle(),
        null
      );

      const newEditorState = EditorState.push(
        editorState,
        contentState,
        'insert-characters'
      );

      const nextState = EditorState.forceSelection(
        newEditorState,
        contentState.getSelectionAfter()
      );
      props.onChange(nextState);
      onTextChange && onTextChange(getFullText(nextState));
      editorStateRef.current = { ...props, value: nextState };
    };
    return (
      <Controller
        name={name}
        defaultValue={
          Array.isArray(defaultValue?.blocks)
            ? editorStateFromRaw(defaultValue)
            : defaultValue
        }
        control={control}
        render={(props) => (
          <div
            style={{
              marginLeft: 80,
              background:
                variant === 'white'
                  ? 'white'
                  : theme.colors.greyScale.backgroundGrey,
            }}
          >
            <MegadraftEditor
              handleBeforeInput={() => {
                if (isOnChangeDisabled && isOnChangeDisabled()) {
                  return 'handled';
                }
              }}
              handlePastedText={(pastedText, var1, editorState) => {
                if (
                  isOnChangeDisabled &&
                  isOnChangeDisabled(pastedText.length)
                ) {
                  return 'handled';
                } else {
                  manualAddPastedText(pastedText, editorState, props);
                  return 'handled';
                }
              }}
              readOnly={readOnly}
              editorState={props.value || editorStateFromRaw(defaultValue)}
              onChange={(e) => {
                props.onChange(e);
                onTextChange && onTextChange(getFullText(e));
                editorStateRef.current = { ...props, value: e };
              }}
              plugins={[
                {
                  title: 'Image embed',
                  type: 'image-embed',
                  buttonComponent: ImageButton,
                  blockComponent: (props) =>
                    ImageBlock({
                      ...props,
                      onFileChange,
                      extensions,
                      filesMaxSize,
                      maxFiles,
                      updateImagesCounterRef,
                      onFileTooBig,
                      onTooManyFiles,
                      onExtensionNotAllowed,
                    }),
                },
              ]}
              actions={actions}
              hideSidebarOnBlur
              movableBlocks
            />
          </div>
        )}
      />
    );
  };

  const getFullText = useCallback((currentState) => {
    const jsonState = JSON.parse(editorStateToJSON(currentState));
    return jsonState.blocks.reduce(
      (res, curr) => (!res ? curr.text : `${res}\n${curr.text}`),
      ''
    );
  }, []);

  const paste = useCallback(
    (char) => {
      const selection = editorStateRef.current.value.getSelection();
      const map = selection['_map'];
      const focusKey = map.get('focusKey');
      const focusOffset = map.get('focusOffset');
      const eState = JSON.parse(
        editorStateToJSON(editorStateRef.current.value)
      );
      const { blocks } = eState;
      // get block to change
      const block = blocks.find((b) => b.key === focusKey);
      // insert char into position
      const nextText =
        block.text.slice(0, focusOffset) + char + block.text.slice(focusOffset);
      // create the updated block
      const nextBlock = { ...block, text: nextText };
      // substitute the old version of the block
      // with the new one
      const nextBlocks = eState.blocks.map((obj) =>
        obj.key === focusKey ? nextBlock : obj
      );
      // build the new editor state
      const nextEditorState = { ...eState, blocks: nextBlocks };

      console.log(nextEditorState);

      editorStateRef.current.onChange(editorStateFromRaw(nextEditorState));
    },
    [editorStateRef]
  );

  return [
    React.memo(AimMegadraftBase),
    paste,
    () => JSON.parse(editorStateToJSON(editorStateRef.current.value)),
  ];
};
