import { constants } from '@aim/common';
import { massiveQueriesHelper } from '@aim/common';
import { publishServiceTranslation } from './translation';

export const publishPaxServiceHelper = {
  ServicesTempIds: {
    REGISTRATION: 1,
    TRAVEL: 2,
    TRANSFER: 3,
    ALLOTMENT: 4,
    SOCIAL_EVENT: 5,
  },
  getParticipantsProducts: async (participantsIds) => {
    const productsQuery = `productByClientIdServiceType(clientId: $id, filter: { isFrontOfficePurchase: { ne: "true" } }) {
      items {
        clientId
        isPublished
        id
        productType
        serviceId
        serviceType
        isFrontOfficePurchase
      }
    }
    `;
    const paxQuery = `getParticipation(id: $id) {
      id
      transfer {
        id
        isPublished
      }
      participationTravel {
        items {
          id
          isPublished
        }
      }
    }
    `;

    const result = await massiveQueriesHelper.massiveQuery({
      query: [
        ...participantsIds.map(() => paxQuery),
        ...participantsIds.map(() => productsQuery),
      ],
      queryName: 'GetTranferTravelProductsParticipants',
      items: [
        ...participantsIds.map((id) => ({ id })),
        ...participantsIds.map((id) => ({ id })),
      ],
      // chunkSize: 50,
    });

    const nextPaxWithProducts = [];
    Object.values(result).map((entity, idx) => {
      const isPax = idx < participantsIds.length;
      isPax
        ? nextPaxWithProducts.push(entity)
        : (nextPaxWithProducts[idx - participantsIds.length] = {
            ...nextPaxWithProducts[idx - participantsIds.length],
            products: entity.items,
          });
    });
    return nextPaxWithProducts;
  },
  updateProductsPublishStatus: async (productsIds, isPublished) => {
    const updateProductMutation = /* GraphQL */ `
      updateProduct(input: $input) {
        id
        isPublished
      }
    `;
    const updateQueryInputType = 'UpdateProductInput';
    return await massiveQueriesHelper.massiveMutation({
      query: updateProductMutation,
      queryName: 'UpdateProductsPublishStatus',
      items: productsIds.map((id) => ({
        id,
        isPublished,
      })),
      queryInputType: productsIds.map(() => updateQueryInputType),
    });
  },
  updateTravelOrTransferPublishStatus: async (ids, isPublished, isTravel) => {
    const updateTravelMutation = /* GraphQL */ `
      updateTravel(input: $input) {
        id
        isPublished
      }
    `;
    const updateTransferMutation = /* GraphQL */ `
     updateTransfer(input: $input) {
       id
       isPublished
     }
   `;

    const updateTravelQueryInputType = 'UpdateTravelInput';
    const updateTransferQueryInputType = 'UpdateTransferInput';

    return await massiveQueriesHelper.massiveMutation({
      query: isTravel ? updateTravelMutation : updateTransferMutation,
      queryName: isTravel
        ? 'UpdateTravelPublishStatus'
        : 'UpdateTransferPublishStatus',
      items: ids.map((id) => ({
        id,
        isPublished,
      })),
      queryInputType: ids.map(() =>
        isTravel ? updateTravelQueryInputType : updateTransferQueryInputType
      ),
    });
  },
  getAvailableServices: ({ eventInfo, intl, i18n }) => [
    ...(eventInfo?.availableServices?.includes(
      constants.Services.REGISTRATION.key
    )
      ? [
          {
            id: publishPaxServiceHelper.ServicesTempIds.REGISTRATION,
            text: constants.Services.REGISTRATION.label(intl),
            productType: constants.ProductType.registration,
          },
        ]
      : []),
    ...(eventInfo?.availableServices?.includes(
      constants.Services.TRAVEL_TRANSFER.key
    )
      ? [
          {
            id: publishPaxServiceHelper.ServicesTempIds.TRAVEL,
            text: i18n.travel,
            productType: undefined,
          },
          {
            id: publishPaxServiceHelper.ServicesTempIds.TRANSFER,
            text: i18n.transfer,
            productType: undefined,
          },
        ]
      : []),
    ...(eventInfo?.availableServices?.includes(constants.Services.ALLOTMENT.key)
      ? [
          {
            id: publishPaxServiceHelper.ServicesTempIds.ALLOTMENT,
            text: constants.Services.ALLOTMENT.label(intl),
            productType: constants.ProductType.allotment,
          },
        ]
      : []),
    ...(eventInfo?.availableServices?.includes(
      constants.Services.SOCIAL_EVENT.key
    )
      ? [
          {
            id: publishPaxServiceHelper.ServicesTempIds.SOCIAL_EVENT,
            text: constants.Services.SOCIAL_EVENT.label(intl),
            productType: constants.ProductType.socialEvents,
          },
        ]
      : []),
  ],

  publishServiceTranslation,
};
