import React, { useEffect, useState } from 'react';

import PDFRenderer from './PrintPDFTicket/PDFRenderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { AimIconAndTextButton } from '@aim/components';

import GetAppIcon from '@material-ui/icons/GetApp';
import { fileHelper } from '@aim/common';

export const PrintPDFTicket = ({
  clientInformations,
  billingInformations,
  paymentDetails,
  includedServicesAllotment,
  // includedServicesAdditionalServices = Array.from({ length: 2 }, () =>
  //   Math.floor(Math.random() * 40)
  // ),
  includedServicesAdditionalServices,
  registrationInformations,
  additionalServicesInformations,
  allotmentInformations,
  travelInformations,
  transferInformations,
  module = '',
  intl,
  i18n,
}) => {
  const [invoices, setInvoices] = useState([]);
  const [creditNotes, setCreditNotes] = useState([]);

  const handleClick = async (promiseArray) => {
    const res = await Promise.all(promiseArray);
    window.open(res[0], '_blank');
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    if (allotmentInformations) {
      allotmentInformations?.allotmentInformations.data.map(async (product) => {
        await getLinkInvoiceDocuments(
          product.serviceId,
          product.billingDocument,
          product.creditNotesDocument
        );
      });
    } else if (registrationInformations) {
      return getLinkInvoiceDocuments(
        registrationInformations?.serviceId,
        registrationInformations?.billingDocument,
        registrationInformations?.creditNotesDocument
      );
    } else if (additionalServicesInformations) {
      return getLinkInvoiceDocuments(
        additionalServicesInformations?.serviceId,
        additionalServicesInformations?.billingDocument,
        additionalServicesInformations?.creditNotesDocument
      );
    }
  };

  const getLinkInvoiceDocuments = async (
    eventId,
    billingDocument,
    creditNotesDocument
  ) => {
    const { getS3Link } = fileHelper;

    if (billingDocument?.billingFlow && billingDocument?.isPublished) {
      const invoiceLink = await getS3Link(
        `events/${eventId}/billing/${billingDocument.billingFlow.flowNumber}`,
        {
          id: `${billingDocument.document.id}${billingDocument.document.extension}`,
          extension: '',
          originalName: billingDocument.document.originalName,
        }
      );
      setInvoices([...invoices, invoiceLink]);
    }
    if (creditNotesDocument?.billingFlow && creditNotesDocument?.isPublished) {
      const creditNotesLink = await getS3Link(
        `events/${eventId}/billing/${creditNotesDocument.billingFlow.flowNumber}`,
        {
          id: `${creditNotesDocument.document.id}${creditNotesDocument.document.extension}`,
          extension: '',
          originalName: creditNotesDocument.document.originalName,
        }
      );
      setCreditNotes([...creditNotes, creditNotesLink]);
    }
  };

  return (
    <>
      <PDFDownloadLink
        style={{ textDecoration: 'none' }}
        document={
          <PDFRenderer
            {...{
              intl,
              clientInformations,
              billingInformations,
              paymentDetails,
              includedServicesAllotment,
              includedServicesAdditionalServices,
              registrationInformations,
              additionalServicesInformations,
              allotmentInformations,
              travelInformations,
              transferInformations,
              module,
              i18n,
            }}
          />
        }
        fileName={`${
          clientInformations?.givenName && clientInformations?.familyName
            ? `${clientInformations?.givenName}_${clientInformations?.familyName}`
            : 'Booking_info'
        }.pdf`}
      >
        {({ blob, url, loading, error }) => {
          return !url ? (
            <AimIconAndTextButton variant="secondary" text={`Loading...`}>
              <GetAppIcon />
            </AimIconAndTextButton>
          ) : (
            <AimIconAndTextButton variant="primary" text={i18n.general.print}>
              <GetAppIcon />
            </AimIconAndTextButton>
          );
        }}
      </PDFDownloadLink>
      {invoices?.length > 0 && (
        <AimIconAndTextButton
          variant="primary"
          text={`Download invoice`}
          onClick={() => handleClick(invoices)}
        >
          <GetAppIcon />
        </AimIconAndTextButton>
      )}
      {creditNotes?.length > 0 && (
        <AimIconAndTextButton
          variant="primary"
          text={`Download credit notes`}
          onClick={() => handleClick(creditNotes)}
        >
          <GetAppIcon />
        </AimIconAndTextButton>
      )}
    </>
  );
};
