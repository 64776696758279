import React from 'react';
import { MegadraftIcons, MegadraftPlugin, editorStateToJSON } from 'megadraft';

const { BlockContent, BlockData, BlockInput, CommonBlock } = MegadraftPlugin;

const imageBlockStyle = {
  display: 'inline-block', // Eliminates whitespace between block and data fields block
  maxWidth: '100%',
  verticalAlign: 'middle',
};

function validateUrl(url) {
  const url_regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  return url_regex.test(url);
}

const readFileAsDataURL = (file) =>
  new Promise((resolve, reject) => {
    const fileredr = new FileReader();
    fileredr.onload = () => resolve(fileredr.result);
    fileredr.onerror = () => reject(fileredr);
    fileredr.readAsDataURL(file);
  });

export default function ImageBlock(props) {
  const {
    container,
    blockProps,
    data,
    onFileChange,
    extensions,
    filesMaxSize,
    maxFiles,
    updateImagesCounterRef,
    onFileTooBig,
    onTooManyFiles,
    onExtensionNotAllowed,
  } = props;
  const { updateData, remove } = container;
  const { getInitialReadOnly } = blockProps;
  const actions = [
    {
      key: 'delete',
      icon: MegadraftIcons.DeleteIcon,
      action: (event) => {
        if (data.src) {
          updateImagesCounterRef.current((currentCount) =>
            currentCount > 0 ? currentCount - 1 : 0
          );
          !data.isNew &&
            onFileChange &&
            onFileChange({ added: [], removed: [data] });
        }
        remove(event);
      },
    },
  ];
  const readOnly = getInitialReadOnly();

  const handleCaptionChange = async (event) => {
    event.stopPropagation();

    if (maxFiles && updateImagesCounterRef !== undefined) {
      let isTooManyFiles = false;
      updateImagesCounterRef.current((currentCount) => {
        if (currentCount + 1 > maxFiles) {
          isTooManyFiles = true;
          return currentCount;
        } else {
          return currentCount + 1;
        }
      });
      if (isTooManyFiles) {
        return onTooManyFiles && onTooManyFiles();
      }
    } else if (
      maxFiles &&
      JSON.parse(
        editorStateToJSON(props.blockProps.getEditorState())
      )?.blocks?.filter((b) => b.type === 'atomic').length > maxFiles
    ) {
      return onTooManyFiles && onTooManyFiles();
    }
    if (event.target.files[0].size > filesMaxSize) {
      return onFileTooBig && onFileTooBig();
    }

    const fileName = event.target.files[0].name;
    // eslint-disable-next-line no-useless-escape
    const testAgainst = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    const extension = fileName.match(testAgainst)[0];

    if (extensions.indexOf(extension) < 0) {
      return onExtensionNotAllowed && onExtensionNotAllowed();
    }

    let file = event.target.files[0];
    if (!file) return;
    let src = await readFileAsDataURL(file);

    updateData({ src, name: file.name, isNew: true });
    onFileChange &&
      onFileChange({ added: [{ file, fileUrl: src }], removed: [] });
  };

  // const handleLinkChange = (event) => {
  //   const link = event.target.value;
  //   updateData({ link });
  // };

  const handleImageClick = (event) => {
    event.preventDefault();
    const isValid = validateUrl(data.link);
    if (!isValid) return;

    window.open(data.link, '_blank');
  };

  return (
    <CommonBlock {...props} actions={actions}>
      <BlockData>
        <BlockInput
          style={{ display: data.src ? 'none' : 'block' }}
          multiple={false}
          value=""
          onChange={handleCaptionChange}
          readOnly={readOnly}
          type="file"
          accept={extensions ? extensions.join(', ') : '*'}
        />
      </BlockData>

      <BlockContent>
        <img
          style={imageBlockStyle}
          src={data.src}
          alt={data.caption}
          onClick={handleImageClick}
        />
      </BlockContent>

      {/* <BlockData>
        <BlockInput
          placeholder="Caption"
          value={data.caption}
          onChange={handleCaptionChange}
          readOnly={readOnly}
        />

        <BlockInput
          placeholder="Link URL"
          value={data.link}
          onChange={handleLinkChange}
          readOnly={readOnly}
        />

        <BlockInput
          placeholder="Rights Holder"
          value={data.rightsHolder}
          onChange={handleRightsHolderChange}
          readOnly={readOnly}
        />
      </BlockData> */}
    </CommonBlock>
  );
}
