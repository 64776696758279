/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { styled } from './../styled';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { AimPagination } from './AimPagination';
import { AimCheckbox } from '../atoms/AimCheckbox';
import { theme } from '../../theme';
import './tableHeader.css';
import { appState } from '@aim/common';

export const AimTableRow = styled((props) => (
  <TableRow component="tr" {...props} />
))({
  height: 50,
  boxShadow: (props) =>
    props.isRowSelected
      ? '0 0 3px ' + theme.colors.primary.yellow + ' inset'
      : null,
  background: 'white',
  '&.MuiTableRow-root.Mui-selected': {
    background: 'white',
  },
});

export const AimTableCell = styled(TableCell)(({ style }) => ({
  border: 0,
  ...style,
}));

function descendingComparator(a, b, orderBy) {
  const notNullBOrderBy = b[orderBy] ? b[orderBy] : '';
  const notNullAOrderBy = a[orderBy] ? a[orderBy] : '';

  if (notNullBOrderBy < notNullAOrderBy) {
    return -1;
  }
  if (notNullBOrderBy > notNullAOrderBy) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const VisuallyHidden = styled('span')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
});

function EnhancedTableHead({
  onCheckAllClick,
  order,
  orderBy,
  numChecked,
  rowCount,
  onRequestSort,
  headCells,
  notCheckable,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!notCheckable && (
          <TableCell style={{ border: 0 }} padding="checkbox">
            <AimCheckbox
              // variant="white"
              indeterminate={numChecked > 0 && numChecked < rowCount}
              checked={rowCount > 0 && numChecked === rowCount}
              onChange={onCheckAllClick}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            style={{ border: 0, background: 'green !important' }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable === false ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <VisuallyHidden>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </VisuallyHidden>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CustomToolbar = styled(Toolbar)({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
});

const EnhancedTableToolbar = ({ title, numChecked }) => (
  <CustomToolbar>
    {numChecked > 0 ? (
      <Typography color="inherit" variant="subtitle1" component="div">
        {numChecked} selected
      </Typography>
    ) : (
      <Typography variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
    )}
  </CustomToolbar>
);

export const AimTable = ({
  className,
  notCheckable,
  headCells,
  rows,
  children: Row,
  tableContainerMaxHeight,
  selected,
  setSelected = () => {},
  onChecked,
  checkedItems,
  hideToolbar = true,
  rowKey,
  noPagination,
  pageSize = [5, 10, 25],
  bottomPagination = false,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();
  const [checked, setChecked] = useState(checkedItems || []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize[0]);

  useEffect(() => {
    if (checkedItems) setChecked(checkedItems);
  }, [checkedItems]);

  const TableRoot = styled('div')({
    flex: 1,
  });

  const CustomTableContainer = styled(TableContainer)({
    maxHeight: tableContainerMaxHeight,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCheckAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[rowKey]);
      setChecked(newSelecteds);
      onChecked && onChecked(newSelecteds);
      return;
    }
    setChecked([]);
    onChecked && onChecked([]);
  };

  const handleSelectedClick = (event, row) => {
    event.stopPropagation();
    if (selected && selected[rowKey] === row[rowKey]) {
      setSelected(null);
    } else {
      setSelected(row);
    }
  };

  const handleCheck = (event, name) => {
    event.stopPropagation();
    const selectedIndex = checked.indexOf(name);
    let newChecked = [];

    if (selectedIndex === -1) {
      newChecked = newChecked.concat(checked, name);
    } else if (selectedIndex === 0) {
      newChecked = newChecked.concat(checked.slice(1));
    } else if (selectedIndex === checked.length - 1) {
      newChecked = newChecked.concat(checked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newChecked = newChecked.concat(
        checked.slice(0, selectedIndex),
        checked.slice(selectedIndex + 1)
      );
    }

    setChecked(newChecked);
    onChecked && onChecked(newChecked);
  };

  const isChecked = (name) => checked.indexOf(name) !== -1;

  const emptyRows =
    !noPagination &&
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const SortedRows = React.memo(
    ({ rows, children }) => {
      const sorted = stableSort(rows, getComparator(order, orderBy));

      return React.cloneElement(children, { elements: sorted });
    },
    (prevProps, nextProps) =>
      prevProps.order === nextProps.order &&
      prevProps.orderBy === nextProps.orderBy
  );

  const Pager = React.memo(
    ({ elements, children }) => {
      const rows = elements.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      return rows.map((row, index) => {
        return React.cloneElement(children, {
          key: row[rowKey],
          row,
          index,
        });
        // const isValid = React.isValidElement(el);
        // return isValid ? el : 'ciao';
      });
    },
    (prevProps, nextProps) =>
      prevProps.page === nextProps.page &&
      prevProps.rowsPerPage === nextProps.rowsPerPage
  );

  const WrapperRow = React.memo(
    (props) => {
      return React.cloneElement(Row, props);
    },
    (prevProps, nextProps) => prevProps.isChecked === nextProps.isChecked
  );

  return (
    <TableRoot className={className}>
      {!hideToolbar && <EnhancedTableToolbar numChecked={checked.length} />}
      <CustomTableContainer>
        <Table
          stickyHeader
          style={{
            minWidth: 750,
            borderCollapse: 'separate',
            borderSpacing: isMobile ? '0px 3px' : '0px 7px',
          }}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="small"
        >
          <EnhancedTableHead
            numChecked={checked.length}
            order={order}
            orderBy={orderBy}
            onCheckAllClick={handleCheckAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
            headCells={headCells}
            notCheckable={notCheckable}
          />
          <TableBody>
            <SortedRows rows={rows}>
              <Pager>
                <WrapperRow
                  name="wrapper row"
                  {...{
                    rowKey,
                    isChecked,
                    handleCheck,
                    handleSelectedClick,
                  }}
                />
              </Pager>
            </SortedRows>
            {/* {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) =>
                cloneElement(Row, {
                  row,
                  rowKey,
                  index,
                  isChecked,
                  handleCheck,
                  handleSelectedClick,
                })
              )} */}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 50 * emptyRows,
                }}
              >
                <TableCell
                  colSpan={
                    notCheckable ? headCells.length : headCells.length + 1
                  }
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>

      {!noPagination && (
        <div
          {...{
            ...(bottomPagination && {
              style: {
                position: 'absolute',
                bottom: 0,
                width: `calc(${
                  appState.mainContainerSize.getValue().width
                }px - 180px)`,
              },
            }),
          }}
        >
          <AimPagination
            elementsCount={rows?.length}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={pageSize}
          />
        </div>
      )}
    </TableRoot>
  );
};
