import React, { useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';

import {
  AimIconAndTextButton,
  AimTypography,
  theme,
  AimTextFieldForm,
  AimRadioButton,
  AimFormControlLabel,
  AimRadioForm,
  AimSnackbarSeverity,
  AimSnackbar,
  CitiesAutocompleteByGeoDb,
  CountriesAutocompleteByGeoDb,
  AimFileSelector,
} from '@aim/components';

import translation from './translation';
import {
  updateBillingInformation,
  createBillingInformation,
  updateParticipation,
  getBillingInformationsPax,
  getBillingInformationsSponsor,
  updateSponsor,
  getProduct,
  getPurchase,
  updatePayment,
  getDefaultVat,
} from './gqlHelper';

import { extractBillingInformationFromFieldValues } from './utilities';

import {
  appState,
  constants,
  googleHelper,
  fileHelper,
  utilities,
} from '@aim/common';

const { getGoogleMapsPlaceByPlaceId } = googleHelper;

const { createFile, downloadFile } = fileHelper;

const formControlStyle = { width: 'calc(100% - 5px)' };

const CompanyData = ({
  i18n,
  control,
  valueType,
  companyIndividualType,
  errors,
  register,
  setValue,
  clearErrors,
  country,
  invoiceTo,
  invoiceToPublicAuthority,
  type,
  poFilePath,
  isBackofficeUser,
}) => (
  <div
    style={{
      background: theme.colors.greyScale.backgroundGrey,
      padding: 10,

      margin: '0px 10px',
    }}
  >
    <div>
      <Grid container>
        <AimTextFieldForm
          formControlStyle={{ display: 'none' }}
          control={control}
          name="id"
        />
        <Grid item xs={12} md={12}>
          <AimRadioForm
            name="invoiceTo"
            control={control}
            label={i18n.invoiceTo}
            radioVariant={'white'}
            defaultValue={constants.InvoiceToType.COMPANY.id}
            formControlStyle={
              type === constants.Clusters.Sponsor.id || type === 'purchase'
                ? { display: 'none' }
                : {}
            }
          >
            {companyIndividualType.map(({ label, value }) => (
              <AimFormControlLabel
                key={value}
                value={value}
                control={<AimRadioButton />}
                label={label}
                formControlStyle={formControlStyle}
              />
            ))}
          </AimRadioForm>
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="name"
            label={
              i18n[
                invoiceTo === constants.InvoiceToType.INDIVIDUAL.id
                  ? 'name'
                  : 'companyName'
              ]
            }
            formControlStyle={formControlStyle}
            isRequired={!isBackofficeUser}
            errors={errors}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={
            invoiceTo === constants.InvoiceToType.INDIVIDUAL.id
              ? {}
              : { display: 'none' }
          }
        >
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="surname"
            label={i18n.surname}
            formControlStyle={formControlStyle}
            isRequired={
              invoiceTo === constants.InvoiceToType.INDIVIDUAL.id &&
              !isBackofficeUser
            }
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="address"
            label={i18n.address}
            formControlStyle={formControlStyle}
            isRequired={!isBackofficeUser}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CitiesAutocompleteByGeoDb
            textfieldVariant="white"
            control={control}
            register={register}
            setValue={setValue}
            errors={errors}
            name="city"
            label={i18n.city}
            formControlStyle={formControlStyle}
            clearErrors={clearErrors}
            isRequired={!isBackofficeUser}
            onChange={(e, city) => {
              if (city?.region) {
                setValue('region', city.region);
              }

              if (city.country) {
                setValue('country', {
                  label: city.country,
                  value: city.country,
                  //hard hack only for inner update hidden iso code
                  id: city.id,
                  code: city.countryCode,
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="postalCode"
            label={i18n.postalCode}
            formControlStyle={formControlStyle}
            isRequired={!isBackofficeUser}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="region"
            label={i18n.region}
            formControlStyle={formControlStyle}
            isRequired={!isBackofficeUser}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="province"
            label={i18n.province}
            formControlStyle={formControlStyle}
            isRequired={!isBackofficeUser}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CountriesAutocompleteByGeoDb
            textfieldVariant="white"
            control={control}
            register={register}
            setValue={setValue}
            errors={errors}
            name="country"
            label={i18n.country}
            formControlStyle={formControlStyle}
            clearErrors={clearErrors}
            isRequired
          />
          <AimTextFieldForm
            formControlStyle={{ display: 'none' }}
            control={control}
            name="countryIsoCode"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="vatCode"
            label={i18n.vatCode}
            formControlStyle={formControlStyle}
            isRequired={
              invoiceTo === constants.InvoiceToType.COMPANY.id &&
              !isBackofficeUser
            }
            errors={errors}
            validate={(value) => {
              if (isBackofficeUser) {
                return true;
              }
              const countryLabelLower = country?.label
                ?.toLowerCase?.()
                ?.trim?.();
              if (
                (countryLabelLower === 'italy' ||
                  countryLabelLower === 'italia') &&
                invoiceTo === constants.InvoiceToType.COMPANY.id
              ) {
                if (value?.toUpperCase()?.startsWith('IT-')) {
                  return value.length === 14;
                } else {
                  return value.length === 11;
                }
              }
              return true;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="taxCode"
            label={i18n.taxCode}
            formControlStyle={formControlStyle}
            isRequired={
              invoiceTo === constants.InvoiceToType.INDIVIDUAL.id &&
              !isBackofficeUser
            }
            errors={errors}
            validate={(value) => {
              if (isBackofficeUser) {
                return true;
              }
              const countryLabelLower = country?.label
                ?.toLowerCase?.()
                ?.trim?.();
              if (
                (countryLabelLower === 'italy' ||
                  countryLabelLower === 'italia') &&
                invoiceTo === constants.InvoiceToType.INDIVIDUAL.id
              ) {
                return value && value.length === 16;
              }
              return true;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="reference"
            label={i18n.reference}
            formControlStyle={formControlStyle}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="email"
            label={i18n.email}
            formControlStyle={formControlStyle}
            errors={errors}
            // isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="pec"
            label={i18n.pec}
            formControlStyle={formControlStyle}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="phone"
            label={i18n.phone}
            formControlStyle={formControlStyle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="purchaseOrderNumber"
            label={i18n.purchaseOrderNumber}
            formControlStyle={formControlStyle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="uniqueCode"
            label={i18n.uniqueCode}
            formControlStyle={formControlStyle}
            errors={errors}
            validate={(value) => {
              // can be 6 for PA or 7 for B2B
              if (value) return value.length >= 6 && value.length <= 7;
              else return true;
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AimRadioForm
            name="invoiceToPublicAuthority"
            control={control}
            label={i18n.invoiceToPublicAuthority}
            defaultValue={'false'}
            radioVariant={'white'}
            formControlStyle={
              type === constants.Clusters.Sponsor.id ? { display: 'none' } : {}
            }
          >
            {valueType.map(({ label, value }) => (
              <AimFormControlLabel
                key={value}
                value={value}
                control={<AimRadioButton />}
                label={label}
                formControlStyle={formControlStyle}
              />
            ))}
          </AimRadioForm>
        </Grid>
        <Grid item xs={12} md={6}>
          <AimRadioForm
            name="isSplitPayment"
            control={control}
            label={i18n.isSplitPayment}
            radioVariant={'white'}
            defaultValue={'false'}
            formControlStyle={
              type === constants.Clusters.Sponsor.id ? { display: 'none' } : {}
            }
            disabled={invoiceToPublicAuthority === 'false'}
          >
            {valueType.map(({ label, value }) => (
              <AimFormControlLabel
                key={value}
                value={value}
                control={<AimRadioButton />}
                label={label}
                formControlStyle={formControlStyle}
              />
            ))}
          </AimRadioForm>
        </Grid>
        <Grid item xs={12} md={6}>
          <AimRadioForm
            name="isVatEvent"
            control={control}
            label={i18n.isVatEvent}
            radioVariant={'white'}
            defaultValue={'false'}
            formControlStyle={
              type === constants.Clusters.Sponsor.id ? { display: 'none' } : {}
            }
            disabled={invoiceToPublicAuthority === 'false'}
          >
            {valueType.map(({ label, value }) => (
              <AimFormControlLabel
                key={value}
                value={value}
                control={<AimRadioButton />}
                label={label}
                formControlStyle={formControlStyle}
              />
            ))}
          </AimRadioForm>
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="ipaCode"
            label={i18n.ipaCode}
            formControlStyle={
              type === constants.Clusters.Sponsor.id
                ? { ...formControlStyle, display: 'none' }
                : formControlStyle
            }
            disabled={invoiceToPublicAuthority === 'false'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={(props) => (
              <AimFileSelector
                label={i18n.po}
                variant="white"
                selectFileLabel={i18n.poUpload}
                onUploadFile={(res) => {
                  setValue('poFile', res.file);
                  props.onChange(res.parsed);
                }}
                onRemove={() => {
                  setValue('poFile', null);
                  props.onChange(null);
                }}
                onDownload={(file) => {
                  downloadFile({ file, dirPath: poFilePath });
                }}
                file={props.value}
                maxFiles={1}
                formControlStyle={
                  type === constants.Clusters.Sponsor.id
                    ? { ...formControlStyle, display: 'none' }
                    : formControlStyle
                }
                readOnly={invoiceToPublicAuthority === 'false'}
              />
            )}
            name={'po'}
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="venue"
            label={i18n.venue}
            formControlStyle={
              type === constants.Clusters.Sponsor.id
                ? { ...formControlStyle, display: 'none' }
                : formControlStyle
            }
            disabled={invoiceToPublicAuthority === 'false'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="cup"
            label={i18n.cup}
            formControlStyle={
              type === constants.Clusters.Sponsor.id
                ? { ...formControlStyle, display: 'none' }
                : formControlStyle
            }
            disabled={invoiceToPublicAuthority === 'false'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="cig"
            label={i18n.cig}
            formControlStyle={
              type === constants.Clusters.Sponsor.id
                ? { ...formControlStyle, display: 'none' }
                : formControlStyle
            }
            disabled={invoiceToPublicAuthority === 'false'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AimTextFieldForm
            textfieldVariant="white"
            control={control}
            name="tripletta"
            label={i18n.tripletta}
            formControlStyle={
              type === constants.Clusters.Sponsor.id
                ? { ...formControlStyle, display: 'none' }
                : formControlStyle
            }
            disabled={invoiceToPublicAuthority === 'false'}
          />
        </Grid>
      </Grid>
    </div>
  </div>
);

export const BillingInformations = ({
  type,
  customerId,
  history,
  intl,
  saveButtonText,
  SaveButtonIcon = CheckIcon,
  pageTitle,
  hidePageTitle,
  subTitle,
  showSubTitle,
  onAfterSave = () => {},
  // hideBillingInformationsLabel,
  backUrl,
  showSnackbar = false,
  setFormExportedValues,
  isBackofficeUser = false,
  billingInfoBookings = [],
}) => {
  const i18n = translation.billingInformations(intl);
  const [productData, setProductData] = useState();
  const [purchaseData, setPurchaseData] = useState();
  const [eventId, setEventId] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [defaultVat, setDefaultVat] = useState({
    vatRate: utilities.encodeDbNumber(22),
  });

  const valueType = [
    { value: 'true', label: i18n.yes },
    { value: 'false', label: i18n.no },
  ];

  const companyIndividualType = [
    { value: constants.InvoiceToType.COMPANY.id, label: i18n.company },
    {
      value: constants.InvoiceToType.INDIVIDUAL.id,
      label: i18n.individual,
    },
  ];

  const poFilePath = useMemo(() => {
    if (type === constants.Clusters.Pax.id) {
      return `events/${eventId}/participations/${customerId}/billing-informations/po`;
    } else if (type === constants.Clusters.Sponsor.id) {
      return `events/${eventId}/sponsor/${customerId}/billing-informations/po`;
    } else if (type === 'product') {
      return `events/${eventId}/participations/${productData?.clientId}/products/${customerId}/billing-informations/po`;
    } else if (type === 'purchase') {
      return `events/${eventId}/sponsor/${purchaseData?.purchaseId}/purchase/${customerId}/billing-informations/po`;
    }
  }, [type, customerId, eventId, productData?.clientId]);

  const {
    control,
    reset,
    handleSubmit,
    errors,
    setValue,
    getValues,
    register,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {},
    shouldUnregister: false,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const _formState = watch();
  const {
    city,
    country,
    invoiceTo,
    invoiceToPublicAuthority,
    isVatEvent,
    vatCode,
  } = _formState;

  const [edit, setEdit] = useState(false);

  // ref
  //   const gatewayRef = useRef();
  //   const additionalServicesRef = useRef();

  // output
  // {
  //   123: { vatRate: 2200, includeVat: true},
  //   234: { vatRate1: 2200, vatRate1Id: '4643637', isForced: true, vatRate2: 2200, includeVat: true}
  // }
  useEffect(() => {
    const nextFormExportValues = Object.entries(billingInfoBookings).reduce(
      (
        prev,
        [key, { vatRate1, vat1Id, vatRate2, vat2Id, participationMode }]
      ) => {
        prev[key] = utilities.vatOptions({
          participationType: participationMode,
          invoiceToPublicAuthority,
          invoiceTo,
          country,
          vatCode,
          defaultVat,
          isVatEvent,
          vatRate1,
          vat1Id,
          vatRate2,
          vat2Id,
        });
        return prev;
      },
      {}
    );

    setFormExportedValues?.(nextFormExportValues);
  }, [
    invoiceToPublicAuthority,
    isVatEvent,
    defaultVat,
    invoiceTo,
    country?.label,
    vatCode,
    billingInfoBookings,
  ]);

  useEffect(() => {
    if (country) {
      setValue('countryIsoCode', country.code);
    } else {
      setValue('countryIsoCode', '');
    }
  }, [country?.id]);

  useEffect(() => {
    const loadData = async () => {
      if (type === constants.Clusters.Pax.id && customerId) {
        getBillingInformationsPax(customerId).then(
          async ({ billingInformation, event, ...participation }) => {
            setEventId(event.id);

            const formData =
              billingInformation ||
              (await extractBillingInformationFromFieldValues(
                participation,
                false
              ));

            if (formData) {
              setEdit(true);
              reset({
                ...formData,
                invoiceTo:
                  formData.invoiceTo || constants.InvoiceToType.INDIVIDUAL.id,
                city: formData.city
                  ? { label: formData.city, value: formData.city }
                  : undefined,
                country: formData.country
                  ? { label: formData.country, value: formData.country }
                  : undefined,
                invoiceToPublicAuthority: formData?.invoiceToPublicAuthority?.toString(),
                isSplitPayment: formData?.isSplitPayment?.toString(),
                isVatEvent: formData?.isVatEvent?.toString(),
              });
            }
          }
        );
      } else if (type === constants.Clusters.Sponsor.id && customerId) {
        getBillingInformationsSponsor(customerId).then(
          async ({ billingInformation, event }) => {
            setEventId(event.id);

            if (billingInformation) {
              setEdit(true);
              reset({
                ...billingInformation,
                invoiceTo:
                  billingInformation.invoiceTo ||
                  constants.InvoiceToType.COMPANY.id,
                city: billingInformation.city
                  ? {
                      label: billingInformation.city,
                      value: billingInformation.city,
                    }
                  : undefined,
                country: billingInformation.country
                  ? {
                      label: billingInformation.country,
                      value: billingInformation.country,
                    }
                  : undefined,
                invoiceToPublicAuthority: billingInformation?.invoiceToPublicAuthority?.toString(),
                isSplitPayment: billingInformation?.isSplitPayment?.toString(),
                isVatEvent: billingInformation?.isVatEvent?.toString(),
              });
            }
          }
        );
      } else if (type === 'product' && customerId) {
        getProduct(customerId).then(async (res) => {
          setEventId(res?.serviceId);
          setProductData(res);
          if (res?.payment?.BillingInformation) {
            setEdit(true);
            const resFormData = res.payment.BillingInformation;
            reset({
              ...resFormData,
              invoiceTo:
                resFormData.invoiceTo || constants.InvoiceToType.INDIVIDUAL.id,
              city: { label: resFormData.city, value: resFormData.city },
              country: {
                label: resFormData.country,
                value: resFormData.country,
              },
              invoiceToPublicAuthority: resFormData?.invoiceToPublicAuthority?.toString(),
              isSplitPayment: resFormData?.isSplitPayment?.toString(),
              isVatEvent: resFormData?.isVatEvent?.toString(),
            });
          }
        });
      } else if (type === 'purchase' && customerId) {
        getPurchase(customerId).then(async (res) => {
          setEventId(res.eventId);
          setPurchaseData(res);
          if (res?.payment?.BillingInformation) {
            setEdit(true);
            const resFormData = res.payment.BillingInformation;
            reset({
              ...resFormData,
              invoiceTo:
                resFormData.invoiceTo || constants.InvoiceToType.INDIVIDUAL.id,
              city: { label: resFormData.city, value: resFormData.city },
              country: {
                label: resFormData.country,
                value: resFormData.country,
              },
              invoiceToPublicAuthority: resFormData?.invoiceToPublicAuthority?.toString(),
              isSplitPayment: resFormData?.isSplitPayment?.toString(),
              isVatEvent: resFormData?.isVatEvent?.toString(),
            });
          }
        });
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    getDefaultVat(false).then((res) => {
      setDefaultVat((value) => res || value);
    });
  }, []);

  const sendData = async ({ id, po, poFile, ...submittedData }) => {
    try {
      const billingInformationPoId =
        poFile && !po?.id
          ? await createFile({
              dirPath: poFilePath,
              fileData: po,
              localFile: poFile,
              useLoader: false,
            })
          : po?.id || null;

      const countryLabelLower = country?.label?.toLowerCase?.()?.trim?.();
      const vatCode =
        countryLabelLower === 'italy' || countryLabelLower === 'italia'
          ? submittedData?.vatCode?.toUpperCase()?.startsWith('IT-')
            ? submittedData?.vatCode?.toUpperCase()
            : submittedData?.vatCode
            ? `IT-${submittedData?.vatCode}`
            : submittedData?.vatCode
          : submittedData?.vatCode?.toUpperCase();
      const nextBillingInformations = {
        ...submittedData,
        billingInformationPoId,
        country: submittedData?.country?.value || '',
        city: submittedData?.city?.value || '',
        vatCode,
      };
      let billingInformationId = id;
      if (type === constants.Clusters.Pax.id) {
        if (id) {
          await updateBillingInformation({
            id,
            ...nextBillingInformations,
          });
        } else {
          billingInformationId = await createBillingInformation({
            ...nextBillingInformations,
          });
          await updateParticipation({
            id: customerId,
            participationBillingInformationId: billingInformationId,
          });
        }
      } else if (type === constants.Clusters.Sponsor.id) {
        if (id) {
          await updateBillingInformation({
            id,
            ...nextBillingInformations,
          });
        } else {
          billingInformationId = await createBillingInformation({
            ...nextBillingInformations,
          });
          await updateSponsor({
            id: customerId,
            sponsorBillingInformationId: billingInformationId,
          });
        }
      } else if (type === 'product') {
        const billingInformationInput = {
          ...nextBillingInformations,
        };

        if (id) {
          await updateBillingInformation({
            id,
            ...billingInformationInput,
          });
        } else {
          billingInformationId = await createBillingInformation(
            billingInformationInput
          );
          await updatePayment({
            id: productData.payment.id,
            paymentBillingInformationId: billingInformationId,
          });

          const paxBillingInfo = await getBillingInformationsPax(
            productData?.clientId
          );
          if (!paxBillingInfo) {
            const participationBillingInformationId = await createBillingInformation(
              billingInformationInput
            );
            await updateParticipation({
              id: productData.clientId,
              participationBillingInformationId,
            });
          }
        }
      } else if (type === 'purchase') {
        const billingInformationInput = {
          ...nextBillingInformations,
        };

        if (id) {
          await updateBillingInformation({
            id,
            ...billingInformationInput,
          });
        } else {
          billingInformationId = await createBillingInformation(
            billingInformationInput
          );
          await updatePayment({
            id: purchaseData.payment.id,
            paymentBillingInformationId: billingInformationId,
          });
        }
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.success,
      });
      // to return billing info data for purchases
      onAfterSave({
        ...nextBillingInformations,
        id: billingInformationId,
      });
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.error,
      });
    }
  };

  return (
    <>
      {backUrl ? (
        <AimIconAndTextButton
          variant="none"
          text={i18n.actions.backButton}
          style={{
            padding: 0,
          }}
          onClick={() => history.push(backUrl)}
        >
          <ArrowBack />
        </AimIconAndTextButton>
      ) : null}
      {/* <AimTypography variant="textThin" style={{ lineHeight: '0.5' }}>
        {showPageTitle &&
          (pageTitle ||
            (type === 'product'
              ? i18n.productTitle
              : !edit
              ? i18n.title
              : i18n.editTitle))}
      </AimTypography> */}

      {console.log('billing informations hidePageTitle ', hidePageTitle)}

      <form onSubmit={handleSubmit(sendData)}>
        <div
          style={{
            background: 'white',
            borderRadius: 3,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginTop: 30,
            paddingBottom: 30,
          }}
        >
          {!hidePageTitle ? (
            <div
              style={{
                background: 'white',
                borderRadius: 3,
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <AimTypography variant="h1">
                {pageTitle || i18n.billingInformations}
              </AimTypography>
            </div>
          ) : null}

          {showSubTitle && (
            <AimTypography
              variant={`textThin`}
              boxStyle={{ fontSize: '0.875rem' }}
            >
              {subTitle || i18n.subtitle}
            </AimTypography>
          )}

          <CompanyData
            i18n={i18n}
            control={control}
            errors={errors}
            valueType={valueType}
            companyIndividualType={companyIndividualType}
            register={register}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            country={country}
            invoiceTo={invoiceTo}
            invoiceToPublicAuthority={invoiceToPublicAuthority}
            type={type}
            poFilePath={poFilePath}
            isBackofficeUser={isBackofficeUser}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            marginTop: 20,
          }}
        >
          <AimIconAndTextButton
            text={saveButtonText || i18n.actions.continue}
            variant={
              appState.template.getValue() === constants.Templates.FRONTEND
                ? 'primary'
                : 'greenFill'
            }
            type="submit"
          >
            <SaveButtonIcon />
          </AimIconAndTextButton>
        </div>
      </form>
      {showSnackbar ? (
        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      ) : null}
    </>
  );
};
