import React, { useMemo, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useForm, useFieldArray } from 'react-hook-form';
import CheckIcon from '@material-ui/icons/Check';

import { AimIconAndTextButton, AimTextFieldForm } from '@aim/components';
import ReplayIcon from '@material-ui/icons/Replay';

const tabelStyle = {
  marginTop: 20,
  marginBottom: 20,
  marginLeft: 10,
  marginRight: 10,
};

const headerStyle = {
  backgroundColor: 'rgb(248, 246, 250)',
  textAlign: 'center',
  borderRight: '1px solid rgb(236, 234, 241)',
  fontWeight: 'normal',
  padding: 0,
};

const bodyStyle = {
  borderRight: '1px solid rgb(236, 234, 241)',
  textAlign: 'center',
  minWidth: 70,
  width: 70,
  height: 70,
  maxWidth: 70,
  padding: 0,
};

const bodyStyleBold = {
  ...bodyStyle,
  fontWeight: 'bold',
};

const totalCellColStyle = {
  ...bodyStyle,
  fontWeight: 'bold',
};

const totalCellRowStyle = {
  ...bodyStyle,
  fontWeight: 'bold',
  backgroundColor: 'rgb(248, 246, 250)',
  borderBottom: 0,
  borderTop: 'solid 1px rgb(236, 234, 241)',
};

const buttonsStyle = {
  display: 'flex',
  marginTop: 20,
  alignItems: 'end',
  justifyContent: 'flex-end',
};

const getCellStyle = ({
  cell: cell,
  rowIndex: rowIndex,
  index: index,
  isFirstRow: isFirstRow,
  isSecondRow: isSecondRow,
  cellTotalsLength: cellTotalsLength,
  getValues: getValues,
}) => {
  let style = cell.isHeader ? headerStyle : bodyStyle;

  if (isFirstRow && rowIndex === 0) {
    style = {
      ...style,
      borderBottom: 0,
      borderTop: 'solid 1px rgb(236, 234, 241)',
      paddingBottom: 10,
      paddingTop: 10,
    };
  }

  if (isSecondRow && rowIndex === 1) {
    style = {
      ...style,
      borderRight: 0,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
    };
    if (index === 0) {
      style = { ...style, borderRight: 'solid 1px rgb(236, 234, 241)' };
    }
    if (index % cellTotalsLength === 0) {
      style = { ...style, borderRight: 'solid 1px rgb(236, 234, 241)' };
    }
  }

  if (cell.isFirst) {
    style = {
      ...style,
      borderLeft: 'solid 1px rgb(236, 234, 241)',
      minWidth: 150,
      paddingLeft: 16,
      textAlign: 'left',
    };
  }
  if (cell.isBold) {
    style = { ...style, fontWeight: 'bold' };
  }

  if (cell.renderStyle) {
    style = {
      ...style,
      ...cell.renderStyle(transformData(getValues(rowIndex)), index, rowIndex),
    };
  }
  return style;
};

const HeaderRow = ({
  headerRow,
  rowIndex,
  totalRowText,
  showTotalsCell,
  totalsCell,
}) => {
  const cellTotals = totalsCell[rowIndex];
  const cellTotalsLength = cellTotals?.length ?? 0;

  return (
    <TableRow key={rowIndex}>
      {headerRow.map((cell, index) => (
        <TableCell
          key={index}
          colSpan={
            rowIndex === 0 && index !== 0 ? cellTotalsLength : cell.colSpan
          }
          style={getCellStyle({
            cell: cell,
            rowIndex: rowIndex,
            index: index,
            isFirstRow: rowIndex === 0,
            isSecondRow: rowIndex === 1,
            cellTotalsLength: cellTotalsLength,
          })}
        >
          {cell.label}
        </TableCell>
      ))}
      {showTotalsCell &&
        (rowIndex === 0 ? (
          <TableCell style={totalCellRowStyle} colSpan={cellTotalsLength}>
            {totalRowText}
          </TableCell>
        ) : (
          [...Array(cellTotalsLength)].map((_, index) => (
            <TableCell
              key={index}
              style={{
                backgroundColor: 'rgb(248, 246, 250)',
                padding: 0,
                ...(index === 0
                  ? {
                      borderLeft: '1px solid rgb(236, 234, 241)',
                    }
                  : {}),
                ...(index === cellTotalsLength - 1
                  ? {
                      borderRight: '1px solid rgb(236, 234, 241)',
                    }
                  : {}),
              }}
            />
          ))
        ))}
    </TableRow>
  );
};

const BodyRow = ({
  rowIndex,
  control,
  totalsCell,
  showTotalsCell,
  getValues,
  watch,
}) => {
  const { fields: rows } = useFieldArray({
    control,
    name: `rows[${rowIndex}].cells`,
    keyName: 'formKey',
  });

  const currentCellTotals = totalsCell[rowIndex];
  return (
    <TableRow>
      {rows.map((cell, index) => (
        <TableCell
          key={cell.formKey}
          colSpan={cell.colSpan}
          style={getCellStyle({
            cell: cell,
            rowIndex: rowIndex,
            index: index,
            getValues: getValues,
          })}
        >
          {cell.isEditable ? (
            <AimTextFieldForm
              type={cell.type ? cell.type : 'text'}
              control={control}
              name={`rows[${rowIndex}].cells[${index}].label`}
              style={{
                minWidth: '100%',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              formControlStyle={{
                minWidth: '100%',
                padding: 0,
              }}
              inputPropsStyle={{
                textAlign: 'center',
                padding: 0,
                margin: 0,
                marginLeft: cell.type === 'number' ? 15 : 0,
                fontSize: '0.875rem',
                fontWeight: 'bold',
              }}
              fieldStyle={{
                backgroundColor: 'transparent',
                color: 'inherit',
              }}
              displayLabel={false}
              defaultValue={cell.label}
            />
          ) : (
            <>
              {cell.label}
              <AimTextFieldForm
                control={control}
                name={`rows[${rowIndex}].cells[${index}].label`}
                formControlStyle={{ display: 'none' }}
                defaultValue={cell.label}
              />
            </>
          )}
        </TableCell>
      ))}
      {showTotalsCell &&
        currentCellTotals &&
        currentCellTotals.map((total, index) => (
          <TableCell key={index} style={bodyStyleBold}>
            {total}
          </TableCell>
        ))}
    </TableRow>
  );
};

const calculateTotalsCol = (data) => {
  if (!data || data.length === 0) return [];

  const numberOfColumns = data[0].cells.length - 1;
  const totals = new Array(numberOfColumns).fill(0);

  data.forEach((row) => {
    for (let i = 1; i < row.cells.length; i++) {
      const cell = row.cells[i];
      const value = Number(cell.label);
      if (!isNaN(value)) {
        totals[i - 1] += value;
      }
    }
  });

  return totals;
};

const calculateSumTotalsCell = (data) => {
  if (!data || data.length === 0) return [];

  const numberOfColumns = Math.max(...data.map((row) => row.length));
  let sumTotalsCell = new Array(numberOfColumns).fill(0);

  data.forEach((row) => {
    row.forEach((value, index) => {
      if (index < numberOfColumns) {
        sumTotalsCell[index] += value;
      }
    });
  });

  return sumTotalsCell;
};

const calculateTotalPercentage = (
  data,
  paramsPercentFirst,
  paramsPercentSecond,
  headerData
) => {
  if (headerData?.length === 0) return [];
  const headersParams = headerData?.slice(1)[0].slice(1);

  const findAllIndices = (label) =>
    headersParams.reduce((indices, header, index) => {
      if (header.label === label) {
        indices.push(index);
      }
      return indices;
    }, []);

  const indicesFirstParam = findAllIndices(paramsPercentFirst);
  const indicesSecondParam = findAllIndices(paramsPercentSecond);

  let sumFirstParams = indicesFirstParam.map((index) =>
    data.reduce((sum, row) => sum + Number(row.cells[index + 1].label || 0), 0)
  );

  let sumSecondParams = indicesSecondParam.map((index) =>
    data.reduce((sum, row) => sum + Number(row.cells[index + 1].label || 0), 0)
  );

  let percentages = sumFirstParams.map((sumFirst, i) => {
    const sumSecond = sumSecondParams[i];
    if (sumSecond) {
      let percentage = (sumFirst / sumSecond) * 100;
      percentage = Math.round(percentage);
      return percentage === 0
        ? '0.0'
        : percentage < 1
        ? '1.0'
        : percentage.toString();
    }
    return '0.0';
  });

  return percentages;
};

const calculateTotalsCell = (data, headerData) => {
  if (headerData?.length === 0) return [];

  const headersParams = headerData?.slice(1)[0].slice(1);

  const uniqueParams = [...new Set(headersParams.map((cell) => cell.label))];
  const indicesMap = uniqueParams.reduce((acc, param) => {
    acc[param] = headersParams
      .map((header, index) => (header.label === param ? index + 1 : -1))
      .filter((index) => index !== -1);
    return acc;
  }, {});

  return data.map((row) => {
    const totals = uniqueParams.map((param) => {
      return indicesMap[param].reduce((sum, index) => {
        const value = Number(row.cells[index]?.label || 0);
        return sum + value;
      }, 0);
    });
    return totals;
  });
};

const transformData = (data) => data?.rows?.map((row) => row.cells);

export const NumericTableGrid = ({
  showTotalsCol = true,
  showTotalsCell = false,
  shotTotalsPercentage = true,
  showButtonSave = false,
  showButtonCancel = false,
  paramsPercentFirst = 'booked',
  paramsPercentSecond = 'available',
  numberOfCell = 4,
  bodyData = [],
  headerData = [],
  onClickSubmit = () => {},
  handleDisagree = () => {},
  formStyle,
  totlaPercentText = 'Total occupancy',
  totalRowText = 'Total',
  totalColText = 'Total',
  textCancelButton = 'cancel',
  textSaveButton = 'save',
}) => {
  const { handleSubmit, control, reset, watch, getValues } = useForm({
    defaultValues: { rows: [] },
  });

  const { fields: data } = useFieldArray({
    control,
    name: 'rows',
    keyName: 'formKey',
  });

  useEffect(() => {
    const data = bodyData.map((row) => ({
      cells: row,
    }));
    reset({ rows: data });
  }, [bodyData, reset]);

  const watchedRows = watch('rows');

  const calculateTotals = (data) => {
    const newTotalsCol = calculateTotalsCol(data);
    const newPercentages = calculateTotalPercentage(
      data,
      paramsPercentFirst,
      paramsPercentSecond,
      headerData
    );
    const newTotalsCell = calculateTotalsCell(data, headerData);
    const newSumTotalsCell = calculateSumTotalsCell(newTotalsCell);
    console.log('newSumTotalsCell', newSumTotalsCell);
    return {
      totalsCol: newTotalsCol,
      percentages: newPercentages,
      totalsCell: newTotalsCell,
      sumTotalsCell: newSumTotalsCell,
    };
  };

  const totals = useMemo(() => calculateTotals(watchedRows), [watchedRows]);

  const sendData = (data) => {
    const row = transformData(data);
    onClickSubmit(row);
  };

  return (
    <form
      onSubmit={handleSubmit(sendData)}
      style={{ ...tabelStyle, ...formStyle }}
    >
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {headerData.map((headerRow, rowIndex) => (
              <HeaderRow
                key={rowIndex}
                headerRow={headerRow}
                rowIndex={rowIndex}
                totalRowText={totalRowText}
                totalsCell={totals.totalsCell}
                showTotalsCell={showTotalsCell}
              />
            ))}
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <BodyRow
                key={row.formKey}
                rowIndex={rowIndex}
                control={control}
                totalsCell={totals.totalsCell}
                showTotalsCell={showTotalsCell}
                watch={watch}
                getValues={getValues}
              />
            ))}
            {showTotalsCol && (
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    borderRight: '1px solid rgb(236, 234, 241)',
                    borderLeft: 'solid 1px rgb(236, 234, 241)',
                  }}
                >
                  {totalColText}
                </TableCell>
                {totals.totalsCol.map((total, index) => (
                  <TableCell key={index} style={totalCellColStyle}>
                    {total}
                  </TableCell>
                ))}
                {showTotalsCell &&
                  totals.sumTotalsCell.map((total, index) => (
                    <TableCell key={index} style={totalCellColStyle}>
                      {total}
                    </TableCell>
                  ))}
              </TableRow>
            )}
            {shotTotalsPercentage && (
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    borderRight: '1px solid rgb(236, 234, 241)',
                    borderLeft: 'solid 1px rgb(236, 234, 241)',
                  }}
                >
                  {totlaPercentText}
                </TableCell>
                {totals.percentages.map((perc, index) => (
                  <TableCell
                    key={index}
                    style={totalCellColStyle}
                    colSpan={numberOfCell}
                  >
                    {perc}%
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={buttonsStyle}>
        {showButtonCancel && (
          <AimIconAndTextButton
            isLowerCase
            onClick={handleDisagree}
            variant="greyFill"
            text={textCancelButton}
          >
            <ReplayIcon />
          </AimIconAndTextButton>
        )}
        {showButtonSave && (
          <AimIconAndTextButton
            type="submit"
            variant="greenFill"
            text={textSaveButton}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        )}
      </div>
    </form>
  );
};
