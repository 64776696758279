import React from 'react';
import useI18n from './i18n';
import { AimDialog } from '../../atoms/AimDialog';
import { AimTypography } from '../../atoms/AimTypography';

export const AimAlertDialog = ({ dialog, setDialog, intl }) => {
  const i18n = useI18n(intl);

  return (
    <AimDialog
      title={dialog.title}
      open={dialog.isOpen}
      disagreeText={i18n.close}
      onClose={() => setDialog({ isOpen: false })}
      hideAgreeButton
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <AimTypography variant="body1">{dialog.message}</AimTypography>
      </div>
    </AimDialog>
  );
};
