import React, { useEffect } from 'react';

import { styled } from './../styled';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { AimTextField } from './AimTextField';

import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AimTypography } from './AimTypography';

const CustomAutocomplete = styled(Autocomplete)({
  '& .aim-event-MuiTextField-root': { height: '100%' },
});

export const AimAutocomplete = ({
  label,
  placeholder,
  formControlStyle,
  textfieldVariant,
  addAsterisk = false,
  style,
  limit = 20,
  // value = '',
  ...rest
}) => {
  const defaultFilterOptions = createFilterOptions({ limit });
  return (
    <CustomAutocomplete
      filterOptions={(options, state) => defaultFilterOptions(options, state)}
      {...rest}
      style={style}
      autoHighlight
      autoComplete={false}
      autoSelect={false}
      filterSelectedOptions
      renderInput={(params) => (
        <AimTextField
          {...params}
          textfieldVariant={textfieldVariant}
          addAsterisk={addAsterisk}
          label={label}
          displayLabel={label && label !== 'undefined' ? true : false}
          placeholder={placeholder}
          formControlStyle={formControlStyle}
          InputProps={{
            ...params.InputProps,
            style: {
              ...params.InputProps,
              '&:hover': {
                background: 'transparent !important',
              },
            },
          }}
        />
      )}
    ></CustomAutocomplete>
  );
};

export const AimAutocompleteMultipleForm = ({
  setValue,
  register,
  name,
  control,
  errors,
  isRequired,
  defaultValue,
  label,
  options = [],
  clearErrors,
  ...rest
}) => {
  useEffect(() => {
    if (!isRequired) return;
    register(name, {
      validate: (value) => {
        if (value) {
          clearErrors(name);
        }
        return !!value || 'This is required.';
      },
    });
  }, [register]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(props) => (
          <AimAutocomplete
            multiple
            label={label}
            autoHighlight
            value={props.value || []}
            options={options}
            getOptionLabel={(option) => option.label || []}
            getOptionSelected={(opt, value) => opt.value === value?.value}
            onChange={(e, value) => {
              setValue(name, value, { shouldDirty: true });
              clearErrors(name);
            }}
            {...rest}
          />
        )}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <AimTypography variant="formError">
              {message || `${label} is required`}
            </AimTypography>
          )}
        />
      )}
    </>
  );
};

export const AimAutocompleteForm = ({
  setValue,
  register,
  name,
  control,
  errors,
  isRequired,
  defaultValue,
  label,
  options = [],
  clearErrors,
  onInputChangeFn,
  addAsterisk = false,
  onChange,
  ...rest
}) => {
  useEffect(() => {
    if (!isRequired) return;
    register(name, {
      validate: (value) => {
        if (value) {
          clearErrors(name);
        }
        return !!value || 'This is required.';
      },
    });
  }, [register]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(props) => (
          <AimAutocomplete
            label={label}
            addAsterisk={isRequired || addAsterisk}
            autoHighlight
            value={props.value || ''}
            options={options}
            getOptionLabel={(option) => option.label || ''}
            getOptionSelected={(opt, value) => opt.value === value?.value}
            onChange={(e, value) => {
              setValue(name, value, { shouldDirty: true });
              clearErrors(name);
              onChange && onChange(e, value);
            }}
            onInputChange={(e, value) => {
              onInputChangeFn && onInputChangeFn(value);
            }}
            {...rest}
          />
        )}
      />
      {/* <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(props) =>
          console.log('props.value', props.value) || (
            <AimAutocomplete
              options={options}
              getOptionLabel={(option) => option?.label || ''}
              // {...rest}
              label={label}
              value={props.value}
              onChange={(e, item) => {
                console.log('onchange', item);
                props.onChange(item.value);
              }}
              // inputValue={props.value}
              //NELLA VERSIONE NUOVA DI MATERIAL E' DIVENTATO
              //isOptionEqualToValue
              getOptionSelected={(opt, value) => opt.value === value}
            />
          )
        }
        // rules={{
        //   required: isRequired,
        // }}
      /> */}
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <AimTypography variant="formError">
              {message || `${label} is required`}
            </AimTypography>
          )}
        />
      )}
    </>
  );
};

export const AimAutocompleteDynamic = ({
  control,
  options,
  register,
  setValue,
  getValues,
  errors,
  label,
  placeholder,
  name,
  formControlStyle,
  isRequired,
  disabled,
  clearErrors,
  ...rest
}) => {
  const opts = typeof options === 'string' ? JSON.parse(options) : options;
  return (
    <AimAutocompleteForm
      label={label}
      errors={errors}
      placeholder={placeholder}
      name={name}
      control={control}
      isRequired={isRequired}
      disabled={disabled}
      register={register}
      setValue={setValue}
      getValues={getValues}
      options={opts || []}
      // getOptionLabel={(option) => option?.label || ''}
      formControlStyle={formControlStyle}
      clearErrors={clearErrors}
      {...rest}
    ></AimAutocompleteForm>
  );
};
