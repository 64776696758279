import React from 'react';
import { AimDialog } from '../../../atoms/AimDialog';
import { styled } from '../../../styled';

export const CustomStyledDialog = styled(({ ...other }) => (
  <AimDialog {...other} />
))({
  '& .MuiDialog-scrollPaper': {
    '& ::-webkit-scrollbar': {
      width: 6,
    },
    '& ::-webkit-scrollbar-track': {
      background: '#ffffff',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: 'rgba(149, 152, 154, 0.43)',
      borderRadius: 5,
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(149, 152, 154, 1)',
    },
  },
});

export const formControlStyle = { width: 'calc(100% - 16px)' };
