const translation = {
  billingInformations: (intl) => ({
    actions: {
      continue: intl.formatMessage({
        description: 'billing informations form save button',
        defaultMessage: 'Save',
      }),
      backButton: intl.formatMessage({
        description: 'billing informations form back button',
        defaultMessage: 'Back',
      }),
    },
    title: intl.formatMessage({
      description: 'New Billing customer title',
      defaultMessage: 'New billing customer',
    }),
    editTitle: intl.formatMessage({
      description: 'Edit Billing customer title',
      defaultMessage: 'Edit billing customer',
    }),
    productTitle: intl.formatMessage({
      description: 'Purchase billing info title',
      defaultMessage: 'Purchase billing information',
    }),
    billingInformations: intl.formatMessage({
      description: 'billing informations form card title',
      defaultMessage: 'Billing Informations',
    }),
    yes: intl.formatMessage({
      description: 'billing informations form yes label',
      defaultMessage: 'Yes',
    }),
    no: intl.formatMessage({
      description: 'billing informations form no label',
      defaultMessage: 'No',
    }),
    company: intl.formatMessage({
      description: 'billing informations form company label',
      defaultMessage: 'Company',
    }),
    individual: intl.formatMessage({
      description: 'billing informations form individual label',
      defaultMessage: 'Individual',
    }),
    address: intl.formatMessage({
      description: 'billing informations form address label',
      defaultMessage: 'Address',
    }),
    city: intl.formatMessage({
      description: 'billing informations form city label',
      defaultMessage: 'City',
    }),
    email: intl.formatMessage({
      description: 'billing informations form email label',
      defaultMessage: 'Email',
    }),
    pec: intl.formatMessage({
      description: 'billing informations form pec label',
      defaultMessage: 'Pec',
    }),
    phone: intl.formatMessage({
      description: 'billing informations form phone label',
      defaultMessage: 'Phone',
    }),
    companyName: intl.formatMessage({
      description: 'billing informations form company name label',
      defaultMessage: "Company's name",
    }),
    name: intl.formatMessage({
      description: 'billing informations form name label',
      defaultMessage: 'Name',
    }),
    surname: intl.formatMessage({
      description: 'billing informations form surname label',
      defaultMessage: 'Surname',
    }),
    postalCode: intl.formatMessage({
      description: 'billing informations form postal code/zip label',
      defaultMessage: 'Postal code/Zip',
    }),
    regionProvinceState: intl.formatMessage({
      description: 'billing informations form region/province/state label',
      defaultMessage: 'Region/Province/State',
    }),
    region: intl.formatMessage({
      description: 'billing informations form region label',
      defaultMessage: 'Region',
    }),
    province: intl.formatMessage({
      description: 'billing informations form province label',
      defaultMessage: 'Province',
    }),
    country: intl.formatMessage({
      description: 'billing informations form country label',
      defaultMessage: 'Country',
    }),
    vatCode: intl.formatMessage({
      description: 'billing informations form vat number label',
      defaultMessage: 'VAT Number',
    }),
    taxCode: intl.formatMessage({
      description: 'billing informations form fiscal code label',
      defaultMessage: 'Fiscal code',
    }),
    reference: intl.formatMessage({
      description: 'billing informations form reference label',
      defaultMessage: 'Reference',
    }),
    invoiceToPublicAuthority: intl.formatMessage({
      description:
        'Billing informations form Invoice to public authority label',
      defaultMessage: 'Invoice to public authority',
    }),
    invoiceTo: intl.formatMessage({
      description: 'billing informations form invoice to label',
      defaultMessage: 'Invoice to:',
    }),
    uniqueCode: intl.formatMessage({
      description: 'billing informations form unique code label',
      defaultMessage: 'Unique Code',
    }),
    ipaCode: intl.formatMessage({
      description: 'billing informations form ipa code label',
      defaultMessage: 'IPA code',
    }),
    isSplitPayment: intl.formatMessage({
      description: 'billing informations form split payment? label',
      defaultMessage: 'Split Payment?',
    }),
    isVatEvent: intl.formatMessage({
      description:
        'Billing informations form Is VAT Exent? (Art.10 DPR N. 633/72) label',
      defaultMessage: 'Is VAT Exent? (Art.10 DPR N. 633/72)',
    }),
    purchaseOrderNumber: intl.formatMessage({
      description: 'billing informations form purchase order number label',
      defaultMessage:
        'Purchase order (if you want you can insert the order number)',
    }),
    po: intl.formatMessage({
      description: 'billing informations form po label',
      defaultMessage: 'PO',
    }),
    poUpload: intl.formatMessage({
      description: 'billing informations form po upload label',
      defaultMessage: 'Upload',
    }),
    venue: intl.formatMessage({
      description: 'billing informations form venue label',
      defaultMessage: 'Venue',
    }),
    cup: intl.formatMessage({
      description: 'billing informations form cup label',
      defaultMessage: 'CUP',
    }),
    cig: intl.formatMessage({
      description: 'billing informations form cig label',
      defaultMessage: 'CIG',
    }),
    tripletta: intl.formatMessage({
      description: 'billing informations form tripletta label',
      defaultMessage: 'Tripletta',
    }),
    snackbar: {
      success: intl.formatMessage({
        description: 'Billing informations form save success snackbar text',
        defaultMessage: 'Save successful',
      }),
      error: intl.formatMessage({
        description: 'Billing informations form save error snackbar text',
        defaultMessage: 'An error has occurred',
      }),
    },
  }),
};

export default translation;
