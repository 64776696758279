import React from 'react';
import { AimTypography } from './AimTypography';
// import { navigateToUrl } from 'single-spa';

const handleNavigateToUrl = (event, redirectUrl, target = '_blank') => {
  event.stopPropagation();
  // navigateToUrl(redirectUrl,);
  globalThis.open(redirectUrl, target);
};

export const AimLink = ({
  text,
  to,
  textVariant,
  color,
  target,
  style,
  margin,
}) => (
  <AimTypography
    variant={textVariant || 'text'}
    style={{ ...style, color: color || '#30CFD0' }}
    margin={margin}
    onClick={(e) => handleNavigateToUrl(e, to, target)}
  >
    {text}
  </AimTypography>
);
