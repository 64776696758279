import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import { styled } from './../styled';
import { theme } from './../../theme';
import { AimTypography } from './AimTypography';
import { appState, constants } from '@aim/common';

const StyledCard = styled(Card)(({ cardVariant, shadow }) => ({
  margin: 10,
  borderRadius: 0,
  boxShadow: shadow
    ? '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    : 'none',
  ...cardVariant,
}));

const StyledCardHeader = styled(CardHeader)({
  padding: '16px 16px 4px 16px !important',
});

const StyledCardContent = styled(CardContent)(({ innerHeight }) => ({
  padding: '8px 16px 8px 16px !important',
  height: innerHeight || 'unset',
}));

const StyledDivider = styled(Divider)({
  margin: '0px 16px 0px 16px !important',
});

const variantsBACKEND = {
  white: {
    background: theme.colors.greyScale.white,
    borderRadius: 0,
  },
  grey: {
    background: theme.colors.greyScale.backgroundGrey,
    borderRadius: 0,
  },
};

const variantsFRONTEND = {
  white: {
    background: theme.colors.primary.white,
    borderRadius: 4,
  },
  grey: {
    background: theme.colors.greyScale.backgroundGrey,
    borderRadius: 4,
  },
};

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const variantType = appState.template.getValue();

export const AimCard = ({
  children,
  actionChildren,
  title,
  subTitle,
  bordered,
  titleBoxStyle,
  innerHeight,
  cardVariant = 'white',
  titleVariant = 'h4',
  subTitleVariant = 'h5Regular',
  shadow = false,
  endDivider = false,
  startDivider = true,
  ...rest
}) => {
  const styleBordered = {
    border: `2px solid ${theme.colors.primary.yellow}`,
    borderRadius: 5,
  };

  const templateVariant = variants[variantType] || variantsBACKEND;

  return (
    <StyledCard
      style={bordered ? styleBordered : null}
      cardVariant={templateVariant[cardVariant]}
      {...(shadow && { shadow: { shadow } })}
      {...rest}
    >
      {(actionChildren || title || subTitle) && (
        <>
          <StyledCardHeader
            disableTypography
            title={
              title ? (
                <AimTypography
                  variant={titleVariant}
                  margin={0}
                  boxStyle={titleBoxStyle}
                >
                  {title}
                </AimTypography>
              ) : null
            }
            subheader={
              subTitle ? (
                <AimTypography variant={subTitleVariant} margin={0}>
                  {subTitle}
                </AimTypography>
              ) : null
            }
            action={actionChildren}
          />
          {startDivider && <StyledDivider />}
        </>
      )}

      <StyledCardContent innerHeight={innerHeight}>
        {children}
      </StyledCardContent>
      {endDivider && <StyledDivider />}
    </StyledCard>
  );
};
