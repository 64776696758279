import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { isMobile } from 'react-device-detect';

import { AimTypography, AimIconAndTextButton } from './../atoms';
import { styled } from '../styled';
import { theme } from '../../theme';

const StyledCard = styled(Card)(({ cardVariant }) => ({
  ...variants[cardVariant],
  padding: 16,
  margin: 10,
  width: isMobile ? '300px' : '653px',
  height: isMobile ? 'unset' : '104px',
}));

const StyledCardContent = styled(CardContent)({
  padding: '0px !important',
  display: 'flex',
  height: '100%',

  flexDirection: isMobile ? 'column' : 'row',
  justifyContent: isMobile ? 'center' : 'unset',
  alignItems: isMobile ? 'center' : 'unset',
});

const FirstColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 2,
  justifyContent: 'center',
});

const SecondColumn = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
const AvatarColumn = styled('div')({
  alignItems: 'center',
  marginRight: '10px',
  display: 'flex',
});

const variants = {
  white: {
    backgroundColor: `${theme.colors.greyScale.white} !important`,
  },
  grey: {
    backgroundColor: `#F3F3F4 !important`,
  },
};

export const AimWallButtonCard = ({
  title,
  description,
  onContainerClick,
  onButtonClick,
  disableClick,
  textButton,
  cardVariant = 'white',
  logoVirtualBag,
  buttonDisabled,
  cardStyle,
}) => {
  return (
    <StyledCard
      cardVariant={cardVariant}
      style={cardStyle}
      onClick={() => !disableClick && onContainerClick && onContainerClick()}
    >
      <StyledCardContent>
        <AvatarColumn>
          {logoVirtualBag && <Avatar src={logoVirtualBag} alt={'avatar'} />}
        </AvatarColumn>
        <FirstColumn>
          <AimTypography
            margin={0}
            style={{
              fontWeight: 'bold',
              fontSize: '1.125rem',
              textTransform: 'uppercase',
              margin: 0,
            }}
          >
            {title}
          </AimTypography>
          <AimTypography margin={0} variant="text">
            {description}
          </AimTypography>
        </FirstColumn>
        <SecondColumn>
          <AimIconAndTextButton
            isUpperCase
            variant={'primary'}
            text={textButton}
            onClick={onButtonClick}
            disabled={buttonDisabled}
          />
        </SecondColumn>
      </StyledCardContent>
    </StyledCard>
  );
};
