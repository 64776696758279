import React from 'react';

import { styled } from './../styled';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const CustomAlert = styled(Alert)({
  background: '#fff',
});

export const AimSnackbarSeverity = {
  info: 'info',
  error: 'error',
  warning: 'warning',
  success: 'success',
};

const colors = {
  info: 'blue',
  error: 'red',
  warning: 'yellow',
  success: 'green',
};

const capitalizeFirstLetter = (string) => {
  return string.length ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const AimSnackbar = ({
  open,
  onClose,
  severity = '',
  children,
  customStyle,
  alertActions,
  autoHideDuration = 6000,
  autoHide = true,
  ...rest
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    onClose && onClose(false);
  };

  if (!onClose) throw new Error('AimSnackbar - onClose attribute is required');

  return (
    <Snackbar
      {...{
        open: open,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        ...(autoHide && { autoHideDuration }),
        onClose: handleClose,
      }}
      {...rest}
    >
      <CustomAlert
        elevation={6}
        variant="filled"
        style={customStyle ? { ...customStyle } : { color: colors[severity] }}
        onClose={handleClose}
        severity={severity}
        action={alertActions}
      >
        <AlertTitle>{capitalizeFirstLetter(severity)}</AlertTitle>
        {children}
      </CustomAlert>
    </Snackbar>
  );
};
