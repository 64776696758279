/* eslint-disable react/display-name */
import React, { useState, useRef } from 'react';

import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
  //   PDFViewer,
  BlobProvider,
} from '@react-pdf/renderer';
import PDFViewer from 'mgr-pdf-viewer-react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { fileHelper } from '@aim/common';
import { AimIconAndTextButton } from '../atoms/AimIconAndTextButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { globalStyles } from './AimReactPdfRenderer/ReactPdfHelper';
import './AimReactPdfRenderer/PDFViewer.css';
import { styled } from '../styled';
import translation from './AbstractDetailPage/translation';
import { chunk } from 'lodash';

// Font.register({
//   family: 'Roboto',
//   fonts: [
//     { src: RobotoRegular, fontStyle: 'normal', fontWeight: 400 },
//     { src: RobotoBold, fontStyle: 'normal', fontWeight: 700 },
//     { src: RobotoBoldItalic, fontStyle: 'italic', fontWeight: 700 },
//   ],
// });

const CustomIconButton = styled(IconButton)({
  '&:hover': {
    background: 'transparent !important',
  },
});

const AbstractDocument = ({ document, i18n }) => {
  const sections = sortAndParseAbstractSections(
    document?.abstractSections?.items || []
  );

  const styles = StyleSheet.create({
    title: {
      fontSize: 24,
      textAlign: 'center',
    },
    paragraph: {
      marginTop: 20,
    },
    row: {
      marginTop: 5,
      flexDirection: 'row',
    },
    bold: {
      fontWeight: 'bold',
    },
    labelBold: {
      fontSize: 10,
      fontWeight: 'bold',
    },
    label: {
      fontSize: 10,
      flexGrow: 0,
      width: 100,
    },
    value: {
      fontSize: 10,
      flexGrow: 1,
    },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    table: {
      marginTop: 20,
      borderWidth: 1,
      padding: 5,
    },
    tableHead: {
      fontSize: 10,
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 'bold',
      borderBottom: '1px solid black',
    },
    tableRow: {
      marginTop: 5,
      fontSize: 10,
      flexDirection: 'row',
      alignItems: 'center',
    },
    cell: {
      width: '20%',
    },
    author: {
      fontSize: 10,
      textAlign: 'center',
      marginBottom: 40,
    },
    text: {
      margin: 12,
      fontSize: 10,
      textAlign: 'justify',
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
  });

  // Functions
  const extractPresenterFields = (author) => {
    const affiliation = author.affiliation
      ? {
          name: i18n.page.authors.affiliation.label,
          value: author.affiliation.institution ?? '',
        }
      : null;
    const affiliationArray = affiliation ? [{ ...affiliation }] : [];
    return [
      ...author.fieldValues.items.map((f) => {
        const nextValue = f?.value !== null ? JSON.parse(f?.value) : '';
        return {
          name: f.fieldDefinition?.label ?? '',
          value: nextValue?.label || nextValue,
        };
      }),
      ...affiliationArray,
    ];
  };

  // Functions
  const extractAuthorsFields = (authors) => {
    const affiliation = authors?.[0]?.affiliation
      ? {
          name: i18n.page.authors.affiliation.label,
          values: authors.map(
            (author) => author?.affiliation?.institution || ''
          ),
        }
      : null;
    const affiliationArray = affiliation ? [{ ...affiliation }] : [];
    const nextAuthorFields = authors?.[0]?.fieldValues.items.map((f) => ({
      name: f.fieldDefinition?.label ?? '',
      values: authors.map((author) => {
        const nextValue =
          author?.fieldValues?.items?.find(
            (fv) => fv.fieldDefinition?.id === f.fieldDefinition?.id
          )?.value !== null
            ? JSON.parse(
                author?.fieldValues?.items?.find(
                  (fv) => fv.fieldDefinition?.id === f.fieldDefinition?.id
                )?.value
              )
            : '';

        return nextValue?.label || nextValue;
      }),
    }));

    return [...nextAuthorFields, ...affiliationArray];
  };

  const presenterFields = document?.presenter
    ? extractPresenterFields(document.presenter)
    : [];

  const presenterChunks = chunk(presenterFields, 5);

  const authorsFields = document?.authors?.items?.length
    ? extractAuthorsFields(document.authors.items)
    : [];

  const authorsChuncks = chunk(authorsFields, 5);
  return (
    <>
      <Text style={styles.title}>{document?.title}</Text>
      <View style={styles.paragraph}>
        <View style={styles.row}>
          <Text style={styles.label}>
            <Text style={styles.bold}>Category: </Text>
          </Text>
          <Text style={styles.value}>
            <Text>{document?.category?.name || '-'}</Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>
            <Text style={styles.bold}>Subcategory: </Text>
          </Text>
          <Text style={styles.value}>
            <Text>{document?.subCategory?.name || '-'}</Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>
            <Text style={styles.bold}>Tag: </Text>
          </Text>
          <Text style={styles.value}>
            <Text>{document?.tags?.map((i) => i).join(', ') || '-'}</Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>
            <Text style={styles.bold}>Presentation: </Text>
          </Text>
          <Text style={styles.value}>
            <Text>
              {document?.acceptedPresentationTypology?.name ||
                document?.proposedPresentationTypology?.name ||
                '-'}
            </Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>
            <Text style={styles.bold}>Keywords: </Text>
          </Text>
          <Text style={styles.value}>
            <Text>{document?.keywords?.map((i) => i).join(', ') || '-'}</Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>
            <Text style={styles.bold}>Status: </Text>
          </Text>
          <Text style={styles.value}>
            <Text>{document?.status || '-'}</Text>
          </Text>
        </View>
      </View>
      {presenterChunks.length ? (
        <View>
          <View style={styles.paragraph}>
            <Text style={styles.labelBold}>{'Presenter:'}</Text>
          </View>
          <View style={styles.table}>
            {presenterChunks.map((fields, key) => (
              <View key={key}>
                <View style={styles.tableHead}>
                  {fields.map(({ name }, idx) => (
                    <Text style={styles.cell} key={idx}>
                      {name}
                    </Text>
                  ))}
                </View>
                <View style={styles.tableRow}>
                  {fields.map(({ value }, idx) => (
                    <Text style={styles.cell} key={idx}>
                      {value}
                    </Text>
                  ))}
                </View>
              </View>
            ))}
          </View>
        </View>
      ) : (
        <Text></Text>
      )}
      {authorsChuncks.length ? (
        <View>
          <View style={styles.paragraph}>
            <Text style={styles.labelBold}>{'Authors:'}</Text>
          </View>
          <View style={styles.table}>
            {document?.authors?.items?.[0] &&
              authorsChuncks.map((fields, key) => (
                <View key={key}>
                  <View style={styles.tableHead}>
                    {fields.map(({ name }, idx) => (
                      <Text style={styles.cell} key={idx}>
                        {name}
                      </Text>
                    ))}
                  </View>
                  {document?.authors?.items?.map((author, idx) => (
                    <View style={styles.tableRow} key={idx}>
                      {fields.map(({ values }, index) => (
                        <Text style={styles.cell} key={index}>
                          {values[idx]}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              ))}
          </View>
        </View>
      ) : (
        <Text></Text>
      )}
      {sections.map((s) => (
        <>
          <View style={styles.paragraph}>
            <Text
              style={styles.labelBold}
            >{`${s.fieldDefinition.label}:`}</Text>
            {s?.value?.blocks?.map((block) =>
              block.type === 'atomic' ? (
                <Image style={styles.image} src={block.data.src} />
              ) : (
                <Text style={styles.text}>{block.text}</Text>
              )
            )}
          </View>
        </>
      ))}
    </>
  );
};

const sortAndParseAbstractSections = (sections) => {
  return sections
    ? sections
        .sort((a, b) => {
          if (
            a.fieldDefinition?.contextsOfUse?.items[0]?.position <
            b.fieldDefinition?.contextsOfUse?.items[0]?.position
          )
            return -1;
          if (
            b?.fieldDefinition?.contextsOfUse[0]?.position >
            a?.fieldDefinition?.contextsOfUse[0]?.position
          )
            return 1;
          return 0;
        })
        .map((s) => ({ ...s, value: JSON.parse(s.value) }))
    : [];
};

export const AbstractDetailPdf = ({ intl, abstractDocuments, files = [] }) => {
  if (!abstractDocuments.length) return null;

  console.log('abstractDocuments', abstractDocuments);
  const i18n = translation.abstractDetail(intl);

  const [page] = useState(1);
  const handleNextButtonRef = useRef(null);
  const handlePrevButtonRef = useRef(null);
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <PDFDownloadLink
        style={{ textDecoration: 'none' }}
        document={
          <Document>
            {abstractDocuments.map((document, idx) => (
              <Page size="A4" style={globalStyles.page} key={idx}>
                <AbstractDocument document={document} i18n={i18n} />
              </Page>
            ))}
          </Document>
        }
        fileName="abstracts.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            'Loading document...'
          ) : (
            <>
              <AimIconAndTextButton
                text="Download"
                style={{
                  background: 'white',
                  marginTop: 20,
                  marginBottom: 20,
                  textDecoration: 'none',
                }}
              >
                <GetAppIcon />
              </AimIconAndTextButton>
              {files.length ? (
                <AimIconAndTextButton
                  text="Download With images"
                  style={{
                    background: 'white',
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  boxStyle={{ textDecoration: 'none' }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    await fileHelper.downloadZipFromS3(
                      [
                        ...files,
                        {
                          originalName: 'abstracts.pdf',
                          Body: blob,
                          folder: 'abstracts',
                        },
                      ],
                      'abstracts.zip'
                    );
                  }}
                >
                  <GetAppIcon />
                </AimIconAndTextButton>
              ) : (
                <></>
              )}
            </>
          )
        }
      </PDFDownloadLink>
      <BlobProvider
        document={
          <Document>
            {abstractDocuments.map((document, idx) => (
              <Page size="A4" style={globalStyles.page} key={idx}>
                <AbstractDocument document={document} i18n={i18n} />
              </Page>
            ))}
          </Document>
        }
      >
        {({ blob, url, loading, error }) => {
          // Do whatever you need with blob here
          return loading ? (
            <></>
          ) : url ? (
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              <CustomIconButton
                style={{
                  marginRight: 100,
                }}
                disableRipple
                disableFocusRipple
                onClick={() => handlePrevButtonRef.current()}
              >
                <ArrowBackIosIcon />
              </CustomIconButton>
              <div style={{ boxShadow: '0 25px 50px 0 rgb(62 62 62 / 15%)' }}>
                <PDFViewer
                  document={{
                    url,
                  }}
                  page={page}
                  css="customViewer"
                  navigation={{
                    css: {
                      wrapper: 'customWrapper',
                    },
                    elements: {
                      previousPageBtn: ({ handlePrevClick }) => {
                        handlePrevButtonRef.current = handlePrevClick;
                        return <></>;
                      },
                      nextPageBtn: ({ handleNextClick }) => {
                        handleNextButtonRef.current = handleNextClick;
                        return <></>;
                      },
                      pages: () => <></>,
                    },
                  }}
                />
              </div>
              <CustomIconButton
                style={{ marginLeft: 100 }}
                disableRipple
                disableFocusRipple
                onClick={() => handleNextButtonRef.current()}
              >
                <ArrowForwardIosIcon />
              </CustomIconButton>
            </div>
          ) : (
            <></>
          );
        }}
      </BlobProvider>
    </div>
  );
};
