import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import NumberFormat from 'react-number-format';

import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { appState, constants } from '@aim/common';
import { AimTypography } from './AimTypography';

// const getSeparator = (separatorType, locale) => {
// thread on stackoverflow https://stackoverflow.com/a/51411310/22422709
//   const numberWithGroupAndDecimalSeparator = 1000.1;
//   return Intl.NumberFormat(locale)
//     .formatToParts(numberWithGroupAndDecimalSeparator)
//     .find((part) => part.type === separatorType).value;
// };

// const thousandSeparator = getSeparator('group');
// const decimalSeparator = getSeparator('decimal');
const thousandSeparator = Number(1000).toLocaleString().charAt(1) || '.';
const decimalSeparator = Number(1.1).toLocaleString().charAt(1) || ',';

const defaultStyle = {
  borderRadius: 3,
  padding: '3px 8px',
  margin: 3,
  minWidth: 130,
};

const variantsBACKEND = {
  grey: {
    background: '#f9f5fa',
  },
  white: {
    background: '#fff',
  },
};

const variantsFRONTEND = {
  primary: {
    background: '#f9f5fa',
  },
  secondary: {
    background: '#fff',
  },
};

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const variantMap = {
  grey: 'primary',
  white: 'secondary',
};

const variantTemplate = appState.template.getValue();

const getVariant = ({ eventConfiguration, variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[variantTemplate]({ eventConfiguration });
    return template[variant] || template[variantMap[variant]];
  }
};

export const AimInput = ({
  id,
  value,
  style,
  helperText,
  variant = 'grey',
  onChange,
  type = 'number',
  disableUnderline = true,
  endAdornment,
  startAdornment,
  ariaDescribedby,
  inputProps,
  InputProps,
  formControlStyle, // SAME ad style added only to mantain same api of other compoents
  ...rest
}) => {
  const [eventConfiguration, setEventConfiguration] = useState({});

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <Input
        id={id}
        value={value}
        variant={variant}
        style={{
          ...defaultStyle,
          // backgroundColor: variants[variant],
          ...getVariant({ eventConfiguration, variant }),
          ...style,
          ...formControlStyle,
        }}
        type={type}
        onChange={onChange}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        disableUnderline={disableUnderline}
        aria-describedby={ariaDescribedby}
        inputProps={inputProps}
        InputProps={InputProps}
        {...rest}
      />
      <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
    </>
  );
};

export const AimInputForm = ({
  control,
  name,
  defaultValue = '',
  errors,
  isRequired,
  label,
  id,
  style,
  variant,
  type = 'number',
  helperText,
  disableUnderline,
  startAdornment,
  endAdornment,
  ariaDescribedby,
  inputProps,
  InputProps,
  onChange,
  zeroAdjust = false,
  ...rest
}) => {
  const [valueStorer, setValueStorer] = useState();
  return (
    <>
      <Controller
        id={`controller-${id}`}
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(props) => (
          <AimInput
            id={id}
            value={props.value}
            style={style}
            variant={variant}
            type={type}
            helperText={helperText}
            disableUnderline={disableUnderline}
            // onKeyDown={(e) => {
            //   if (zeroAdjust && type === 'number') {
            //     const value = props.ref.current.value;
            //     if (value.trim() === '0') {
            //       props.ref.current.value = e.key;
            //       // e.preventDefault();
            //     }
            //   }
            // }}
            onChange={(e) => {
              let value =
                type === 'number' ? Number(e.target.value) : e.target.value;
              if (
                (zeroAdjust && valueStorer?.trim() === '0') ||
                valueStorer?.trim() === ''
              ) {
                value = e.target.value.replace('0', '');
              }
              props.onChange(value);
              onChange && onChange(value);
              setValueStorer(e.target.value);
            }}
            onFocus={() => {
              if (zeroAdjust && type === 'number') {
                setValueStorer(props.ref.current.value);
              }
            }}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            aria-describedby={ariaDescribedby}
            inputProps={inputProps}
            InputProps={InputProps}
            inputRef={props.ref}
            {...rest}
          />
        )}
        rules={{ required: isRequired }}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <AimTypography variant="formError">
              {message || `${label} is required`}
            </AimTypography>
          )}
        />
      )}
    </>
  );
};

export const AimCurrencyInput = ({
  id,
  value,
  prefix,
  helperText,
  style,
  onChange,
  onValueChange,
  variant = 'grey',
  noStyle = false,
  ...rest
}) => {
  const [eventConfiguration, setEventConfiguration] = useState({});

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <NumberFormat
        id={id}
        value={value}
        prefix={prefix}
        isNumericString={true}
        allowNegative={false}
        style={
          noStyle
            ? {}
            : {
                ...defaultStyle,
                ...getVariant({ eventConfiguration, variant }),
                ...style,
              }
        }
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={2}
        fixedDecimalScale={2}
        inputmode="numeric"
        onChange={onChange}
        onValueChange={onValueChange}
        {...rest}
      />
      {helperText && (
        <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
      )}
    </>
  );
};

export const AimCurrencyInputForm = ({
  id,
  name,
  style,
  prefix,
  defaultValue,
  helperText,
  label,
  control,
  errors,
  isRequired,
  ...rest
}) => (
  <>
    <Controller
      id={`controller-${id}`}
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <AimCurrencyInput
          label={label}
          id={id}
          value={props.value}
          prefix={prefix ? `${prefix} ` : null}
          style={style}
          helperText={helperText}
          onValueChange={(values) => props.onChange(values.floatValue)}
          inputRef={props.ref}
          {...rest}
        />
      )}
      rules={{ required: isRequired }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <AimTypography variant="formError">
            {message || `${label} is required`}
          </AimTypography>
        )}
      />
    )}
  </>
);
