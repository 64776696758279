import React, { useRef, useState, useEffect } from 'react';
import { debounce } from 'lodash';

import { geoDbHelper } from '@aim/common';
import { AimAutocompleteForm } from '@aim/components';
import Box from '@mui/material/Box';

const { getGeoDbCountriesByInput } = geoDbHelper;

export const CountriesAutocompleteByGeoDb = ({
  control,
  register,
  setValue,
  textfieldVariant,
  errors,
  name,
  label,
  formControlStyle,
  clearErrors,
  isRequired,
  addAsterisk,
  defaultValue,
  ...rest
}) => {
  const [countriesOptions, setCountriesOptions] = useState([]);

  const debouncedInputCountries = useRef(
    debounce(async (value) => {
      const descriptions = await getGeoDbCountriesByInput(value);
      console.log('descriptions', descriptions);
      setCountriesOptions(descriptions);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedInputCountries.cancel();
    };
  }, [debouncedInputCountries]);

  return (
    <AimAutocompleteForm
      defaultValue={defaultValue}
      control={control}
      register={register}
      setValue={setValue}
      textfieldVariant={textfieldVariant}
      errors={errors}
      options={countriesOptions}
      name={name}
      label={label}
      formControlStyle={formControlStyle}
      clearErrors={clearErrors}
      onInputChangeFn={async (value) => {
        debouncedInputCountries(value);
      }}
      renderOption={(option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      isRequired={isRequired}
      addAsterisk={addAsterisk}
      filterOptions={(options) => options}
      limit={100}
      {...rest}
    />
  );
};

export const CountriesAutocompleteByGeoDbDynamic = ({
  control,
  register,
  setValue,
  textfieldVariant,
  errors,
  name,
  label,
  formControlStyle,
  clearErrors,
  isRequired,
  addAsterisk,
  defaultValue,
}) => {
  return (
    <CountriesAutocompleteByGeoDb
      defaultValue={defaultValue}
      control={control}
      register={register}
      setValue={setValue}
      textfieldVariant={textfieldVariant}
      errors={errors}
      name={name}
      label={label}
      formControlStyle={formControlStyle}
      clearErrors={clearErrors}
      isRequired={isRequired}
      addAsterisk={addAsterisk}
      filterOptions={(options) => options}
    />
  );
};
