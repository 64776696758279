import { Extension, getAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

export const UploadFromRepoMenuState = {
  HIDDEN: 'hidden',
  VISIBLE: 'visible',
};

/**
 * To be used in conjunction with the `LinkBubbleMenu` component, as this
 * extension provides editor commands to control the state of the link bubble
 * menu.
 *
 * The Tiptap Link extension (@tiptap/extension-link) should also be installed
 * and included in your extensions when using LinkBubbleMenuHandler:
 * https://tiptap.dev/api/marks/link.
 */
const UploadFromRepoBubbleMenuHandler = Extension.create({
  name: 'uploadFromRepoBubbleMenuHandler',

  addStorage() {
    return {
      state: UploadFromRepoMenuState.HIDDEN,
      bubbleMenuOptions: undefined,
    };
  },

  addCommands() {
    return {
      openUploadFromRepoBubbleMenu: (bubbleMenuOptions = {}) => ({
        editor,
        chain,
        dispatch,
      }) => {
        if (dispatch) {
          // Only change the state if this is not a dry-run
          // https://tiptap.dev/api/commands#dry-run-for-commands. Note that
          // this happens automatically for the Tiptap built-in commands
          // called with `chain()` above.
          this.storage.state = UploadFromRepoMenuState.VISIBLE;
          this.storage.bubbleMenuOptions = bubbleMenuOptions;
        }

        return true;
      },

      closeUploadFromRepoBubbleMenu: () => ({ commands, dispatch }) => {
        const currentMenuState = this.storage.state;
        if (currentMenuState === UploadFromRepoMenuState.HIDDEN) {
          return false;
        }

        // Re-focus on the editor (e.g. for re-selection) since the user was
        // previously editing and has now canceled
        commands.focus();

        if (dispatch) {
          // Only change the state if this is not a dry-run
          // https://tiptap.dev/api/commands#dry-run-for-commands. Note that
          // this happens automatically for the Tiptap built-in commands
          // called with `commands` above.
          this.storage.state = UploadFromRepoMenuState.HIDDEN;
        }

        return true;
      },
    };
  },

  onSelectionUpdate() {
    // To ensure we maintain the proper bubble menu state, if someone is
    // viewing/editing a link but moves off of it (e.g. with their keyboard
    // arrow keys, or by clicking out, or by typing over the currently selected
    // link), we'll close the bubble menu. Note that when in "view" mode (and
    // not "edit") for an existing link, we only close if the state shows the
    // user is not on an active link anymore, since the selection can be updated
    // via `openLinkBubbleMenu` (and we don't want to immediately close it upon
    // initial opening of the bubble menu). By contrast in "edit" mode, the
    // user's focus should be in the edit form and selection shouldn't
    // automatically update during opening or otherwise, so clicking out (i.e.
    // changing selection) definitively indicates cancellation.
    // onSelectionUpdate runs before handleClick, so we need to promptly close
    // in that scenario.
    if (this.storage.state === UploadFromRepoMenuState.VISIBLE) {
      this.editor.commands.closeUploadFromRepoBubbleMenu();
    }
  },
});

export default UploadFromRepoBubbleMenuHandler;
