import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import translation from './TravelTransferPrefs/translation';
import { styled } from '../styled';

import { AimIconAndTextButton } from '../atoms';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { Travel } from './TravelTransferPrefs/components/Travel';
import { Transfer } from './TravelTransferPrefs/components/Transfer';
import { AimTextFieldForm } from '@aim/components';
import { theme } from '../../theme';
import { AimTypography } from '../atoms/AimTypography';

//Constants
const formControlStyle = { width: 'calc(100% - 15px)' };

const InnerContainer = styled('div')({
  padding: 20,
});

const Container = styled('div')({
  backgroundColor: 'white',
  margin: '10px 0px',
  display: 'flex',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});

export const TravelTransferPrefs = ({
  goBack,
  intl,
  participantIdList,
  eventId,
  isEditable = false,
}) => {
  const i18n = translation.TravelTransfer(intl);
  const [dataTravel, setDataTravel] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [travels, setTravels] = useState([]);
  const [terminals, setTerminals] = useState();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      transferId: null,
    },
  });
  const participationId = participantIdList[0];

  const onSave = async (data) => {};

  const getData = async () => {};

  useEffect(() => {}, []);

  useEffect(() => {
    if (!participationId || participantIdList?.length > 1) return;
    getData();
  }, [participationId]);

  // useEffect(() => {
  //   const fetchTerminals = async () => {
  //     const terminalList = await listTerminals();
  //     setTerminals(terminalList);
  //   };

  //   fetchTerminals();
  // }, []);

  return (
    <InnerContainer>
      <Tooltip title={i18n.page.backButton.tooltip}>
        <AimIconAndTextButton
          variant="text"
          style={{ padding: 0 }}
          text={i18n.page.backButton.label}
          onClick={goBack}
        >
          <ArrowBack />
        </AimIconAndTextButton>
      </Tooltip>
      <Container style={{ flexDirection: 'column' }}>
        <ButtonContainer>
          <AimIconAndTextButton
            text={i18n.page.save}
            variant="greenFill"
            onClick={() => {}}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </ButtonContainer>
        <AimTypography variant="text">{i18n.page.travel.title}</AimTypography>
        <AimTypography variant="textBold" boxStyle={{ margin: '0px 10px' }}>
          {i18n.page.travel.subTitle1}
        </AimTypography>
        <AimTypography variant="text" boxStyle={{ margin: '0px 10px' }}>
          {i18n.page.travel.subTitle2}
        </AimTypography>
        <Travel
          i18n={i18n}
          formControlStyle={formControlStyle}
          control={control}
          intl={intl}
          isEditable={isEditable}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              control={control}
              style={{ backgroundColor: 'white' }}
              name="checkedBaggage"
              placeholder=""
              label={i18n.page.travel.consentForTheCheckedBaggage}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
              value={'Yes'} // test
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              control={control}
              style={{ backgroundColor: 'white' }}
              name="issueTickets"
              placeholder=""
              label={i18n.page.travel.issueTickets}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
              value={'Yes'} // test
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              control={control}
              style={{ backgroundColor: 'white' }}
              name="proposal"
              placeholder=""
              label={i18n.page.travel.proposal}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
              value={'No Answer'} // test
            />
          </Grid>
        </Grid>
        <Transfer
          i18n={i18n}
          formControlStyle={formControlStyle}
          control={control}
          intl={intl}
          isEditable={isEditable}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              control={control}
              style={{ backgroundColor: 'white' }}
              name="uploadByDocId"
              placeholder=""
              label={i18n.page.transfer.uploadByDocId}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
              value={'Yes'} // test
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              control={control}
              style={{ backgroundColor: 'white' }}
              name="uploadForPurchase"
              placeholder=""
              label={i18n.page.transfer.uploadForPurchase}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
              value={'Yes'} // test
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              control={control}
              style={{ backgroundColor: 'white' }}
              name="uploadForAutoBooking"
              placeholder=""
              label={i18n.page.transfer.uploadForAutoBooking}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
              value={'No'} // test
            />
          </Grid>
        </Grid>

        <ButtonContainer>
          <AimIconAndTextButton
            onClick={goBack}
            variant="greyFill"
            text={i18n.page.cancel}
            type="submit"
          >
            <ReplayIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            text={i18n.page.save}
            variant="greenFill"
            // type="submit"
            onClick={handleSubmit(onSave)}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </ButtonContainer>
      </Container>
    </InnerContainer>
  );
};
