import React, { useEffect, useState, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import {
  listDelegationScheduledS3ImportFile,
  listEventScheduledS3ImportFile,
  listUsernameScheduledS3ImportFile,
} from './OrchideaImportDialog/gqlHelper';
import translation from './OrchideaImportDialog/translation';
import { format } from 'date-fns';
import { appState, aws } from '@aim/common';
import { AimSnackbarSeverity } from '../atoms/AimSnackbar';
import { AimDataGrid } from '../bundles/AimDataGrid/AimDataGrid';
import { AimDialog } from '../atoms/AimDialog';
export const OrchideaImportDialog = ({
  dialog,
  setDialog,
  setSnackbar,
  eventId,
  clusterId,
  participantId,
  intl,
  refreshData,
}) => {
  const [scheduledS3ImportFiles, setScheduledS3ImportFiles] = useState([]);
  const [rows, setRows] = useState([]);
  const i18n = translation.orchideaImprtDialog(intl);
  const [tableRows, setTableRows] = useState({
    checkedRows: [],
    filteredRows: [],
    rawData: {},
  });

  useEffect(() => {
    const loadData = async () => {
      const filtes = participantId
        ? await listUsernameScheduledS3ImportFile({
            participantId,
            source: 'orchidea',
          })
        : clusterId
        ? await listDelegationScheduledS3ImportFile({
            delegationId: clusterId,
            source: 'orchidea',
          })
        : eventId
        ? await listEventScheduledS3ImportFile({ eventId, source: 'orchidea' })
        : {};

      setScheduledS3ImportFiles(filtes);
    };

    loadData();
  }, [eventId, clusterId, participantId]);

  useEffect(() => {
    if (dialog.participations) {
      const travels = scheduledS3ImportFiles
        .filter((f) => dialog.participations.find((p) => p.id === f.searchKey))
        .flatMap(({ id, data, ...rest }) => {
          const ourPax = dialog.participations.find(
            (p) => p.id === rest.searchKey
          );
          return {
            ...JSON.parse(data),
            ...rest,
            id,
            ourGivenName: ourPax.givenName,
            ourFamilyName: ourPax.familyName,
          };
        });
      console.log('travels', travels);
      setRows(travels);
    } else {
      if (rows.length) {
        setRows([]);
      }
    }
  }, [dialog.participations]);

  const columns = useMemo(
    () => [
      { field: 'username', headerName: i18n.username, width: 100 },
      {
        field: 'ourGivenName',
        headerName: i18n.ourGivenName,
        // eslint-disable-next-line react/display-name
        renderCell: (props) => {
          return (
            <>
              {props.value}
              {props.row.givenName?.trim().toLowerCase() !==
              props.value?.trim().toLowerCase() ? (
                <strong
                  style={{ color: 'red', marginLeft: 5 }}
                >{`(${props.row.givenName})`}</strong>
              ) : null}
            </>
          );
        },
        flex: 1,
      },
      {
        field: 'ourFamilyName',
        headerName: i18n.ourFamilyName,
        // eslint-disable-next-line react/display-name
        renderCell: (props) => {
          return (
            <>
              {props.value}
              {props.row.familyName?.trim().toLowerCase() !==
              props.value?.trim().toLowerCase() ? (
                <strong
                  style={{ color: 'red', marginLeft: 5 }}
                >{`(${props.row.familyName})`}</strong>
              ) : null}
            </>
          );
        },
        flex: 1,
      },
      {
        field: 'returnTrip',
        headerName: i18n.ar,
        valueFormatter: ({ value }) => (value ? 'R' : 'A'),
        width: 70,
      },
      {
        field: 'departureDate',
        headerName: i18n.departureDate,
        valueGetter: ({ row }) => new Date(row.departureDate),
        valueFormatter: ({ value }) => format(value, 'dd/MM/yyyy'),
        width: 150,
      },
      {
        field: 'start',
        headerName: i18n.start,
        type: 'date',
        width: 160,
      },
      { field: 'terminalIn', headerName: i18n.terminalIn, flex: 1 },
      {
        field: 'arrivalDate',
        headerName: i18n.arrivalDate,
        valueGetter: ({ row }) => new Date(row.arrivalDate),
        valueFormatter: ({ value }) => format(value, 'dd/MM/yyyy'),
        width: 120,
      },
      { field: 'end', headerName: i18n.end, width: 100 },
      { field: 'terminalOut', headerName: i18n.terminalOut, flex: 1 },
    ],
    []
  );

  const updateVisibleAndCheckedRows = (data) => {
    const filteredRows =
      Object.entries(data.filter?.visibleRowsLookup || {})
        ?.filter(([_, isVisible]) => isVisible)
        .map(([key]) => data.rows.idRowsLookup[key]) || [];
    const checkedRows =
      data.selection?.map((s) => data.rows.idRowsLookup[s]) || [];
    // const allRows =
    //   data.rows.allRows?.map((s) => data.rows.idRowsLookup[s]) || [];
    setTableRows({ filteredRows, checkedRows, rawData: data });
  };

  //to prevent too many rerender
  const debouncedSelectedVisibleRows = useRef(
    debounce((data) => {
      updateVisibleAndCheckedRows(data);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSelectedVisibleRows.cancel();
    };
  }, [debouncedSelectedVisibleRows]);

  const handleOnAgree = async () => {
    try {
      appState.isLoader.next(true);
      const ids = tableRows.checkedRows.map(({ id }) => id);
      await aws.standardAPI.post('aimlambdaproxy', '/async/', {
        body: {
          lambdaName: 'aimOrchidea',
          eventId,
          ids,
        },
      });
      setRows((rows) => rows.filter((r) => !ids.includes(r.id)));
      refreshData && (await refreshData());
      appState.isLoader.next(false);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.success,
      });
      // setLastUpdate(Date.now());
    } catch (err) {
      console.error(err);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.error,
      });
    }
    setDialog({ isOpen: false });
  };

  return (
    <AimDialog
      fullWidth
      maxWidth="xl"
      title={i18n.title}
      open={dialog.isOpen}
      onAgree={handleOnAgree}
      onAgreeDisabled={!tableRows.checkedRows.length}
      agreeText={i18n.import}
      disableOnClose
      onClose={() => setDialog({ isOpen: false })}
      onDisagree={() => setDialog({ isOpen: false })}
    >
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: 600 }}>
        <AimDataGrid
          columns={columns}
          rows={rows}
          checkboxSelection
          disableSelectionOnClick
          onStateChange={(data) => debouncedSelectedVisibleRows(data)}
          tableName="orchidea-travels"
          pinnedColumns={{ left: [], right: ['actions'] }}
          sortModel={[
            { field: 'ourFamilyName', sort: 'asc' },
            { field: 'returnTrip', sort: 'asc' },
            { field: 'departureDate', sort: 'asc' },
            { field: 'start', sort: 'asc' },
          ]}
        />
      </div>
    </AimDialog>
  );
};
