import { aws, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const updatePaymentMutation = /* GraphQL */ `
  mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      id
    }
  }
`;

const updateProductMutation = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
    }
  }
`;

const participantsByEventIdQuery = /* GraphQL */ `
  query ParticipantsByEventId($participationEventId: ID) {
    participantByEventId(
      participationEventId: $participationEventId
      filter: { isDeleted: { ne: true } }
    ) {
      items {
        id
        username
        email
        givenName
        familyName
        type
        billingInformation {
          id
          invoiceTo
          name
          surname
          address
          postalCode
          city
          regionProvinceState
          region
          province
          country
          countryIsoCode
          vatCode
          taxCode
          reference
          email
          pec
          phone
          invoiceToPublicAuthority
          uniqueCode
          ipaCode
          isSplitPayment
          isVatEvent
          venue
          cig
          cup
          tripletta
        }
      }
    }
  }
`;

const billingFlowTypesByEventQuery = /* GraphQL */ `
  query BllingFlowTypesByEventQuery($eventId: ID!) {
    getEvent(id: $eventId) {
      id
      billingFlowTypes {
        items {
          id
          name
        }
      }
    }
  }
`;

const getMaxBillingFlowNumberQuery = /* GraphQL */ `
  query MaxBillingFlowNumberByEvent($eventId: ID!) {
    getEvent(id: $eventId) {
      id
      billingFlows {
        items {
          id
          flowNumber
        }
      }
    }
  }
`;

const createBillingFlowMutation = /* GraphQL */ `
  mutation CreateBillingFlow(
    $input: CreateBillingFlowInput!
    $condition: ModelBillingFlowConditionInput
  ) {
    createBillingFlow(input: $input, condition: $condition) {
      id
    }
  }
`;

const createProductBillingFlowMutation = /* GraphQL */ `
  mutation CreateProductBillingFlow(
    $input: CreateProductBillingFlowInput!
    $condition: ModelProductBillingFlowConditionInput
  ) {
    createProductBillingFlow(input: $input, condition: $condition) {
      id
    }
  }
`;

const CreateBillingInformationMutation = /* GraphQL */ `
  mutation CreateBillingInformation(
    $input: CreateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    createBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getBillingFlowQuery = /* GraphQL */ `
  query GetBillingFlowProducts($id: ID!) {
    getBillingFlow(id: $id) {
      id
      products {
        items {
          id
          product {
            id
            productId
            clientId
            clientType
            serviceId
            serviceType
            participationType
            createdAt
            billingStatus
            billing {
              id
              invoiceTo
              name
              surname
              address
              city
              postalCode
              region
              province
              country
              countryIsoCode
              vatCode
              taxCode
              reference
              email
              pec
              phone
              invoiceToPublicAuthority
              uniqueCode
              ipaCode
              isSplitPayment
              isVatEvent
              venue
              cig
              cup
              tripletta
            }
            payment {
              id
              paymentId
              paymentType
              paymentIdMonetaOnLine
              paymentIdIngenico
              paymentIdGpWebpay
              paymentError
              amount
              paymentStatus
              createdAt
              updatedAt
              isDeleted
              BillingInformation {
                id
                invoiceTo
                name
                surname
                address
                city
                postalCode
                region
                province
                country
                countryIsoCode
                vatCode
                taxCode
                reference
                email
                pec
                phone
                invoiceToPublicAuthority
                uniqueCode
                ipaCode
                isSplitPayment
                isVatEvent
                venue
                cig
                cup
                tripletta
              }
            }
          }
        }
      }
    }
  }
`;

const getPurchasesQuery = (serviceType) => /* GraphQL */ `
query getProductsByServiceIdServiceType(
  $serviceId: String
  ${serviceType ? '$serviceType: String' : ''}
) {
  products: productByServiceIdServiceType(
    serviceId: $serviceId
    ${serviceType ? 'serviceType: { eq: $serviceType }' : ''}
  ) {
    items {
      id
      productId
      clientId
      clientType
      serviceId
      serviceType
      participationType
      createdAt
      billingStatus
      billing {
        id
        invoiceTo
        name
        surname
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
        venue
        cig
        cup
        tripletta
      }
      payment {
        id
        paymentId
        paymentType
        paymentIdMonetaOnLine
        paymentIdIngenico
        paymentIdGpWebpay
        paymentError
        amount
        paymentStatus
        createdAt
        updatedAt
        isDeleted
        BillingInformation {
          id
          invoiceTo
          name
          surname
          address
          city
          postalCode
          region
          province
          country
          countryIsoCode
          vatCode
          taxCode
          reference
          email
          pec
          phone
          invoiceToPublicAuthority
          uniqueCode
          ipaCode
          isSplitPayment
          isVatEvent
          venue
          cig
          cup
          tripletta
        }
      }
      billingStatus
    }
  }
}
`;

const getPurchasesByProductIdList = /* GraphQL */ `
  query getProductByProductId($productId: String) {
    products: productByProductId(productId: $productId) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        participationType
        createdAt
        billing {
          id
          invoiceTo
          name
          surname
          address
          city
          postalCode
          region
          province
          country
          countryIsoCode
          vatCode
          taxCode
          reference
          email
          pec
          phone
          invoiceToPublicAuthority
          uniqueCode
          ipaCode
          isSplitPayment
          isVatEvent
          purchaseOrderNumber
          venue
          cig
          cup
          tripletta
        }
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
          createdAt
          updatedAt
          isDeleted
          BillingInformation {
            id
            invoiceTo
            name
            surname
            address
            city
            postalCode
            region
            province
            country
            countryIsoCode
            vatCode
            taxCode
            reference
            email
            pec
            phone
            invoiceToPublicAuthority
            uniqueCode
            ipaCode
            isSplitPayment
            isVatEvent
            venue
            cig
            cup
            tripletta
          }
        }
      }
    }
  }
`;

export const updateProduct = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: updateProductMutation,
      variables: { input },
    })
      .then(resolve)
      .catch((e) => {
        console.error('update-product', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const updatePayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: updatePaymentMutation,
      variables: { input },
    })
      .then(resolve)
      .catch((e) => {
        console.error('update-payment', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const listParticipantsByEventId = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: participantsByEventIdQuery,
      variables: {
        participationEventId: eventId,
      },
    })
      .then((response) => {
        resolve(response.data.participantByEventId.items);
      })
      .catch((e) => {
        console.error('participants-by-eventId', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const listPurchases = (
  { eventId, serviceType, billingFlowId, productId },
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: billingFlowId
        ? getBillingFlowQuery
        : productId
        ? getPurchasesByProductIdList
        : getPurchasesQuery(serviceType),
      variables: billingFlowId
        ? { id: billingFlowId }
        : productId
        ? { productId }
        : {
            serviceId: eventId,
            serviceType,
          },
    })
      .then((response) => {
        const items = billingFlowId
          ? response?.data.getBillingFlow?.products?.items?.map(
              (pbf) => pbf.product
            )
          : response?.data?.products?.items;

        resolve(items);
      })
      .catch((e) => {
        console.error('get-products-and-billing-flow', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getBillingFlowTypesByEvent = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: billingFlowTypesByEventQuery,
      variables: {
        eventId,
      },
    })
      .then((response) => {
        resolve(response.data.getEvent.billingFlowTypes.items);
      })
      .catch((e) => {
        console.error('billingFlowTypes-by-eventId', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getMaxBillingFlowNumber = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: getMaxBillingFlowNumberQuery,
      variables: {
        eventId,
      },
    })
      .then((response) => {
        const billingFlows = response.data.getEvent.billingFlows.items.filter(
          (bf) => bf.flowNumber >= 0
        );
        const maxBillingFlowNumber = billingFlows.length
          ? Math.max(...billingFlows.map((flow) => flow.flowNumber))
          : 0;
        resolve(maxBillingFlowNumber);
      })
      .catch((e) => {
        console.error('maxBillingFlowNumber-by-eventId', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createBillingFlow = (input) =>
  new Promise((resolve, reject) => {
    showLoader();

    aws.API.graphql({ query: createBillingFlowMutation, variables: { input } })
      .then(async (response) => {
        resolve(response.data.createBillingFlow);
      })
      .catch((e) => {
        console.error('create-BillingFlow', e);
        reject();
      })
      .finally(hideLoader);
  });

export const createProductBillingFlow = (input) =>
  new Promise((resolve, reject) => {
    showLoader();

    aws.API.graphql({
      query: createProductBillingFlowMutation,
      variables: { input },
    })
      .then(async (response) => {
        resolve(response.data.createProductBillingFlow);
      })
      .catch((e) => {
        console.error('create-ProductBillingFlow', e);
        reject();
      })
      .finally(hideLoader);
  });

export const createBillingInformation = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: CreateBillingInformationMutation,
      variables: {
        input,
      },
    })
      .then((response) => resolve(response.data.createBillingInformation))
      .catch((e) => {
        console.error('create-billing-information', e);
        reject();
      });
  });
