export default {
  importFromFile: (intl) => ({
    title: intl.formatMessage({
      description: 'massive Import',
      defaultMessage: 'Massive Import',
    }),
    selectTemplate: intl.formatMessage({
      description: 'select template',
      defaultMessage: 'Select the template',
    }),
    titleTable: intl.formatMessage({
      description: 'import detail title',
      defaultMessage: 'Import Detail',
    }),
    backLabel: intl.formatMessage({
      description: 'back',
      defaultMessage: 'Back',
    }),
    record: {
      total: intl.formatMessage({
        description: 'total record',
        defaultMessage: 'Total Records',
      }),
      imported: intl.formatMessage({
        description: 'succes record',
        defaultMessage: 'Successfully Imported Records',
      }),
      notImported: intl.formatMessage({
        description: 'notImported',
        defaultMessage: 'Not Imported Records',
      }),
    },
    fileSelector: {
      label: intl.formatMessage({
        description: 'fileSelector label',
        defaultMessage: 'Select the file to import',
      }),
      clickHere: intl.formatMessage({
        description: 'clickHere',
        defaultMessage: 'Click here to',
      }),
    },
    buttons: {
      cancel: intl.formatMessage({
        description: 'cancel',
        defaultMessage: 'Cancel',
      }),
      import: intl.formatMessage({
        description: 'import',
        defaultMessage: 'Import',
      }),
      export: intl.formatMessage({
        description: 'export',
        defaultMessage: 'Export conflict',
      }),
    },
    exportText: intl.formatMessage({
      description: 'massive Import exportText',
      defaultMessage: 'Export the template for insertion',
    }),
    importText: intl.formatMessage({
      description: 'massive Import importText',
      defaultMessage: 'Import data for insertion',
    }),
    table: {
      buttons: {
        delete: intl.formatMessage({
          description: 'delete button',
          defaultMessage: 'Delete',
        }),
        view: intl.formatMessage({
          description: 'view button',
          defaultMessage: 'View',
        }),
      },
      columns: {
        idx: intl.formatMessage({
          description: 'idx column',
          defaultMessage: 'Row index',
        }),
        givenName: intl.formatMessage({
          description: 'givenName column',
          defaultMessage: 'Name',
        }),
        familyName: intl.formatMessage({
          description: 'familyName column',
          defaultMessage: 'Lastname',
        }),
        email: intl.formatMessage({
          description: 'email column',
          defaultMessage: 'Email',
        }),
        notes: intl.formatMessage({
          description: 'notes column',
          defaultMessage: 'Notes',
        }),
      },
    },
    snackbar: {
      errors: {
        field: intl.formatMessage({
          description: 'fieldSnakbarError',
          defaultMessage: 'Error: Unmatching fields in the excel',
        }),
        files: intl.formatMessage({
          description: 'fieldSnakbarError',
          defaultMessage:
            'Error: not found correspondence with the selected profile',
        }),
        remainingPlaces: intl.formatMessage({
          description: 'remaining places error',
          defaultMessage: 'Error: Remaining places are not enough',
        }),
      },
    },
    AlertDialog: {
      title: intl.formatMessage({
        description: 'alert dialog import partecipants title',
        defaultMessage: 'Check records to import',
      }),
      description: intl.formatMessage({
        description: 'alert dialog import partecipants description',
        defaultMessage: 'Import Files',
      }),
      tableName: intl.formatMessage({
        description: 'import massive parteicpants table',
        defaultMessage: 'Import massive partecipants',
      }),
      agreeText: intl.formatMessage({
        description: 'button import alert dialog partecipants selected',
        defaultMessage: 'Import Selected',
      }),
      DataGrid: {
        row: intl.formatMessage({
          description: 'position items in excel',
          defaultMessage: 'Row',
        }),
        name: intl.formatMessage({
          description: 'name items',
          defaultMessage: 'Name',
        }),
        surname: intl.formatMessage({
          description: 'surname items',
          defaultMessage: 'Surname',
        }),
        email: intl.formatMessage({
          description: 'email items',
          defaultMessage: 'Email',
        }),
        telephone: intl.formatMessage({
          description: 'telephone items',
          defaultMessage: 'telephone',
        }),
      },
    },
  }),
};
