import React, { useState, useEffect, useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import DragHandleIcon from '@material-ui/icons/DragHandle';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { styled } from './../../styled';

const StyledDragListIcon = styled(ListItemIcon)({
  position: 'absolute',
  right: 10,
  top: '50%',
  msTransform: 'translateY(-50%)',
  transform: 'translateY(-50%)',
});

const StyledItemWrapperComponent = styled('div')(({ rowWidth }) => ({
  width: rowWidth,
}));

const StyledList = styled(List)({
  width: '100%',
  padding: 0,
});

const HeightPreservingItem = ({ children, ...props }) => {
  const test = { ...props };
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize == 0 ? prevSize : knownSize;
    });
  }, [knownSize]);
  return (
    <div
      {...props}
      className="height-preserving-container"
      // check styling in the style tag below
      style={{ '--child-height': `${size}px` }}
    >
      {children}
    </div>
  );
};

// const DraggableListItem = ({
//   item,
//   items,
//   setItems,
//   index,
//   draggableItemStyle,
//   hideDragIcon,
//   ItemComponent,
//   itemComponentProps,
//   rowWidth = '90%',
// }) => {
//   return (
//     <Draggable key={index} draggableId={`item-${index}`} index={index}>
//       {(provided, snapshot) => (
//         <ListItemCustom
//           item={item}
//           items={items}
//           setItems={setItems}
//           index={index}
//           draggableItemStyle={draggableItemStyle}
//           hideDragIcon={hideDragIcon}
//           ItemComponent={ItemComponent}
//           itemComponentProps={itemComponentProps}
//           rowWidth={rowWidth}
//           provided={provided}
//           snapshot={snapshot}
//         />
//       )}
//     </Draggable>
//   );
// };

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const AimDraggableListVirtualized = ({
  items,
  setItems,
  onDragEnd,
  draggableItemStyle,
  hideDragIcon,
  ItemComponent,
  itemComponentProps,
  rowWidth,
  keyName,
  height = 200,
}) => {
  const onDragEndDefault = useCallback((result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const nextItems = reorder(
      [...items],
      result.source.index,
      result.destination.index
    );

    setItems(nextItems);
  });

  // const onDragEndDefault = useCallback(
  //   (result) => {
  //     if (!result.destination) {
  //       return;
  //     }
  //     if (result.source.index === result.destination.index) {
  //       return;
  //     }

  //     const nextItems = reorder(
  //       [...items],
  //       result.source.index,
  //       result.destination.index
  //     );

  //     setItems(nextItems);
  //   },
  //   [setItems]
  // );

  const Item = ({ provided, item, isDragging }) => {
    return (
      <div style={{ paddingBottom: '8px' }}>
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          className={`item ${isDragging ? 'is-dragging' : ''}`}
        >
          {item.event}
        </div>
      </div>
    );
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(isDragging && draggableItemStyle ? draggableItemStyle : {}),
  });

  const ListItemCustom = ({
    item,
    items,
    setItems,
    index,
    draggableItemStyle,
    hideDragIcon,
    ItemComponent,
    itemComponentProps,
    rowWidth,
    provided,
    snapshot,
    height,
  }) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
      // styles we need to apply on draggables
      ...draggableStyle,
      ...(isDragging && draggableItemStyle ? draggableItemStyle : {}),
    });
    return (
      <ListItem
        key={item.id}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        ref={provided.innerRef}
        className={`item ${snapshot.isDragging ? 'is-dragging' : ''}`}
      >
        <StyledItemWrapperComponent
          {...provided.dragHandleProps}
          rowWidth={rowWidth}
        >
          <ItemComponent
            item={item}
            items={items}
            setItems={setItems}
            index={index}
            {...itemComponentProps}
          />
          {!hideDragIcon && (
            <StyledDragListIcon>
              <DragHandleIcon />
            </StyledDragListIcon>
          )}
        </StyledItemWrapperComponent>
      </ListItem>
    );
  };

  return (
    <>
      <style>
        {`
          .height-preserving-container:empty {
            min-height: calc(var(--child-height));
            box-sizing: border-box;
          }
      `}
      </style>
      <DragDropContext onDragEnd={onDragEnd || onDragEndDefault}>
        <StyledList disablePadding>
          <Droppable
            droppableId="droppable"
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => {
              return (
                // <Item
                //   provided={provided}
                //   isDragging={snapshot.isDragging}
                //   item={items[rubric.source.index]}
                // />

                <ListItemCustom
                  item={items[rubric.source.index]}
                  items={items}
                  setItems={setItems}
                  index={rubric.source.index}
                  draggableItemStyle={draggableItemStyle}
                  hideDragIcon={hideDragIcon}
                  ItemComponent={ItemComponent}
                  itemComponentProps={itemComponentProps}
                  rowWidth={rowWidth}
                  provided={provided}
                  snapshot={snapshot}
                  height={height}
                />

                // <ListItem
                //   key={items[rubric.source.index].id}
                //   {...provided.draggableProps}
                //   {...provided.dragHandleProps}
                //   style={getItemStyle(
                //     snapshot.isDragging,
                //     provided.draggableProps.style
                //   )}
                //   ref={provided.innerRef}
                //   className={`item ${snapshot.isDragging ? 'is-dragging' : ''}`}
                // >
                //   <StyledItemWrapperComponent
                //     {...provided.dragHandleProps}
                //     rowWidth={rowWidth}
                //   >
                //     <ItemComponent
                //       item={items[rubric.source.index]}
                //       items={items}
                //       setItems={setItems}
                //       index={rubric.source.index}
                //       {...itemComponentProps}
                //     />
                //     {!hideDragIcon && (
                //       <StyledDragListIcon>
                //         <DragHandleIcon />
                //       </StyledDragListIcon>
                //     )}
                //   </StyledItemWrapperComponent>
                // </ListItem>
              );
            }}
          >
            {(provided) => (
              <Virtuoso
                components={{
                  Item: HeightPreservingItem,
                }}
                scrollerRef={provided.innerRef}
                data={items}
                style={{ width: '100%', height }}
                itemContent={(index) => (
                  <Draggable
                    key={index}
                    draggableId={`item-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <ListItemCustom
                        item={items[index]}
                        items={items}
                        setItems={setItems}
                        index={index}
                        draggableItemStyle={draggableItemStyle}
                        hideDragIcon={hideDragIcon}
                        ItemComponent={ItemComponent}
                        itemComponentProps={itemComponentProps}
                        rowWidth={rowWidth}
                        provided={provided}
                        snapshot={snapshot}
                      />
                    )}
                  </Draggable>
                )}
                // itemContent={(index, item) => {
                //   return (
                //     <Draggable
                //       draggableId={item.id}
                //       index={index}
                //       key={item.id}
                //     >
                //       {(provided) => (
                //         <Item
                //           provided={provided}
                //           item={item}
                //           isDragging={false}
                //         />
                //       )}
                //     </Draggable>
                //   );
                // }}
              />
            )}
          </Droppable>
        </StyledList>
      </DragDropContext>
    </>
  );
};
