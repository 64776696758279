export default {
  TravelTransfer: (intl) => ({
    page: {
      save: intl.formatMessage({
        description: 'Save',
        defaultMessage: 'save',
      }),
      cancel: intl.formatMessage({
        description: 'Cancel',
        defaultMessage: 'Cancel',
      }),
      selectedParticipants: intl.formatMessage({
        description: 'selected participants',
        defaultMessage: 'N° of selected participants: ',
      }),
      backButton: {
        label: intl.formatMessage({
          description: 'back',
          defaultMessage: 'Back',
        }),
        tooltip: intl.formatMessage({
          description: 'back',
          defaultMessage: 'Back',
        }),
      },
      name: intl.formatMessage({
        description: 'Name',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'Surname',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'Email user',
        defaultMessage: 'Email user',
      }),
      generalInfo: {
        title: intl.formatMessage({
          description: 'GENERAL INFO',
          defaultMessage: 'GENERAL INFO',
        }),
        travel: intl.formatMessage({
          description: 'travel included',
          defaultMessage: 'Travel Included',
        }),
        accompanying: intl.formatMessage({
          description: 'accompanying person',
          defaultMessage: 'Accompanying person',
        }),
        businessUnit: intl.formatMessage({
          description: 'business unit label',
          defaultMessage: 'Business unit',
        }),
        orderCode: intl.formatMessage({
          description: 'order code label',
          defaultMessage: 'Order code',
        }),
        transfer: intl.formatMessage({
          description: 'transfer included',
          defaultMessage: 'Transfer Included',
        }),
        yes: intl.formatMessage({
          description: 'yes',
          defaultMessage: 'yes',
        }),
        no: intl.formatMessage({
          description: 'no',
          defaultMessage: 'no',
        }),
        travelPreferences: intl.formatMessage({
          description: 'travel preferences',
          defaultMessage: 'Travel Preferences',
        }),
      },
      travelsTable: {
        table: {
          title: intl.formatMessage({
            description: 'title label',
            defaultMessage: 'ASSIGNED TRAVEL',
          }),
          columns: {
            travelType: intl.formatMessage({
              description: 'travel type label',
              defaultMessage: 'Type of travel',
            }),
            code: intl.formatMessage({
              description: 'code label',
              defaultMessage: 'Code travel',
            }),
            start: intl.formatMessage({
              description: 'start label',
              defaultMessage: 'Start',
            }),
            startTime: intl.formatMessage({
              description: 'start time label',
              defaultMessage: 'Start time',
            }),
            arrive: intl.formatMessage({
              description: 'arrive label',
              defaultMessage: 'Arrive',
            }),
            arriveTime: intl.formatMessage({
              description: 'arrive time label',
              defaultMessage: 'Arrive time',
            }),
            oneWayReturn: intl.formatMessage({
              description: 'one way / return label',
              defaultMessage: 'A/R',
            }),
            date: intl.formatMessage({
              description: 'date label',
              defaultMessage: 'Date',
            }),
            pnr: intl.formatMessage({
              description: 'pnr label',
              defaultMessage: 'PNR',
            }),
          },
          dialog: {
            title: intl.formatMessage({
              description: 'delete dialog title',
              defaultMessage: 'Cancellation confirmation',
            }),
            message: intl.formatMessage({
              description: 'Delete dialog message',
              defaultMessage: 'Are you sure you want to proceed?',
            }),
            agreeText: intl.formatMessage({
              description: 'Delete dialog agree text',
              defaultMessage: 'Confirm',
            }),
          },
        },
      },
      travel: {
        title: intl.formatMessage({
          description: 'travel transfer preferences',
          defaultMessage: 'TRAVEL & TRANSFER PREFERENCES',
        }),
        subTitle1: intl.formatMessage({
          description: 'included Travel preferences',
          defaultMessage: 'Included Travel preferences',
        }),
        subTitle2: intl.formatMessage({
          description: 'delegation to AIM Travel',
          defaultMessage: 'Delegation to AIM Travel',
        }),
        oneWay: intl.formatMessage({
          description: 'travel one Way',
          defaultMessage: 'TRAVEL ONE WAY',
        }),
        return: intl.formatMessage({
          description: 'travel return',
          defaultMessage: 'TRAVEL RETURN',
        }),
        travelDate: intl.formatMessage({
          description: 'Date',
          defaultMessage: 'Date',
        }),
        travelBy: intl.formatMessage({
          description: 'travel by',
          defaultMessage: 'Travel by',
        }),
        flightTrainN: intl.formatMessage({
          description: 'flight / train number',
          defaultMessage: 'Flight/Train n°',
        }),
        leavingFrom: intl.formatMessage({
          description: 'Leaving from',
          defaultMessage: 'Leaving from',
        }),
        at: intl.formatMessage({
          description: 'at',
          defaultMessage: 'At (hrs)',
        }),
        intermediateAirport: intl.formatMessage({
          description: 'intermediate airport',
          defaultMessage: 'Intermediate airport',
        }),
        arrivingIn: intl.formatMessage({
          description: 'arriving in',
          defaultMessage: 'Arriving in',
        }),
        returnDate: intl.formatMessage({
          description: 'Pick Up Date',
          defaultMessage: 'Pick Up Date',
        }),
        airplaneFidelityCard: intl.formatMessage({
          description: 'airplane fidelity fard (e.g. Mille Miglia)',
          defaultMessage: 'Airplane Fidelity Card (e.g. Mille Miglia)',
        }),
        trainFidelityCard: intl.formatMessage({
          description: 'train fidelity fard (e.g. Carta Freccia)',
          defaultMessage: 'Train Fidelity Card (e.g. Carta Freccia)',
        }),
        telephoneNumber: intl.formatMessage({
          description: 'telephone number',
          defaultMessage: 'Telephone number',
        }),
        notes: intl.formatMessage({
          description: 'notes',
          defaultMessage: 'Notes',
        }),
        consentForTheCheckedBaggage: intl.formatMessage({
          description: 'consent for the checked baggage',
          defaultMessage: 'Consent for the checked baggage',
        }),
        issueTickets: intl.formatMessage({
          description: 'issue tickets according to the indications provided',
          defaultMessage: 'Issue tickets according to the indications provided',
        }),
        proposal: intl.formatMessage({
          description: 'send me proposal accoding to the specifications',
          defaultMessage: 'Send me proposal accoding to the specifications',
        }),
        // to: intl.formatMessage({
        //   description: 'To',
        //   defaultMessage: 'To',
        // }),
        // pickUpPlace: intl.formatMessage({
        //   description: 'Place Pick Up',
        //   defaultMessage: 'Place Pick Up',
        // }),
        // pickUpDestination: intl.formatMessage({
        //   description: 'Destination Pick Up',
        //   defaultMessage: 'Destination Pick Up',
        // }),
      },
      transfer: {
        title: intl.formatMessage({
          description: 'TRANSFER ',
          defaultMessage: 'TRANSFER',
        }),
        oneWay: intl.formatMessage({
          description: 'transfer one way',
          defaultMessage: 'Transfer One way',
        }),
        return: intl.formatMessage({
          description: 'transfer return',
          defaultMessage: 'Transfer return',
        }),
        transferDate: intl.formatMessage({
          description: 'date',
          defaultMessage: 'Date',
        }),
        returnDate: intl.formatMessage({
          description: 'date ',
          defaultMessage: 'Date',
        }),
        pickUpTime: intl.formatMessage({
          description: 'pick up time',
          defaultMessage: 'Pick up time',
        }),
        from: intl.formatMessage({
          description: 'From ',
          defaultMessage: 'From',
        }),
        to: intl.formatMessage({
          description: 'To',
          defaultMessage: 'To',
        }),
        pickUpPlace: intl.formatMessage({
          description: 'pick up place',
          defaultMessage: 'Pick up place',
        }),
        pickUpDestination: intl.formatMessage({
          description: 'pick up destination',
          defaultMessage: 'Pick up destination',
        }),
        uploadByDocId: intl.formatMessage({
          description: 'upload by document ID',
          defaultMessage: 'Upload by Document ID',
        }),
        uploadForPurchase: intl.formatMessage({
          description: 'upload for purchase through Agency',
          defaultMessage: 'Upload for purchase through Agency',
        }),
        uploadForAutoBooking: intl.formatMessage({
          description: 'upload for autonomous booking',
          defaultMessage: 'Upload for autonomous booking',
        }),
      },
    },
  }),
};
