export const translation = {
  advancedFilters: (intl) => ({
    yes: intl.formatMessage({
      description: 'advanced filters radio type yes label',
      defaultMessage: 'Yes',
    }),
    no: intl.formatMessage({
      description: 'advanced filters radio type no label',
      defaultMessage: 'No',
    }),
  }),
};
