import React from 'react';

import Grid from '@material-ui/core/Grid';
import { theme } from '../../theme';
import { AimTypography } from '@aim/components';

export const AimBigStats = ({
  data,
  textColor = theme.colors.primary.black,
  backColor = theme.colors.greyScale.grey2,
  isUpperCase,
  containerStyle,
}) => {
  return (
    <div
      style={
        containerStyle || {
          backgroundColor: backColor,
          padding: '20px 20px 30px',
          margin: '10px 0px',
          borderRadius: 3,
        }
      }
    >
      <Grid container spacing={2}>
        {data.map((item, index) => {
          return (
            <Grid item xs={12} sm={6} key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  borderBottom: '1px solid white',
                }}
              >
                <AimTypography
                  variant="h6"
                  style={{ textTransform: isUpperCase ? 'uppercase' : 'none' }}
                >
                  {item.title}
                </AimTypography>
                <AimTypography
                  variant="text"
                  margin="0"
                  style={{
                    backgroundColor: backColor,
                    color: textColor,
                  }}
                  boxStyle={{
                    fontSize: '2.5rem',
                    fontWeight: '100',
                    paddingLeft: '10px',
                  }}
                >
                  {item.value}
                </AimTypography>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
