import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { theme } from '../../../theme';
import { AimTypography } from '../../atoms/AimTypography';
import { AimIconAndTextButton } from '../../atoms/AimIconAndTextButton';

const useStyles = makeStyles({
  collapse: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 28,
    paddingBottom: 36,
    backgroundColor: '#30CFD021',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardRoot: {
    // margin: 10,
    padding: 25,
    flex: 1,
    // height: '80%',
    // width: 'calc(100% - 10px)',
    borderRadius: 4,
    boxShadow: '0 0 black !important',
    //cursor: 'pointer',
  },
  cardContentRoot: {
    padding: '0px !important',
    margin: '20px !important',
  },

  subtitle: {
    flex: 1,
    color: theme.colors.greyScale.grey3,
  },
  labelTypography: {
    flex: 1,
    fontSize: '0.875rem',
    color: theme.colors.greyScale.black,
    opacity: 1,
  },
  fontTypography: {
    flex: 1,
    fontSize: '0.875rem',
    color: theme.colors.greyScale.grey3,
  },
  rowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderBottom: '1px solid white',
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-end',
    borderBottom: '1px solid white',
    textAlign: 'right',
  },
});

const PageCard = ({
  row,
  title,
  subtitle,
  labels, // [{label, value}]
  buttons,
  cardActions, //variant, disabled, onClick, icon
}) => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardContent style={{ padding: 0 }}>
          <div style={{ borderBottom: '1px solid grey' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '65%',
                }}
              >
                <AimTypography variant="h4" noWrap>
                  {title}
                </AimTypography>

                {subtitle && (
                  <AimTypography className={classes.subtitle} variant="text">
                    {subtitle}
                  </AimTypography>
                )}
              </div>
              <div style={{ display: 'flex', marginBottom: 15 }}>
                {/* {cardActions.map(
                  ({ variant, disabled, onClick, icon, tooltip }, idx) => (
                    <Tooltip key={`row-action-${idx}`} title={tooltip}>
                      <AimIconButton
                        small
                        key={idx}
                        aria-label={tooltip}
                        variant={variant}
                        disabled={disabled}
                        onClick={onClick}
                      >
                        {icon}
                      </AimIconButton>
                    </Tooltip>
                  )
                )} */}
                {cardActions}
              </div>
            </div>
          </div>
          {labels.map(({ label, value }) => (
            <div className={classes.rowContainer} key={label}>
              <div className={classes.labelContainer}>
                <AimTypography className={classes.labelTypography}>
                  {label}
                </AimTypography>
              </div>
              <div className={classes.valueContainer}>
                <AimTypography className={classes.fontTypography}>
                  {value}
                </AimTypography>
              </div>
            </div>
          ))}
          {buttons?.length > 0 &&
            buttons.map(({ label, value }) => {
              if (value)
                return (
                  <AimIconAndTextButton
                    style={
                      value?.hiddenWhen && value?.hiddenWhen(row)
                        ? {
                            visibility: 'hidden',
                          }
                        : undefined
                    }
                    key={label}
                    variant={value.variant}
                    text={label}
                    onClick={async () => {
                      if (value.link && typeof value.link === 'function') {
                        await value.link();
                      }
                    }}
                  >
                    {value.icon}
                  </AimIconAndTextButton>
                );
            })}
        </CardContent>
      </Card>
    </>
  );
};

export default PageCard;
