import React from 'react';

import filesize from 'filesize';

import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';

import { theme } from './../../theme';
import { AimTypography, AimIconButton } from './../atoms';
import { appState, constants } from '@aim/common';

const variantTemplate = appState.template.getValue();

const getVariant = (variant) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variant;
  } else {
    return 'primary';
  }
};
export const AimFileList = ({
  onRemove,
  onDownload,
  files = [],
  padding = 6,
  marginLeft = 11,
  marginRight = 11,
  marginTop = 11,
  marginBottom = 11,
}) => {
  return (
    <>
      {files.map((file, idx) => (
        <Grid
          key={idx}
          container
          alignItems="center"
          style={{
            marginLeft,
            marginRight,
            marginTop,
            marginBottom,
            padding,
            backgroundColor: theme.colors.greyScale.backgroundGrey,
            width: 'auto',
          }}
        >
          <Grid item xs={10}>
            <AimTypography variant={'h4regular'}>
              {file?.name}
              {file?.isMandatory ? ` * ` : ``}
            </AimTypography>
          </Grid>
          <Grid
            item
            container
            xs={2}
            alignItems={'center'}
            style={{ textAlign: 'center' }}
          >
            <Grid xs={8}>
              <AimTypography variant={'h4regular'} textAlign="right">
                {filesize(file?.size || 0)}
              </AimTypography>
            </Grid>
            <Grid xs={4}>
              {onDownload && (
                <AimIconButton
                  variant={getVariant('lightBlueFill')}
                  small="true"
                  onClick={() => onDownload(file)}
                >
                  <GetAppIcon />
                </AimIconButton>
              )}
              {onRemove && (
                <AimIconButton
                  variant={getVariant('redFill')}
                  small="true"
                  onClick={() => onRemove(file)}
                >
                  <CloseIcon />
                </AimIconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
