const translation = {
  headerParticipationAssign: (intl) => ({
    errors: {
      noParticipant: intl.formatMessage({
        description: 'noParticipant label',
        defaultMessage: 'Please select at least one participant',
      }),
    },
    paxMultiple: intl.formatMessage({
      description:
        'participation assign services faculty header n selected participants label',
      defaultMessage: 'N° of selected participants: ',
    }),
    participant: {
      username: intl.formatMessage({
        description:
          'participation assign services faculty header id column label',
        defaultMessage: 'ID',
      }),
      givenName: intl.formatMessage({
        description:
          'participation assign services faculty header givenName column label',
        defaultMessage: 'Name',
      }),
      familyName: intl.formatMessage({
        escription:
          'participation assign services faculty header familyName column label',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description:
          'participation assign services faculty header email column label',
        defaultMessage: 'Email user',
      }),
      phone: intl.formatMessage({
        description: 'participation assign services header phone column label',
        defaultMessage: 'Phone',
      }),
    },
  }),
};

export default translation;
