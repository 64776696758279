import React, { useState, useEffect } from 'react';

import { styled } from './../styled';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';

import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { theme } from '../../theme';
import { AimTypography } from './AimTypography';
import { AimRadioButton } from './AimRadioButton';
import { AimFormControlLabel } from './AimFormControlLabel';
import { constants, appState } from '@aim/common';

const CustomFormControl = styled(({ formControlWidth, ...other }) => (
  <FormControl {...other} />
))(({ style }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
  flex: 1,
  width: (props) => props.formControlWidth,
  ...style,
}));

const CustomFormLabel = styled(FormLabel)(({ variantTemplate }) => ({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
  '&.MuiFormLabel-root.Mui-focused': {
    color: 'black',
  },
  fontFamily:
    variantTemplate === constants.Templates.FRONTEND ? 'hind' : 'Roboto',
}));

const CustomRadio = styled(Radio)({
  '&:hover': {
    background: 'transparent !important',
  },
});

const CustomIcon = styled('span')(({ variant }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  ...variant,
}));

const CustomCheckedIcon = styled(CustomIcon)({
  // backgroundImage: 'linear-gradient(180deg, #84819A, 84819A)',
  '&:before': {
    display: 'block',
    width: 11,
    height: 11,
    borderRadius: '50%',
    background: '#84819A',
    marginLeft: '50%',
    marginTop: '50%',
    transform: 'translate(-50%, -50%)',
    content: '""',
  },
});

const formControlLabelPlacementTop = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const StyledRadio = ({ variant, ...props }) => (
  <CustomRadio
    disableRipple
    color="default"
    checkedIcon={<CustomCheckedIcon variant={variant} />}
    icon={<CustomIcon variant={variant} />}
    {...props}
  />
);

const variantsBACKEND = {
  grey: {
    background: '#f9f5fa',
  },
  white: {
    background: '#ffffff',
  },
};

const variantsFRONTEND = {
  primary: {
    background: theme.colors.greyScale.backgroundGrey,
  },
  secondary: {
    background: theme.colors.primary.white,
  },
};

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const variantTemplate = appState.template.getValue();

// TODO delete when restyle is completed
const variantMap = {
  grey: 'primary',
  white: 'secondary',
};

const getVariant = ({ variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[variantTemplate];
    return template[variant] || template[variantMap[variant]];
  }
};

const getDefaultVariant = (eventConfiguration) =>
  variantTemplate === constants.Templates.BACKEND
    ? 'grey'
    : eventConfiguration.background === theme.colors.primary.white
    ? 'primary'
    : 'secondary';

export const AimRadio = ({
  label,
  formControlWidth,
  children,
  formControlStyle,
  disabled,
  addAsteriks,
  radioVariant,
  labelPlacementLeft,
  options = [],
  ...rest
}) => {
  const [eventConfiguration, setEventConfiguration] = useState({});

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  const nextRadiovariant =
    radioVariant || getDefaultVariant(eventConfiguration);
  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };
  const labelStyle = labelPlacementLeft ? formLabelLabelPlacementTop : {};

  return (
    <CustomFormControl formControlWidth={formControlWidth} style={controlStyle}>
      {label && (
        <CustomFormLabel style={labelStyle} variantTemplate={variantTemplate}>
          {label}
          {addAsteriks && '*'}
        </CustomFormLabel>
      )}
      <RadioGroup row {...rest}>
        {options.length
          ? options.map((opt, idx) => (
              <div key={idx}>
                <AimFormControlLabel
                  label={opt.label}
                  value={opt.value}
                  control={
                    <StyledRadio
                      variant={{ ...getVariant({ variant: nextRadiovariant }) }}
                      disabled={disabled}
                    />
                  }
                />
                {opt.icon ? opt.icon : null}
              </div>
            ))
          : React.Children.map(children, (child) => {
              return React.cloneElement(child, {
                ...child.props,
                control: (
                  <StyledRadio
                    variant={{ ...getVariant({ variant: nextRadiovariant }) }}
                    disabled={disabled}
                  />
                ),
              });
            })}
      </RadioGroup>
    </CustomFormControl>
  );
};

export const AimRadioForm = ({
  control,
  name,
  defaultValue,
  errors,
  children,
  isRequired,
  label,
  ...rest
}) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <AimRadio
          label={label}
          addAsteriks={isRequired}
          value={props.value ?? 'true'}
          onChange={(e) =>
            console.log('onChange', e.target.value) ||
            props.onChange(e.target.value.toString())
          }
          {...rest}
        >
          {children}
        </AimRadio>
      )}
      rules={{
        required: isRequired,
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <AimTypography variant="formError">
            {message || `${label} is required`}
          </AimTypography>
        )}
      />
    )}
  </>
);

export const AimRadioDynamic = ({
  control,
  errors,
  formControlStyle,
  options,
  label,
  name,
  disabled,
  isRequired,
  defaultValue,
  ...rest
}) => {
  const opts = typeof options === 'string' ? JSON.parse(options) : options;

  return (
    <AimRadioForm
      control={control}
      name={name}
      errors={errors}
      label={label}
      formControlStyle={formControlStyle}
      disabled={disabled}
      isRequired={isRequired}
      defaultValue={defaultValue || opts?.[0]?.value || ''}
      {...rest}
    >
      {opts.map(({ label, value }) => (
        <AimFormControlLabel
          key={value}
          value={value}
          control={<AimRadioButton />}
          label={label}
        />
      ))}
    </AimRadioForm>
  );
};
