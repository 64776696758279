import React from 'react';
import { theme } from '../../theme';

export const AimStatsPanel = ({ children }) => (
  <div
    style={{
      backgroundColor: theme.colors.greyScale.grey2,
      padding: '20px 20px 30px',
      margin: '10px 0px',
      borderRadius: 3,
    }}
  >
    {children}
  </div>
);
