import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { AimIconButton, AimTypography, AimDialog } from '../../../../atoms';

import {
  PublishPaxServiceDialog,
  PublishPaxServiceDefaultValues,
} from '../../../PublishPaxServiceDialog';
import { theme } from '../../../../../theme';
import { AssignTravelDialog } from '../assignTravelDialog/AssignTravelDialog';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import LanguageIcon from '@material-ui/icons/Language';
import Grid from '@material-ui/core/Grid';

import { AimList } from '../../../../bundles/AimList';
import { styled } from '../../../../styled';
import { constants } from '@aim/common';
import { OrchideaImportDialog } from '../../../OrchideaImportDialog';

const CustomGrid = styled(Grid)(({ bold }) => {
  return {
    '& div': {
      fontWeight: bold ? 'bold' : 'normal',
    },
  };
});

export const AssignTravel = ({
  i18n,
  intl,
  addTravel,
  removeTravel,
  travels,
  isMultiple,
  handlePublishTravelStatus,
  participantId,
  eventId,
  control,
  businessUnitItems,
  dataTravel,
  reloadTravels,
  setSnackbar,
}) => {
  const [publishServiceDialog, setPublishServiceDialog] = useState(
    PublishPaxServiceDefaultValues
  );
  const [isOpen, setIsOpen] = useState(false);
  const [orchideaDialog, setOrchideaDialog] = useState({ isOpen: false });
  const [selectedRow, setSelectedRow] = useState();
  const [deleteDialog, setDeleteDialog] = useState({ isOpen: false });

  const openDialog = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedRow();
    }
  }, [isOpen]);

  const onClickEdit = (row) => {
    setSelectedRow({ ...row });
    setIsOpen(true);
  };

  const onClickCancel = (rowId, index) => {
    setDeleteDialog({ isOpen: true, id: rowId, index });
  };

  const onClickPublish = (service) => {
    setPublishServiceDialog({
      isOpen: true,
      onAgree: () => handlePublishTravelStatus?.(service),
      onClose: () => setPublishServiceDialog(PublishPaxServiceDefaultValues),
      isPublished: service?.isPublished === 'true',
    });
  };

  const HeaderRow = ({ i18n }) => {
    return (
      <>
        <Grid container>
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.travelType}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.code}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.start}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.startTime}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.arrive}
            </AimTypography>
          </Grid>
          <Grid container item xs={1}>
            <Grid item xs={8}>
              <AimTypography variant="columnHeader">
                {i18n.page.travelsTable.table.columns.arriveTime}
              </AimTypography>
            </Grid>
            <Grid item xs={4}>
              <AimTypography variant="columnHeader">
                {i18n.page.travelsTable.table.columns.oneWayReturn}
              </AimTypography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.departureDate}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.arrivalDate}
            </AimTypography>
          </Grid>
          {/* <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.publicationDate}
            </AimTypography>
          </Grid> */}
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.pnr}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant="columnHeader">
              {i18n.page.travelsTable.table.columns.order}
            </AimTypography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </>
    );
  };
  const RowItem = ({ row, index }) => {
    return (
      <>
        <CustomGrid container bold={row.highlight}>
          <Grid item xs={1}>
            <AimTypography>
              {Object.values(constants.TravelTypes)
                .find((tt) => tt.id === row.travelType)
                .label(intl)}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography>{row.transportCode}</AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography>{row.terminalIn}</AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography>{row.startDate}</AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography>{row.terminalOut}</AimTypography>
          </Grid>
          <Grid container item xs={1}>
            <Grid item xs={8}>
              <AimTypography>{row.endDate}</AimTypography>
            </Grid>
            <Grid item xs={4}>
              <AimTypography>{row.returnTrip ? 'R' : 'A'}</AimTypography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <AimTypography>
              {row.date && format(new Date(row.date), 'dd/MM/yyyy')}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography>
              {row.arrivalDate &&
                format(new Date(row.arrivalDate), 'dd/MM/yyyy')}
            </AimTypography>
          </Grid>
          {/* <Grid item xs={1}>
            <AimTypography>
              {row.publicationDate &&
                format(new Date(row.publicationDate), 'dd/MM/yyyy')}
            </AimTypography>
          </Grid> */}
          <Grid item xs={1}>
            <AimTypography>{row.pnrCode}</AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography>{row.orderCode}</AimTypography>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
            <AimIconButton
              aria-label="view"
              variant="lightBlueFill"
              onClick={() => onClickEdit(row)}
            >
              <EditIcon />
            </AimIconButton>
            <AimIconButton
              aria-label="cancel"
              variant="redFill"
              onClick={() => onClickCancel(row.id, index)}
            >
              <CloseIcon />
            </AimIconButton>
            <AimIconButton
              aria-label="cancel"
              variant={row?.isPublished === 'true' ? 'greenFill' : 'redFill'}
              onClick={() => onClickPublish(row)}
            >
              <LanguageIcon />
            </AimIconButton>
          </Grid>
        </CustomGrid>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 10,
          marginRight: 10,
        }}
      >
        <AimTypography variant="text">
          {i18n.page.travelsTable.table.title}
        </AimTypography>
        {dataTravel ? (
          <AimIconButton
            variant="green"
            small
            onClick={() =>
              setOrchideaDialog({
                isOpen: true,
                participations: [dataTravel],
              })
            }
          >
            <CachedIcon />
          </AimIconButton>
        ) : null}
      </div>
      {travels.length > 0 && (
        <div
          style={{
            margin: 8,
            backgroundColor: theme.colors.greyScale.backgroundGrey,
          }}
        >
          <AimList
            rows={travels}
            rowKey="id"
            HeaderRow={<HeaderRow i18n={i18n} />}
            deletable={false}
            virtualized={travels.length > 5 ? true : false}
            listHeight={travels.length > 5 ? 380 : 'auto'}
            style={{ margin: '0px 20px' }}
            marginHeader={false}
          >
            <RowItem />
          </AimList>
        </div>
      )}
      <AimIconButton variant="yellowFill" onClick={openDialog}>
        <AddIcon />
      </AimIconButton>
      <AssignTravelDialog
        {...{
          intl,
          isOpen,
          setIsOpen,
          data: selectedRow,
          addTravel: (data, selectedRoute) => {
            addTravel(data, selectedRoute, selectedRow?.index);
          },
          isMultiple,
          participantId,
          eventId,
          control,
          businessUnitItems,
          // handleFiles: (files) => setFiles(files),
        }}
      />
      {dataTravel ? (
        <OrchideaImportDialog
          intl={intl}
          dialog={orchideaDialog}
          setDialog={setOrchideaDialog}
          setSnackbar={setSnackbar}
          eventId={eventId}
          participantId={participantId}
          refreshData={reloadTravels}
        />
      ) : null}
      <AimDialog
        disableEnforceFocus
        open={deleteDialog.isOpen}
        onDisagree={() => setDeleteDialog({ isOpen: false })}
        onAgree={() => {
          removeTravel({
            id: deleteDialog.id,
            index: deleteDialog.index,
          });
          setDeleteDialog({ isOpen: false });
        }}
        title={i18n.page.travelsTable.table.dialog.title}
        agreeText={i18n.page.travelsTable.table.dialog.agreeText}
      >
        <AimTypography variant="body1">
          {i18n.page.travelsTable.table.dialog.message}
        </AimTypography>
      </AimDialog>
      <PublishPaxServiceDialog dialog={publishServiceDialog} intl={intl} />
    </>
  );
};
