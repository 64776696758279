import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import useI18n from '../utils/i18n';
import { CustomStyledDialog } from '../utils/CustomStyledDialog';
import { AimTypography } from '../../../atoms/AimTypography';
import { AimRadio } from '../../../atoms/AimRadio';
import { AimFormControlLabel } from '../../../atoms/AimFormControlLabel';
import { AimRadioButton } from '../../../atoms/AimRadioButton';

export const InnerMassiveImportDialog = ({
  intl,
  massiveImportDialog,
  onAgree,
  onClose,
  exportTemplateWithRowsVisible,
}) => {
  // Hooks
  const i18n = useI18n.importDialog(intl);

  // States
  const [selected, setSelected] = useState(1);

  // Constants
  const radioOptions = [
    { id: 1, text: i18n.exportText },
    { id: 2, text: i18n.importText },
    ...(exportTemplateWithRowsVisible
      ? [
          {
            id: 3,
            text: i18n.exportTemplateWithRows,
          },
        ]
      : []),
  ];

  // Function
  const handleChange = (val) => {
    setSelected(parseInt(val));
  };

  const handleOnAgree = () => {
    // const action = selected === 1 ? 'export' : 'import';
    let action;
    switch (selected) {
      case 1:
        action = 'export';
        break;
      case 2:
        action = 'import';
        break;
      case 3:
        action = 'exportTemplateWithRows';
        break;
    }
    onAgree && action && onAgree(action);
  };

  return (
    <CustomStyledDialog
      disableEnforceFocus
      title={i18n.title}
      open={massiveImportDialog.isOpen}
      onClose={() => {
        onClose();
      }}
      onAgree={handleOnAgree}
    >
      <Grid container>
        <Grid item xs={12}>
          <AimTypography variant="textBold">{i18n.subTitle}</AimTypography>
          <AimRadio
            value={selected}
            onChange={(e) => handleChange(e.target.value)}
            formControlWidth="calc(100% - 10px)"
            style={{
              marginLeft: 16,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {radioOptions.map((o, idx) => (
              <AimFormControlLabel
                key={idx}
                value={o.id}
                control={<AimRadioButton />}
                label={o.text}
              />
            ))}
          </AimRadio>
        </Grid>
      </Grid>
    </CustomStyledDialog>
  );
};
