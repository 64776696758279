import React, { useCallback, useMemo } from 'react';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { MenuSelect, useRichTextEditorContext } from 'mui-tiptap';
// import MenuButtonTooltip from './MenuButtonTooltip';
// import { MENU_BUTTON_FONT_SIZE_DEFAULT } from './MenuButton';
// import MenuSelect from './MenuSelect';

const MENU_BUTTON_FONT_SIZE_DEFAULT = '1.25rem';

const useStyles = makeStyles({
  name: { MenuSelectTextAlign: MenuSelectLineSpacing },
})((theme) => ({
  selectInput: {
    // We use a fixed width equal to the size of the menu button icon so that
    // the Select element won't change sizes even if we show the "blank"
    // interface when the selected content contains multiple different text
    // alignments.
    width: MENU_BUTTON_FONT_SIZE_DEFAULT,
  },

  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  menuOption: {
    // These styles ensure the item fills its MenuItem container, and the
    // tooltip appears in the same place when hovering over the item generally
    // (not just the text of the item)
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },

  menuButtonIcon: {
    fontSize: MENU_BUTTON_FONT_SIZE_DEFAULT,
    // For consistency with toggle button default icon color and the Select
    // dropdown arrow icon color
    // https://github.com/mui/material-ui/blob/2cb9664b16d5a862a3796add7c8e3b088b47acb5/packages/mui-material/src/ToggleButton/ToggleButton.js#L60,
    // https://github.com/mui/material-ui/blob/0b7beb93c9015da6e35c2a31510f679126cf0de1/packages/mui-material/src/NativeSelect/NativeSelectInput.js#L96
    color: theme.palette.action.active,
  },
}));

const DEFAULT_ALIGNMENT_OPTIONS = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '1.15',
    label: '1.15',
  },
  {
    value: '1.5',
    label: '1.5',
  },
  {
    value: '2',
    label: '2',
  },
];

export default function MenuSelectLineSpacing({
  options = DEFAULT_ALIGNMENT_OPTIONS,
  emptyLabel = '',
  spacingOptions,
  ...menuSelectProps
}) {
  const { classes, cx } = useStyles();
  const editor = useRichTextEditorContext();

  options = spacingOptions ?? options;

  const handleLineSpacingSelect = useCallback(
    (event) => {
      const lineSpacing = event.target.value;
      editor?.chain().setLineSpacing(lineSpacing).focus().run();
    },
    [editor]
  );

  // Figure out which settings the user has enabled with the heading extension
  const lineSpacingExtensionOptions = useMemo(() => {
    const lineSpacingExtension = editor?.extensionManager.extensions.find(
      (extension) => extension.name == 'lineSpacing'
    );
    return lineSpacingExtension?.options;
  }, [editor]);

  const enabledLineSpacings = useMemo(() => {
    return new Set(lineSpacingExtensionOptions?.lineSpacings);
  }, [lineSpacingExtensionOptions]);

  // Only set the Select `value` as non-empty if all alignments are the same
  // (which we'll know if `isActive({ textAlign: alignment })` returns true).
  // This allows the user to change all current selected nodes' alignments to
  // any alignment, including the default alignment. If we instead set the
  // `value` as the default for instance, attempting to change multiple node's
  // alignments to that default would not work (not triggering "onChange").
  const selectedValue =
    Array.from(enabledLineSpacings).find((spacing) =>
      editor?.isActive({ lineSpacing: spacing })
    ) ?? '';

  return (
    <MenuSelect
      onChange={handleLineSpacingSelect}
      disabled={
        !editor?.isEditable ||
        !Array.from(enabledLineSpacings).some((spacing) =>
          editor.can().setLineSpacing(spacing)
        )
      }
      // Override the rendering of the selected value so that we don't show
      // tooltips on hovering (like we do for the menu options)
      renderValue={(value) => {
        let content;
        if (value) {
          {
            /* const lineSpacingOptionForValue = options.find(
            (option) => option.value === value
          );
          content = lineSpacingOptionForValue ? (
            <lineSpacingOptionForValue.IconComponent
              className={classes.menuButtonIcon}
            />
          ) : ( */
          }
          content = value;
          {
            /* ); */
          }
        } else {
          content = emptyLabel;
        }
        return <span className={classes.menuOption}>{content}</span>;
      }}
      aria-label="Line Spacing"
      tooltipTitle="Line Spacing"
      value={selectedValue}
      displayEmpty
      {...menuSelectProps}
      inputProps={{
        ...menuSelectProps.inputProps,
        className: cx(
          classes.selectInput,
          menuSelectProps.inputProps?.className
        ),
      }}
    >
      {options
        .filter((lineSpacingOption) =>
          enabledLineSpacings.has(lineSpacingOption.value)
        )
        .map((lineSpacingOption) => (
          <MenuItem
            key={lineSpacingOption.value}
            value={lineSpacingOption.value}
            disabled={!editor?.can().setLineSpacing(lineSpacingOption.value)}
            className={classes.menuItem}
          >
            {lineSpacingOption.label}
            {/* <MenuButtonTooltip
              label={alignmentOption.label ?? ''}
              placement="right"
              contentWrapperClassName={classes.menuOption}
            >
               <alignmentOption.IconComponent
                className={classes.menuButtonIcon}
              /> 
            </MenuButtonTooltip> */}
          </MenuItem>
        ))}
    </MenuSelect>
  );
}
