import { aws, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const participantsByEventIdQuery = /* GraphQL */ `
  query ParticipantsByEventId($participationEventId: ID) {
    participantByEventId(
      participationEventId: $participationEventId
      filter: { isDeleted: { ne: true } }
    ) {
      items {
        id
        email
        givenName
        familyName
        type
        billingInformation {
          id
        }
      }
    }
  }
`;

const hotelReservationsByEventIdQuery = /* GraphQL */ `
  query HotelReservationsByEventId($hotelReservationEventId: ID!) {
    hotelReservations: listHotelReservationByEventId(
      hotelReservationEventId: $hotelReservationEventId
    ) {
      items {
        id
        start
        end
        hotelReservationEventId
      }
    }
  }
`;

const hotelReservationByProductIdQuery = /* GraphQL */ `
  query hotelReservationByProductId($productId: ID!) {
    hotelReservation: getHotelReservation(id: $productId) {
      id
      start
      end
      roomsReservation: hotelRoomReservation {
        items {
          id
          depositAmount
          balanceAmount
          startDate
          endDate
          isDeleted
          guests {
            items {
              familyName
              givenName
            }
          }
          hotelRoom {
            id
            name
            hotel {
              name
            }
          }
        }
      }
    }
  }
`;

export const listParticipantsByEventId = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: participantsByEventIdQuery,
      variables: {
        participationEventId: eventId,
      },
    })
      .then((response) => {
        resolve(response.data.participantByEventId.items);
      })
      .catch((e) => {
        console.error('participants-by-eventId', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const listHotelReservationsByEventId = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: hotelReservationsByEventIdQuery,
      variables: {
        hotelReservationEventId: eventId,
      },
    })
      .then((response) => {
        resolve(response?.data?.hotelReservations?.items);
      })
      .catch((e) => {
        console.error('participants-by-eventId', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getHotelReservationByProductId = (productId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: hotelReservationByProductIdQuery,
      variables: {
        productId,
      },
    })
      .then((response) => {
        resolve(response?.data?.hotelReservation);
      })
      .catch((e) => {
        console.error('reservation from productId', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
