import { appState } from '@aim/common';

export const useLoaderHelper = () => {
  const showLoader = () => appState.isLoader.next(true);
  const hideLoader = () => appState.isLoader.next(false);
  return {
    showLoader,
    hideLoader,
  };
};
