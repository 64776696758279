import React, { useEffect, useState, useRef } from 'react';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';

import { constants, appState } from '@aim/common';
import {
  AimIconAndTextButton,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
} from './../../atoms';

import { ParticipationForm } from './ParticipationForm';
import {
  getEventDetails,
  getParticipationUserAndFieldValues,
} from './gqlHelper';
import translation from './translation';
import { format } from 'date-fns';

import { HeaderParticipationAssign } from './../../templates';

// divide standard and custom fields for the ui
// use baseFields
// make good use of profiles and typologies from event
// filter results by router state

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const pNew = {
  givenName: '',
  familyName: '',
  email: '',
  isParticipant: true,
  isSpeaker: false,
  isReviewer: false,
};

export const ParticipationEdit = ({
  intl,
  backUrl,
  onSave,
  editFrontOffice = false,
  isPostDeadline,
  sponsorAdmins = [],
  isAdminEnabled,
  eventId,
  participationData,
  participationIdList,
  history,
  requiredBaseFields = [],
  toSkipBaseFieldsIds = [],
  toHideBaseFields = [],
  isRequiredEnabled,
}) => {
  const i18n = translation.participationEdit(intl);
  const saveDataRef = useRef();

  const [currentParticipation, setCurrentParticipation] = useState();
  const [profiles, setProfiles] = useState([]);
  const [feeDateRanges, setFeeDateRanges] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [preTitles, setPreTitles] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [user, setUser] = useState();

  useEffect(() => {
    // Get cognitoId of the user logged to know if it is Congress or Travel
    const subscription = appState.user.subscribe(setUser);
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    console.log('effect');
    if (!eventId && !participationData.id) return;

    const getData = async () => {
      showLoader();
      const evDetails = await getEventDetails(
        eventId,
        participationData.cluster,
        false
      );

      // filter profile by active feebrackets
      let itemsProfiles = evDetails.profiles.items;
      if (editFrontOffice) {
        itemsProfiles = evDetails.profiles.items.filter((p) =>
          p.profileFeeBrackets.items.some(
            (i) => i.profileFeeConfiguration?.isActive
          )
        );
      }

      const nextProfiles = itemsProfiles.map((profile) => ({
        label:
          profile.name +
          (profile?.category?.name ? ` - ${profile.category.name}` : ''),
        value: profile.id,
        maxAccompanyingPersons: profile.maxAccompanyingPersons,
      }));

      setProfiles(nextProfiles);

      const nextFeeDateRanges = evDetails?.fee?.feeDateRanges.items.map(
        ({ id, start, end, label }) => {
          const eventDateStart = format(new Date(start), 'PP');
          const eventDateEnd = format(new Date(end), 'PP');
          const nextLabel =
            eventDateStart === eventDateEnd
              ? `${label} - ${eventDateStart}`
              : `${label} - ${eventDateStart} - ${eventDateEnd}`;
          return {
            value: id,
            label: nextLabel,
          };
        }
      );
      setFeeDateRanges(nextFeeDateRanges);

      const nextTypologies = evDetails.participationTypologies.items.map(
        (participationTypology) => ({
          label: participationTypology.name,
          value: participationTypology.id,
        })
      );
      setTypologies(nextTypologies);

      const nextPreTitles = evDetails.preTitles.items
        .filter((preTitle) => preTitle.isActive)
        .map((p) => ({
          label: p.title,
          value: p.key,
        }));
      setPreTitles(nextPreTitles);

      let p = {
        ...pNew,
        ...participationData,
        clusterId: participationData.clusterId,
        event: { id: eventId, type: evDetails.type },
      };

      if (participationData.cluster === constants.Clusters.Delegations.id)
        p.delegation = { id: participationData.clusterId };

      if (participationData.cluster === constants.Clusters.Groups.id)
        p.group = { id: participationData.clusterId };

      if (participationData.cluster === constants.Clusters.SponsorStaff.id)
        p.sponsorStaff = { id: participationData.clusterId };

      if (participationData.cluster === constants.Clusters.SponsorList.id)
        p.sponsorList = { id: participationData.clusterId };

      if (participationData.id) {
        p = await getParticipationUserAndFieldValues(
          participationData.id,
          false
        );
        if (!p) {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: 'No participation found',
          });
          return;
        }
      }
      setCurrentParticipation({ ...p, feeDateRange: p.feeDateRange?.id });
    };

    getData();
  }, [eventId, participationData.id]);

  const sendData = async (submittedData, dirtyFields) => {
    onSave &&
      (await onSave(submittedData, currentParticipation, backUrl, dirtyFields));
  };

  const handleSave = () => {
    if (!saveDataRef.current) return;
    saveDataRef.current.click();
  };

  const getSaveText = () => {
    return isPostDeadline ? i18n.actions.request : i18n.actions.save;
  };

  const buildToSkipBaseFieldsIds = () => {
    let fields = [];

    if (user && user.type === constants.UserTypes.TRAVEL.id)
      fields = [
        ...fields,
        // 'typology',
        'isParticipant',
        'isSpeaker',
        'feeDateRange',
        'isReviewer',
      ];

    if (!isAdminEnabled) fields = [...fields, 'isSponsorAdmin'];

    if (currentParticipation?.cluster === constants.Clusters.SponsorStaff.id)
      fields = [...fields, 'isParticipant', 'isSpeaker', 'isReviewer'];

    return fields;
  };

  return (
    <>
      {backUrl && (
        <>
          <div style={{ display: 'flex', flex: 1, width: '100%' }}>
            <AimIconAndTextButton
              variant="none"
              text={i18n.actions.backButton.labels.back}
              style={{ padding: 0 }}
              onClick={() => history.push(backUrl)}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </div>
          <AimTypography variant="h2">
            {currentParticipation
              ? `${currentParticipation.givenName} ${currentParticipation.familyName}` ||
                currentParticipation.email
              : ''}
          </AimTypography>
        </>
      )}
      <div
        style={{
          background: 'white',
          borderRadius: 4,
          padding: '20px 40px',
          margin: '20px 0px',
        }}
      >
        <ParticipationForm
          {...{
            intl,
            participation: currentParticipation,
            profiles,
            typologies,
            preTitles,
            feeDateRanges,
            refButton: saveDataRef,
            onSaveDataFn: sendData,
            toSkipBaseFieldsIds: toSkipBaseFieldsIds.length
              ? [...toSkipBaseFieldsIds, ...buildToSkipBaseFieldsIds()]
              : buildToSkipBaseFieldsIds(),
            toDisableBaseFields:
              sponsorAdmins.length &&
              !sponsorAdmins.includes(currentParticipation?.id)
                ? ['isSponsorAdmin']
                : [],
            requiredBaseFields,
            toHideBaseFields,
            isRequiredEnabled,
            onLoadEnd: hideLoader,
          }}
        />
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            marginTop: 20,
          }}
        >
          {backUrl && (
            <AimIconAndTextButton
              variant="greyFill"
              text={i18n.actions.cancel}
              onClick={() => history.push(backUrl)}
            >
              <ReplayIcon />
            </AimIconAndTextButton>
          )}
          <AimIconAndTextButton
            variant="greenFill"
            text={getSaveText()}
            onClick={handleSave}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </div>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};
