import React from 'react';

import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';

import { styled } from '../../styled';
import { AimTypography, AimIconButton } from '../../atoms';
import { AimSwitch, AimTextField, theme } from '@aim/components';
import './AimSubWallCard.css';

const StepDivider = styled(Divider)({
  marginTop: 10,
  marginBottom: 10,
});

const StyledCard = styled(Card)(({ height, background }) => ({
  margin: 10,
  height: height,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 16,
  paddingRight: 16,
  background,
  border: 0,
}));

const StyledCardContent = styled(CardContent)({
  padding: '0px !important',
  flex: 1,
});

const formControlStyle = { width: 'calc(100% - 10px)' };

export const AimSubWallCard = ({
  label,
  onContainerClick,
  onEyeClick,
  disableClick,
  icon,
  iconStyle,
  hasEye = true,
  hasActiveFlag = false,
  activeFlagChecked = false,
  badgeContent = '',
  children,
  variant = 'white',
  ...rest
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      onClick={() => !disableClick && onContainerClick && onContainerClick()}
      {...rest}
    >
      <StyledCard
        variant="outlined"
        height={activeFlagChecked ? undefined : 75}
        background={variant}
      >
        <StyledCardContent>
          <Grid container>
            <Grid item xs={10}>
              <AimTypography
                style={{
                  fontWeight: 500,
                  fontSize: '1.125rem',
                }}
                className="card-multiline-ellipsis"
              >
                {label}
              </AimTypography>
              {hasActiveFlag && (
                <div style={{ marginLeft: 18, paddingBottom: 3 }}>
                  <AimSwitch
                    label={activeFlagChecked ? 'Active' : 'Inactive'}
                    checked={activeFlagChecked}
                    onChange={rest.onChangeFn}
                  />
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: 'flex', alignItems: 'center', ...iconStyle }}
            >
              {hasEye ? (
                <div style={{ position: 'relative' }}>
                  <AimIconButton
                    small="true"
                    variant="lightBlueFill"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEyeClick();
                    }}
                    disabled={disableClick}
                  >
                    {icon ? icon : <Visibility />}
                  </AimIconButton>
                  {badgeContent && (
                    <div
                      style={{
                        backgroundColor: theme.colors.secondary.red,
                        minWidth: 20,
                        minHeight: 20,
                        borderRadius: 50,
                        position: 'absolute',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        right: '-10px',
                        top: '-10px',
                      }}
                    >
                      <AimTypography
                        style={{ fontSize: 12, fontWeight: 'bold' }}
                        margin={0}
                      >
                        {badgeContent}
                      </AimTypography>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </Grid>
            {activeFlagChecked && !children && (
              <>
                <Grid item xs={12}>
                  <StepDivider />
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <AimTextField
                      type="text"
                      label={
                        'Label item on the menu (it will overwrite the default text)'
                      }
                      value={rest.menuItemLabel}
                      formControlStyle={formControlStyle}
                      onChange={rest.changeMenuItemLabelFn}
                    />
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <AimTextField
                      type="date"
                      label={'Date of activation'}
                      value={rest.dateOfActivation}
                      formControlStyle={formControlStyle}
                      onChange={rest.changeDateOfActivationFn}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {rest.hasShowPremiumRanges && (
                    <div style={{ marginLeft: 18, paddingBottom: 3 }}>
                      <AimSwitch
                        label={'Show Premium Ranges'}
                        checked={rest.showPremiumRangesFlagChecked}
                        onChange={rest.toggleShowPremiumRangesFn}
                      />
                    </div>
                  )}
                  {rest.hasShowUpNext && (
                    <div style={{ marginLeft: 18, paddingBottom: 3 }}>
                      <AimSwitch
                        label={'Show Up Next'}
                        checked={rest.showUpNextFlagChecked}
                        onChange={rest.toggleShowUpNextFn}
                      />
                    </div>
                  )}
                </Grid>
              </>
            )}
            {activeFlagChecked && children && <>{children}</>}
          </Grid>
        </StyledCardContent>
      </StyledCard>
    </Grid>
  );
};
