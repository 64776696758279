import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { appState, constants, mailUpHelper } from '@aim/common';

import { AimTypography } from './AimTypography';
import { styled } from '../styled';

import { fileHelper } from '@aim/common';

import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownUploadFromRepoPlugin from './aimMarkdown/MarkdownUploadFromRepoPlugin';

const plugins = [
  'header',
  'font-bold',
  'font-italic',
  'font-underline',
  'font-strikethrough',
  'list-unordered',
  'list-ordered',
  'block-quote',
  'block-wrap',
  'block-code-inline',
  'block-code-block',
  'table',
  'image',
  'image-from-repo',
  'link',
  'clear',
  'logger',
  'mode-toggle',
  'full-screen',
  'tab-insert',
];

MdEditor.use(MarkdownUploadFromRepoPlugin);

const CustomFormLabel = styled(FormLabel)(({ variantTemplate }) => ({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
  fontFamily:
    variantTemplate === constants.Templates.FRONTEND ? 'hind' : 'Roboto',
  '&.MuiFormLabel-root.Mui-focused': {
    color: 'black',
  },
}));

const onImageUpload = async (file, eventId) => {
  if (!eventId) {
    alert('Image uploading disabled. Must specify eventId');
    return;
  }

  const key = `events/${eventId}/mailTemplates/`;
  let fileName = file.name;
  const ext = `.${fileName.split('.').pop()}`;
  fileName = fileName.slice(0, ext.length * -1);

  const fileData = {
    originalName: fileName,
    extension: ext,
    size: file.size,
  };
  const res = await fileHelper.createFile({
    dirPath: key,
    fileData,
    localFile: file,
    useLoader: false,
    isPublic: true,
  });

  const link = await fileHelper.getPublicFileLink({
    dirPath: key,
    fileData: {
      id: res,
      extension: ext,
      originalName: fileName,
    },
  });

  return link;
};

export const AimMarkdown = ({
  label,
  value = '',
  onChange,
  height = '500px',
  addAsterisk,
  eventId = null,
}) => {
  return (
    <>
      <CustomFormLabel variantTemplate={appState.template.getValue()}>
        {label}
        {addAsterisk && '*'}
      </CustomFormLabel>
      <MdEditor
        value={value}
        style={{ height }}
        renderHTML={mailUpHelper.renderMarkdownToHtml}
        imageAccept={`.jpg,.png,.jpeg`}
        onChange={onChange}
        onImageUpload={(file) => onImageUpload(file, eventId)}
        plugins={plugins}
      />
    </>
  );
};

export const AimMarkdownForm = ({
  control,
  name,
  defaultValue = '',
  errors,
  isRequired,
  label,
  maxLength,
  minLength,
  validate,
  height = '200px',
  eventId = null,
}) => (
  <div style={{ padding: 8 }}>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <AimMarkdown
          height={height}
          onChange={({ text }) => props.onChange(text)}
          label={label}
          value={props.value}
          addAsterisk={isRequired}
          eventId={eventId}
        />
      )}
      rules={{
        required: isRequired,
        maxLength: maxLength,
        minLength: minLength,
        validate: validate,
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => {
          return (
            <div>
              <AimTypography variant="formError">
                {message ||
                  (errors?.[name]?.type === 'minLength'
                    ? `${label} is too short`
                    : errors?.[name]?.type === 'maxLength'
                    ? `${label} is too long`
                    : errors?.[name]?.type === 'validate'
                    ? `${label} is not valid`
                    : `${label} is required`)}
              </AimTypography>
            </div>
          );
        }}
      />
    )}
  </div>
);
