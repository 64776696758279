import React, { useRef, useState, useEffect } from 'react';
import { debounce } from 'lodash';

import { googleHelper } from '@aim/common';
import { AimAutocompleteForm } from '@aim/components';
const { getGoogleMapsAddressesByInput } = googleHelper;

export const AddressAutocomplete = ({
  control,
  register,
  setValue,
  textfieldVariant,
  errors,
  name,
  label,
  formControlStyle,
  clearErrors,
  isRequired,
  addAsterisk,
}) => {
  const [addressOptions, setAddressOptions] = useState([]);

  const debouncedInputAddresses = useRef(
    debounce(async (value) => {
      const descriptions = await getGoogleMapsAddressesByInput(value);
      setAddressOptions(descriptions);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedInputAddresses.cancel();
    };
  }, [debouncedInputAddresses]);

  return (
    <AimAutocompleteForm
      control={control}
      register={register}
      setValue={setValue}
      textfieldVariant={textfieldVariant}
      errors={errors}
      options={addressOptions}
      name={name}
      label={label}
      formControlStyle={formControlStyle}
      clearErrors={clearErrors}
      onInputChangeFn={async (value) => {
        debouncedInputAddresses(value);
      }}
      isRequired={isRequired}
      addAsterisk={addAsterisk}
      filterOptions={(options) => options}
    />
  );
};
