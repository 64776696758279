/* eslint-disable react/display-name */
import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { AimPagination } from './AimPagination';
import { appState } from '@aim/common';

export const AimCardList = ({
  labels,
  items,
  itemKey,
  children: Card,
  selected,
  setSelected = () => {},
  onChecked,
  checkedItems,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [checked, setChecked] = useState(checkedItems || []);

  const handleSelectedClick = (event, row) => {
    event.stopPropagation();
    if (selected && selected[itemKey] === row[itemKey]) {
      setSelected(null);
    } else {
      setSelected(row);
    }
  };

  const handleCheck = (event, name) => {
    event.stopPropagation();
    const selectedIndex = checked.indexOf(name);
    let newChecked = [];

    if (selectedIndex === -1) {
      newChecked = newChecked.concat(checked, name);
    } else if (selectedIndex === 0) {
      newChecked = newChecked.concat(checked.slice(1));
    } else if (selectedIndex === checked.length - 1) {
      newChecked = newChecked.concat(checked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newChecked = newChecked.concat(
        checked.slice(0, selectedIndex),
        checked.slice(selectedIndex + 1)
      );
    }

    setChecked(newChecked);
    onChecked && onChecked(newChecked);
  };

  const isChecked = (name) => checked.indexOf(name) !== -1;

  const Pager = React.memo(
    ({ children }) => {
      const rows = items.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      return rows.map((row, index) => {
        return React.cloneElement(children, {
          key: row[itemKey],
          row,
          index,
        });
      });
    },
    (prevProps, nextProps) =>
      prevProps.page === nextProps.page &&
      prevProps.rowsPerPage === nextProps.rowsPerPage
  );

  const WrapperCard = React.memo(
    (props) => {
      return (
        <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
          {React.cloneElement(Card, props)}
        </Grid>
      );
    },
    (prevProps, nextProps) => prevProps.isChecked === nextProps.isChecked
  );

  return (
    <>
      <Grid container spacing={2} style={{ flex: 1 }}>
        <Pager>
          <WrapperCard
            {...{
              itemKey,
              isChecked,
              handleCheck,
              handleSelectedClick,
            }}
          />
        </Pager>
      </Grid>
      <AimPagination
        elementsCount={items.length}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPageOptions={[8, 16, 24]}
      />
    </>
  );
};
