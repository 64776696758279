import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';

import {
  AimTypography,
  AimIconAndTextButton,
  AimIconButton,
} from '../../atoms';

import { MainContainer, InnerContainer } from './Containers';
import translation from './translation';

export const PaymentOptions = ({ intl, history }) => {
  const i18n = translation.paymentOptions(intl);

  //Effects
  useEffect(() => {
    if (history?.location?.state?.preselectedPaymentType) {
      selectPaymentType({
        paymentType: history?.location?.state?.preselectedPaymentType,
        preselectedPaymentId: history?.location?.state?.preselectedPaymentId,
        paymentData: history?.location?.state?.paymentData,
        autoBuy: true,
      });
    }
  }, []);

  const selectPaymentType = async ({
    paymentType,
    autoBuy,
    preselectedPaymentId,
    paymentData,
  }) => {
    history.push({
      pathname: `${history.location?.state?.url}/payment`,
      state: {
        ...history.location?.state,
        paymentType,
        // netPrice: history.location?.state?.netPrice,
        // productId: history.location?.state?.productId,
        // serviceId: history.location?.state?.serviceId,
        // serviceType: history.location?.state?.serviceType,
        // additionalServices: history.location?.state?.additionalServices,
        // clientId: history.location?.state?.clientId,
        // clientType: history.location?.state?.clientType,
        // serviceLabel: history.location?.state?.serviceLabel,
        // serviceDescr: history.location?.state?.serviceDescr,
        // vat: history.location?.state?.vat,
        // paymentMetadata: history.location?.state?.paymentMetadata,
        // gateway: history.location?.state?.gateway,
        // relUrl: history.location?.state?.relUrl,
        // url: history.location?.state?.url,
        // baseUrl: history.location?.state?.baseUrl,
        // options: history.location?.state?.options,
        // billingInfo: history.location?.state?.billingInfo,
        // participationGuests: history.location?.state?.participationGuests,
        // quantity: history.location?.state?.quantity,
        maxQuantity:
          history.location?.state?.maxQuantity ||
          history.location?.state?.availableSubscription,
        ...(autoBuy && { autoBuy }),
        ...(preselectedPaymentId && { preselectedPaymentId }),
        ...(paymentData && { paymentData }),
      },
    });
  };

  return (
    <MainContainer>
      <div>
        <InnerContainer>
          <Grid container>
            <Grid item xs={12}>
              <Tooltip title={i18n.page.back.tooltip}>
                <AimIconAndTextButton
                  variant="text"
                  text={i18n.page.back.button}
                  onClick={() => {
                    if (history.location?.state?.backUrl) {
                      history.push(history.location?.state?.backUrl);
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <ArrowBack />
                </AimIconAndTextButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <AimTypography variant="h3Regular">
                {i18n.page.title}
              </AimTypography>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'white',
                  padding: '20px',
                  marginBottom: '10px',
                  alignItems: 'center',
                }}
              >
                <AimTypography variant="h4Regular">
                  {i18n.page.bankTransfer.label}
                </AimTypography>
                <AimIconButton
                  variant={'lightBlue'}
                  onClick={() =>
                    selectPaymentType({ paymentType: 'bankTransfer' })
                  }
                >
                  <ArrowForward />
                </AimIconButton>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'white',
                  padding: '20px',
                  alignItems: 'center',
                }}
              >
                <AimTypography variant="h4Regular">
                  {i18n.page.creditCard.label}
                </AimTypography>
                <AimIconButton
                  variant={'lightBlue'}
                  onClick={() =>
                    selectPaymentType({ paymentType: 'creditCard' })
                  }
                >
                  <ArrowForward />
                </AimIconButton>
              </div>
            </Grid>
          </Grid>
        </InnerContainer>
      </div>
    </MainContainer>
  );
};
