import { fileHelper } from '@aim/common';

export const onImageUpload = async (file, eventId) => {
  if (!eventId) {
    alert('Image uploading disabled. Must specify eventId');
    return;
  }

  const key = `events/${eventId}/richTextImages/`;
  let fileName = file.name;
  const ext = `.${fileName.split('.').pop()}`;
  fileName = fileName.slice(0, ext.length * -1);

  const fileData = {
    originalName: fileName,
    extension: ext,
    size: file.size,
  };
  const res = await fileHelper.createFile({
    dirPath: key,
    fileData,
    localFile: file,
    useLoader: false,
    isPublic: true,
  });

  const link = await fileHelper.getPublicFileLink({
    dirPath: key,
    fileData: {
      id: res,
      extension: ext,
      originalName: fileName,
    },
  });

  return { src: link, alt: fileName };
};
