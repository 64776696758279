import React from 'react';
import {
  Button,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { getMarkRange, getMarkType } from '@tiptap/core';
import { useKeyDown } from 'mui-tiptap';
import encodeurl from 'encodeurl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { listFolders } from '../gqlHelper';
import { aws } from '@aim/common';

const mainFolderPath = 'aim_main/';
const ACCEPTED_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.gif', '.svg'];

/** Shown when a user is adding/editing a Link for Tiptap. */
const UploadFromRepoBubbleMenuContent = ({
  editor,
  onCancel,
  onSave,
  labels,
}) => {
  //   const existingHref = editor.isActive('link')
  //     ? editor.getAttributes('link').href
  //     : '';
  //   const linkRange = getMarkRange(
  //     editor.state.selection.$from,
  //     getMarkType('link', editor.schema)
  //   );
  //   const linkText = linkRange
  //     ? editor.state.doc.textBetween(linkRange.from, linkRange.to)
  //     : '';

  //   const selectedText = editor.state.doc.textBetween(
  //     editor.state.selection.$from.pos,
  //     editor.state.selection.$to.pos
  //   );

  // If we're on a link, we'll use the full link text, otherwise we'll fall back
  // to the selected text

  const [folders, setFolders] = useState([]);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [selectedFolderPath, setSelectedFolderPath] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const textRef = useRef();
  const hrefRef = useRef();

  useEffect(() => {
    listFolders().then((result) => {
      const foldersPaths = result.map((x) => {
        return {
          path: x.path,
          label: x.path.substring(mainFolderPath.length),
          id: x.id,
        };
      });
      setFolders(foldersPaths);
    });
  }, []);

  useEffect(() => {
    if (selectedFolderPath) {
      listFilesInPath({ path: selectedFolderPath });
    } else {
      setDisplayFiles([]);
    }
  }, [selectedFolderPath]);

  const listFilesInPath = async ({ path }) => {
    let res = await aws.Storage.list(path, { pageSize: 'ALL' });
    res = res.filter((x) => !x.key.endsWith('/'));

    const filesToDisplay = res
      .map((x) => {
        const fileData = x.key.split('/');
        const fileNameWithExtension = fileData[fileData.length - 1];

        const fileExtensions = fileNameWithExtension.split('.');
        const fileExtension = `.${fileExtensions[fileExtensions.length - 1]}`;
        const fileName = fileExtensions
          .slice(0, fileExtensions.length - 1)
          .join('.');

        const filePath = fileData.slice(0, fileData.length - 1).join('/');
        return {
          ...x,
          label: x.key.substring(selectedFolderPath.length),
          dirPath: filePath,
          id: fileName,
          extension: fileExtension,
          originalName: fileNameWithExtension,
        };
      })
      .filter((f) => ACCEPTED_EXTENSIONS.includes(f.extension.toLowerCase()));

    setDisplayFiles(filesToDisplay);
  };

  // If there's already a link where the user has clicked, they're "editing",
  // otherwise the menu has been brought up to add a new link

  // When bringing up the Popper of the `ControlledBubbleMenu` and using
  // autoFocus on the TextField elements, it is causing a scroll jump as
  // described here https://github.com/mui-org/material-ui/issues/16740. (It
  // seems the fix that was merged for that has since been undone, as the popper
  // styles now using `absolute` positioning again.) So we'll focus on the
  // appropriate input with `useEffect` below instead.
  useEffect(() => {
    // We'll auto-focus on the text input if (a) it's not a new link, or (b)
    // it's a new link and they do not have some initial text already (e.g.,
    // they brought up the link menu with some text selected already). Otherwise
    // well focus on the href input.
    hrefRef.current?.focus();
  }, []);

  // If the user presses escape, we should cancel
  useKeyDown('Escape', onCancel);

  // const formatHref = useCallback(() => {
  //   if (!hrefRef.current) {
  //     return;
  //   }

  //   // Parse what the user typed in, and add a protocol if they typed in a value
  //   // but didn't include a protocol. (This also includes mailto and tel, since
  //   // they are also valid for `href`
  //   // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-href and
  //   // Tiptap has builtin autolink support for email address conversion to
  //   // mailto.) This is what Slack does, and seems like a reasonable behavior to
  //   // ensure it's a valid URL (e.g. if someone types "example.com", we should
  //   // accept it and treat it as "http://example.com", not a relative path on the
  //   // current site). It also allows the value to pass browser-builtin
  //   // `type="url"` validation.
  //   let currentHrefValue = hrefRef.current.value.trim();
  //   if (
  //     currentHrefValue &&
  //     !currentHrefValue.startsWith('http://') &&
  //     !currentHrefValue.startsWith('https://') &&
  //     !currentHrefValue.startsWith('mailto:') &&
  //     !currentHrefValue.startsWith('tel:')
  //   ) {
  //     currentHrefValue = `http://${currentHrefValue}`;
  //   }
  //   // URL-encode any characters that wouldn't be valid. We use `encodeurl`
  //   // instead of the builtin `encodeURI` so that if there are any
  //   // already-encoded sequences, they're not double-encoded and thus broken.
  //   // (Useful for instance when a user pastes a URL into the form with complex
  //   // and already-encoded parameters.)
  //   setHrefValue(encodeurl(currentHrefValue));
  // }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <form
      onSubmit={(event) => {
        // Don't submit the form with a standard full-page request
        event.preventDefault();
        // Don't let this event propagate upwards in the React tree, to prevent
        // submitting any form the rich text editor is wrapped in
        // (https://github.com/sjdemartini/mui-tiptap/issues/105)
        event.stopPropagation();

        setIsSubmitting(true);
        const text = textRef.current?.value ?? '';
        const href = hrefRef.current?.value ?? '';
        onSave({ selectedFile });
        setIsSubmitting(false);
      }}
      autoComplete="off"
    >
      <Typography variant="h6">{'Upload from Repository'}</Typography>

      <Autocomplete
        sx={{ width: 300 }}
        disabled={isSubmitting}
        getOptionLabel={(option) => option.label}
        options={folders}
        onChange={(event, value) =>
          setSelectedFolderPath(value ? value.path : null)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={textRef}
            label={'Folder'}
            margin="normal"
            size="small"
            fullWidth
            required
          />
        )}
      />

      {displayFiles.length > 0 ? (
        <>
          <Typography variant="h6">{'Files'}</Typography>

          <Autocomplete
            autoHighlight
            options={displayFiles}
            getOptionLabel={(option) => option.label || []}
            onChange={(e, value) => {
              setSelectedFile(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={textRef}
                label={'File'}
                margin="normal"
                size="small"
                fullWidth
                required
              />
            )}
          />
        </>
      ) : (
        <Typography>
          {selectedFolderPath ? `No files for this folder.` : `Pick a folder.`}
        </Typography>
      )}

      {/* <TextField
        inputRef={hrefRef}
        value={hrefValue}
        onChange={(event) => setHrefValue(event.target.value)}
        disabled={isSubmitting}
        label={labels?.editLinkHrefInputLabel ?? 'Link'}
        margin="dense"
        size="small"
        type="url"
        onBlur={formatHref}
        onKeyDown={(event) => {
          // If the user is trying to submit the form directly from the href field, make
          // sure we first format what they entered (which will update it to allow it to
          // pass URL field validation)
          if (event.key === 'Enter') {
            formatHref();
          }
        }}
        fullWidth
        required
      /> */}

      <DialogActions sx={{ px: 0 }}>
        <Button onClick={onCancel} variant="outlined" size="small">
          {'Cancel'}
        </Button>

        <Button
          type="submit"
          color="primary"
          variant="outlined"
          size="small"
          disabled={isSubmitting}
        >
          {'Add'}
        </Button>
      </DialogActions>
    </form>
  );
};

export default UploadFromRepoBubbleMenuContent;
