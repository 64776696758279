import React from 'react';
import {
  startOfDay,
  endOfDay,
  startOfToday,
  endOfToday,
  format,
} from 'date-fns';
import { AimTypography } from '../atoms/AimTypography';
import { AimCalendar, calendarVariants } from './AimCalendar';

export const breakoutRoomEvent = ({ props, i18n }) => {
  const event = props.event;
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {event.title ? (
            <AimTypography variant={'h6'} margin={0}>
              {event.title}
            </AimTypography>
          ) : null}
          <AimTypography variant={event.title ? 'text' : 'h6'} margin={0}>
            {`${
              event.invitations.filter((r) => r.state === 'accepted').length
            } ${i18n.accepted}; ${
              event.invitations.filter((r) => r.state === 'pending').length
            } ${i18n.pending}`}
          </AimTypography>
          <AimTypography variant={'text'} margin={0}>
            {`${format(event.start, 'HH:mm')} - ${format(event.end, 'HH:mm')}`}
          </AimTypography>
        </div>
      </div>
    </div>
  );
};

export const AimCalendarOneDay = ({
  i18n,
  step = 15,
  timeslots = 1,
  day,
  events, //eventi del giorno
  onSelectEvent,
  selectedEvent,
  minSize,
  maxSize,
  scrollToFirst = true,
  variant = calendarVariants.BREAKOUT_ROOM,
  eventComponent = breakoutRoomEvent,
  name,
  selectable = false,
  onSelectSlot = null,
  style = { background: 'white', borderRadius: 5 },
  ...rest
}) => {
  const _events = events.map((e) => ({ ...e, resourceId: 'room' }));
  const _day = day
    ? {
        start: startOfDay(day),
        end: endOfDay(day),
      }
    : {
        start: startOfToday(),
        end: endOfToday(),
      };

  const eventRender = (props) => {
    return eventComponent(props);
  };

  return (
    <AimCalendar
      variant={variant}
      event={_day} //l'evento è in realtà una singola giornata (day)
      currentDay={day}
      sessions={_events} //sono gli eventi della giornata
      rooms={[
        {
          id: 'room',
          name: name || format(_day.start, 'dd-MM-yyyy'),
        },
      ]}
      selectable={selectable}
      timeslots={timeslots}
      step={step / 2}
      i18n={i18n}
      scrollToFirst={scrollToFirst}
      minSize={minSize}
      maxSize={maxSize}
      onSelectEvent={onSelectEvent}
      selectedEvent={selectedEvent}
      showToolbar={false}
      eventComponent={eventRender}
      style={style}
      onSelectSlot={onSelectSlot}
      {...rest}
    />
  );
};
