import React from 'react';

import { styled } from './../styled';
import { AimTypography } from './AimTypography';

import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const StyledAccordion = styled(Accordion)({
  boxShadow: 'none !important',
  margin: '10px 0px',
  '&:before': {
    display: 'none',
  },
});

export const CustomDivider = styled(Divider)({
  margin: '0 20px !important',
});

export const AimAccordion = ({
  title,
  children,
  hasDivider = true,
  titleVariant = 'h3',
  expandIcon,
  isOpen = false,
}) => {
  return (
    <StyledAccordion defaultExpanded={isOpen}>
      <AccordionSummary
        expandIcon={expandIcon || <ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          '&:before': {
            display: 'none',
          },
        }}
      >
        <div>
          <AimTypography
            variant={titleVariant}
            style={{ flex: 1 }}
            boxStyle={{
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            {title}
          </AimTypography>
        </div>
      </AccordionSummary>
      {hasDivider && <CustomDivider />}
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
