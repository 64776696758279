import React from 'react';
import { useFieldArray } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import Cell from './Cell';
import TotalColumn from './TotalColumn';

const RowBase = ({
  rowIdx,
  control,
  rowStyle,
  dummyCellStyle,
  children,
  errors,
  // handleCellUpdate,
  renderProp,
  totalColumns,
  matrix,
  totalColumnStyle,
}) => {
  const { fields: row } = useFieldArray({
    control,
    name: `data[${rowIdx}].items`,
    keyName: 'itemId',
  });

  return (
    <Grid
      key={`rowItem-${row[0].id}`}
      item
      xs={10}
      style={{ display: 'flex', flexDirection: 'row', ...rowStyle }}
    >
      {row.map((item, itemIdx) => (
        <Cell
          key={`row-cell-${item.id}`}
          {...{
            item,
            itemIdx,
            rowIdx,
            dummyCellStyle,
            children,
            control,
            errors,
            // handleCellUpdate,
            renderProp,
          }}
        />
      ))}
      {totalColumns?.length
        ? totalColumns.map((totalColumn, itemIdx) => (
            <TotalColumn
              key={`row-total-cell-${itemIdx}`}
              as={totalColumn.as}
              fn={totalColumn.fn}
              matrix={matrix}
              rowIdx={rowIdx}
              {...{ totalColumnStyle: totalColumnStyle }}
            />
          ))
        : null}
    </Grid>
  );
};

const Row = React.memo(RowBase, () => true);

export default Row;
