import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { groupBy, sortBy } from 'lodash';

import ListSubheader from '@material-ui/core/ListSubheader';
import ClearIcon from '@material-ui/icons/Clear';

import {
  AimTextFieldForm,
  AimSelect,
  AimSelectForm,
  AimSelectDynamic,
  AimSelectMenuItem,
  AimCheckboxDynamic,
  AimRadioDynamic,
  AimAutocompleteDynamic,
  AimIconButton,
} from './../atoms';
import { appState, constants } from '@aim/common';
import { translation } from './aimAdvancedFiltersGeneric/translation';
import { AimTypography } from '../atoms/AimTypography';
import { AimIconAndTextButton } from '../atoms/AimIconAndTextButton';

// fieldtype               condizione              risultato query
// -----------------------------------------------------------------------------
// boolean                 eq                      filter.query_string: 'text'
// boolean                 not_eq                  must_not.query_string: 'text'
// text                    eq                      filter.query_string: 'text'
// text                    not_eq                  must_not.query_string: 'text'
// text                    ct                      filter.query_string: '*text*'
// text                    not_ct                  filter.query_string: '*text*'
// date                    eq                      filter.query_string: 'text'
// date                    not_eq                  must_not.query_string: 'text'
// date                    gt                      filter.range: gt:'text'
// date                    lt                      filter.range: lt: 'text'
// date                    gte                     filter.range: gte: 'text'
// date                    lte                     filter.range: lte: 'text'
// time                    eq                      filter.query_string: 'text'
// time                    not_eq                  must_not.query_string: 'text'
// time                    gt                      filter.range: gt:'text'
// time                    lt                      filter.range: lt: 'text'
// time                    gte                     filter.range: gte: 'text'
// time                    lte                     filter.range: lte: 'text'
// number                  eq                      filter.query_string: 'text'
// number                  not_eq                  must_not.query_string: 'text'
// number                  gt                      filter.range: gt:'text'
// number                  lt                      filter.range: lt: 'text'
// number                  gte                     filter.range: gte: 'text'
// number                  lte                     filter.range: lte: 'text'

const compareCriteria = {
  boolean: [
    { label: 'equals', value: 'eq' },
    { label: 'not equals', value: 'not_eq' },
  ],
  standard: [
    { label: 'equals', value: 'eq' },
    { label: 'not equals', value: 'not_eq' },
    { label: 'contains', value: 'ct' },
    { label: 'not contains', value: 'not_ct' },
  ],
  select: [
    { label: 'equals', value: 'eq' },
    { label: 'not equals', value: 'not_eq' },
  ],
  number: [
    { label: 'equals', value: 'eq' },
    { label: 'not equals', value: 'not_eq' },
    { label: 'is greater than', value: 'gt' },
    { label: 'is greater than equal to', value: 'gte' },
    { label: 'is less than', value: 'lt' },
    { label: 'is less than equal to', value: 'lte' },
  ],
};

const formControlStyle = { width: 'calc(100% - 10px)' };

const renderFormComponent = {
  text: AimTextFieldForm,
  select: AimSelectDynamic,
  checkbox: AimCheckboxDynamic,
  date: AimTextFieldForm,
  time: AimTextFieldForm,
  number: AimTextFieldForm,
  multiselect: AimSelectDynamic,
  radio: AimRadioDynamic,
  selectautocomplete: AimAutocompleteDynamic,
};

const variants = {
  text: 'grey',
  select: 'grey',
  checkbox: 'grey',
  date: 'grey',
  number: 'grey',
  multiselect: 'grey',
  radio: 'grey',
  selectautocomplete: 'grey',
  upload: 'grey',
};

const AimAdvancedFiltersBase = ({
  filterDefinitions = [],
  filterDefinitionsOption,
  onFormWatchEvent,
  filterSectionName,
  intl,
  isDebug = false,
  i18n: labels,
}) => {
  const nextFilterDefinitionOptions =
    filterDefinitionsOption || filterDefinitions;
  const i18n = translation.advancedFilters(intl);

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    reset,
    errors,
  } = useForm({
    defaultValues: {
      filters: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
    keyName: 'keyId',
  });

  const data = watch('filters');

  useEffect(() => {
    const queryBoolInitObj = {
      filter: [],
      must_not: [],
    };

    const queryBoolObj = data
      .filter(
        (item) =>
          item[item.id].fieldType ===
            constants.advancedFiltersFieldTypes.STANDARD ||
          //TO PREVENT MISSING FIELD TYPE
          item[item.id].fieldType === null
      )
      .filter((item) => item[item.id].criteria)
      .reduce(makeRootBoolObj, queryBoolInitObj);

    data
      .filter(
        (item) =>
          item[item.id].fieldType === constants.advancedFiltersFieldTypes.CUSTOM
      )
      .filter((item) => item[item.id].criteria)
      .reduce(makeNestedCustomFieldsBoolObj, queryBoolInitObj);

    const nestedFields = data
      .filter(
        (item) =>
          item[item.id].fieldType === constants.advancedFiltersFieldTypes.NESTED
      )
      .filter((item) => item[item.id].criteria);
    const groupedNestedFieldsObj = groupBy(
      nestedFields,
      (item) => item[item.id].path
    );
    Object.entries(groupedNestedFieldsObj).reduce(
      makeNestedFitlersBoolObj,
      queryBoolInitObj
    );
    // ).reduce(([key, items]) => makeNestedFitlersBoolObj(key, items));

    // queryBoolObj.filter = [...queryBoolObj.filter, ...nestedFieltersArray];

    onFormWatchEvent &&
      onFormWatchEvent(data.filter((item) => item[item.id].criteria));

    const values = getValues();
    const nextFormState = { filters: [], ...values };
    if (nextFormState.filters?.length) {
      appState.advancedFilterState.set({
        name: filterSectionName,
        filter: queryBoolObj,
        formState: nextFormState,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!filterDefinitions.length) return;

    const formState = appState.advancedFilterState.get(
      `${filterSectionName}FormState`
    );

    const filters = formState.filters.reduce((res, curr) => {
      const filterDef = filterDefinitions.find((fd) => fd.id === curr.id);
      const label =
        typeof filterDef.label === 'function'
          ? filterDef.label(labels)
          : filterDef.label;
      const section =
        typeof filterDef.section === 'function'
          ? filterDef.section(labels)
          : filterDef.section;
      const mapped = { ...filterDef, label, section };
      console.log('mapped', mapped);
      return [
        ...res,
        {
          ...curr,
          [curr.id]: {
            ...curr[curr.id],
            ...mapped,
          },
        },
      ];
    }, []);

    reset({ filters });
  }, [filterDefinitions]);

  const createField = (field, idx) => {
    let controlType = field[field.id].controlType.toLowerCase();
    //renderizziamo le checkbox come radio true false
    if (controlType === 'checkbox') {
      controlType = 'radio';
      field[field.id].options = [
        { label: i18n.yes, value: 'true' },
        { label: i18n.no, value: 'false' },
      ];
      field[field.id].defaultValue = 'true';
    }
    console.log('controlType pre', controlType, field);
    controlType =
      controlType === 'multiselect'
        ? 'select'
        : controlType === 'autocompletecity' ||
          controlType === 'autocompletecountries'
        ? 'text'
        : controlType;

    console.log('controlType', controlType, field);
    const variant = variants[controlType];
    const { value, ...fieldRest } = field[field.id];
    const CustomComponent = renderFormComponent[controlType]({
      control,
      errors,
      register,
      setValue,
      getValues,
      variant,
      textfieldVariant: variant,
      selectVariant: variant,
      formControlStyle,
      ...fieldRest,
      defaultValue: value,
      type: controlType,
      name: `filters[${idx}].${field.id}.value`,
      intl,
    });

    const criteria =
      controlType === 'checkbox' || controlType === 'radio'
        ? compareCriteria.boolean
        : controlType === 'select'
        ? compareCriteria.select
        : controlType !== 'number' &&
          controlType !== 'date' &&
          controlType !== 'time'
        ? compareCriteria.standard
        : compareCriteria.number;

    const criteriaSelector =
      controlType === 'number' ||
      controlType === 'date' ||
      controlType === 'time'
        ? 'number'
        : 'standard';

    return (
      <div
        key={field.keyId}
        style={{
          backgroundColor: '#fff',
          borderRadius: 4,
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <AimSelectForm
          control={control}
          errors={errors}
          label={`${field[field.id].label} criteria`}
          displayLabel
          name={`filters[${idx}].${field.id}.criteria.${criteriaSelector}`}
          formControlStyle={formControlStyle}
          defaultValue={field[field.id]?.criteria?.[criteriaSelector] || 'eq'}
        >
          {criteria.map((item, idx) => (
            <AimSelectMenuItem key={`${item.value}-${idx}`} value={item.value}>
              {item.label}
            </AimSelectMenuItem>
          ))}
        </AimSelectForm>
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.fieldType`}
          defaultValue={field[field.id].fieldType}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.name`}
          defaultValue={field[field.id].name || field[field.id].id}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.module`}
          defaultValue={field[field.id].module}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].id`}
          defaultValue={field.id}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.subPath`}
          defaultValue={field[field.id].subPath}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.path`}
          defaultValue={field[field.id].path}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.label`}
          defaultValue={field[field.id].label}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.options`}
          defaultValue={field[field.id].options}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.controlType`}
          defaultValue={field[field.id].controlType}
        />
        <Controller
          control={control}
          as={<input />}
          type="hidden"
          name={`filters[${idx}].${field.id}.section`}
          defaultValue={field[field.id].section}
        />

        {React.cloneElement(CustomComponent)}
      </div>
    );
  };

  const handleRemove = (label, module) => {
    const indexes = [];
    fields.forEach((field, idx) => {
      if (
        field[field.id].module === module &&
        field[field.id].label === label
      ) {
        indexes.push(idx);
      }
    });
    remove(indexes);
  };

  const makeQueryStringCondition = (
    key,
    value = '',
    withWildcards = false
  ) => ({
    query_string: {
      default_field: key,
      query: withWildcards ? `*${value.trim()}*` : `"${value.trim()}"`,
    },
  });

  const makeRangeCondition = (key, criteria, value) => ({
    range: {
      [key]: {
        [criteria]: value,
      },
    },
  });

  const makeBoolObj = (key, item, boolObj, isNested, isMustNot) => {
    // se è un campo nested e stiamo applicando una condizione di must_not, non possiamo mettere filter ma dobbiamo mettere should
    // il filter è un array di condizioni che devono essere tutte soddisfatte, ma essendo dentro un must_not di un nested deve applicare ogni condizione separatamente
    // ne basta una valida per escludere il documento dalla ricerca
    const filterField = item[item.id];
    const value = filterField.value?.value || filterField.value;
    const filterArrayCondition = (
      filterField.criteria.standard || filterField.criteria.number
    )?.startsWith('not_')
      ? boolObj.must_not
      : boolObj.filter;
    if (
      filterField.criteria.standard === 'eq' ||
      filterField.criteria.standard === 'not_eq' ||
      filterField.criteria.number === 'eq' ||
      filterField.criteria.number === 'not_eq'
    ) {
      filterArrayCondition.push(makeQueryStringCondition(key, value));
    } else if (
      filterField.criteria.standard === 'ct' ||
      filterField.criteria.standard === 'not_ct'
    ) {
      filterArrayCondition.push(makeQueryStringCondition(key, value, true));
    } else if (filterField.criteria.number) {
      filterArrayCondition.push(
        makeRangeCondition(key, filterField.criteria.number, value)
      );
    } else {
      throw new Error('Criteria not found');
    }
  };

  const makeRootBoolObj = (prev, item) => {
    const key = item[item.id]?.name || item.id;
    const prefix = item[item.id]?.path
      ? `data.${item[item.id].path}.${key}`
      : `data.${key}`;

    makeBoolObj(prefix, item, prev);
    return prev;
  };

  const makeNestedCustomFieldsBoolObj = (prev, item) => {
    const filterField = item[item.id];
    const value = filterField.value?.value || filterField.value;
    const key = filterField.criteria.number
      ? 'data.fieldValues.numericValue'
      : 'data.fieldValues.value';

    const boolObj = {
      filter: [
        makeQueryStringCondition('data.fieldValues.fieldDefinitionId', item.id),
      ],
      must_not: [],
    };

    const filterArrayCondition = (
      filterField.criteria.standard || filterField.criteria.number
    )?.startsWith('not_')
      ? prev.must_not
      : prev.filter;

    if (
      filterField.criteria.standard === 'eq' ||
      filterField.criteria.standard === 'not_eq' ||
      filterField.criteria.number === 'eq' ||
      filterField.criteria.number === 'not_eq'
    ) {
      boolObj.filter.push(makeQueryStringCondition(key, value));
    } else if (
      filterField.criteria.standard === 'ct' ||
      filterField.criteria.standard === 'not_ct' ||
      filterField.criteria.number === 'ct' ||
      filterField.criteria.number === 'not_ct'
    ) {
      boolObj.filter.push(makeQueryStringCondition(key, value, true));
    } else if (filterField.criteria.number) {
      boolObj.filter.push(
        makeRangeCondition(key, filterField.criteria.number, value)
      );
    } else {
      throw new Error('Criteria not found');
    }
    filterArrayCondition.push({
      nested: {
        path: `data.fieldValues`,
        query: {
          bool: boolObj,
        },
      },
    });
    return prev;
  };

  const makeNestedFitlersBoolObj = (prev, [key, items]) => {
    const groupedByPrefix = groupBy(
      items,
      (item) =>
        `data.${key}${
          item[item.id].subPath ? `.${item[item.id].subPath}.` : `.`
        }${item[item.id].name}`
    );

    const boolObjs = Object.entries(groupedByPrefix).reduce(
      (prev, [prefix, prefixItems]) => {
        return prefixItems.flatMap((i) => {
          return prev.map((p) => {
            const nextBoolObj = {
              filter: [...p.filter],
              must_not: [...p.must_not],
            };
            makeBoolObj(prefix, i, nextBoolObj, false);
            return nextBoolObj;
          });
        });
        // } else{
        //  return  prev.map((p) => {
        //     makeBoolObj(prefix, item, p, false);
        //     return p;
        //   });
        //   makeBoolObj(prefix, item, boolObj, true);
        // }
      },
      [
        {
          filter: [],
          must_not: [],
        },
      ]
    );

    console.log('boolObjs', boolObjs);

    //divido le condizioni nested in filter e must_not

    boolObjs
      .filter((boolObj) => boolObj.filter.length)
      .forEach((boolObj) => {
        prev.filter.push({
          nested: {
            path: `data.${key}`,
            query: {
              bool: { filter: boolObj.filter },
            },
          },
        });
      });

    boolObjs
      .filter((boolObj) => boolObj.must_not.length)
      .forEach((boolObj) => {
        prev.must_not.push({
          nested: {
            path: `data.${key}`,
            query: {
              bool: { should: boolObj.must_not },
            },
          },
        });
      });

    console.log('prev', prev);

    return prev;
  };
  return (
    <>
      <form>
        <AimSelect
          formControlStyle={formControlStyle}
          label="Filter list"
          selectPlaceholder="select a filter to use"
          displayLabel
          selectVariant="grey"
          disableSorting
          onChange={(e) => {
            const nextFilter = nextFilterDefinitionOptions.find(
              (f) => f.id === e.target.value
            );
            console.log('nextFilter', nextFilter);
            const label =
              typeof nextFilter.label === 'function'
                ? nextFilter.label(labels)
                : nextFilter.label;
            const section =
              typeof nextFilter.section === 'function'
                ? nextFilter.section(labels)
                : nextFilter.section;

            append({
              id: nextFilter.id,
              [nextFilter.id]: { ...nextFilter, label, section },
            });
          }}
          // onChange={(e) =>
          //   setSelectedFilter(
          //     filterDefinitions.find((f) => f.id === e.target.value)
          //   )
          // }
          // value={selectedFilter ? selectedFilter.id : null}
          value=""
        >
          {sortBy(
            Object.entries(
              groupBy(
                nextFilterDefinitionOptions.map((fd) => ({
                  ...fd,
                  section: fd.section(labels),
                  label: fd.label(labels),
                })),
                (item) => item.section
              )
            ),
            ([section]) => section
          ).flatMap(
            ([section, items], idx) =>
              console.log('items', sortBy(items, 'label')) || [
                <ListSubheader key={section}>
                  {section?.toUpperCase() || section}
                </ListSubheader>,
                ...sortBy(items, 'label').map((item) => (
                  <AimSelectMenuItem
                    paddingLeft={30}
                    key={`${item.id}-${idx}`}
                    value={item.id}
                  >
                    {item.label}
                  </AimSelectMenuItem>
                )),
              ]
          )}
        </AimSelect>
        <ul style={{ padding: 0 }}>
          {Object.entries(
            groupBy(
              fields.map((f, idx) => ({ ...f, idx })),
              (item) => item[item.id].module
            )
          ).map(([module, items]) => (
            <div
              key={module}
              style={{
                display: 'flex',
                flex: 1,
                background: 'white',
                flexDirection: 'column',
                borderRadius: 4,
                marginLeft: 10,
                padding: 12,
              }}
            >
              <AimTypography>{module?.toUpperCase() || module}</AimTypography>
              <AimTypography variant="textBold"> Active Filters:</AimTypography>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                {[...new Set(items.map((item) => item[item.id].label))]
                  .sort()
                  .map((label) => (
                    <AimIconAndTextButton
                      key={label}
                      variant={'yellow'}
                      text={label}
                      isIconLeft
                      onClick={() => {
                        handleRemove(label, module);
                        // pushNavigation(br, 'edit');
                      }}
                    >
                      <ClearIcon />
                    </AimIconAndTextButton>
                  ))}
              </div>
            </div>
          ))}
          {fields
            .map((f, idx) => ({ ...f, idx }))
            .map((item) => (
              <li
                key={item.idx}
                style={{
                  listStyle: 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  // maxWidth: 800,
                  flex: 1,
                }}
              >
                {createField(item, item.idx)}
                <AimIconButton
                  style={{ margin: 8 }}
                  variant="redFill"
                  onClick={() => {
                    fields.length === 1 &&
                      appState.advancedFilterState.reset(filterSectionName);
                    remove(item.idx);
                  }}
                  aria-label="delete filter"
                >
                  <ClearIcon />
                </AimIconButton>
              </li>
            ))}
        </ul>
        {/* <AimIconButton
          disabled={!selectedFilter}
          variant="greenFill"
          onClick={() => {
            append({
              id: selectedFilter.id,
              [selectedFilter.id]: selectedFilter,
            });
          }}
          aria-label="add filter"
        >
          <AddIcon />
        </AimIconButton> */}
      </form>
      {isDebug && <DevTool control={control} />}
    </>
  );
};

export const AimAdvancedFiltersGeneric = React.memo(AimAdvancedFiltersBase);
