const translation = {
  paymentOptions: (intl) => ({
    page: {
      back: {
        tooltip: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Go back',
        }),
        button: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'back',
        }),
      },
      title: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Choose the payment method',
      }),
      bankTransfer: {
        label: intl.formatMessage({
          description: 'payemnt by bank transfer',
          defaultMessage: 'Bank Transfer',
        }),
      },
      creditCard: {
        label: intl.formatMessage({
          description: 'payment by credit card',
          defaultMessage: 'Credit Card',
        }),
      },
    },
  }),
  payment: (intl) => ({
    page: {
      title: {
        cart: intl.formatMessage({
          description: 'cart title text',
          defaultMessage: 'Cart',
        }),
        checkout: intl.formatMessage({
          description: 'checkout title text',
          defaultMessage: 'Purchase',
        }),
      },
      back: {
        cart: {
          label: intl.formatMessage({
            description: 'back to payment options',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to payment options',
            defaultMessage: 'Payment options',
          }),
        },
        checkout: {
          label: intl.formatMessage({
            description: 'back to payment',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to payment',
            defaultMessage: 'Payment',
          }),
        },
      },
      service: {
        label: intl.formatMessage({
          description: 'service',
          defaultMessage: 'Service',
        }),
      },
      servicesIncluded: {
        label: intl.formatMessage({
          description: 'services included label',
          defaultMessage: 'services included',
        }),
      },
      price: {
        label: intl.formatMessage({
          description: 'price label',
          defaultMessage: 'Price(€)',
        }),
      },
      quantity: {
        label: intl.formatMessage({
          description: 'quantity label',
          defaultMessage: 'Quantity',
        }),
      },
      unitaryPrice: {
        label: intl.formatMessage({
          description: 'unitary price label',
          defaultMessage: 'Unitary Price (€)',
        }),
      },
      ticket: {
        label: intl.formatMessage({
          description: 'ticket label',
          defaultMessage: 'Ticket',
        }),
      },
      vatDescr: {
        label: intl.formatMessage({
          description:
            'VAT may change depending on the billing information label',
          defaultMessage:
            '*VAT may change depending on the billing information',
        }),
      },
      discountCode: {
        label: intl.formatMessage({
          description: 'Insert Discount code label',
          defaultMessage: 'Insert Discount code',
        }),
      },
      discount: {
        label: intl.formatMessage({
          description: 'Discount label',
          defaultMessage: 'Discount (€)',
        }),
      },
      bankTransferIndication: {
        label: intl.formatMessage({
          description: 'bank transfer indication label',
          defaultMessage: 'Bank transfer indication',
        }),
      },
      iban: {
        label: intl.formatMessage({
          description: 'IBAN label',
          defaultMessage: 'IBAN',
        }),
      },
      reasonForPayment: {
        label: intl.formatMessage({
          description: 'Reason for payment label',
          defaultMessage: 'Reason for payment',
        }),
      },
      addressedTo: {
        label: intl.formatMessage({
          description: 'addressed to',
          defaultMessage: 'Addressed to',
        }),
      },
      total: {
        label: intl.formatMessage({
          description: 'total label',
          defaultMessage: 'Total (€)',
        }),
      },
      totalToPay: {
        label: intl.formatMessage({
          description: 'total to pay label',
          defaultMessage: 'Total to pay (€)',
        }),
      },
      buy: {
        label: intl.formatMessage({
          description: 'buy label',
          defaultMessage: 'buy',
        }),
      },
      // messages: {
      //   noBookings: {
      //     label: intl.formatMessage({
      //       description: 'no bookings message',
      //       defaultMessage: 'Cart is empty',
      //     }),
      //   },
      // },
      errors: {
        loadBookings: {
          label: intl.formatMessage({
            description: 'load bookings error',
            defaultMessage: 'Error loading bookings list',
          }),
        },
        product: {
          label: intl.formatMessage({
            description: 'product error',
            defaultMessage: 'Error during creating product',
          }),
        },
        initPayment: {
          label: intl.formatMessage({
            description: 'init payment error',
            defaultMessage: 'Error during init payment',
          }),
        },
      },
    },
    checkoutTable: {
      buttons: {
        confirm: {
          label: intl.formatMessage({
            description: 'confirm checkout button',
            defaultMessage: 'Confirm',
          }),
        },
      },
      columns: {
        service: {
          label: intl.formatMessage({
            description: 'service column',
            defaultMessage: 'Service',
          }),
        },
        quantity: {
          label: intl.formatMessage({
            description: 'quantity column',
            defaultMessage: 'Quantity',
          }),
        },
        amount: {
          label: intl.formatMessage({
            description: 'amount column',
            defaultMessage: 'Amount (€)',
          }),
        },
      },
      summary: {
        netTotal: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Net Total (€)',
          }),
        },
        on: {
          label: intl.formatMessage({
            description: 'on label',
            defaultMessage: 'on',
          }),
        },
        vat: {
          label: intl.formatMessage({
            description: 'vat summary',
            defaultMessage: 'VAT*',
          }),
        },
        total: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Total (€)',
          }),
        },
      },
    },
    checkoutAlerts: {
      eventIsOver: {
        label: intl.formatMessage({
          description: "the event is over, you can't confirm the purchase",
          defaultMessage: "The event is over, you can't confirm the purchase",
        }),
      },
    },
  }),
  paymentSuccess: (intl) => ({
    goToPersonalArea: intl.formatMessage({
      description: 'ticket purchase success go to personal area button',
      defaultMessage: 'Go to personal area',
    }),
    goToServices: intl.formatMessage({
      description: 'ticket purchase success go to services button',
      defaultMessage: 'Go to services',
    }),
    paymentConfirmMessageConfirm: intl.formatMessage({
      description: 'tickets payment confirm message confirm',
      defaultMessage: 'Thanks, the procedure was successful!',
    }),
    paymentConfirmMessageTitle: intl.formatMessage({
      description: 'tickets payment confirm message title',
      defaultMessage:
        'Remember that you can review your information when you want within your personal area.',
    }),
    paymentConfirmMessageSubtitle: intl.formatMessage({
      description: 'tickets payment confirm message subtitle',
      defaultMessage:
        'It the ticket includes the partecipation to other events, you will find the voucher in your personal area.',
    }),
    orderNumber: intl.formatMessage({
      description: 'order number title',
      defaultMessage: 'Your order number is: ',
    }),
  }),
};

export default translation;
