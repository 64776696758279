import React from 'react';

import useI18n from './i18n';

import Tooltip from '@material-ui/core/Tooltip';

import ClearIcon from '@material-ui/icons/Clear';
import { AimTable, AimTableCell, AimTableRow } from '../../bundles/AimTable';
import { AimIconButton } from '../../atoms/AimIconButton';
import { theme } from '../../../theme';

const ImportFromFileTable = ({
  intl,
  participantsWithErrors,
  onClickRowDelete,
}) => {
  if (participantsWithErrors?.length === 0) return null;
  // Hooks
  const i18n = useI18n.importFromFile(intl).table;

  // States

  // Columns
  const headCells = [
    {
      id: 'idx',
      numeric: false,
      disablePadding: false,
      label: i18n.columns.idx,
    },
    {
      id: 'givenName',
      numeric: false,
      disablePadding: false,
      label: i18n.columns.givenName,
    },
    {
      id: 'familyName',
      numeric: false,
      disablePadding: false,
      label: i18n.columns.familyName,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: i18n.columns.email,
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: false,
      label: i18n.columns.notes,
    },
    {
      numeric: false,
      disablePadding: true,
      label: '',
      id: 'actions',
    },
  ];

  // Row definition
  const Row = ({ row }) => {
    return (
      <>
        <AimTableRow hover>
          <AimTableCell>{row.aim_excel_index + 1}</AimTableCell>
          <AimTableCell>{row.givenName}</AimTableCell>
          <AimTableCell>{row.familyName}</AimTableCell>
          <AimTableCell>{row.email}</AimTableCell>
          <AimTableCell>{row.aim_failed_import_reason}</AimTableCell>
          <AimTableCell>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title={i18n.buttons.delete}>
                <AimIconButton
                  aria-label="delete"
                  variant="redFill"
                  onClick={() => onClickRowDelete && onClickRowDelete(row)}
                >
                  <ClearIcon htmlColor={theme.colors.secondary.red} />
                </AimIconButton>
              </Tooltip>
            </div>
          </AimTableCell>
        </AimTableRow>
      </>
    );
  };

  return (
    <AimTable
      hideToolbar
      title={i18n.buttons.view}
      headCells={headCells}
      rows={participantsWithErrors.sort(
        (a, b) => a.aim_excel_index - b.aim_excel_index
      )}
      notCheckable={true}
      tableContainerMaxHeight={'calc(85vh - 320px)'}
      rowKey="aim_excel_index"
    >
      <Row />
    </AimTable>
  );
};

export default ImportFromFileTable;
