import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';

import { AimTypography, AimIconButton } from './../atoms';
import { styled } from './../styled';
import { theme } from './../../theme';
import { appState, constants } from '@aim/common';

const StyledCard = styled(Card)(({ cardVariant }) => ({
  ...variants[cardVariant],
  padding: 16,
  margin: 10,
}));

const StyledCardContent = styled(CardContent)({
  padding: '0px !important',
});

const variantsFRONTEND = {
  primary: {
    backgroundColor: `${theme.colors.greyScale.white} !important`,
  },
  secondary: {
    backgroundColor: `${theme.colors.greyScale.backgroundGrey} !important`,
  },
};

const variantsBACKEND = {
  white: {
    backgroundColor: `${theme.colors.greyScale.white} !important`,
  },
  grey: {
    backgroundColor: `${theme.colors.greyScale.backgroundGrey} !important`,
  },
};

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

// TODO delete when restyle is completed
const variantMap = {
  white: 'primary',
  grey: 'secondary',
};

const variantTemplate = appState.template.getValue();

const getVariant = ({ variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[variantTemplate];
    return template[variant] || template[variantMap[variant]];
  }
};

const getIconVariant = () => {
  variantTemplate === constants.Templates.BACKEND ? 'lightBlueFill' : 'primary';
};

export const AimWallCard = ({
  label,
  onContainerClick,
  onIconClick,
  disableClick,
  icon = <Visibility />,
  minWidth,
  cardVariant = 'white',
}) => {
  const variant = getVariant({ variant: cardVariant });
  return (
    <div
      style={{ minWidth: minWidth || '100%' }}
      onClick={() => !disableClick && onContainerClick && onContainerClick()}
    >
      <StyledCard cardVariant={variant}>
        <StyledCardContent>
          <Grid container>
            <Grid item xs={10}>
              <AimTypography
                style={{
                  fontWeight: 500,
                  fontSize: '1.125rem',
                }}
              >
                {label}
              </AimTypography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <AimIconButton
                variant={getIconVariant()}
                onClick={(e) => {
                  e.stopPropagation();
                  onIconClick && onIconClick();
                }}
                disabled={disableClick}
              >
                {icon}
              </AimIconButton>
            </Grid>
          </Grid>
        </StyledCardContent>
      </StyledCard>
    </div>
  );
};
