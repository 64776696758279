import { appState } from '@aim/common';
import { useState, useEffect } from 'react';

export const useZendesk = () => {
  const [zendeskData, setZendeskData] = useState();
  const [prevZendeskData, setPrevZendeskData] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    const subscription = appState.user.subscribe((user) => {
      let data = {};
      if (user?.userAndParticipation?.participation) {
        data = {
          name:
            user.userAndParticipation.givenName &&
            user.userAndParticipation.familyName
              ? `${user.userAndParticipation.givenName} ${user.userAndParticipation.familyName}`
              : '',
          email: user.userAndParticipation.email,
          eventName: user.userAndParticipation.participation.event.name,
          eventId: user.userAndParticipation.participation.event.id,
        };
      }
      setZendeskData(data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      (!isFirstRender && zendeskData.email === prevZendeskData.email) ||
      !zendeskData
    )
      return;
    setIsFirstRender(false);
    const { name, email, eventName, eventId } = zendeskData;
    window.zESettings = {
      webWidget: {
        contactForm: {
          fields: [
            {
              id: 360019553940,
              prefill: {
                '*': eventName,
              },
            },
            {
              id: 360019553980,
              prefill: {
                '*': eventId,
              },
            },
          ],
        },
      },
    };

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.async = true;
    script.onload = () => {
      window.zE('webWidget', 'reset');
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            fields: [
              {
                id: 360019553940,
                prefill: {
                  '*': eventName,
                },
              },
              {
                id: 360019553980,
                prefill: {
                  '*': eventId,
                },
              },
            ],
          },
        },
      });
      window.zE('webWidget', 'prefill', {
        name: {
          value: name,
          readOnly: name ? true : false,
        },
        email: {
          value: email,
          readOnly: email ? true : false,
        },
      });
      window.zE('webWidget', 'setLocale', appState.locale.getValue());
      window.zE('webWidget', 'show');
    };
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=94d50ee3-12c0-483c-a086-b42bb27dcf1f';
    const existingDomElem = document.getElementById('ze-snippet');
    existingDomElem.replaceWith(script);
    setPrevZendeskData(zendeskData);
  }, [zendeskData]);

  return zendeskData;
};
