import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';

import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';

import { AimTypography } from './../atoms/AimTypography';
import { AimTable, AimTableRow, AimTableCell } from './AimTable';

import { styled } from './../styled';
import { theme } from './../../theme';
import { utcToZonedTime } from 'date-fns-tz';
import { appState } from '@aim/common';

export const NegativeBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    color: 'transparent',
    backgroundColor: theme.colors.secondary.red,
    left: '50%',
  },
});

export const AimPurchasesTable = ({ purchases, i18n, tableHeight }) => {
  // Columns
  const headCells = [
    {
      id: 'purchaseCode',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.purchaseCode,
    },
    {
      id: 'sponsorName',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.sponsorName,
    },
    {
      id: 'package',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.package,
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.date,
    },
    {
      id: 'paymentMethod',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.paymentMethod,
    },
    {
      numeric: false,
      disablePadding: true,
      label: '',
      id: 'vat',
    },
    {
      id: 'paidFee',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.paidFee,
    },
    {
      id: 'totalFee',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.totalFee,
    },
    {
      numeric: false,
      disablePadding: true,
      label: '',
      id: 'actions',
    },
  ];

  //States
  const [displayPurchases, setDisplayPurchases] = useState([]);
  const [currentTimezone, setCurrentTimezone] = useState();

  useEffect(() => {
    const subscriptionEvent = appState.eventInfo.subscribe((info) => {
      setCurrentTimezone(info.timezone);
    });
    return () => {
      subscriptionEvent.unsubscribe();
    };
  }, []);

  //Effects
  useEffect(() => {
    if (purchases) setDisplayPurchases(purchases);
  }, [purchases]);

  const customDateFormat = (date) =>
    format(utcToZonedTime(new Date(date), currentTimezone), 'dd/MM/yyyy');

  // Row definition
  const Row = ({ row, handleSelectedClick }) => {
    return (
      <>
        <AimTableRow
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
          // hover
          onClick={(event) => handleSelectedClick(event, row)}
        >
          <AimTableCell>{row.purchaseId}</AimTableCell>
          <AimTableCell>{row?.sponsor?.name || ''}</AimTableCell>
          <AimTableCell>{row?.packageName || ''}</AimTableCell>
          <AimTableCell>{customDateFormat(row.date)}</AimTableCell>
          <AimTableCell>{row.paymentTypeLabel}</AimTableCell>
          <AimTableCell />
          <AimTableCell>{Number(row.paidFee).toFixed(2)}€</AimTableCell>
          <AimTableCell>
            {row.hasPaymentError ? (
              <>
                {Number(row.paidFee).toFixed(2)}€
                <Tooltip
                  title={`Payment Error ${
                    row.payment?.description
                      ? ': ' + row.payment?.description
                      : ''
                  }`}
                >
                  <NegativeBadge badgeContent={'-'} />
                </Tooltip>
              </>
            ) : (
              `${Number(row.totalFee).toFixed(2)}€`
            )}
          </AimTableCell>
        </AimTableRow>
        {row.hasPaymentError ? null : (
          <>
            {/* <Divider /> */}
            <AimTableRow>
              <AimTableCell>
                <AimTypography margin={0} variant="columnHeader">
                  {i18n.table.subColumns.service}
                </AimTypography>
              </AimTableCell>
              <AimTableCell />
              <AimTableCell />
              <AimTableCell>
                <AimTypography margin={0} variant="columnHeader">
                  {i18n.table.subColumns.instance}
                </AimTypography>
              </AimTableCell>
              <AimTableCell>
                <AimTypography margin={0} variant="columnHeader">
                  {i18n.table.subColumns.quantity}
                </AimTypography>
              </AimTableCell>
              <AimTableCell>
                <AimTypography margin={0} variant="columnHeader">
                  {i18n.table.subColumns.vat}
                </AimTypography>
              </AimTableCell>
              <AimTableCell>
                <AimTypography margin={0} variant="columnHeader">
                  {i18n.table.subColumns.netPrice}
                </AimTypography>
              </AimTableCell>
              <AimTableCell>
                <AimTypography margin={0} variant="columnHeader">
                  {i18n.table.subColumns.finalPrice}
                </AimTypography>
              </AimTableCell>
            </AimTableRow>
            {row.buyOperations.items.map((i, idx) => (
              <AimTableRow key={idx}>
                <AimTableCell>{i.serviceType}</AimTableCell>
                <AimTableCell />
                <AimTableCell />
                <AimTableCell>{i.instance}</AimTableCell>
                <AimTableCell>{i.quantity}</AimTableCell>
                <AimTableCell>
                  {i.parsedUnitaryVatRate !== 'hidden'
                    ? `${Number(i.parsedUnitaryVatRate).toFixed(2)}%`
                    : ''}
                </AimTableCell>
                <AimTableCell>
                  {i.parsedUnitaryPrice !== 'hidden'
                    ? `${Number(i.parsedUnitaryPrice).toFixed(2)}€`
                    : ''}
                </AimTableCell>
                <AimTableCell>
                  {i.parsedFinalPrice !== 'hidden'
                    ? `${Number(i.parsedFinalPrice).toFixed(2)}€`
                    : ''}
                </AimTableCell>
              </AimTableRow>
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div style={{ flex: 1 }}>
        {displayPurchases.length === 0 ? (
          <AimTypography variant="h5">{i18n.table.noData}</AimTypography>
        ) : (
          <AimTable
            title={i18n.table.title}
            headCells={headCells}
            rows={displayPurchases}
            notCheckable={true}
            tableContainerMaxHeight={tableHeight}
            rowKey="id"
          >
            <Row />
          </AimTable>
        )}
      </div>
    </>
  );
};
