import React from 'react';
import Grid from '@material-ui/core/Grid';
import { AimTypography } from '@aim/components';

const TotalRowHeaderBase = ({ name, totalRowHeaderStyle, alignRowHeaders }) => (
  <Grid item xs={2}>
    <AimTypography
      noWrap
      variant="textBold"
      style={totalRowHeaderStyle}
      boxStyle={{ textAlign: alignRowHeaders }}
    >
      {name}
    </AimTypography>
  </Grid>
);

const TotalRowHeader = React.memo(TotalRowHeaderBase);

export default TotalRowHeader;
