import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';

import { AimGridFieldArray, arrayToMatrix } from './aimGrid/index';

const AimGridBase = (
  {
    title,
    subtitle,
    data,
    dataSettings,
    children,
    styles = {},
    onSubmit,
    onGetValuesFn,
    isDebug,
  },
  ref
) => {
  const originalDataWithIdsRef = useRef(null);

  if (!data || !data.length) return null;

  const onSubmitInner = (values) => {
    const resultItems = values.data.reduce(
      (res, curr) => [...res, ...curr.items],
      []
    );
    const nextValues = resultItems.map((resultItem) => {
      const originalObj = originalDataWithIdsRef.current.find(
        (nd) => nd.id === resultItem.id
      );
      return { ...originalObj, ...resultItem };
    });

    onSubmit && onSubmit(nextValues);
    return nextValues;
  };

  const { control, reset, errors, handleSubmit, getValues } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!onGetValuesFn) return;
    onGetValuesFn(() => onSubmitInner(getValues()));
  }, [getValues]);

  useEffect(() => {
    if (!reset) return;

    const { sortRowsBy, sortColumnsBy } = dataSettings;

    const originalDataWithIds = data.map((i) => ({
      ...i,
      id: i?.id || `generatedId-${nanoid()}`,
    }));

    originalDataWithIdsRef.current = originalDataWithIds;

    const dataMatrix = arrayToMatrix({
      data: originalDataWithIds,
      sortRowsBy,
      sortColumnsBy,
      isDebug,
    });

    reset({ data: dataMatrix });
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <AimGridFieldArray
        {...{
          title,
          subtitle,
          control,
          errors,
          dataSettings,
          styles,
          isDebug,
        }}
      >
        {children}
      </AimGridFieldArray>
      <input type="submit" hidden ref={ref} />
    </form>
  );
};

// const AimGridMemo = React.memo(AimGridFr, () => true);

const AimGrid = React.forwardRef(AimGridBase);

export { AimGrid };

/**
 * Build a AIM table page from a model.
 * @typedef {string} title - The string containing the title for the grid section.
 * @typedef {object} data - array of objects rapresenting the cells.
 * @typedef {function(values)} onSubmit - function to call when form will submit values.
 * @typedef {object} submitRef - React useRef object that will fire the form hidden submit input button.
 * @typedef {object} styles - object containing styles for the table.
 * @typedef {object={}} styles.panelStyle - object containing styles for the table panel.
 * @typedef {object={}} styles.rowStyle - object containing styles for the table row.
 * @typedef {object={}} styles.columnHeaderStyle - object containing styles for the table column header.
 * @typedef {object={}} styles.rowHeaderStyle - object containing styles for the table row header.
 * @typedef {object={}} styles.totalRowHeaderStyle - object containing styles for the table total row header.
 * @typedef {object={}} styles.totalRowStyle - object containing styles for the table total row.
 * @typedef {object|function(value)={}} styles.totalCellSyle - object containing styles for the single item of a total row or function that gives you the result of `dataSettings.totalRows[].fn` as parameter
 * @property {string=right} styles.alignColumnHeaders - The string containing the alignment of the column headers.
 * @property {string=right} styles.alignRowHeaders - The string containing the alignment of the row headers.
 * @property {object={ margin: 3 }} styles.dummyCellStyle - The object containing the styles for the dummy cell.
 * @typedef {Object} dataSettings - The model responsible for the data.
 * @property {object} dataSettings.dataMatrix - The object containing the data matrix rowsxitems.
 * @property {string} dataSettings.sortRowsBy - The string containing the property name for the sorting of the rows.
 * @property {string} dataSettings.sortColumnsBy - The string containing the property name for the sorting of the columns.
 * @property {string} dataSettings.headerFormatter - a function that formats the header value.
 * @property {object[]} dataSettings.totalRows - array of definitions for total rows
 * @property {string} dataSettings.totalRows[].header - string for the total row header title.
 * @property {function({column, columns, matrix})} dataSettings.totalRows[].fn - function to calculate value of the single item of the total row.
 * @typedef {AimTypography|AimInputForm} children - The react component to use for the grid items.
 * @return {ReactComponent}
 */
