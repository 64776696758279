import React from 'react';
import { styled } from '../styled';
import { theme } from '../../theme';
import { AimTypography } from './AimTypography';
import LinearProgress from '@material-ui/core/LinearProgress';

const CustomLinearProgress = styled(LinearProgress)({
  '& .MuiLinearProgress-barColorPrimary': {
    color: theme.colors.greyScale.grey3,
    backgroundColor: theme.colors.primary.yellow,
  },
});
const LinearProgressWithLabel = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100%', marginRight: 1 }}>
          <CustomLinearProgress variant="determinate" {...props} />
        </div>
        <div style={{ minWidth: 35 }}>
          <AimTypography variant="textBold">{`${Math.round(
            props.value
          )}%`}</AimTypography>
        </div>
      </div>
    </div>
  );
};
export const AimProgressIndicator = React.forwardRef(
  ({ total, completed, style, ...rest }, ref) => (
    <LinearProgressWithLabel
      value={((completed + 1) / (total + 1)) * 100}
      style={style}
      ref={ref}
      {...rest}
    />
  )
);
