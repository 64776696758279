import React from 'react';
import { styled } from './../styled';
import { AimTypography } from './AimTypography';

const HeaderList = styled('div')({
  display: 'flex',
  '&>*': {
    flex: 1,
  },
});

export const AimSimpleHeader = ({ headers, typographyMargin, ...rest }) => {
  return (
    <HeaderList {...rest}>
      {headers.map((header) => (
        <AimTypography variant="columnHeader" margin={typographyMargin}>
          {header}
        </AimTypography>
      ))}
    </HeaderList>
  );
};
